import React from "react";
import { Icon } from "@nubeteck/icons";
import { Button } from "primereact/button";
import { ConfirmDialog } from "@components";
import { Calendar } from "primereact/calendar";
import { CardTable, IDataTableColumnsProps } from "@nubeteck/prime";

import { IMonthlyClosing } from "../../interfaces";
import { useAccountingQuery, useAccountingMutation } from "../../hooks";

const MonthlyClosingPage = () => {
  const [visible, setVisible] = React.useState(false);
  const [selectedYear, setSelectedYear] = React.useState<Date | null>();
  const [selectedMonth, setSelectedMonth] = React.useState<Date | null>();
  const [anular, setAnular] = React.useState(false);
  const [status, setStatus] = React.useState<number>();
  const [selectMensualidad, setSelectMensualidad] = React.useState(0);
  const createCierreMensual = useAccountingMutation(
    "AccountingMovements",
    "createCierreMensual",
  );

  const changeStateCierreMensual = useAccountingMutation(
    "AccountingMovements",
    "changeStateCierreMensual",
  );

  const monthlyClosings = useAccountingQuery(
    "AccountingMovements",
    "getMesesCierreMensual",
  );

  const columns: IDataTableColumnsProps<IMonthlyClosing>[] = [
    {
      header: "Código",
      field: "CierreMensualId",
    },
    {
      header: "Año",
      sortable: true,
      field: "SucursalAnioFiscalAnio",
    },
    {
      header: "Mes",
      field: "MesNombre",
    },
    {
      header: "Estado",
      field: "EstadoNombre",
      //body: (data) => <StatusTag status={data.EstadoNombre ?? "Activo"} />,
    },
  ];

  const filteredData =
    monthlyClosings.data?.filter((item) => {
      const selectedMonthValue = selectedMonth
        ? selectedMonth.getMonth() + 1
        : null;
      const selectedYearValue = selectedYear
        ? selectedYear.getFullYear()
        : null;

      const matchesYear = selectedYear
        ? item.SucursalAnioFiscalAnio === selectedYearValue
        : true;

      const matchesMonth = selectedMonth
        ? item.MesId === selectedMonthValue
        : true;

      return matchesYear && matchesMonth;
    }) ?? [];

  return (
    <div>
      <CardTable
        type="data"
        hideGlobalSearch
        columns={columns}
        // title="Cierres mensuales"
        value={filteredData}
        // dataKey="id"
        // hideSelectFilter
        // hideFilterSettingsIcon
        loading={monthlyClosings.isPending}
        tableActions={(rowData) => [
          {
            label: rowData.EstadoId === 1 ? "Cerrar mes" : "Reabrir mes",
            icon: rowData.EstadoId === 1 ? "calendar-x" : "calendar-stats",
            iconClassName:
              rowData.EstadoId === 1 ? "text-red-500" : "text-primary",
            onClick: () => {
              setAnular(true);
              setStatus(rowData.EstadoId);
              setSelectMensualidad(rowData.CierreMensualId);
            },
          },
        ]}
        headActions={[
          <Calendar
            view="year"
            showButtonBar
            dateFormat="yy"
            key="CalendarInput"
            value={selectedYear}
            placeholder="Selecciona un año"
            className="p-inputtext-sm w-14rem"
            onChange={(e) => setSelectedYear(e.value)}
          />,
          <Calendar
            view="month"
            showButtonBar
            dateFormat="MM"
            value={selectedMonth}
            key="CalendarInputMonth"
            placeholder="Selecciona un mes"
            className="p-inputtext-sm w-14rem"
            onChange={(e) => setSelectedMonth(e.value)}
          />,
          <Button
            size="small"
            label="Abrir mes"
            key="ButtonOpenMonth"
            onClick={() => setVisible(true)}
            icon={<Icon size={20} name="plus" />}
          />,
        ]}
      />
      <ConfirmDialog
        visible={visible}
        message={"¿Esta seguro que desea abrir un nuevo mes?"}
        onHide={() => {
          if (!visible) return;
          setVisible(false);
        }}
        header={
          <div className="flex flex-row align-items-center mt-0 mb-0">
            <Icon size={"small"} className="mr-2" name="calendar-month"></Icon>
            <p className="text-base">Abrir nuevo mes</p>
          </div>
        }
        accept={() =>
          createCierreMensual.mutateAsync(
            {
              MesId: 0,
              EstadoId: 1,
              CierreMensualId: 0,
              SucursalAnioFiscalId: 0,
            },
            {
              onSuccess: () => {
                setVisible(false);
              },
            },
          )
        }
      />

      <ConfirmDialog
        visible={anular}
        onHide={() => {
          setAnular(false), setSelectMensualidad(0);
        }}
        message={`¿Está seguro de que desea ${status === 1 ? "cerrar" : "reabrir"} este mes?`}
        accept={() =>
          changeStateCierreMensual.mutateAsync({
            Id: selectMensualidad,
            EstadoId: status === 1 ? 8 : 1,
          })
        }
        header={
          <div className="flex flex-row align-items-center mt-0 mb-0">
            <Icon
              size={"small"}
              className="mr-2"
              name={status === 1 ? "calendar-x" : "calendar-stats"}
            ></Icon>
            <p className="text-base">{`${status === 1 ? "Cerrar" : "Reabrir"} mes`}</p>
          </div>
        }
      />
    </div>
  );
};

export default MonthlyClosingPage;
