import React from "react";
import { Icon } from "@nubeteck/icons";
import { useForceUpdate } from "@hooks";
import { CardTable } from "@nubeteck/prime";
import { MenuItem } from "primereact/menuitem";
import { useForm, useWatch } from "react-hook-form";
import { ContextMenu } from "primereact/contextmenu";

import { useDashboardQuery } from "../../../hooks";
import { DashboardDialogSources } from "../../dashboard-dialog-sources";
import { DashboardTableDialogSettings } from "../../dashboard-table-dialog-settings";
import {
  Widget,
  IDataSourceField,
  IDashboardComponent,
} from "../../../interfaces";

interface IDashboardProps {
  dates: Date[];
  title: string;
  subtitle: string;
  FuenteId: null | number;
  CamposId: IDataSourceField[];
}

export type IDashboardTableCardProps = {
  (props: IDashboardProps): React.JSX.Element;
  Dashboard: React.FC<IDashboardProps & IDashboardComponent>;
};

type FormValues = {
  title: string;
  subtitle: string;
  FuenteId: null | number;
  CamposId: IDataSourceField[];
};

const TableCard: IDashboardTableCardProps = ({
  dates,
  title,
  subtitle,
  CamposId,
  FuenteId,
}: IDashboardProps) => {
  const { key, forceUpdate } = useForceUpdate("TableCard");

  const { isFetching, data: result } = useDashboardQuery(
    "DataSources",
    "getData",
    { date1: dates[0], date2: dates[1], id: FuenteId ?? 0 },
    { enabled: !!FuenteId },
  );

  const columns = React.useMemo(
    () =>
      CamposId?.map((field) => ({
        field: field.NombreCampo,
        header: field.Label ? field.Label : field.NombreCampo,
      })) ?? [],
    [CamposId],
  );

  return (
    <CardTable
      key={key}
      scrollable
      type="data"
      title={title}
      hideSelectFilter
      hideGlobalSearch
      columns={columns}
      tableActions={[]}
      subtitle={subtitle}
      value={result ?? []}
      loading={isFetching}
      paginatorClassName="p-0"
      paginatorTemplate="PrevPageLink CurrentPageReport NextPageLink"
      emptyMessage={
        FuenteId ? undefined : "No has seleccionado la fuente de datos."
      }
      headActions={[
        <Icon
          size={20}
          key="Tool"
          name="tool"
          title="Fix"
          onClick={forceUpdate}
        />,
      ]}
    />
  );
};

TableCard.Dashboard = function Dashboard({
  i,
  title,
  subtitle,
  CamposId,
  FuenteId,
  onEditable,
  onRemoveWidget,
  onUpdateWidget,
}) {
  const contextMenuRef = React.useRef<ContextMenu>(null);

  const form = useForm<FormValues>();
  const { reset } = form;
  const widgets = useWatch({ name: "widgets" }) as Widget[];

  const [dialogSourcesOpen, setDialogSourcesOpen] = React.useState(false);
  const [dialogSettingsOpen, setDialogSettingsOpen] = React.useState(false);

  React.useEffect(() => {
    reset(
      { title, CamposId, FuenteId, subtitle },
      { keepDefaultValues: false },
    );
  }, [CamposId, FuenteId, reset, subtitle, title]);

  const onRightClick = React.useCallback((event: React.MouseEvent) => {
    if (contextMenuRef.current) {
      contextMenuRef.current.show(event);
    }
  }, []);

  const onSubmit = (values: FormValues) => {
    const widgetIndex = widgets.findIndex((widget) => widget.i === i);
    onUpdateWidget?.(widgetIndex, { i, type: "table", ...values });
    setDialogSettingsOpen(false);
    setDialogSourcesOpen(false);
    onEditable?.(true);
  };

  const items: MenuItem[] = [
    {
      label: "Ajustes",
      icon: <Icon size={20} name="settings" className="mr-2" />,
      command: () => {
        onEditable?.(false);
        setDialogSettingsOpen(true);
      },
    },
    {
      label: "Fuente de datos",
      icon: <Icon size={20} name="database" className="mr-2" />,
      command: () => {
        onEditable?.(false);
        setDialogSourcesOpen(true);
      },
    },
    {
      label: "Eliminar",
      command: () => onRemoveWidget?.(i),
      icon: <Icon size={20} name="trash" className="mr-2" />,
    },
  ];

  return (
    <>
      <div className="flex flex-1" onContextMenu={onRightClick}>
        <TableCard
          dates={[]}
          title={title}
          subtitle={subtitle}
          FuenteId={FuenteId}
          CamposId={CamposId}
        />
      </div>
      {/* ContextMenu */}
      <ContextMenu model={items} breakpoint="767px" ref={contextMenuRef} />
      <DashboardTableDialogSettings
        form={form}
        onSubmitClick={onSubmit}
        visible={dialogSettingsOpen}
        onHide={() => {
          onEditable?.(true);
          setDialogSettingsOpen(false);
        }}
      />
      <DashboardDialogSources
        form={form}
        onSubmitClick={onSubmit}
        visible={dialogSourcesOpen}
        onHide={() => {
          onEditable?.(true);
          setDialogSourcesOpen(false);
        }}
      />
    </>
  );
};

TableCard.Dashboard.displayName = "TableCard.Dashboard";

export default TableCard;
