import API from "@lib/api";
import { BaseApi } from "@api";
import { Messages } from "@interfaces";

import { ISectores } from "../interfaces";

export class SectorApi extends BaseApi {
  protected static api = API({ namespace: "Sectores" });
  protected static filterApi = API({ namespace: "Filtro" });
  protected static messages: Messages<typeof SectorApi> = {};

  public static async getAll(): Promise<ISectores[]> {
    const result = await this.api.get("GetAll");
    return result.data;
  }
}
