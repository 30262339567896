import React from "react";
import { useGlobalQuery } from "@hooks";
import { IStrategicPlansDetails } from "@interfaces";
import { StrategicPlansTreeTable } from "@components";

export interface IPoaDetailsTableProps {
  peiId: number;
  peuId: number;
  stateId: number;
  templateId: number;
  detailsChildren: IStrategicPlansDetails[];
}

const PoaDetailsTable = ({
  peiId,
  peuId,
  stateId,
  templateId,
  detailsChildren,
}: IPoaDetailsTableProps) => {
  const planPei = useGlobalQuery("StrategicPlans", "getById", peiId, {
    enabled: !!peiId,
  });
  const planPeu = useGlobalQuery("StrategicPlans", "getById", peuId, {
    enabled: !!peuId,
  });

  const detailsPei = React.useMemo(() => {
    if (!planPei.data?.Detalles?.length) return [];
    return planPei.data?.Detalles;
  }, [planPei.data?.Detalles]);

  const detailsPeu = React.useMemo(() => {
    if (!planPeu.data?.Detalles?.length) return [];
    return planPeu.data?.Detalles;
  }, [planPeu.data?.Detalles]);

  return (
    <StrategicPlansTreeTable
      typesAllowed={[]}
      stateId={stateId}
      templateId={templateId}
      customActionAllowed={[]}
      loading={planPei.isPending || planPeu.isPending}
      details={[...detailsPei, ...detailsPeu, ...detailsChildren]}
      onRefresh={() => {
        planPei.refetch();
        planPeu.refetch();
      }}
      permissions={{
        canEdit: false,
        canOrder: false,
        canCreate: false,
        canNullify: false,
        canRestore: false,
        canSeeLogs: false,
        canSeeAdditionalInfo: false,
      }}
    />
  );
};

export default PoaDetailsTable;
