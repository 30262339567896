export const ACTIVE_CODE = 1;
export const INACTIVE_CODE = 2;
export const DELETED_CODE = 3;
export const INCOMPLETE_CODE = 4;
export const NULLIFIED_CODE = 5;
export const DRAFT_CODE = 6;
export const OPEN_CODE = 7;
export const CLOSE_CODE = 8;
export const HIDDEN_CODE = 9;
export const PENDING_APPROVAL_CODE = 10;
export const APPROVED_CODE = 11;
export const COMPLETED_CODE = 12;
