import React from "react";
import { Dialog } from "@components";
import { Datejs } from "@nubeteck/utils";
import { Button } from "primereact/button";
import { useNavigate } from "react-router";
import { StrategicPlansTypes } from "@core";
import { IStrategicPlans } from "@interfaces";
import { DialogProps } from "primereact/dialog";
import { useForm, useWatch } from "react-hook-form";
import { Form, Calendar, InputText } from "@components";
import { useGlobalQuery, useGlobalMutation } from "@hooks";

import { PeiRoutes, PeiRouteNames } from "../../routes";

export interface IPeiDialogProps extends DialogProps {
  strategicPlanId: number;
}

type FormValues = Omit<
  IStrategicPlans,
  "TipoId" | "FechaFin" | "FechaInicio" | "PlantillaId" | "ResponsableId"
> & {
  FechaFin?: Date;
  FechaInicio?: Date;
  TipoId: null | number;
  PlantillaId: null | number;
  ResponsableId: null | number;
};

const model = {
  from: (data: IStrategicPlans): FormValues => {
    return {
      Nombre: data.Nombre,
      TipoId: data.TipoId,
      EstadoId: data.EstadoId,
      PlantillaId: data.PlantillaId,
      ResponsableId: data.ResponsableId,
      FechaFin: Datejs(data.FechaFin).toDate(),
      PlanEstrategicoId: data.PlanEstrategicoId,
      FechaInicio: Datejs(data.FechaInicio).toDate(),
    };
  },
  to: (data: FormValues): IStrategicPlans => {
    return {
      Nombre: data.Nombre,
      EstadoId: data.EstadoId,
      TipoId: data.TipoId ?? 0,
      PlantillaId: data.PlantillaId ?? 0,
      ResponsableId: data.ResponsableId ?? 0,
      PlanEstrategicoId: data.PlanEstrategicoId,
      FechaFin: Datejs(data.FechaFin).toISOString(),
      FechaInicio: Datejs(data.FechaInicio).toISOString(),
    };
  },
};

const PeiDialog = ({ visible, strategicPlanId, ...props }: IPeiDialogProps) => {
  const navigate = useNavigate();

  const createStrategicPlan = useGlobalMutation("StrategicPlans", "create");
  const updateStrategicPlan = useGlobalMutation("StrategicPlans", "update");
  const getCreate = useGlobalQuery(
    "StrategicPlans",
    "getCreate",
    StrategicPlansTypes.PEI,
    { enabled: !strategicPlanId && visible },
  );
  const getUpdate = useGlobalQuery(
    "StrategicPlans",
    "getUpdate",
    strategicPlanId,
    { enabled: !!strategicPlanId && visible },
  );

  const form = useForm<FormValues>();
  const { reset } = form;

  const FechaInicio = useWatch({ name: "FechaInicio", control: form.control });

  React.useEffect(() => {
    if (!strategicPlanId && getCreate.data) {
      reset(model.from(getCreate.data));
    }
  }, [reset, getCreate.data, strategicPlanId]);

  React.useEffect(() => {
    if (strategicPlanId && getUpdate.data) {
      reset(model.from(getUpdate.data));
    }
  }, [reset, getUpdate.data, strategicPlanId]);

  const handleSuccess = (data: { PlanId: number }) => {
    form.reset();
    props.onHide();
    navigate(
      `.${PeiRoutes[PeiRouteNames.PEI_DETAILS].create({
        id: `${data.PlanId}`,
      })}`,
    );
  };

  const onSubmit = (values: FormValues) => {
    if (strategicPlanId) {
      return updateStrategicPlan.mutateAsync(model.to(values), {
        onSuccess: handleSuccess,
      });
    }
    return createStrategicPlan.mutateAsync(model.to(values), {
      onSuccess: handleSuccess,
    });
  };

  const validations = React.useMemo(() => {
    if (strategicPlanId) return getUpdate.data?.Validaciones;
    else return getCreate.data?.Validaciones;
  }, [
    getCreate.data?.Validaciones,
    getUpdate.data?.Validaciones,
    strategicPlanId,
  ]);

  const loading = React.useMemo(() => {
    if (strategicPlanId) return getUpdate.isPending;
    else return getCreate.isPending;
  }, [getCreate.isPending, getUpdate.isPending, strategicPlanId]);

  return (
    <Dialog
      {...props}
      visible={visible}
      loading={loading}
      className="w-full md:w-10 lg:w-6"
      subtitle="Rellena los datos del formulario"
      onHide={() => {
        if (strategicPlanId) form.reset();
        props.onHide();
      }}
      title={
        strategicPlanId
          ? `Editando ${getUpdate.data?.Nombre ?? ""}`
          : "Creando nuevo plan estratégico institucional"
      }
      footer={() => (
        <div className="flex flex-1 justify-content-end gap-2">
          <Button
            size="small"
            severity="warning"
            label="Reiniciar formulario"
            disabled={!form.formState.isDirty}
            onClick={() => {
              form.reset();
            }}
            loading={
              createStrategicPlan.isPending || updateStrategicPlan.isPending
            }
          />
          <Button
            size="small"
            disabled={!form.formState.isDirty}
            onClick={form.handleSubmit(onSubmit)}
            label={
              strategicPlanId ? "Guardar cambios" : "Crear plan estratégico"
            }
            loading={
              createStrategicPlan.isPending || updateStrategicPlan.isPending
            }
          />
        </div>
      )}
    >
      <Form form={form}>
        <InputText
          name="Nombre"
          className="p-inputtext-sm"
          rules={validations?.["Nombre"]}
          label="Nombre del plan estratégico"
          placeholder="Ej: Plan Estratégico de la nueva gestión."
        />
        <div className="flex flex-column md:flex-row gap-2 flex-1">
          <Calendar
            name="FechaInicio"
            fieldClassName="flex-1"
            label="Fecha de inicio"
            className="p-inputtext-sm"
            rules={validations?.["FechaInicio"]}
            placeholder="Selecciona una fecha de inicio"
          />
          <Calendar
            name="FechaFin"
            fieldClassName="flex-1"
            className="p-inputtext-sm"
            label="Fecha de finalización"
            rules={validations?.["FechaFin"]}
            placeholder="Selecciona una fecha final"
            minDate={Datejs(FechaInicio).add(1, "day").toDate()}
          />
        </div>
      </Form>
    </Dialog>
  );
};

export default PeiDialog;
