import React from "react";
import { Tag } from "primereact/tag";
import { Icon } from "@nubeteck/icons";
import { renderTooltip } from "@utils";
import { Navigate } from "react-router";
import { Button } from "primereact/button";
import { EventUtils } from "@nubeteck/utils";
import { useAuthPermissions } from "@nubeteck/auth";
import { IStrategicPlansActivity } from "@interfaces";
import { useQueryClient } from "@tanstack/react-query";
import { confirmDialog } from "primereact/confirmdialog";
import { Reducers, Statuses, StrategicPlansTypes } from "@core";
import { useGetParams, useGlobalQuery, useGlobalMutation } from "@hooks";
import {
  ConfirmDialog,
  ChangeLogDialog,
  RequestCommentsCard,
  RequestCommentsDialog,
} from "@components";
import {
  CardTable,
  StatusTag,
  PaginatorState,
  useHeaderActions,
  IDataTableColumnsProps,
} from "@nubeteck/prime";

import { Permissions } from "../../core";
import { ActivityFormDialog, ActivityDetailsTabs } from "../../components";

const PoaActivitiesPage = () => {
  const queryClient = useQueryClient();
  const permissions = useAuthPermissions();
  const strategicPlanId = useGetParams("id");
  const { dispatch: dispatchHeaderActions } = useHeaderActions();

  const changeStateActivity = useGlobalMutation(
    "StrategicPlansActivities",
    "changeState",
  );
  const poa = useGlobalQuery(
    "StrategicPlans",
    "getById",
    strategicPlanId ?? 0,
    { enabled: !!strategicPlanId },
  );
  const {
    mutateAsync: sendRequestReview,
    isPending: isPendingSendRequestReview,
  } = useGlobalMutation("StrategicPlans", "sendRequestReview");
  const activities = useGlobalQuery(
    "StrategicPlansActivities",
    "getAllByPoa",
    strategicPlanId ?? 0,
    { enabled: !!strategicPlanId },
  );

  const isDraft = poa.data?.EstadoId === Statuses.DRAFT_CODE;

  const [activitySelected, setActivitySelected] = React.useState<number>(0);
  const [activityState, activityDispatch] = React.useReducer(
    Reducers.DialogReducer,
    { id: 0, open: false },
  );
  const [changeLogState, changeLogDispatch] = React.useReducer(
    Reducers.DialogReducer,
    { id: 0, open: false },
  );
  const [requestReviewState, requestReviewDispatch] = React.useReducer(
    Reducers.DialogReducer,
    { id: 0, open: false },
  );
  const [paginator, setPaginator] = React.useState<PaginatorState>({
    page: 0,
    first: 0,
    rows: 10,
  });

  React.useEffect(() => {
    dispatchHeaderActions({
      type: "SET_VARIABLES",
      payload: {
        pageTitle: poa.data?.Nombre || "...",
      },
    });
    return () => dispatchHeaderActions({ type: "CLEAR_VARIABLES" });
  }, [dispatchHeaderActions, poa.data]);

  React.useEffect(() => {
    if (poa.data) {
      dispatchHeaderActions({
        type: "SET_ACTIONS",
        payload: [
          permissions.hasPermission(Permissions.SEE_LOGS_POA) ? (
            <React.Fragment key="Logs">
              {renderTooltip(
                "Historial de cambios",
                "changelog-peu-poa",
                "right",
              )}
              <Icon
                key=""
                name="book"
                className="changelog-peu-poa align-self-center text-primary mr-1"
                onClick={EventUtils.callEvent(changeLogDispatch, {
                  type: "OPEN_DIALOG",
                  payload: strategicPlanId,
                })}
              />
            </React.Fragment>
          ) : (
            <></>
          ),
          isDraft &&
          permissions.hasPermission(
            Permissions.REQUEST_REVIEWS_COMMENTS_POA,
          ) ? (
            <Button
              size="small"
              severity="info"
              key="RequestReview"
              label="Solicitar revision"
              loading={isPendingSendRequestReview}
              icon={<Icon size={18} name="send" className="mr-1" />}
              onClick={async () => {
                await sendRequestReview(strategicPlanId ?? 0);
              }}
            />
          ) : (
            <></>
          ),
          poa.data.EstadoId === Statuses.PENDING_APPROVAL_CODE &&
          permissions.hasPermission(Permissions.APPROVE_REJECT_POA) ? (
            <Button
              size="small"
              iconPos="right"
              label="Revisión"
              icon={<Icon size={18} className="mr-1" name="eye-search" />}
              onClick={EventUtils.callEvent(requestReviewDispatch, {
                type: "OPEN_DIALOG",
                payload: strategicPlanId,
              })}
            />
          ) : (
            <></>
          ),
        ],
      });
    }
    return () => dispatchHeaderActions({ type: "CLEAR_ACTIONS" });
  }, [
    dispatchHeaderActions,
    isDraft,
    isPendingSendRequestReview,
    permissions,
    poa.data,
    sendRequestReview,
    strategicPlanId,
  ]);

  const invalidateDetails = React.useCallback(() => {
    queryClient.invalidateQueries({ queryKey: ["StrategicPlansDetails"] });
  }, [queryClient]);

  const handleRestoreActivity = React.useCallback(
    (id: number) => {
      confirmDialog({
        acceptLabel: "Activar",
        header: "Activar actividad",
        acceptClassName: "p-button-sm p-button-success",
        message: "¿Está seguro de que desea activar esta actividad?",
        accept: async () => {
          await changeStateActivity.mutateAsync(
            { ActividadId: id, EstadoId: Statuses.ACTIVE_CODE },
            { onSuccess: invalidateDetails },
          );
        },
      });
    },
    [changeStateActivity, invalidateDetails],
  );

  const handleNullifyActivity = React.useCallback(
    (id: number) => {
      confirmDialog({
        acceptLabel: "Anular",
        header: "Anular actividad",
        message: "¿Está seguro de que desea anular esta actividad?",
        accept: async () => {
          await changeStateActivity.mutateAsync(
            { ActividadId: id, EstadoId: Statuses.NULLIFIED_CODE },
            { onSuccess: invalidateDetails },
          );
        },
      });
    },
    [changeStateActivity, invalidateDetails],
  );

  const columns: IDataTableColumnsProps<IStrategicPlansActivity>[] = [
    { field: "ActividadId", selectionMode: "single", style: { width: "40px" } },
    { field: "Actividad", header: "Actividad" },
    { field: "LineasBases", header: "Lineas Bases" },
    {
      field: "Presupuesto",
      header: "Presupuesto",
      body: (data) => {
        return (
          <Tag severity={data.Presupuesto ? "success" : "danger"}>
            {data.Presupuesto ? "Necesita" : "No necesita"}
          </Tag>
        );
      },
    },
    {
      field: "Recursos",
      header: "Recursos",
      body: (data) => data.Recursos || "Sin recursos",
    },
    {
      header: "Estado",
      field: "EstadoNombre",
      body: (data) => <StatusTag status={data.EstadoNombre ?? "Activo"} />,
    },
  ];

  if (!poa.isPending && poa.data?.TipoId !== StrategicPlansTypes.POA) {
    throw new Error("Esta pagina solo es para planes operativos anuales.");
  }

  if (poa.data?.EstadoId === Statuses.ACTIVE_CODE) {
    return <Navigate to=".." replace />;
  }

  return (
    <>
      <div className="flex flex-column gap-3">
        <CardTable<IStrategicPlansActivity>
          type="data"
          hideSelectFilter
          columns={columns}
          title="Actividades"
          rows={paginator.rows}
          dataKey="ActividadId"
          hideFilterSettingsIcon
          first={paginator.first}
          selectionKey="ActividadId"
          value={activities.data ?? []}
          loading={activities.isPending}
          customSelection={activitySelected}
          onRefresh={() => activities.refetch()}
          onPaginationChange={(event) => setPaginator(event)}
          onSelectionChange={(event) => {
            setActivitySelected(event.value.ActividadId);
          }}
          isDataSelectable={() => {
            return (
              isDraft ||
              permissions.hasPermission(
                [
                  Permissions.CONFIGURE_SCHEDULE_ACTIVITIES_POA,
                  Permissions.SEE_POA_ACTIVITIES_INDICATORS_TAB,
                ],
                "OR",
              )
            );
          }}
          headActions={[
            <Button
              size="small"
              label="Agregar actividad"
              key="NewStrategicPlanDetailActivity"
              icon={<Icon size={20} name="plus" className="mr-1" />}
              disabled={
                !isDraft ||
                !permissions.hasPermission(Permissions.CREATE_ACTIVITIES_POA)
              }
              onClick={EventUtils.callEvent(activityDispatch, {
                payload: 0,
                type: "OPEN_DIALOG",
              })}
            />,
          ]}
          tableActions={(data) => [
            {
              icon: "pencil",
              label: "Editar",
              disabled:
                !isDraft ||
                !permissions.hasPermission(Permissions.EDIT_ACTIVITIES_POA),
              onClick: EventUtils.callEvent(activityDispatch, {
                type: "OPEN_DIALOG",
                payload: data.ActividadId ?? 0,
              }),
            },
            {
              icon: "ban",
              label: "Anular",
              iconClassName: "text-red-500",
              onClick: EventUtils.callEvent(
                handleNullifyActivity,
                data.ActividadId ?? 0,
              ),
              disabled:
                data.EstadoId === Statuses.NULLIFIED_CODE ||
                !isDraft ||
                !permissions.hasPermission(Permissions.NULLIFY_ACTIVITIES_POA),
            },
            {
              icon: "plug",
              label: "Activar",
              iconClassName: "text-primary-500",
              onClick: EventUtils.callEvent(
                handleRestoreActivity,
                data.ActividadId ?? 0,
              ),
              disabled:
                data.EstadoId !== Statuses.NULLIFIED_CODE ||
                !isDraft ||
                !permissions.hasPermission(Permissions.RESTORE_ACTIVITIES_POA),
            },
            {
              icon: "book",
              label: "Historial de cambios",
              disabled: !permissions.hasPermission(
                Permissions.SEE_LOGS_ACTIVITIES_POA,
              ),
              onClick: EventUtils.callEvent(changeLogDispatch, {
                type: "OPEN_DIALOG",
                payload: data.ActividadId ?? 0,
              }),
            },
          ]}
        />
        <ActivityDetailsTabs activityId={activitySelected ?? 0} />
        {isDraft &&
        permissions.hasPermission(Permissions.SEE_REVIEWS_COMMENTS_POA) ? (
          <RequestCommentsCard strategicPlanId={strategicPlanId} />
        ) : null}
      </div>
      <RequestCommentsDialog
        strategicPlanId={strategicPlanId}
        visible={requestReviewState.open}
        onHide={EventUtils.callEvent(requestReviewDispatch, {
          type: "CLOSE_DIALOG",
        })}
      />
      <ChangeLogDialog
        entityName="actividadid"
        entityId={changeLogState.id}
        visible={changeLogState.open}
        onHide={EventUtils.callEvent(changeLogDispatch, {
          type: "CLOSE_DIALOG",
        })}
      />
      <ActivityFormDialog
        visible={activityState.open}
        activityId={activityState.id}
        onCompleteForm={setActivitySelected}
        onHide={EventUtils.callEvent(activityDispatch, {
          type: "CLOSE_DIALOG",
        })}
      />
      <ConfirmDialog />
    </>
  );
};

export default PoaActivitiesPage;
