import API from "@lib/api";
import { BaseApi } from "@api";
import {
  Messages,
  IIndicatorExecution,
  IStrategicPlansIndicatorReview,
  IIndicatorExecutionGetForUpdate,
  IStrategicPlansActivityIndicator,
  IStrategicPlansActivityIndicatorType,
  IStrategicPlansActivityIndicatorResult,
  IStrategicPlansActivityIndicatorGetForCreate,
  IStrategicPlansActivityIndicatorGetForUpdate,
} from "@interfaces";

export class StrategicPlansIndicatorApi extends BaseApi {
  protected static api = API({ namespace: "PlanesEstrategicos" });
  protected static messages: Messages<typeof StrategicPlansIndicatorApi> = {
    create: {
      error: (error) => error,
      loading: "Creando nuevo indicador...",
      success: "Indicador creado correctamente.",
    },
    update: {
      error: (error) => error,
      loading: "Actualizando indicador...",
      success: "Indicador actualizado correctamente.",
    },
    createExecution: {
      error: (error) => error,
      loading: "Ejecutando indicador...",
      success: "Indicador ejecutado correctamente.",
    },
    updateExecution: {
      error: (error) => error,
      loading: "Ejecutando indicador...",
      success: "Indicador ejecutado correctamente.",
    },
    requestReview: {
      error: (error) => error,
      loading: "Enviando revision del indicador...",
      success: "Revision del indicador enviada correctamente.",
    },
    changeOrder: {
      error: (error) => error,
      loading: "Actualizando orden del indicador...",
      success: "Orden del indicador actualizado correctamente.",
    },
    changeState: {
      error: (error) => error,
      loading: "Actualizando estado del indicador...",
      success: "Estado del indicador actualizado correctamente.",
    },
  };

  public static async getAll(props: {
    ActividadId: number;
    IndicadorTipoId: number;
  }) {
    const result = await this.api.get<IStrategicPlansActivityIndicator[]>(
      `GetAllActividadesIndicadores/${props.ActividadId}?IndicadorTipoId=${props.IndicadorTipoId}`,
    );
    return result.data;
  }

  public static async getAllTypes() {
    const result = await this.api.get<IStrategicPlansActivityIndicatorType[]>(
      "GetAllIndicadoresTipos",
    );
    return result.data;
  }

  public static async getAllResults(id: number) {
    const result = await this.api.get<IStrategicPlansActivityIndicatorResult[]>(
      `GetAllResultados/${id}`,
    );
    return result.data;
  }

  public static async create(data: IStrategicPlansActivityIndicator) {
    const result = await this.api.post("AddActividadIndicador", data);
    return result.data;
  }

  public static async getCreate(activityId: number) {
    const result =
      await this.api.get<IStrategicPlansActivityIndicatorGetForCreate>(
        `GetForCreateActividadIndicador/${activityId}`,
      );
    return result.data;
  }

  public static async update(data: Partial<IStrategicPlansActivityIndicator>) {
    const result = await this.api.put(
      `UpdateActividadIndicador/${data.ActividadId}`,
      data,
    );
    return result.data;
  }

  public static async getUpdate(id: number) {
    const result =
      await this.api.get<IStrategicPlansActivityIndicatorGetForUpdate>(
        `GetForUpdateActividadIndicador/${id}`,
      );
    return result.data;
  }

  public static async changeState(data: {
    IndicadorId: number;
    EstadoId: number;
  }) {
    const result = await this.api.post(
      "ChangeStatePlanEstrategicoActividadIndicador",
      data,
    );
    return result.data;
  }

  public static async changeOrder(
    data: { IndicadorId: number; Orden: number }[],
  ) {
    const result = await this.api.post("ChangePlanActividadIndicadorOrder", {
      ActividadIndicadores: data,
    });
    return result.data;
  }

  public static async getUpdateExecution(id: number) {
    const result = await this.api.get<IIndicatorExecutionGetForUpdate>(
      `GetForUpdateIndicadoresEjecucion/${id}`,
    );
    return result.data;
  }

  public static async createExecution(data: IIndicatorExecution) {
    const result = await this.api.post("CreateIndicadorEjecucion", data);
    return result.data;
  }

  public static async updateExecution(data: IIndicatorExecution) {
    const result = await this.api.put(
      `UpdateIndicadorEjecucion/${data.IndicadorId}`,
      data,
    );
    return result.data;
  }

  public static async requestReview(data: {
    IndicadorId: number;
    Observacion: string;
    TipoRevisionId: number;
  }) {
    const result = await this.api.post("RequestReviewIndicador", data);
    return result.data;
  }

  public static async getAllReviews(id: number) {
    const result = await this.api.get<IStrategicPlansIndicatorReview[]>(
      `GetAllRevisionesComentariosByIndicadorId/${id}`,
    );
    return result.data;
  }
}
