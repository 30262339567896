import React from "react";
import { Button } from "primereact/button";
import { useWatch } from "react-hook-form";
import { ArrayUtils } from "@nubeteck/utils";
import { Form, Dialog, Dropdown } from "@components";

import { useDashboardQuery } from "../../hooks";
import { IDashboardWidgetDialogProps } from "../../interfaces";
import { DataSourcesDropdown } from "../data-sources-dropdown";

const DashboardKPIDialogSources = ({
  form,
  onSubmitClick,
  ...props
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
}: IDashboardWidgetDialogProps<any>) => {
  const sourceId = useWatch({ name: "FuenteId", control: form.control });

  const { data: fields, isPending: isFieldsPending } = useDashboardQuery(
    "DataSources",
    "getCampos",
    sourceId ?? 0,
    { enabled: !!sourceId },
  );

  const fieldsOptions = React.useMemo(() => {
    if (!fields?.length) return [];
    return ArrayUtils.selectLabelValue(fields, "NombreCampo", "NombreCampo");
  }, [fields]);

  return (
    <Dialog
      {...props}
      title="Fuentes de datos"
      className="w-full md:w-10 lg:w-5"
      footer={
        <div className="flex justify-content-end gap-2">
          <Button
            size="small"
            type="button"
            label="Cancelar"
            severity="secondary"
            onClick={() => {
              form.reset();
              props.onHide();
            }}
          />
          <Button
            size="small"
            label="Guardar cambios"
            onClick={form.handleSubmit(onSubmitClick)}
          />
        </div>
      }
    >
      <Form form={form}>
        <DataSourcesDropdown
          label="Fuente"
          name="FuenteId"
          placeholder="Selecciona una fuente"
        />
        <Dropdown
          label="Campo"
          name="CampoId"
          optionLabel="label"
          options={fieldsOptions}
          disabledInput={!sourceId}
          loading={isFieldsPending}
          className="p-inputtext-sm"
          placeholder="Selecciona un campo"
        />
      </Form>
    </Dialog>
  );
};

export default DashboardKPIDialogSources;
