import React from "react";
import Webcam from "react-webcam";
import { Dialog } from "@components";
import { Card } from "primereact/card";
import { Icon } from "@nubeteck/icons";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { PickNullable } from "@interfaces";
import { Divider } from "primereact/divider";
import { useHeaderActions } from "@nubeteck/prime";
import { useParams, useNavigate } from "react-router";
import { FileUpload, FileUploadHandlerEvent } from "primereact/fileupload";
import {
  Form,
  useForm,
  Dropdown,
  InputText,
  InputMask,
  InputSwitch,
} from "@nubeteck/forms";

import validateDocument from "../../utils/validate-document";
import { IProvider, IProviderCreate } from "../../interfaces";
import { AccountsPayableRoutes, AccountsPayableRouteNames } from "../../routes";
import {
  useAccountsPayableQuery,
  useAccountsPayableMutation,
} from "../../hooks";

// interface IValidateDNI {
//   Exist: boolean;
//   IsDniValid: boolean;
// }

// interface IAccountCatalogFormProps {
//   edit: boolean;
//   open: boolean;
//   onClose?: () => void;
//   cuentaPadreId?: number;
// }

type FormValues = PickNullable<
  IProviderCreate,
  | "DNI"
  | "Celular"
  | "EstadoId"
  | "Telefono"
  | "EsEmpresa"
  | "DNITipoId"
  | "ProveedorId"
  | "NacionalidadId"
  | "ProveedorNombre"
  | "ProveedorApellido"
  | "CorreoValidadoPor"
  | "CorreoElectronico"
  | "CelularValidadoPor"
  | "CorreoEstaValidado"
  | "CelularEstaValidado"
  | "CorreoFormaValidacionId"
  | "CelularFormaValidacionId"
>;

const model = {
  to: (data: FormValues): IProviderCreate => {
    return {
      EstadoId: 1,
      EsEmpresa: true,
      DNI: data.DNI ?? "",
      CorreoValidadoPor: 0,
      CelularValidadoPor: 0,
      CorreoEstaValidado: true,
      CelularEstaValidado: true,
      CorreoFormaValidacionId: 1,
      Celular: data.Celular ?? "",
      CelularFormaValidacionId: 1,
      Telefono: data.Telefono ?? "",
      DNITipoId: data.DNITipoId ?? 0,
      ProveedorId: data.ProveedorId ?? 0,
      NacionalidadId: data.NacionalidadId ?? 0,
      //Representante: string,
      ProveedorNombre: data.ProveedorNombre ?? "",
      ProveedorApellido: data.ProveedorApellido ?? "",
      CorreoElectronico: data.CorreoElectronico ?? "",
    };
  },
  from: (data: IProviderCreate): FormValues => {
    return {
      DNI: data.DNI,
      Celular: data.Celular,
      EstadoId: data.EstadoId,
      Telefono: data.Telefono,
      EsEmpresa: data.EsEmpresa,
      DNITipoId: data.DNITipoId,
      ProveedorId: data.ProveedorId,
      NacionalidadId: data.NacionalidadId,
      //Representante: string,
      ProveedorNombre: data.ProveedorNombre,
      CorreoValidadoPor: data.CorreoValidadoPor,
      ProveedorApellido: data.ProveedorApellido,
      CorreoElectronico: data.CorreoElectronico,
      CorreoEstaValidado: data.CorreoEstaValidado,
      CelularValidadoPor: data.CelularValidadoPor,
      CelularEstaValidado: data.CelularEstaValidado,
      CorreoFormaValidacionId: data.CorreoFormaValidacionId,
      CelularFormaValidacionId: data.CelularFormaValidacionId,
    };
  },
};

const ProviderFormPage = () => {
  const { id } = useParams();
  const form = useForm<FormValues>();
  const [formDataFile, setFormDataFile] = React.useState<
    FormData | undefined
  >();
  const [visible, setVisible] = React.useState(false);
  const [messageError, setShowMessageError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState<string>("");
  const createProvider = useAccountsPayableMutation("Provider", "create");
  const updateProvider = useAccountsPayableMutation("Provider", "update");
  const navigate = useNavigate();
  const { dispatch } = useHeaderActions();
  const documentoIdentidad = form.watch("DNI", "");
  const selectedDocumentType = form.watch("DNITipoId");
  // const documentoTexto = documentoIdentidad?.replace(/[^0-9]/g, "");
  const toast = React.useRef<null | Toast>(null);
  const [fileId, setFileId] = React.useState(0);
  const [filteredProviders, setFilteredProviders] = React.useState<IProvider[]>(
    [],
  );
  const webcamRef = React.useRef<Webcam>(null);
  const fileUploadRef = React.useRef<FileUpload>(null);
  const providers = useAccountsPayableQuery("Provider", "getAll");
  const createArchivo = useAccountsPayableMutation("Provider", "addFile");
  const validateDNI = useAccountsPayableMutation("Provider", "validateDNI");
  const downloadFile = useAccountsPayableMutation("Files", "uploadFile");
  const providersForCreate = useAccountsPayableQuery(
    "Provider",
    "getForCreate",
  );
  const providersForUpdate = useAccountsPayableQuery(
    "Provider",
    "getForUpdate",
    Number(id),
    {
      enabled: !!Number(id),
    },
  );
  const getDownloadFile = useAccountsPayableQuery(
    "Files",
    "getDownloadFile",
    fileId,
    {
      enabled: !!fileId,
    },
  );

  const nacionalidadOptions = React.useMemo(() => {
    const nacionalidadSelect =
      providersForCreate.data?.SelectOptions?.NacionalidadSelect;

    if (!nacionalidadSelect || !nacionalidadSelect.length) return [];

    return nacionalidadSelect.map((nacionalidad) => ({
      value: nacionalidad.NacionalidadId,
      label: nacionalidad.NacionalidadNombre,
    }));
  }, [providersForCreate.data?.SelectOptions?.NacionalidadSelect]);

  const documentosTiposOptions = React.useMemo(() => {
    const dniTiposSelect =
      providersForCreate.data?.SelectOptions?.TiposDNISelect;

    if (!dniTiposSelect || !dniTiposSelect.length) return [];

    return dniTiposSelect.map((DNITipo) => ({
      value: DNITipo.TipoDNIId,
      label: DNITipo.TipoDNINombre,
    }));
  }, [providersForCreate.data?.SelectOptions?.TiposDNISelect]);

  const upload = async (event: FileUploadHandlerEvent) => {
    const file = event.files[0];
    const formData = new FormData();
    formData.append("formFile", file);
    setFormDataFile(formData);
    downloadFile.mutateAsync(formData, {
      onSuccess: (data) => {
        if (fileUploadRef.current) {
          fileUploadRef.current.clear();
        }
        setFileId(data.FileId);
      },
    });
  };

  const handleCaptureImage = React.useCallback(() => {
    const imageDataURL = webcamRef.current?.getScreenshot();
    if (imageDataURL) {
      const byteString = atob(imageDataURL.split(",")[1]);
      const mimeString = imageDataURL.split(",")[0].split(":")[1].split(";")[0];
      const arrayBuffer = new ArrayBuffer(byteString.length);
      const uintArray = new Uint8Array(arrayBuffer);

      for (let i = 0; i < byteString.length; i++) {
        uintArray[i] = byteString.charCodeAt(i);
      }

      const blob = new Blob([arrayBuffer], { type: mimeString });
      const formData = new FormData();
      formData.append("formFile", blob, "screenshot.webp");
      downloadFile.mutateAsync(formData, {
        onSuccess: (data) => {
          setFileId(data.FileId);
        },
      });
    }
  }, [downloadFile]);

  // const onSubmit = (values: FormValues) => {
  //   if (documentoIdentidad && selectedDocumentType) {
  //     validateDNI.mutateAsync(
  //       {
  //         DNI: documentoIdentidad,
  //         DNITipoId: selectedDocumentType,
  //       },
  //       {
  //         onError: (error) => {
  //           // console.log(error, "error console");
  //           if (typeof error === "string") {
  //             setErrorMessage(error);
  //           } else {
  //             setErrorMessage("Ocurrió un error inesperado.");
  //           }
  //         },
  //         onSuccess: () => {
  //           setErrorMessage("");
  //           // console.log(data, "data console");

  //           if (Number(id) !== 0) {
  //             return updateProvider.mutateAsync(model.to(values), {
  //               onSuccess: (data) => {
  //                 const proveedorId = data.ProveedorId;
  //                 if (fileId) {
  //                   createArchivo.mutateAsync(
  //                     {
  //                       EstadoId: 1,
  //                       TipoArchivoId: 8,
  //                       ArchivoId: fileId,
  //                       ProveedorId: proveedorId,
  //                     },
  //                     {
  //                       onSuccess: () => {
  //                         setFormDataFile(undefined);
  //                         setShowMessageError(false);
  //                         setFileId(0);
  //                         form.reset();
  //                         navigate(-1);
  //                       },
  //                     },
  //                   );
  //                 }
  //               },
  //             });
  //           }

  //           if (getDownloadFile.data?.Data === undefined) {
  //             setShowMessageError(true);
  //           }

  //           if (Number(id) === 0 && getDownloadFile.data?.Data !== undefined) {
  //             return createProvider.mutateAsync(
  //               model.to({
  //                 ...values,
  //                 DNI: documentoIdentidad,
  //                 DNITipoId: values.DNITipoId,
  //               }),
  //               {
  //                 onSuccess: (data) => {
  //                   const proveedorId = data.ProveedorId;
  //                   if (fileId) {
  //                     createArchivo.mutateAsync(
  //                       {
  //                         EstadoId: 1,
  //                         TipoArchivoId: 8,
  //                         ArchivoId: fileId,
  //                         ProveedorId: proveedorId,
  //                       },
  //                       {
  //                         onSuccess: () => {
  //                           setFormDataFile(new FormData());
  //                           setShowMessageError(false);
  //                           setFileId(0);
  //                           form.reset();
  //                           navigate(-1);
  //                         },
  //                       },
  //                     );
  //                   }
  //                 },
  //               },
  //             );
  //           } else {
  //             toast.current?.show({
  //               life: 3000,
  //               severity: "warn",
  //               summary: "Warning",
  //               detail: "Complete el formulario",
  //             });
  //           }
  //         },
  //       },
  //     );
  //   }
  // };

  const onSubmit = (values: FormValues) => {
    if (documentoIdentidad && selectedDocumentType) {
      if (documentoIdentidad === providersForUpdate.data?.DNI) {
        setErrorMessage("");
        continueWithSuccessFlow(values);
        return;
      }

      // Valida el DNI si no coincide
      validateDNI.mutateAsync(
        {
          DNI: documentoIdentidad,
          DNITipoId: selectedDocumentType,
        },
        {
          onSuccess: () => {
            setErrorMessage("");
            continueWithSuccessFlow(values);
          },
          onError: (error) => {
            if (typeof error === "string") {
              setErrorMessage(error);
            } else {
              setErrorMessage("Ocurrió un error inesperado.");
            }
          },
        },
      );
    }
  };

  // Función para manejar el flujo de éxito
  const continueWithSuccessFlow = (values: FormValues) => {
    if (Number(id) !== 0) {
      return updateProvider.mutateAsync(model.to(values), {
        onSuccess: (data) => {
          const proveedorId = data.ProveedorId;
          if (fileId) {
            createArchivo.mutateAsync(
              {
                EstadoId: 1,
                TipoArchivoId: 8,
                ArchivoId: fileId,
                ProveedorId: proveedorId,
              },
              {
                onSuccess: () => {
                  setFormDataFile(undefined);
                  setShowMessageError(false);
                  setFileId(0);
                  form.reset();
                  navigate(-1);
                },
              },
            );
          } else {
            setFormDataFile(undefined);
            setShowMessageError(false);
            setFileId(0);
            form.reset();
            navigate(-1);
          }
        },
      });
    }

    if (getDownloadFile.data?.Data === undefined) {
      setShowMessageError(true);
    }

    if (Number(id) === 0 && getDownloadFile.data?.Data !== undefined) {
      return createProvider.mutateAsync(
        model.to({
          ...values,
          DNI: documentoIdentidad,
          DNITipoId: values.DNITipoId,
        }),
        {
          onSuccess: (data) => {
            const proveedorId = data.ProveedorId;
            if (fileId) {
              createArchivo.mutateAsync(
                {
                  EstadoId: 1,
                  TipoArchivoId: 8,
                  ArchivoId: fileId,
                  ProveedorId: proveedorId,
                },
                {
                  onSuccess: () => {
                    setFormDataFile(new FormData());
                    setShowMessageError(false);
                    setFileId(0);
                    form.reset();
                    navigate(-1);
                  },
                },
              );
            }
          },
        },
      );
    } else {
      toast.current?.show({
        life: 3000,
        severity: "warn",
        summary: "Warning",
        detail: "Complete el formulario",
      });
    }
  };

  React.useEffect(() => {
    if (Number(id) !== 0) {
      const providersUpdate = providersForUpdate.data;
      if (providersUpdate) {
        const formData = model.from(providersUpdate);
        form.reset(formData, { keepDefaultValues: true });
      }
    }
    if (Number(id) === 0) {
      form.reset();
    }
  }, [form, id, providersForUpdate.data]);

  React.useEffect(() => {
    dispatch({
      type: "SET_VARIABLES",
      payload: {
        title: Number(id) !== 0 ? "Editar proveedor" : "Nuevo proveedor",
      },
    });
    () => dispatch({ type: "CLEAR_VARIABLES" });
  }, [dispatch, id]);

  React.useEffect(() => {
    const filtered = providers.data?.filter(
      (provider) => provider.DNI === documentoIdentidad,
    ) as IProvider[];
    setFilteredProviders(filtered);
  }, [documentoIdentidad, providers.data]);

  React.useEffect(() => {
    form.formState.errors.DNI ?? setErrorMessage("");
    formDataFile ?? setShowMessageError(false);
    // console.log(documentoIdentidad, "doc");
    // console.log(selectedDocumentType, "tipy");
  }, [
    documentoIdentidad,
    form.formState.errors.DNI,
    formDataFile,
    selectedDocumentType,
  ]);

  return (
    <Card
      title={Number(id) !== 0 ? "Editar proveedor" : "Agregar nuevo proveedor"}
    >
      <div className="flex flex-column">
        <div className="flex justify-content-center">
          <img
            style={{ width: "230px", height: "230px", objectFit: "cover" }}
            className="flex justify-content-center border-circle responsive-img mr-5 mb-2"
            src={
              getDownloadFile?.data?.Data
                ? getDownloadFile?.data?.Data
                : Number(id) === 0
                  ? "https://static.vecteezy.com/system/resources/previews/004/141/669/non_2x/no-photo-or-blank-image-icon-loading-images-or-missing-image-mark-image-not-available-or-image-coming-soon-sign-simple-nature-silhouette-in-frame-isolated-illustration-vector.jpg"
                  : providersForUpdate.data?.Imagen
                    ? providersForUpdate.data?.Imagen
                    : "https://static.vecteezy.com/system/resources/previews/004/141/669/non_2x/no-photo-or-blank-image-icon-loading-images-or-missing-image-mark-image-not-available-or-image-coming-soon-sign-simple-nature-silhouette-in-frame-isolated-illustration-vector.jpg"
            }
          />
        </div>
        {messageError || (form.formState.submitCount > 0 && !formDataFile) ? (
          <text className="flex justify-content-center mt-1 mb-3 text-red-600">
            Seleccione una foto de perfil
          </text>
        ) : null}

        <div className="flex flex-row justify-content-center gap-2">
          <Button
            outlined
            onClick={() => setVisible(true)}
            icon={<Icon name="camera" className="mr-2"></Icon>}
          >
            Abrir la camara
          </Button>
          <Dialog
            visible={visible}
            onHide={() => setVisible(false)}
            footer={
              <Button
                outlined
                onClick={handleCaptureImage}
                icon={<Icon name="camera" className="mr-2"></Icon>}
              >
                Tomar foto
              </Button>
            }
          >
            <Webcam audio={false} ref={webcamRef} />
          </Dialog>

          <div key={2} className="card flex justify-content-center">
            <Toast ref={toast}></Toast>
            <FileUpload
              auto
              customUpload
              mode="basic"
              ref={fileUploadRef}
              // name="demo[]"
              // accept="image/*"
              maxFileSize={1000000}
              uploadHandler={upload}
              url="/api/Files/UploadFile"
              chooseLabel="Seleccionar imagen desde archivo"
            />
          </div>
        </div>
      </div>
      <Divider></Divider>

      <Form form={form}>
        <div className="flex flex-row formgrid grid m-0">
          <div className="flex flex-column field col-2 m-0">
            <h4>Opciones</h4>
            <InputSwitch required name="EsEmpresa" label="¿Es empresa?" />
          </div>
        </div>

        <div>
          <h4 className="p-0">Información básica</h4>
          <div className="flex flex-row formgrid grid">
            <div className="flex flex-column field col-6">
              <InputText
                autoFocus={true}
                label="Nombre(s)"
                name="ProveedorNombre"
                rules={{ required: "Este campo es requerido" }}
              />
            </div>
            <div className="flex flex-column field col-6">
              <InputText
                autoFocus={true}
                label="Apellido(s)"
                name="ProveedorApellido"
                rules={{ required: "Este campo es requerido" }}
              />
            </div>
          </div>
        </div>

        <div>
          <h4>Documentación</h4>
          <div className="flex flex-row formgrid grid">
            <div className="flex flex-column field col-6">
              <Dropdown
                name="DNITipoId"
                options={documentosTiposOptions}
                label="Tipo de documento de identidad"
                placeholder="Seleccione un tipo de documento"
                rules={{ required: "Este campo es requerido" }}
              />
            </div>
            <div className="flex flex-column field col-5">
              <InputMask
                name="DNI"
                autoFocus={false}
                label="Documento de identidad"
                disabled={!selectedDocumentType}
                mask={
                  selectedDocumentType === 2 ? "999-9999999-9" : "999999999"
                }
                placeholder={
                  selectedDocumentType === 2 ? "999-9999999-9" : "999999999"
                }
                rules={{
                  validate: { validateDocument },
                  required: "Este campo es requerido",
                  pattern: {
                    message: "Cédula incompleta",
                    value: new RegExp("^[0-9]{3}-[0-9]{7}-[0-9]{1}$"),
                  },
                }}
              />
              {errorMessage && form.formState.submitCount ? (
                <span className="text-sm text-red-600">{errorMessage}</span>
              ) : null}
            </div>
            <div className="flex flex-row field col-1">
              <Button
                className="ml-2 w-full"
                icon={<Icon name="user-share"></Icon>}
                style={{ height: "44px", marginTop: "28px" }}
                disabled={
                  errorMessage === ""
                  // selectedDocumentType === 2
                  //   ? documentoTexto?.length !== 11
                  //   : documentoTexto?.length !== 9
                }
                onClick={(event) => {
                  event.preventDefault();
                  if (filteredProviders.length > 0) {
                    const url = `http://localhost:5173/accounts-payable/provider${AccountsPayableRoutes[
                      AccountsPayableRouteNames.PROVIDER_DETAILS_PAGE
                    ].create({ id: `${filteredProviders[0].ProveedorId}` })}`;

                    window.open(url, "_blank");
                  }
                }}
              />
            </div>
          </div>
        </div>

        <div>
          <h4>Información de contacto</h4>
          <div className="flex flex-row formgrid grid">
            <div className="flex flex-column field col-6">
              <Dropdown
                label="Nacionalidad"
                name="NacionalidadId"
                options={nacionalidadOptions}
                placeholder="Seleccione una nacionalidad"
                rules={{ required: "Este campo es requerido" }}
              />
            </div>
            <div className="flex flex-column field col-6">
              <InputText
                autoFocus={false}
                name="CorreoElectronico"
                label="Correo electrónico"
                placeholder="ejemplo@nubeteck.com"
                rules={{
                  required: "Este campo es requerido",
                  pattern: {
                    value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i,
                    message: "Por favor, ingrese un correo electrónico válido",
                  },
                }}
              />
            </div>
            <div className="flex flex-column field col-6">
              <InputMask
                name="Telefono"
                label="Teléfono"
                autoFocus={false}
                mask="999-999-9999"
                placeholder="000-000-0000"
                rules={{ required: "Este campo es requerido" }}
              />
            </div>
            <div className="flex flex-column field col-6">
              <InputMask
                name="Celular"
                label="Celular"
                autoFocus={false}
                mask="999-999-9999"
                placeholder="000-000-0000"
                rules={{ required: "Este campo es requerido" }}
              />
            </div>
          </div>
        </div>
      </Form>
      <div className="flex justify-content-end pb-6 pt-6 ">
        <Button
          text
          className="mr-4"
          severity="danger"
          onClick={() => navigate(-1)}
        >
          Cancelar
        </Button>
        <Button onClick={form.handleSubmit(onSubmit)}>
          {Number(id) !== 0 ? "Editar" : "Guardar"}
        </Button>
      </div>
    </Card>
  );
};
export default ProviderFormPage;
