import { Tag } from "primereact/tag";
import { Dialog } from "@components";
import React, { useRef } from "react";
import { Icon } from "@nubeteck/icons";
import { Toast } from "primereact/toast";
import { useParams } from "react-router";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { Divider } from "primereact/divider";
import { EventUtils } from "@nubeteck/utils";
import { Dropdown } from "primereact/dropdown";
import { DataTable } from "primereact/datatable";
import { MultiSelect } from "primereact/multiselect";
import { ConfirmDialog } from "primereact/confirmdialog";
import { TreeNode, CardTable, ITreeTableColumnsProps } from "@nubeteck/prime";

import { IDataEquivalencia } from "../../interfaces";
import { useAccountingQuery, useAccountingMutation } from "../../hooks";
import { AccountCatalogForm, EquivalenciaDialog } from "../../components";

type TreeTableSelectionKey = {
  checked: boolean;
  partialChecked: boolean;
};

type TreeTableSelectionKeysType = {
  [key: string]: TreeTableSelectionKey;
};

interface ISucursales {
  SucursalId: number;
  SucursalNombre: string;
}

interface ICatalogo {
  code: number;
  name: string;
}

interface IValoresDeshacer {
  CuentaDesdeId?: number;
  CuentaHastaId?: number;
}

interface IData {
  Nombre: string;
  Cuenta: string;
  EstadoId: number;
  CuentaId: number;
  Descripcion: string;
  CuentaNombre: string;
  CuentaPadreId: number;
  CuentaPadreNombre: string;
}

interface TableData {
  id: number;
  cuenta: string;
  catalogo: string;
}

const AccountCatalogConsolidatePage = () => {
  const { id } = useParams();
  const toast = useRef<null | Toast>(null);

  const catalogId = parseInt(`${id}`, 10);

  const accountCatalogs = useAccountingQuery("AccountCatalog", "getAll");
  const consolidar = useAccountingMutation("AccountCatalog", "consolidar");
  const getEquivalencias = useAccountingQuery(
    "AccountCatalog",
    "getEquivalencias",
  );
  const changeStatusCuentaDetalle = useAccountingMutation(
    "AccountCatalog",
    "changeStateDetalle",
  );
  const deshacerEquivalencia = useAccountingMutation(
    "AccountCatalog",
    "deshacerEquivalencia",
  );
  const { data: accountCatalogData } = useAccountingQuery(
    "AccountCatalog",
    "getById",
    catalogId,
    { enabled: !!catalogId },
  );

  const [edit, setEdit] = React.useState(false);
  const [anular, setAnular] = React.useState(false);
  const [visible, setVisible] = React.useState(false);
  const [status, setStatus] = React.useState<number>();
  const [deshacer, setDeshacer] = React.useState(false);
  const [searchTerm, setSearchTerm] = React.useState("");
  const [deleteId, setDeleteId] = React.useState<number>();
  const [catalogo, setCatalogo] = React.useState<ICatalogo>();
  const [cuentaPadreId, setCuentaPadreId] = React.useState(0);
  const [tableData, setTableData] = React.useState<TableData[]>([]);
  const [showConsolidar, setShowConsolidar] = React.useState(false);
  const [modalConsolidar, setModalConsolidar] = React.useState(false);
  const [quitarSeleccion, setQuitarSeleccion] = React.useState(false);
  const [showEquivalencia, setShowEquivalencia] = React.useState(false);
  const [quitarSeleccionId, setQuitarSeleccionId] = React.useState<number>();
  const [searchTermConsolidar, setSearchTermConsolidar] = React.useState("");
  const [selectedNodeKey, setSelectedNodeKey] = React.useState<null | string>();
  const [valoresDeshacer, setValoresDeshacer] =
    React.useState<IValoresDeshacer>({});
  const [selectedAccounts, setSelectedAccounts] =
    React.useState<TreeTableSelectionKeysType>({});
  const [selectedAccountIds, setSelectedAccountIds] =
    React.useState<number[]>();
  const [selectedCities, setSelectedCities] = React.useState<
    null | { code: number; name: string }[]
  >(null);

  const selectedAccountDetails = (accountCatalogs.data || []).flatMap(
    (catalog) =>
      (catalog.CatalogosCuentasDetalles || [])
        .filter((account) => account.CuentaId === Number(selectedNodeKey))
        .map((account) => ({
          CuentaNombre: account.CuentaNombre,
          CatalogoNombre:
            (
              accountCatalogs.data?.find(
                (cat) => cat.CatalogoId === account.CatalogoId,
              ) || {}
            ).Nombre || "Desconocido",
        })),
  )[0] || { CuentaNombre: "Desconocida", CatalogoNombre: "Desconocido" };

  const groupedAccounts = React.useMemo(() => {
    return (accountCatalogs.data || [])
      .filter((catalog) => catalog && catalog.CatalogosCuentasDetalles)
      .flatMap((catalog) =>
        (catalog.CatalogosCuentasDetalles || [])
          .filter((account) => selectedAccountIds?.includes(account.CuentaId))
          .map((account) => ({
            ...account,
            CuentaId: account.CuentaId,
            CatalogoNombre:
              (
                accountCatalogs.data?.find(
                  (cat) => cat.CatalogoId === account.CatalogoId,
                ) || {}
              ).Nombre || "Desconocido",
          })),
      )
      .reduce(
        (acc, account) => {
          if (!acc[account.CatalogoNombre]) {
            acc[account.CatalogoNombre] = [];
          }
          acc[account.CatalogoNombre].push({
            CuentaId: account.CuentaId,
            CuentaNombre: account.CuentaNombre,
          });
          return acc;
        },
        {} as Record<string, { CuentaId: number; CuentaNombre: string }[]>,
      );
  }, [accountCatalogs.data, selectedAccountIds]);

  React.useEffect(() => {
    const data: TableData[] = [];
    for (const [catalogoNombre, cuentas] of Object.entries(groupedAccounts)) {
      const cuentasData = cuentas.map((cuenta) => ({
        id: cuenta.CuentaId,
        catalogo: catalogoNombre,
        cuenta: cuenta.CuentaNombre,
      }));

      data.push(...cuentasData);
    }

    setTableData(data);
  }, [groupedAccounts]);

  const deleteAccount = (id: number) => {
    setTableData((prev) => prev.filter((account) => account.id !== id));

    setSelectedAccounts((prev) => {
      const newSelectedAccounts = { ...prev };
      delete newSelectedAccounts[id];
      return newSelectedAccounts;
    });
  };

  React.useEffect(() => {
    if (selectedCities) {
      setCatalogo(undefined);
    }
  }, [selectedCities]);

  const sucursalOptions = React.useMemo(() => {
    if (!accountCatalogData?.SucursalesSelect?.length) return [];
    return accountCatalogData?.SucursalesSelect.map(
      (sucursal: ISucursales) => ({
        SucursalId: sucursal.SucursalId,
        SucursalNombre: sucursal.SucursalNombre,
      }),
    );
  }, [accountCatalogData?.SucursalesSelect]);

  const catalogosOptions = React.useMemo(() => {
    if (!accountCatalogs.data?.length) return [];

    const filteredCatalogos = accountCatalogs.data
      .filter(
        (catalog) =>
          catalog.CatalogoTipoId === 1 &&
          (selectedCities?.length !== 0 ||
            catalog.Sucursales?.some((sucursal) =>
              selectedCities?.some((city) => city.code === sucursal.SucursalId),
            )),
      )
      .map((catalog) => ({
        name: catalog.Nombre,
        code: catalog.CatalogoId,
      }));

    return filteredCatalogos;
  }, [accountCatalogs.data, selectedCities]);

  const filteredEquivalencias = React.useMemo(() => {
    return (
      getEquivalencias.data
        ?.filter(
          (item) => item.CuentaContableDesdeId === Number(selectedNodeKey),
        )
        .map((item) => item.CuentaContableHastaId) || []
    );
  }, [selectedNodeKey, getEquivalencias]);

  const handleSearchConsolidar = (term: string) => {
    setSearchTermConsolidar(term.toLowerCase());
  };

  const handleSearch = (term: string) => {
    setSearchTerm(term.toLowerCase());
  };

  const extractAccountIds = (accounts: TreeTableSelectionKeysType) => {
    return Object.keys(accounts)
      .filter((key) => accounts[key].checked)
      .map((key) => Number(key));
  };

  const dataConsolidar = React.useMemo(() => {
    const catalogo1 = accountCatalogs.data?.find(
      (catalogo1) => catalogo1.CatalogoId === catalogo?.code,
    );

    const mappedDetails =
      catalogo1?.CatalogosCuentasDetalles?.map((detalle) => ({
        key: detalle.CuentaId?.toString() || "",
        data: {
          Cuenta: detalle.Cuenta,
          CuentaId: detalle.CuentaId,
          EstadoId: detalle.EstadoId,
          Nombre: detalle.CuentaNombre,
          Descripcion: detalle.Descripcion,
          CuentaPadreId: detalle.CuentaPadreId,
        },
      })) || [];

    const nodesMap = new Map();
    mappedDetails.forEach((node) =>
      nodesMap.set(node.key, { ...node, children: [] }),
    );

    mappedDetails.forEach((node) => {
      const parentNode = nodesMap.get(node.data.CuentaPadreId?.toString());
      if (parentNode) {
        parentNode.children.push(nodesMap.get(node.key));
      }
    });

    const filteredNodes = Array.from(nodesMap.values())
      .filter((node) => !node.data.CuentaPadreId)
      .filter((node) => !filteredEquivalencias.includes(Number(node.key)));

    return filteredNodes;
    // return Array.from(nodesMap.values()).filter(
    //   (node) => !node.data.CuentaPadreId,
    // );
  }, [accountCatalogs.data, catalogo?.code, filteredEquivalencias]);

  React.useEffect(() => {
    const ids = extractAccountIds(selectedAccounts);
    setSelectedAccountIds(ids);
  }, [selectedAccounts]);

  const data = React.useMemo(() => {
    const mappedDetails = (accountCatalogs.data || []).flatMap((catalogo) =>
      catalogo.CatalogoId === Number(id)
        ? catalogo.CatalogosCuentasDetalles?.map((detalle) => ({
            key: detalle.CuentaId?.toString() || "",
            data: {
              Cuenta: detalle.Cuenta,
              CuentaId: detalle.CuentaId,
              EstadoId: detalle.EstadoId,
              Nombre: detalle.CuentaNombre,
              Descripcion: detalle.Descripcion,
              CuentaPadreId: detalle.CuentaPadreId,
            },
          })) || []
        : [],
    );

    const nodesMap = new Map();
    mappedDetails.forEach((node) =>
      nodesMap.set(node.key, { ...node, children: [] }),
    );

    mappedDetails.forEach((node) => {
      const parentNode = nodesMap.get(node.data.CuentaPadreId?.toString());
      if (parentNode) {
        parentNode.children.push(nodesMap.get(node.key));
      }
    });

    return Array.from(nodesMap.values()).filter(
      (node) => !node.data.CuentaPadreId,
    );
  }, [accountCatalogs.data, id]);

  const columns: ITreeTableColumnsProps<IData>[] = [
    {
      expander: true,
      field: "Cuenta",
      header: "Cuenta",
    },
    {
      field: "Nombre",
      header: "Nombre",
    },
    {
      field: "Descripcion",
      header: "Descripción",
    },
    {
      header: "Estado",
      field: "EstadoId",
      body: ({ data }) => (
        <Tag severity={data.EstadoId === 1 ? "info" : "danger"}>
          {data.EstadoId === 1 ? "Activo" : "Inactivo"}
        </Tag>
      ),
    },
  ];

  const filterNodes = (
    nodes: TreeNode<IData>[],
    searchTerm: string,
  ): TreeNode<IData>[] => {
    return nodes
      .map((node) => {
        const filteredChildren = filterNodes(node.children, searchTerm);
        // Verificar si el nodo actual o alguno de sus hijos debe ser incluido en el resultado
        if (
          String(node.data.Cuenta).includes(searchTerm) ||
          node.data.Nombre.toLowerCase().includes(searchTerm) ||
          node.data.Descripcion.toLowerCase().includes(searchTerm) ||
          filteredChildren.length > 0
        ) {
          return {
            ...node,
            children: filteredChildren,
          };
        }

        return null;
      })
      .filter((node) => node !== null);
  };

  const handleDeshacer = (e: IDataEquivalencia) => {
    if (e.sePuedeDeshacer) {
      setDeshacer(true);
      setValoresDeshacer({
        CuentaDesdeId: e.CuentaDesdeId,
        CuentaHastaId: e.CuentaHastaId,
      });
    } else {
      toast.current?.show({
        life: 3000,
        severity: "warn",
        summary: "Warning",
        detail:
          "Esta consolidación no se puede deshacer por que las cuentas estan siendo utilizadas",
      });
    }
  };

  return (
    <div className="w-full">
      <div className="surface-card">
        <CardTable
          type="tree"
          hideSelectFilter
          columns={columns}
          selectionMode="single"
          hideFilterSettingsIcon
          onSearch={handleSearch}
          selectionKeys={selectedNodeKey}
          value={filterNodes(data, searchTerm.toLowerCase())}
          onSelectionChange={(e) => {
            setSelectedNodeKey(String(e.value));
          }}
          title={
            accountCatalogs.data?.find((x) => x.CatalogoId === catalogId)
              ?.Nombre
          }
          headActions={[
            <Button
              size="small"
              label="Consolidar"
              key="ConsolideButton"
              onClick={() => setShowConsolidar(true)}
              disabled={!selectedNodeKey || selectedNodeKey === "null"}
            />,
            <Button
              size="small"
              key="EquivalenciaButton"
              label="Ventana de equivalencia"
              onClick={() => setShowEquivalencia(true)}
            />,
          ]}
          tableActions={(rowData) => {
            if (rowData.data.CuentaPadreId === null) {
              return [
                {
                  icon: "plus",
                  label: "Agregar cuenta",
                  onClick: () => {
                    setVisible(true);
                    setCuentaPadreId(parseInt(`${rowData.key}`));
                  },
                },
              ];
            } else {
              return [
                {
                  icon: "plus",
                  label: "Agregar cuenta",
                  onClick: () => {
                    setVisible(true);
                    setCuentaPadreId(parseInt(`${rowData.key}`));
                  },
                },
                {
                  icon: "pencil",
                  label: "Editar",
                  iconClassName: "text-primary-500",
                  onClick: () => {
                    setEdit(true);
                    setVisible(true);
                    setCuentaPadreId(parseInt(`${rowData.key}`));
                  },
                },
                {
                  icon: rowData.data.EstadoId === 1 ? "ban" : "chart-arcs",
                  label: rowData.data.EstadoId === 1 ? "Anular" : "Activar",
                  iconClassName:
                    rowData.data.EstadoId === 1
                      ? "text-red-600"
                      : "text-cyan-600",
                  onClick: () => {
                    setAnular(true);
                    setStatus(rowData.data.EstadoId);
                    setDeleteId(rowData.data.CuentaId);
                  },
                },
              ];
            }
          }}
        />
        <AccountCatalogForm
          edit={edit}
          open={visible}
          cuentaPadreId={cuentaPadreId}
          onClose={() => {
            setVisible(false);
            setEdit(false);
          }}
        />
        <EquivalenciaDialog
          catalogId={catalogId}
          visible={showEquivalencia}
          onDeshacer={handleDeshacer}
          onHide={EventUtils.callEvent(setShowEquivalencia, false)}
          title={`Ventana de equivalencia (${
            accountCatalogs.data?.find((x) => x.CatalogoId === catalogId)
              ?.Nombre ?? ""
          })`}
        />
        <ConfirmDialog
          visible={quitarSeleccion}
          message={`¿Desea quitar esta seleccion?`}
          accept={() => deleteAccount(Number(quitarSeleccionId))}
          onHide={() => {
            setQuitarSeleccion(false);
            setQuitarSeleccionId(0);
          }}
          header={
            <div className="flex flex-row align-items-center mt-0 mb-0">
              <Icon size={"small"} className="mr-2" name="alert-circle"></Icon>
              <p className="text-base">{`Quitar seleccion`}</p>
            </div>
          }
        />
        <ConfirmDialog
          visible={anular}
          onHide={() => setAnular(false)}
          message={`¿Está seguro de que desea ${status === 1 ? "anular" : "activar"} esta cuenta?`}
          accept={() =>
            changeStatusCuentaDetalle.mutateAsync({
              CuentaId: Number(deleteId),
              EstadoId: status === 1 ? 2 : 1,
            })
          }
          header={
            <div className="flex flex-row align-items-center mt-0 mb-0">
              <Icon size={"small"} className="mr-2" name="alert-circle"></Icon>
              <p className="text-base">{`${status === 1 ? "Anular" : "Activar"} cuenta`}</p>
            </div>
          }
        />
        <ConfirmDialog
          visible={deshacer}
          onHide={() => setDeshacer(false)}
          message={`¿Está seguro de que desea deshacer esta equivalencia?`}
          header={
            <div className="flex flex-row align-items-center mt-0 mb-0">
              <Icon size={"small"} className="mr-2" name="alert-circle"></Icon>
              <p className="text-base">{`Deshacer equivalencia`}</p>
            </div>
          }
          accept={() =>
            deshacerEquivalencia.mutateAsync(
              {
                CuentaDesdeId: valoresDeshacer?.CuentaDesdeId ?? 0,
                CuentaHastaId: valoresDeshacer?.CuentaHastaId ?? 0,
              },
              {
                onSuccess: () => {
                  setValoresDeshacer({});
                  setDeshacer(false);
                },
              },
            )
          }
        />
        <ConfirmDialog
          visible={modalConsolidar}
          onHide={() => {
            setModalConsolidar(false);
          }}
          reject={() => {
            setModalConsolidar(false);
          }}
          message={`¿Está seguro de que desea consolidar las cuentas seleccionadas con la cuenta "${selectedAccountDetails.CuentaNombre}" del catálogo "${selectedAccountDetails.CatalogoNombre}" ?`}
          header={
            <div className="flex flex-row align-items-center mt-0 mb-0">
              <Icon size={"small"} className="mr-2" name="alert-circle"></Icon>
              <p className="text-base">Consolidar cuenta</p>
            </div>
          }
          accept={() => {
            //console.log(selectedNodeKey, selectedAccountIds);
            consolidar.mutateAsync(
              {
                CuentaId: Number(selectedNodeKey),
                CuentasIds: selectedAccountIds ?? [],
              },
              {
                onSuccess: () => {
                  setAnular(false), setModalConsolidar(false);
                  setSelectedNodeKey("");
                  setShowConsolidar(false);
                  setSelectedAccounts({});
                  setCatalogo(undefined);
                  setSelectedCities([]);
                },
              },
            );
          }}
        />
        <Dialog
          visible={showConsolidar}
          className="flex justify-content-center w-8 z-5"
          header={
            <div className="m-3">Consolidación con catálogos de cuentas</div>
          }
          onHide={() => {
            setSelectedAccounts({});
            setShowConsolidar(false), setSelectedNodeKey("");
            setSearchTerm("");
            setCatalogo(undefined);
            setSelectedCities([]);
          }}
          footer={
            <Button
              label="Consolidar"
              onClick={() => {
                setModalConsolidar(true);
                setSearchTerm("");
              }}
              disabled={
                !Array.isArray(selectedAccountIds) ||
                selectedAccountIds.length === 0
              }
            />
          }
        >
          <CardTable
            type="tree"
            hideSelectFilter
            hideBorderStyles
            hideGlobalSearch
            tableActions={[]}
            hideFilterSettingsIcon
            selectionMode="checkbox"
            columns={columns as never} // TODO: Vista mal implementada
            selectionKeys={selectedAccounts}
            onSearch={handleSearchConsolidar}
            title={`Catálogo: ${catalogo?.name ?? ""}`}
            value={filterNodes(
              dataConsolidar,
              searchTermConsolidar.toLowerCase(),
            )}
            onSelectionChange={(e) => {
              setSelectedAccounts(e.value as TreeTableSelectionKeysType);
            }}
            headActions={[
              <MultiSelect
                key={1}
                name="Sucursales"
                value={selectedCities}
                options={sucursalOptions}
                className="p-inputtext-sm"
                optionLabel="SucursalNombre"
                onChange={(e) => setSelectedCities(e.value)}
                placeholder="Seleccione al menos una sucursal"
              />,
              <Dropdown
                key={2}
                name="Catálogos"
                value={catalogo}
                optionLabel="name"
                className="p-inputtext-sm"
                options={catalogosOptions}
                disabled={!selectedCities?.length}
                placeholder="Seleccione un catálogo"
                onChange={(e) => setCatalogo(e.value)}
              />,
            ]}
          />
          {/* <p>Cuentas seleccionadas:</p>
          <div dangerouslySetInnerHTML={{ __html: catalogoMessage }} /> */}
          <Divider></Divider>
          <h3>Cuentas seleccionadas</h3>
          <DataTable
            size="small"
            value={tableData}
            rowGroupMode="rowspan"
            groupRowsBy={"catalogo"}
            title="Cuentas seleccionadas"
          >
            <Column field="catalogo" header="Nombre del Catálogo" />
            <Column field="cuenta" header="Cuentas" />
            <Column
              header="Eliminar"
              className="flex justify-content-center"
              body={(rowData) => (
                <Button
                  text
                  rounded
                  size="small"
                  aria-label="Filter"
                  //onClick={() => deleteAccount(rowData.id)}
                  icon={<Icon name="x" size={"small"} className="mr-2"></Icon>}
                  onClick={() => {
                    setQuitarSeleccion(true);
                    setQuitarSeleccionId(rowData.id);
                  }}
                />
              )}
            />
          </DataTable>
        </Dialog>
      </div>
    </div>
  );
};

export default AccountCatalogConsolidatePage;
