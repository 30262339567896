// TABLE_NAME: ConfiguracionesGeneralesTiposDatos
export const NUMBER = 1;
export const SHORT_TEXT = 2;
export const LONG_TEXT = 3;
export const HTML = 4;
export const BOOLEAN = 5;
export const EMAIL = 6;
export const OPTIONS = 7;
export const MULTI_OPTIONS = 8;
export const EDITOR = 9;
export const RADIO = 10;
export const CLAVE = 11;
