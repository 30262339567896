import React from "react";
import { Icon } from "@nubeteck/icons";
import { useGlobalQuery } from "@hooks";
import { useForm } from "react-hook-form";
import { Button } from "primereact/button";
import { Form, Calendar } from "@components";
import { Constants, Permissions } from "@core";
import { Skeleton } from "primereact/skeleton";
import { Nullable } from "primereact/ts-helpers";
import { useAuthPermissions } from "@nubeteck/auth";
import { SelectButton } from "primereact/selectbutton";
import { OverlayPanel } from "primereact/overlaypanel";
import { Datejs, JsxUtil, EventUtils } from "@nubeteck/utils";
import { Layout, Responsive, WidthProvider } from "react-grid-layout";

import { Widget, Breakpoints } from "../../interfaces";
import { DashboardDrawer } from "../../components/dashboard-drawer";
import {
  DashboardKPICard,
  DashboardChartCard,
  DashboardImageCard,
  DashboardTableCard,
} from "../../components/widgets";

import "react-resizable/css/styles.css";
import "react-grid-layout/css/styles.css";

export interface IDashboardTemplatePageProps {
  settingId: number;
}

interface IDashboard {
  name: string;
  widgets: Widget[];
  layouts: Record<Breakpoints, Layout[]>;
}

const components: Record<
  string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (props: any) => React.JSX.Element
> = {
  kpi: DashboardKPICard,
  chart: DashboardChartCard,
  table: DashboardTableCard,
  image: DashboardImageCard,
};

const ResponsiveGridLayout = WidthProvider(Responsive);

const date = Datejs();

const datesMapping = {
  3: [date.startOf("year").toDate(), date.endOf("year").toDate()],
  1: [date.startOf("month").toDate(), date.endOf("month").toDate()],
  2: [
    date.subtract(1, "month").startOf("month").toDate(),
    date.subtract(1, "month").endOf("month").toDate(),
  ],
};

const DashboardTemplatePage = ({ settingId }: IDashboardTemplatePageProps) => {
  const permissions = useAuthPermissions();
  const overlayPanel = React.useRef<OverlayPanel>(null);

  const { data, refetch, isPending } = useGlobalQuery(
    "GeneralSettings",
    "getById",
    settingId,
    { enabled: !!settingId },
  );

  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [dates, setDates] = React.useState<Nullable<(Date | null)[]>>(
    datesMapping[1],
  );

  const items = [
    { name: "Este mes", value: datesMapping[1] },
    { name: "Ultimo mes", value: datesMapping[2] },
    { name: "Este año", value: datesMapping[3] },
  ];

  const form = useForm();

  const result = JSON.parse(data?.Valor ?? "{}") as IDashboard;

  return (
    <>
      {JsxUtil.renderLoader(
        isPending,
        <div className="flex flex-column flex-1 h-full">
          <Skeleton height="3rem" className="mb-2" />
          <div className="flex flex-1 gap-2">
            <Skeleton height="8rem" />
            <Skeleton height="8rem" />
            <Skeleton height="8rem" />
            <Skeleton height="8rem" />
          </div>
          <Skeleton height="100%" className="mt-2" />
        </div>,
      )(
        <Form form={form}>
          <div className="flex flex-1 flex-wrap gap-2 mb-2 align-items-center justify-content-between">
            <span className="text-2xl font-semibold text-800">
              {result?.name ?? ""}
            </span>
            <div className="flex flex-wrap align-items-center gap-2">
              <SelectButton
                value={dates}
                options={items}
                optionLabel="name"
                className="w-full md:w-20rem"
                onChange={(e) => setDates(e.value)}
              />
              <div className="card relative flex justify-content-center">
                <Button
                  type="button"
                  tooltip="Seleccionar rango de fecha"
                  tooltipOptions={{ position: "bottom" }}
                  aria-label="Seleccionar rango de fecha"
                  icon={<Icon size={20} name="calendar" />}
                  onClick={(e) => overlayPanel.current?.toggle(e)}
                />
                <OverlayPanel ref={overlayPanel}>
                  <Calendar
                    locale="es"
                    value={dates}
                    readOnlyInput
                    hideOnRangeSelection
                    name="CalendarUnused"
                    selectionMode="range"
                    label="Selecciona un rango de fecha"
                    placeholder="Seleccione un rango de fecha"
                    className="p-inputtext-sm w-full md:w-16rem"
                    onChange={(e: {
                      value: React.SetStateAction<Nullable<(Date | null)[]>>;
                    }) => setDates(e.value)}
                  />
                </OverlayPanel>
              </div>
              <Button
                type="button"
                severity="success"
                tooltip="Refrescar datos"
                onClick={() => refetch()}
                aria-label="Refrescar datos"
                tooltipOptions={{ position: "bottom" }}
                icon={<Icon size={20} name="refresh" />}
              />
              {permissions?.hasPermission(
                Permissions.CONFIGURE_ADMIN_PANEL,
              ) && (
                <Button
                  type="button"
                  severity="secondary"
                  tooltip="Configuraciones"
                  aria-label="Configuraciones"
                  tooltipOptions={{ position: "bottom" }}
                  icon={<Icon size={20} name="settings" />}
                  onClick={EventUtils.callEvent(setDrawerOpen, true)}
                />
              )}
            </div>
          </div>
          <ResponsiveGridLayout
            rowHeight={35}
            useCSSTransforms
            margin={[10, 10]}
            maxRows={Infinity}
            isDroppable={false}
            isDraggable={false}
            isResizable={false}
            layouts={result.layouts}
            containerPadding={[0, 0]}
            breakpoints={Constants.breakpoints}
            cols={{ xs: 4, sm: 6, xl: 32, lg: 24, md: 13 }}
          >
            {result?.widgets?.map((widget) => {
              const Component = widget.type.includes("chart")
                ? components["chart"]
                : components[widget.type];
              return (
                <div key={widget.i} style={{ display: "flex" }}>
                  <Component
                    {...widget}
                    animated
                    dates={dates}
                    editable={false}
                  />
                </div>
              );
            })}
          </ResponsiveGridLayout>
        </Form>,
      )}
      <DashboardDrawer
        visible={drawerOpen}
        settingId={settingId}
        onHide={EventUtils.callEvent(setDrawerOpen, false)}
      />
    </>
  );
};

export default DashboardTemplatePage;
