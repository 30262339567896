import API from "@lib/api";
import { QueryUtils } from "@utils";
import { IModule, Messages, IPermissions } from "@interfaces";

import { BaseApi } from "./base-api";

export class ModulesApi extends BaseApi {
  protected static api = API({ namespace: "Modulo" });
  protected static messages: Messages<typeof ModulesApi> = {
    // update: {
    //   loading: "Actualizando moneda...",
    //   success: "Moneda actualizada correctamente.",
    //   error: (error) => `Hubo un error al actualizar la moneda: ${error}`,
    // },
    // create: {
    //   loading: "Creando una nueva moneda...",
    //   success: "Moneda creada correctamente.",
    //   error: (error) => `Hubo un error al crear la nueva moneda: ${error}`,
    // },
  };

  public static async getAll() {
    const modules = await this.api.get<IModule[]>("GetAll");
    return modules.data;
  }

  public static async getPermissions(data: {
    RolId: number;
    ModuloId: number;
  }) {
    const permissions = await this.api.get<IPermissions[]>(
      `GetPermisos${QueryUtils.filter(data)}`,
    );
    return permissions.data;
  }

  // public static async create(data: IModule) {
  //   const module = await this.api.post("Create", data);
  //   return module.data;
  // }

  //   public static async getCreate(area: string) {
  //     const result = await this.api.get<IModuleCreate>(`GetForCreate/${area}`);
  //     return result.data;
  //   }

  //   public static async getUpdate(id: number) {
  //     const result = await this.api.get<IModuleUpdate>(`GetForUpdate/${id}`);
  //     return result.data;
  //   }

  // public static async update(data: Partial<IModule>) {
  //   const module = await this.api.put(`Update/${data.RolId}`, data);
  //   return module.data;
  // }

  //   public static async delete(id: number) {
  //     return await this.api.post("ChangeState", { EstadoId: 3, FiltroId: id });
  //   }
}
