import React from "react";
import API from "@lib/api";
import { Reducers } from "@core";
import { IFile } from "@interfaces";
import { Icon } from "@nubeteck/icons";
import { useParams } from "react-router";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { EventUtils } from "@nubeteck/utils";
import { DataTable } from "primereact/datatable";
import { useQueries } from "@tanstack/react-query";
import { IconFile, EmptyMessage } from "@components";
import { GeneralAccountingFeature } from "@features";
import { ConfirmPopup } from "primereact/confirmpopup";

export interface IFileTableProps {
  files: number[];
  setFiles?: (files: number[]) => void;
}

// TODO: Por alguna razón al eliminar un archivo desde el formulario no hace que se refresque la tabla en el detalle. Arreglar luego

const FileTable = ({ files, setFiles }: IFileTableProps) => {
  const { id } = useParams();
  const [state, dispatch] = React.useReducer(Reducers.DialogReducer, {
    id: 0,
    open: false,
  });
  const [currentTarget, setCurrentTarget] = React.useState<null | HTMLElement>(
    null,
  );

  const handleDeleteClick = (fileId: number, target: HTMLElement) => {
    setCurrentTarget(target);
    dispatch({
      payload: fileId,
      type: "OPEN_DIALOG",
    });
  };

  const { isLoading, data: filesData } = useQueries({
    combine: (results) => ({
      isError: results.some((result) => result.isError),
      isLoading: results.some((result) => result.isLoading),
      data: results
        .map((result) => result.data)
        .filter((data): data is IFile => data !== undefined),
    }),
    queries: files.map((id) => ({
      staleTime: Infinity,
      queryKey: ["downloadFile", id],
      queryFn: async (): Promise<IFile> => {
        const response = await API({
          namespace: "MovimientosContables",
        }).get<IFile>(`DownloadFile/${id}`);
        return response.data;
      },
    })),
  });

  const { data: updateFormValues } =
    GeneralAccountingFeature.useAccountingQuery(
      "AccountingMovements",
      "getUpdate",
      Number(id ?? 0),
      {
        enabled: !!Number(id ?? 0),
      },
    );
  const { mutate: updateAccountingMovement } =
    GeneralAccountingFeature.useAccountingMutation(
      "AccountingMovements",
      "update",
    );

  const accept = React.useCallback(() => {
    const filteredFiles = files.filter((item) => item !== state.id);

    setFiles?.(filteredFiles);

    if (updateFormValues) {
      return updateAccountingMovement({
        ...updateFormValues,
        MovimientosContablesAdjuntos: filteredFiles,
      });
    }
  }, [files, setFiles, state.id, updateAccountingMovement, updateFormValues]);

  const renderEmptyMessage = React.useCallback(() => {
    return <EmptyMessage message="No hay archivos" />;
  }, []);

  return (
    <>
      <DataTable
        rows={8}
        size="small"
        showGridlines
        value={filesData}
        scrollable={true}
        loading={isLoading}
        paginatorLeft={true}
        style={{ minHeight: 300 }}
        paginator={filesData.length > 8}
        emptyMessage={renderEmptyMessage()}
        rowsPerPageOptions={[8, 16, 32, 64]}
      >
        <Column
          header="Archivo"
          body={(rowData: IFile) => (
            <div className="flex align-items-center gap-2">
              <IconFile name={rowData.ContentType?.split("/")[1]} />
              {rowData.FileName}
            </div>
          )}
        ></Column>
        <Column
          header="Peso"
          body={(rowData: IFile) => {
            const fileSizeInMB = (rowData.FileSize ?? 0) / (1024 * 1024); // bytes to MB
            return fileSizeInMB.toFixed(2) + " MB";
          }}
        ></Column>
        <Column
          header="Acciones"
          body={(rowData: IFile) => (
            <div className="flex gap-3">
              <Button
                text
                type="button"
                severity="info"
                label="Descargar"
                className="p-1 w-fit"
                onClick={() => {
                  const file = filesData.find(
                    (item) => item.FileId === rowData.FileId,
                  );
                  if (file) {
                    const base64Data = file.Data as string;
                    const contentType =
                      file.ContentType ?? "application/octet-stream";
                    const downloadLink = document.createElement("a");
                    downloadLink.href = `data:${contentType};base64,${base64Data}`;
                    downloadLink.target = "_blank";
                    downloadLink.download = file.FileName ?? "download";
                    downloadLink.click();
                  }
                }}
              />
              <Button
                text
                type="button"
                label="Eliminar"
                severity="danger"
                className="p-1 w-fit"
                onClick={(e) =>
                  handleDeleteClick(
                    rowData.FileId ?? 0,
                    e.currentTarget as never,
                  )
                }
              />
            </div>
          )}
        ></Column>
      </DataTable>
      <ConfirmPopup
        accept={accept}
        visible={state.open}
        target={currentTarget as never}
        message="¿Confirma eliminar este archivo?"
        icon={<Icon color="#e74c3c" name="exclamation-circle" />}
        onHide={EventUtils.callEvent(dispatch, { type: "CLOSE_DIALOG" })}
      />
    </>
  );
};

export default FileTable;
