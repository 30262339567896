import React from "react";
import { sort } from "radash";
import { Icon } from "@nubeteck/icons";
import { Reducers, Statuses } from "@core";
import { Button } from "primereact/button";
import { EventUtils } from "@nubeteck/utils";
import { ChangeLogDialog } from "@components";
import { useAuthPermissions } from "@nubeteck/auth";
import { confirmDialog } from "primereact/confirmdialog";
import { useGlobalQuery, useGlobalMutation } from "@hooks";
import {
  CardTable,
  StatusTag,
  ActionMenu,
  IDataTableColumnsProps,
} from "@nubeteck/prime";
import {
  IStrategicPlansActivityIndicator,
  IStrategicPlansActivityIndicatorType,
} from "@interfaces";

import { Permissions } from "../../core";
import { IndicatorDialog } from "../indicator-dialog";

export interface IIndicatorTabProps {
  title: string;
  activityId: number;
  indicatorType: IStrategicPlansActivityIndicatorType;
}

const IndicatorTab = ({
  title,
  activityId,
  indicatorType,
}: IIndicatorTabProps) => {
  const permissions = useAuthPermissions();
  const changeState = useGlobalMutation(
    "StrategicPlansIndicator",
    "changeState",
  );
  const changeOrder = useGlobalMutation(
    "StrategicPlansIndicator",
    "changeOrder",
  );
  const indicators = useGlobalQuery(
    "StrategicPlansIndicator",
    "getAll",
    { ActividadId: activityId, IndicadorTipoId: indicatorType.TipoIndicadorId },
    { enabled: !!activityId && !!indicatorType.TipoIndicadorId },
  );

  const [state, dispatch] = React.useReducer(Reducers.DialogReducer, {
    id: 0,
    open: false,
  });
  const [changeLogState, changeLogDispatch] = React.useReducer(
    Reducers.DialogReducer,
    { id: 0, open: false },
  );

  const handleRestore = React.useCallback(
    (id: number) => {
      confirmDialog({
        acceptLabel: "Activar",
        header: "Activar indicador",
        acceptClassName: "p-button-sm",
        message: "¿Está seguro de que desea activar este indicador?",
        accept: async () => {
          await changeState.mutateAsync({
            IndicadorId: id,
            EstadoId: Statuses.ACTIVE_CODE,
          });
        },
      });
    },
    [changeState],
  );

  const handleNullify = React.useCallback(
    (id: number) => {
      confirmDialog({
        acceptLabel: "Anular",
        header: "Anular indicador",
        message: "¿Está seguro de que desea anular este indicador?",
        accept: async () => {
          await changeState.mutateAsync({
            IndicadorId: id,
            EstadoId: Statuses.NULLIFIED_CODE,
          });
        },
      });
    },
    [changeState],
  );

  const columns: IDataTableColumnsProps<IStrategicPlansActivityIndicator>[] = [
    { field: "Indicador", header: "Descripcion" },
    { header: "Tipo de medición", field: "TipoMedicionResultadoNombre" },
    {
      header: "Estado",
      field: "EstadoNombre",
      style: { width: "150px" },
      body: (data) => <StatusTag status={data.EstadoNombre ?? "Activo"} />,
    },
    {
      field: "Orden",
      header: "Ordenar",
      style: { width: "100px" },
      body: (data) => {
        if (
          !permissions.hasPermission(Permissions.ORDER_ACTIVITIES_INDICATOR_POA)
        )
          return null;
        const count = indicators.data?.length ?? 0;
        return (
          <ActionMenu
            floatMenu={false}
            items={[
              {
                label: "",
                icon: "arrow-up",
                disabled: data.Orden === 1,
                onClick: () => handleOrderRows(data.IndicadorId, "up"),
              },
              {
                label: "",
                icon: "arrow-down",
                disabled: count === data.Orden,
                onClick: () => handleOrderRows(data.IndicadorId, "down"),
              },
            ]}
          />
        );
      },
    },
  ];

  const value = React.useMemo(() => {
    return sort(indicators.data ?? [], (item) => item.Orden);
  }, [indicators.data]);

  const moveItem = React.useCallback(
    (id: number, direction: "up" | "down") => {
      const index = value.findIndex((item) => item.IndicadorId === id);
      if (index === -1) return value;
      const newIndex = direction === "up" ? index - 1 : index + 1;
      if (newIndex < 0 || newIndex >= value.length) return value;
      const updatedItems = [...value];
      updatedItems[index].Orden = newIndex + 1;
      updatedItems[newIndex].Orden = index + 1;
      return updatedItems;
    },
    [value],
  );

  const handleOrderRows = React.useCallback(
    (id: number, type: "up" | "down") => {
      const itemsMoved = moveItem(id, type).map((item) => ({
        Orden: item.Orden,
        IndicadorId: item.IndicadorId,
      }));
      return changeOrder.mutateAsync(itemsMoved);
    },
    [changeOrder, moveItem],
  );

  const orderCount = React.useMemo(() => {
    if (state.open) {
      return indicators.data?.length ?? 0;
    }
  }, [indicators.data?.length, state.open]);

  return (
    <>
      <CardTable<IStrategicPlansActivityIndicator>
        type="data"
        title={title}
        hideSelectFilter
        hideBorderStyles
        columns={columns}
        value={value ?? []}
        dataKey="IndicadorId"
        hideFilterSettingsIcon
        loading={indicators.isFetching}
        onRefresh={() => indicators.refetch()}
        headActions={[
          <Button
            size="small"
            key="NewIndicator"
            loading={indicators.isPending}
            icon={<Icon size={20} name="plus" className="mr-1" />}
            label={`Agregar ${indicatorType.TipoIndicadorNombre.toLowerCase()}`}
            onClick={EventUtils.callEvent(dispatch, {
              payload: 0,
              type: "OPEN_DIALOG",
            })}
            disabled={
              !permissions.hasPermission(
                Permissions.CREATE_ACTIVITIES_INDICATOR_POA,
              )
            }
          />,
        ]}
        tableActions={(data) => [
          {
            icon: "pencil",
            label: "Editar",
            disabled: !permissions.hasPermission(
              Permissions.EDIT_ACTIVITIES_INDICATOR_POA,
            ),
            onClick: EventUtils.callEvent(dispatch, {
              type: "OPEN_DIALOG",
              payload: data.IndicadorId ?? 0,
            }),
          },
          {
            icon: "ban",
            label: "Anular",
            iconClassName: "text-red-500",
            onClick: EventUtils.callEvent(handleNullify, data.IndicadorId ?? 0),
            disabled:
              data.EstadoId === Statuses.NULLIFIED_CODE ||
              !permissions.hasPermission(
                Permissions.NULLIFY_ACTIVITIES_INDICATOR_POA,
              ),
          },
          {
            icon: "plug",
            label: "Activar",
            iconClassName: "text-primary-500",
            onClick: EventUtils.callEvent(handleRestore, data.IndicadorId ?? 0),
            disabled:
              data.EstadoId !== Statuses.NULLIFIED_CODE ||
              !permissions.hasPermission(
                Permissions.RESTORE_ACTIVITIES_INDICATOR_POA,
              ),
          },
          {
            icon: "book",
            label: "Historial de cambios",
            disabled: !permissions.hasPermission(
              Permissions.SEE_LOGS_ACTIVITIES_INDICATOR_POA,
            ),
            onClick: EventUtils.callEvent(changeLogDispatch, {
              type: "OPEN_DIALOG",
              payload: data.IndicadorId ?? 0,
            }),
          },
        ]}
      />
      <ChangeLogDialog
        entityName="indicadorid"
        entityId={changeLogState.id}
        visible={changeLogState.open}
        onHide={EventUtils.callEvent(changeLogDispatch, {
          type: "CLOSE_DIALOG",
        })}
      />
      <IndicatorDialog
        visible={state.open}
        indicatorId={state.id}
        activityId={activityId}
        orderCount={orderCount ?? 0}
        indicatorTypeId={indicatorType.TipoIndicadorId ?? 0}
        onHide={EventUtils.callEvent(dispatch, { type: "CLOSE_DIALOG" })}
      />
    </>
  );
};

export default IndicatorTab;
