import API from "@lib/api";
import { BaseApi } from "@api";
import {
  Messages,
  IStrategicPlansTemplatesIndicator,
  IStrategicPlansTemplatesIndicatorGetForCreate,
  IStrategicPlansTemplatesIndicatorGetForUpdate,
} from "@interfaces";

export class StrategicPlansTemplatesIndicatorApi extends BaseApi {
  protected static api = API({ namespace: "PlanesEstrategicos" });
  protected static messages: Messages<
    typeof StrategicPlansTemplatesIndicatorApi
  > = {
    create: {
      error: (error) => error,
      loading: "Creando nueva plantilla...",
      success: "Plantilla creada correctamente.",
    },
    update: {
      error: (error) => error,
      loading: "Actualizando plantilla...",
      success: "Plantilla actualizada correctamente.",
    },
    changeState: {
      error: (error) => error,
      loading: "Actualizando estado de la plantilla...",
      success: "Estado de la plantilla actualizada correctamente.",
    },
  };

  public static async getAll() {
    const result = await this.api.get<IStrategicPlansTemplatesIndicator[]>(
      "GetAllIndicadorCualitativoPlantilla",
    );
    return result.data;
  }

  // public static async getAllDetails(id: number) {
  //   const result = await this.api.get<IStrategicPlansTemplatesDetails[]>(
  //     `GetAllPlantillaDetalle/${id}`,
  //   );
  //   return result.data;
  // }

  public static async getById(id: number) {
    const result = await this.api.get<IStrategicPlansTemplatesIndicator>(
      `GetIndicadorCualitativoPlantillaById/${id}`,
    );
    return result.data;
  }

  public static async create(data: IStrategicPlansTemplatesIndicator) {
    const result = await this.api.post<IStrategicPlansTemplatesIndicator>(
      "AddPlanEstrategicoIndicadorCualitativoPlantilla",
      data,
    );
    return result.data;
  }

  public static async getCreate() {
    const result =
      await this.api.get<IStrategicPlansTemplatesIndicatorGetForCreate>(
        "GetCreatePlanEstrategicoIndicadorCualitativoPlantilla",
      );
    return result.data;
  }

  public static async update(data: Partial<IStrategicPlansTemplatesIndicator>) {
    const result = await this.api.put(
      `UpdatePlanEstrategicoIndicadorCualitativoPlantilla/${data.PlantillaId}`,
      data,
    );
    return result.data;
  }

  public static async getUpdate(id: number) {
    const result =
      await this.api.get<IStrategicPlansTemplatesIndicatorGetForUpdate>(
        `GetUpdatePlanEstrategicoIndicadorCualitativoPlantilla/${id}`,
      );
    return result.data;
  }

  public static async changeState(data: {
    PlantillaId: number;
    EstadoId: number;
  }) {
    const result = await this.api.post(
      "ChangeStatePlanEstrategicoIndicadorCualitativoPlantilla",
      data,
    );
    return result.data;
  }
}
