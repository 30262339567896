import React from "react";
import { Icon } from "@nubeteck/icons";
import { Card } from "@nubeteck/prime";
import { Image } from "primereact/image";
import { MenuItem } from "primereact/menuitem";
import { useForm, useWatch } from "react-hook-form";
import { ContextMenu } from "primereact/contextmenu";

import { Widget, IDashboardComponent } from "../../../interfaces";
import { DashboardImageDialog } from "../../dashboard-image-dialog";

interface IDashboardProps {
  source: string;
}

type IDashboardImageCardProps = {
  (props: IDashboardProps): React.JSX.Element;
  Dashboard: React.FC<IDashboardProps & IDashboardComponent>;
};

const ImageCard: IDashboardImageCardProps = ({ source }) => {
  return (
    <Card hideContentPadding>
      <Image width="100%" src={source} />
    </Card>
  );
};

ImageCard.Dashboard = function Dashboard({
  i,
  source,
  onEditable,
  onRemoveWidget,
  onUpdateWidget,
}) {
  const contextMenuRef = React.useRef<ContextMenu>(null);

  const [dialogSettingsOpen, setDialogSettingsOpen] = React.useState(false);

  const form = useForm<{ source: string }>();
  const { reset } = form;

  const widgets = useWatch({ name: "widgets" }) as Widget[];

  React.useEffect(() => {
    if (source) {
      reset({ source }, { keepDefaultValues: false });
    }
  }, [reset, source]);

  const onRightClick = React.useCallback((event: React.MouseEvent) => {
    if (contextMenuRef.current) {
      contextMenuRef.current.show(event);
    }
  }, []);

  const onSubmit = (values: { source: string }) => {
    const widgetIndex = widgets.findIndex((widget) => widget.i === i);
    onUpdateWidget?.(widgetIndex, { i, type: "image", source: values.source });
    setDialogSettingsOpen(false);
    onEditable?.(true);
  };

  const items: MenuItem[] = [
    {
      label: "Fuente",
      icon: <Icon size={20} name="database" className="mr-2" />,
      command: () => {
        onEditable?.(false);
        setDialogSettingsOpen(true);
      },
    },
    {
      label: "Eliminar",
      command: () => onRemoveWidget?.(i),
      icon: <Icon size={20} name="trash" className="mr-2" />,
    },
  ];

  return (
    <>
      <div className="flex flex-1" onContextMenu={onRightClick}>
        <ImageCard source={source} />
      </div>
      {/* ContextMenu */}
      <ContextMenu model={items} breakpoint="767px" ref={contextMenuRef} />
      <DashboardImageDialog
        form={form}
        onSubmitClick={onSubmit}
        visible={dialogSettingsOpen}
        onHide={() => {
          onEditable?.(true);
          setDialogSettingsOpen(false);
        }}
      />
    </>
  );
};

ImageCard.Dashboard.displayName = "ImageCard.Dashboard";

export default ImageCard;
