import React from "react";
import { omit, isArray } from "radash";
import { Button } from "primereact/button";
import { DialogProps } from "primereact/dialog";
import { ArrayUtils, EventUtils } from "@nubeteck/utils";
import { useGlobalQuery, useGlobalMutation } from "@hooks";
import { IStrategicPlansDetailsFields } from "@interfaces";
import { SettingDataTypes, SettingDataSources } from "@core";
import { useForm, useWatch, useFieldArray } from "react-hook-form";
import { CardTable, IDataTableColumnsProps } from "@nubeteck/prime";
import {
  Form,
  Dialog,
  Dropdown,
  InputText,
  InputSwitch,
  InputTextarea,
} from "@components";

import { usePeiQuery } from "../../hooks";

export interface ISettingsDetailsFieldsDialogProps extends DialogProps {
  fieldId?: number;
  detailTypeId: number;
}

type Option = { label: string; value: string };

type FormValues = IStrategicPlansDetailsFields & {
  FuenteId: number;
  PermitirVarias: boolean;
  Opciones?: Option[];
};

const formatConfiguration = (configuration: string) => {
  return configuration.split("\n").reduce<Option[]>((acc, config) => {
    if (!config) return acc;
    const configSplit = config?.split(",") ?? [];
    return [...acc, { label: configSplit[1], value: configSplit[0] }];
  }, []);
};

const model = {
  to: (data: FormValues): IStrategicPlansDetailsFields => {
    const options = isArray(data.Opciones)
      ? data.Opciones.map((option) => `${option.label},${option.value}`).join(
          "\n",
        )
      : `${data.Opciones}`;

    return {
      Nombre: data.Nombre,
      Requerido: data.Requerido,
      CampoId: data.CampoId ?? 0,
      TipoDatoId: data.TipoDatoId,
      Descripcion: data.Descripcion,
      DetalleTipoId: data.DetalleTipoId,
      CamposOpciones: {
        Configuracion: options ?? "",
        FuenteId: data.FuenteId ?? 0,
        PermitirVarias: data.PermitirVarias,
      },
    };
  },
  from: (data: IStrategicPlansDetailsFields): FormValues => {
    const sourceId = data.CamposOpciones?.FuenteId ?? 0;
    const config =
      sourceId === SettingDataSources.FIXED_VALUE
        ? formatConfiguration(data.CamposOpciones?.Configuracion ?? "")
        : (data.CamposOpciones?.Configuracion ?? "");

    return {
      FuenteId: sourceId,
      Nombre: data.Nombre,
      CampoId: data.CampoId,
      Requerido: data.Requerido,
      TipoDatoId: data.TipoDatoId,
      Descripcion: data.Descripcion,
      CamposOpciones: config as never,
      DetalleTipoId: data.DetalleTipoId,
      PermitirVarias: data.CamposOpciones?.PermitirVarias ?? false,
    };
  },
};

const SettingsDetailsFieldsDialog = ({
  fieldId,
  detailTypeId,
  ...props
}: ISettingsDetailsFieldsDialogProps) => {
  const detailType = usePeiQuery(
    "StrategicPlansDetailsTypes",
    "getById",
    detailTypeId,
    { enabled: !!detailTypeId },
  );

  const createField = useGlobalMutation("StrategicPlansFields", "create");
  const updateField = useGlobalMutation("StrategicPlansFields", "update");
  const getCreate = useGlobalQuery(
    "StrategicPlansFields",
    "getCreate",
    detailTypeId,
    { enabled: !fieldId && !!detailTypeId },
  );
  const getUpdate = useGlobalQuery(
    "StrategicPlansFields",
    "getUpdate",
    fieldId,
    { enabled: !!fieldId },
  );

  const form = useForm<FormValues>({});
  const { reset } = form;

  const TipoDatoId = useWatch({ name: "TipoDatoId", control: form.control });
  const FuenteId = useWatch({ name: "FuenteId", control: form.control });

  const { fields, append, remove } = useFieldArray({
    name: "Opciones",
    control: form.control,
  });

  React.useEffect(() => {
    if (!fieldId && getCreate.data) {
      reset(
        omit(getCreate.data, [
          "CamposOpciones",
          "FuentesSelect",
          "TiposDatosSelect",
          "Validaciones",
          "Rules",
        ]),
        { keepDefaultValues: false },
      );
    }
  }, [reset, getCreate.data, fieldId]);

  React.useEffect(() => {
    if (!fieldId) {
      if (FuenteId === SettingDataSources.FIXED_VALUE) {
        form.setValue("CamposOpciones", [] as never);
      } else form.setValue("CamposOpciones", "" as never);
    }
  }, [FuenteId, fieldId, form]);

  React.useEffect(() => {
    if (fieldId && getUpdate.data) {
      reset(model.from(getUpdate.data), { keepDefaultValues: true });
    }
  }, [reset, getUpdate.data, fieldId]);

  const onSubmit = (values: FormValues) => {
    if (fieldId) {
      return updateField.mutateAsync(
        model.to({ ...values, DetalleTipoId: detailTypeId }),
        {
          onSuccess: () => {
            form.reset();
            props.onHide();
          },
        },
      );
    }
    return createField.mutateAsync(
      model.to({ ...values, DetalleTipoId: detailTypeId }),
      {
        onSuccess: () => {
          form.reset();
          props.onHide();
        },
      },
    );
  };

  const columns: IDataTableColumnsProps<(typeof fields)[0]>[] = [
    {
      header: "#",
      field: "id",
      style: { width: "50px" },
      body: ({ id }) => {
        const index = fields.findIndex((field) => field.id === id);
        return <span className="font-bold">{index + 1}</span>;
      },
    },
    {
      field: "label",
      header: "Etiqueta",
      body: ({ id }) => {
        const index = fields.findIndex((field) => field.id === id);
        return (
          <InputText
            label=""
            fieldClassName="m-0"
            placeholder="Escribe..."
            className="p-inputtext-sm"
            name={`Opciones.${index}.label`}
          />
        );
      },
    },
    {
      field: "value",
      header: "Valor",
      body: ({ id }) => {
        const index = fields.findIndex((field) => field.id === id);
        return (
          <InputText
            label=""
            fieldClassName="m-0"
            className="p-inputtext-sm"
            placeholder="Ingresa el valor"
            name={`Opciones.${index}.value`}
          />
        );
      },
    },
  ];

  const dataTypes = React.useMemo(() => {
    if (fieldId) return getUpdate.data?.TiposDatosSelect ?? [];
    else return getCreate.data?.TiposDatosSelect ?? [];
  }, [
    getCreate.data?.TiposDatosSelect,
    getUpdate.data?.TiposDatosSelect,
    fieldId,
  ]);

  const dataTypesOptions = React.useMemo(() => {
    if (!dataTypes.length) return [];
    return ArrayUtils.selectLabelValue(dataTypes ?? [], "Nombre", "TipoDatoId");
  }, [dataTypes]);

  const dataSources = React.useMemo(() => {
    if (fieldId) return getUpdate.data?.FuentesSelect ?? [];
    else return getCreate.data?.FuentesSelect ?? [];
  }, [getCreate.data?.FuentesSelect, getUpdate.data?.FuentesSelect, fieldId]);

  const dataSourcesOptions = React.useMemo(() => {
    if (!dataSources.length) return [];
    return ArrayUtils.selectLabelValue(dataSources ?? [], "Nombre", "FuenteId");
  }, [dataSources]);

  const validations = React.useMemo(() => {
    if (fieldId) return getUpdate.data?.Validaciones;
    else return getCreate.data?.Validaciones;
  }, [getCreate.data?.Validaciones, getUpdate.data?.Validaciones, fieldId]);

  return (
    <Dialog
      {...props}
      className="w-full lg:w-8 xl:w-7"
      subtitle="Rellena los datos del formulario"
      onHide={() => {
        if (fieldId) form.reset();
        props.onHide();
      }}
      title={
        fieldId
          ? `Editando campo`
          : `Creando campo para el tipo (${detailType.data?.DetalleTipoNombre ?? ""})`
      }
      footer={(props) => (
        <div className="flex flex-1 justify-content-end gap-2">
          <Button
            size="small"
            label="Cancelar"
            severity="secondary"
            onClick={() => {
              form.reset();
              props.onHide();
            }}
          />
          <Button
            size="small"
            onClick={form.handleSubmit(onSubmit)}
            label={fieldId ? "Guardar cambios" : "Crear campo"}
            // loading={createTemplate.isPending || updateTemplate.isPending}
          />
        </div>
      )}
    >
      <Form form={form}>
        <div className="flex flex-column md:flex-row md:gap-3">
          <InputText
            name="Nombre"
            label="Nombre"
            fieldClassName="flex-1"
            className="p-inputtext-sm"
            rules={validations?.["Nombre"]}
            placeholder="Escribe un nombre..."
          />
          <InputSwitch
            name="Requerido"
            label="¿Es requerido?"
            rules={validations?.["Requerido"]}
          />
        </div>
        <InputTextarea
          rows={3}
          name="Descripcion"
          label="Descripción"
          fieldClassName="flex-1"
          className="p-inputtext-sm"
          rules={validations?.["Descripcion"]}
          placeholder="Escribe un Descripción..."
        />
        <Dropdown
          name="TipoDatoId"
          label="Tipo de dato"
          fieldClassName="flex-1"
          options={dataTypesOptions}
          className="p-inputtext-sm"
          rules={validations?.["TipoDatoId"]}
          placeholder="Selecciona un tipo de dato"
        />
        {TipoDatoId === SettingDataTypes.OPTIONS && (
          <div className="flex flex-column md:flex-row md:gap-3">
            <Dropdown
              name="FuenteId"
              label="Fuente de datos"
              fieldClassName="flex-1"
              className="p-inputtext-sm"
              options={dataSourcesOptions}
              rules={validations?.["FuenteId"]}
              placeholder="Selecciona una fuente de datos"
            />
            <InputSwitch
              defaultValue={false}
              name="OpcionesVarias"
              label="¿Permitir escoger varias opciones?"
            />
          </div>
        )}
        {FuenteId === SettingDataSources.STORED_PROCEDURE && (
          <InputText
            name="Opciones"
            fieldClassName="flex-1"
            className="p-inputtext-sm"
            label="Procedimiento almacenado"
            placeholder="Escribe el nombre del procedimiento..."
          />
        )}
        {FuenteId === SettingDataSources.WEB_SERVICE && (
          <InputText
            name="Opciones"
            label="Servicio web"
            fieldClassName="flex-1"
            placeholder="Escribe..."
            className="p-inputtext-sm"
          />
        )}
        {FuenteId === SettingDataSources.FIXED_VALUE && (
          <CardTable
            type="data"
            value={fields}
            hideBorderStyles
            hideGlobalSearch
            hideSelectFilter
            columns={columns}
            paginator={false}
            title="Valores Fijos"
            hideFilterSettingsIcon
            actionColumnProps={{ bodyStyle: { width: "1%" } }}
            headActions={[
              <Button
                size="small"
                type="button"
                key="AddDetails"
                className="flex w-auto"
                label="Agregar nuevo valor"
                onClick={() =>
                  append({ label: "", value: "" }, { shouldFocus: true })
                }
              />,
            ]}
            tableActions={({ id }) => {
              const index = fields.findIndex((field) => field.id === id);
              return [
                {
                  icon: "x",
                  label: "Eliminar",
                  iconClassName: "text-red-500",
                  onClick: EventUtils.callEvent(remove, index),
                },
              ];
            }}
          />
        )}
      </Form>
    </Dialog>
  );
};

export default SettingsDetailsFieldsDialog;
