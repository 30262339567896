/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";

import { SettingTypes } from "../../core";
import {
  Password,
  Dropdown,
  InputText,
  InputNumber,
  RadioButton,
  EditorInput,
  SelectButton,
  InputTextarea,
} from "../inputs";

export interface IConfigurationInputProps {
  data: any;
}

const ConfigurationInput = ({ data }: IConfigurationInputProps) => {
  const options = React.useMemo(() => {
    return data.Configuracion?.split("\n").reduce(
      (acc: any, config: { split: (arg0: string) => never[] }) => {
        if (!config) return acc;

        const configSplitted = config?.split(",") ?? [];

        return [
          ...acc,
          { label: configSplitted[1], value: parseInt(configSplitted[0]) },
        ];
      },
      [] as { label: string; value: number }[],
    );
  }, [data.Configuracion]);

  const rules = React.useMemo(() => {
    try {
      return JSON.parse(data.Rules ?? "{}");
    } catch (error) {
      return {};
    }
  }, [data.Rules]);

  const inputProps = React.useMemo(
    () => ({
      rules,
      name: `${data.ConfiguracionId}`,
      label: `${data.Descripcion} (${data.Campo})`,
    }),
    [data.Campo, data.ConfiguracionId, data.Descripcion, rules],
  );

  const mapping = React.useMemo<Record<number, React.JSX.Element>>(
    () => ({
      [SettingTypes.EDITOR]: <EditorInput valueType="html" {...inputProps} />,
      [SettingTypes.CLAVE]: (
        <Password className="p-inputtext-sm" {...inputProps} />
      ),
      [SettingTypes.EMAIL]: (
        <InputText className="p-inputtext-sm" {...inputProps} type="email" />
      ),
      [SettingTypes.NUMBER]: (
        <InputNumber className="p-inputtext-sm" {...inputProps} type="number" />
      ),
      [SettingTypes.RADIO]: (
        <RadioButton
          mode="horizontal"
          options={options ?? []}
          {...inputProps}
        />
      ),
      // [SettingTypes.HTML]: (
      //   <CodeMirrorInput extensions={[html()]} {...inputProps} />
      // ),
      // [SettingTypes.MULTI_OPTIONS]: (
      //   <Checkbox.Group options={options} {...inputProps} />
      // ),
      [SettingTypes.SHORT_TEXT]: (
        <InputText
          {...inputProps}
          type="text"
          className="p-inputtext-sm"
          placeholder="Escribe un texto"
        />
      ),
      [SettingTypes.LONG_TEXT]: (
        <InputTextarea
          {...inputProps}
          rows={2}
          className="p-inputtext-sm"
          placeholder="Escribe una descripción"
        />
      ),
      [SettingTypes.OPTIONS]: (
        <Dropdown
          {...inputProps}
          options={options ?? []}
          className="p-inputtext-sm"
          placeholder="Selecciona una opción"
        />
      ),
      [SettingTypes.BOOLEAN]: (
        <SelectButton
          {...inputProps}
          defaultValue="False"
          className="w-14rem p-selectbutton-sm"
          options={[
            { value: "True", label: "Habilitar" },
            { value: "False", label: "Deshabilitar" },
          ]}
        />
      ),
    }),
    [inputProps, options],
  );

  return (
    mapping[data.TipoDatoId] ?? `[Este configuración no esta siendo manejada]`
  );
};

export default ConfigurationInput;
