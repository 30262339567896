import React from "react";
import { RouteObject } from "react-router";

interface INavigationContextProps {
  routes: RouteObject[];
}

const NavigationContext = React.createContext<INavigationContextProps>({
  routes: [],
});

export default NavigationContext;
