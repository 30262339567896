import { IRouter } from "@interfaces";
import { MainLayout, SettingsLayout } from "@components";
import { AppRoutes, Permissions, AppRouteNames } from "@core";
import {
  SignInPage,
  SignOutPage,
  VerifyAuthPage,
  PermissionsPage,
  UsersSettingsPage,
  ForgotPasswordPage,
  GeneralSettingsPage,
} from "@pages";

import * as Features from "./features";

export const features: IRouter[] = [
  Features.GeneralAccountingFeature.Route,
  Features.AccountsPayableFeature.Route,
  Features.CostsCenterFeature.Route,
  Features.PeiFeature.Route,
];

export const featuresSettings: IRouter[] = [
  Features.PeiFeature.RouteSettings,
  Features.CostsCenterFeature.RouteSettings,
];

export const routes: IRouter[] = [
  {
    sidebar: true,
    id: "app-root",
    title: "Inicio",
    iconName: "home",
    element: <MainLayout />,
    path: AppRoutes[AppRouteNames.HOME_PAGE].template(),
    children: [
      {
        path: "",
        index: true,
        id: "app-page",
        title: "Inicio",
        dashboardId: 2065,
      },
      ...features,
      {
        sidebar: false,
        id: "settings-root",
        iconName: "settings",
        element: <SettingsLayout />,
        title: "Configuraciones Generales",
        permissions: [Permissions.SEE_GENERAL_SETTINGS],
        path: AppRoutes[AppRouteNames.SETTINGS_PAGE].template(),
        children: [
          {
            path: "",
            index: true,
            sidebar: true,
            id: "settings-page",
            iconName: "settings",
            element: <GeneralSettingsPage />,
            title: "Configuraciones Generales",
          },
          {
            sidebar: true,
            title: "Permisos",
            iconName: "shield-cog",
            element: <PermissionsPage />,
            id: "settings-permissions-page",
            permissions: [Permissions.SEE_GENERAL_SETTINGS_PERMISSIONS],
            path: AppRoutes[AppRouteNames.SETTINGS_PERMISSIONS_PAGE].template(),
          },
          {
            sidebar: true,
            title: "Usuarios",
            iconName: "users-group",
            id: "settings-users-page",
            element: <UsersSettingsPage />,
            permissions: [Permissions.SEE_GENERAL_SETTINGS_USERS],
            path: AppRoutes[AppRouteNames.USERS_SETTINGS_PAGE].template(),
          },
          ...featuresSettings,
        ],
      },
    ],
  },
  {
    index: false,
    sidebar: false,
    id: "verify-auth-page",
    element: <VerifyAuthPage />,
    title: "Verificando usuario...",
    path: AppRoutes[AppRouteNames.VERIFY_AUTH_PAGE].template(),
  },
  {
    index: false,
    sidebar: false,
    id: "sign-out-page",
    element: <SignOutPage />,
    title: "Cerrando sesión...",
    path: AppRoutes[AppRouteNames.SIGN_OUT_PAGE].template(),
  },
  {
    index: true,
    sidebar: false,
    id: "sign-in-page",
    element: <SignInPage />,
    title: "Inicio de Sesión",
    path: AppRoutes[AppRouteNames.SIGN_IN_PAGE].template(),
  },
  {
    index: false,
    sidebar: false,
    id: "forgot-password-page",
    title: "Olvidar contraseña",
    element: <ForgotPasswordPage />,
    path: AppRoutes[AppRouteNames.FORGOT_PASSWORD_PAGE].template(),
  },
];
