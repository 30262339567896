import React from "react";

/**
 * Creates a toast template for displaying discrepancies in an accounting entry.
 *
 * @param {string} message - The main message to be displayed in the toast.
 * @param {string[]} discrepancies - An array of strings representing the discrepancies found in the accounting entry.
 * @returns {React.JSX.Element} A React element representing the toast content.
 *
 * @example
 * const message = "Discrepancies Found";
 * const discrepancies = ["Total amount mismatch", "Invalid account"];
 * const toastContent = ToastDiscrepanciesTemplate(message, discrepancies);
 */
export const ToastDiscrepanciesTemplate = (
  message: string,
  discrepancies: string[],
): React.JSX.Element => (
  <div>
    <h3 className="m-0 mb-1 font-medium text-base">{message}</h3>
    <ul className="list-disc pl-5 text-sm">
      {discrepancies.map((discrepancy, index) => (
        <li key={index}>{discrepancy}</li>
      ))}
    </ul>
  </div>
);
