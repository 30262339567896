// import { listify } from "radash";
// import { generatePath } from "react-router";

import { listify } from "radash";

// import ReportApi from "../api/report-api";
// import { IPathReportType } from "../interfaces";

/**
 * Generate url to navigate to the reporting module.
 *
 * @param reportName Name of the report to generate
 * @param parameters Parameters required by the report.
 * @returns {string} The URL of the report module.
 */
// export const generateReport = (
//   reportName: string,
//   parameters: Record<string, string>,
//   reportType: IPathReportType = "pdf",
// ): string => {
//   return `${Routes.MODULE_ROUTE_BASE}${generatePath(
//     reportType === "pdf"
//       ? Routes.MODULE_REPORT_PDF_ROUTE
//       : Routes.MODULE_REPORT_TICKET_ROUTE,
//     { reportName },
//   )}?${listify(parameters, (key, value) => `${key}=${value}`).join("&")}`;
// };

/**
 * Generate ticket and print
 *
 * @param parameters Parameters required by the report.
 * @returns {string} The status text by printer.
 */
// export const handleTicket = async (parameters: Record<string, string>) => {
//   const ticket = await ReportApi.generateTicket(parameters);
//   const printTicket = await ReportApi.print({
//     data: ticket.formato,
//     printer: ticket.impresoraNombre,
//   });
//   return printTicket;
// };

/**
 * Generate url to navigate to the reporting module.
 *
 * @param reportName Name of the report to generate
 * @param parameters Parameters required by the report.
 * @returns {string} The URL of the report module.
 */
export const getReportViewUrl = (
  reportName: string,
  parameters: Record<string, string>,
): string => {
  // console.log(parameters);

  // const token = localStorage.getItem("token");
  const paramsWithToken: Record<string, string> = {
    ...parameters,
  };

  // Manually constructing the URL to match the desired format
  const SERVER_URL =
    process.env.VITE_NUBETECK_SERVER_URL ?? "https://localhost:7267";
  const baseUrl = `${SERVER_URL}/Reporte/Preview`;

  // Construct the query parameters string
  const queryString = `ReportName=${encodeURIComponent(reportName)}&${listify(
    paramsWithToken,
    (key, value) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`,
  ).join("&")}`;

  return `${baseUrl}?${queryString}`;
};
