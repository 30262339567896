import React from "react";
import { sort } from "radash";
import { Icon } from "@nubeteck/icons";
import { Button } from "primereact/button";
import { useNavigate } from "react-router";
import { Datejs, ArrayUtils } from "@nubeteck/utils";
import { FilterOperator, FilterMatchMode } from "primereact/api";
import { EmptyMessage, ConfirmDialog, OverlayButtonMenu } from "@components";
import { CardTable, StatusTag, IDataTableColumnsProps } from "@nubeteck/prime";

import { renderCheckIcon } from "../../utils";
import { IPurchaseDocument } from "../../interfaces";
import { AccountsPayableRoutes, AccountsPayableRouteNames } from "../../routes";
import {
  useAccountsPayableQuery,
  useCreatePurchaseDrafts,
  usePurchaseDocumentActions,
  useNavigateToPurchaseDetails,
} from "../../hooks";

const purchaseDocumentTypes = [
  { TipoDocumentoId: 1, TipoDocumentoNombre: "Factura de compra" },
  { TipoDocumentoId: 2, TipoDocumentoNombre: "Orden de compra" },
  { TipoDocumentoId: 3, TipoDocumentoNombre: "Conduce" },
];

const purchaseStatuses = [
  { EstadoId: 1, EstadoNombre: "Activo" },
  { EstadoId: 2, EstadoNombre: "Borrador" },
  { EstadoId: 3, EstadoNombre: "Pendiente de aprobación" },
];

// const AREA_FILTER_DEFAULT = "fcDocumentosComprasBuscar";

const PurchaseDocuments = () => {
  const navigate = useNavigate();
  const navigateToPurchaseDetails = useNavigateToPurchaseDetails();
  const tableActions = usePurchaseDocumentActions();
  const {
    refetch,
    data: purchaseDocuments,
    isPending: purchaseDocumentsPending,
    isFetching: purchaseDocumentsFetching,
  } = useAccountsPayableQuery("PurchaseDocuments", "getAll");
  const {
    purchaseDraftPending,
    createDeliveryNoteDraft,
    createPurchaseOrderDraft,
    createPurchaseInvoiceDraft,
    createRecurringPurchaseInvoiceDraft,
  } = useCreatePurchaseDrafts();

  // const { data: filters } = useGlobalQuery(
  //   "Filters",
  //   "getAllByArea",
  //   AREA_FILTER_DEFAULT,
  // );
  // const [filterSelected, setFilterSelected] = React.useState<
  //   number | undefined
  // >();

  // const { data: purchaseDocuments, isPending: purchaseDocumentsPending } =
  //   useAccountsPayableQuery(
  //     "PurchaseDocuments",
  //     "getAllByFilter",
  //     filterSelected,
  //     {
  //       enabled: !!filterSelected,
  //     },
  //   );

  // const [filterSettingsVisible, setFilterSettingsVisible] =
  //   React.useState(false);

  // React.useEffect(() => {
  //   const defaultFilterId = filters?.find(
  //     (filter) => filter.EsPorDefecto,
  //   )?.FiltroId;

  //   setFilterSelected(defaultFilterId);
  // }, [filters]);

  // const filtersOptions = React.useMemo(() => {
  //   if (!filters?.length) return [];
  //   return ArrayUtils.selectLabelValue(filters ?? [], "Nombre", "FiltroId");
  // }, [filters]);

  // TODO: Fetch options from backend
  const documentTypeOptions = React.useMemo(() => {
    if (!purchaseDocumentTypes?.length) return [];

    return ArrayUtils.selectLabelValue(
      purchaseDocumentTypes,
      "TipoDocumentoNombre",
      "TipoDocumentoId",
    );
  }, []);

  const statusesOptions = React.useMemo(() => {
    if (!purchaseStatuses?.length) return [];

    return ArrayUtils.selectLabelValue(
      purchaseStatuses,
      "EstadoNombre",
      "EstadoId",
    );
  }, []);

  const columns: IDataTableColumnsProps<IPurchaseDocument>[] = [
    {
      filter: true,
      sortable: true,
      dataType: "text",
      field: "Secuencia",
      header: "Secuencia",
      filterMatchMode: FilterMatchMode.STARTS_WITH,
      body: (rowData) => (
        <a
          onClick={() => navigateToPurchaseDetails(rowData)}
          className="no-underline text-blue-500 cursor-pointer"
        >
          {rowData.Secuencia}
        </a>
      ),
    },
    { hidden: true, header: "Tipo de documento", field: "TipoDocumentoNombre" },
    {
      filter: true,
      sortable: true,
      dataType: "select",
      field: "TipoDocumentoId",
      header: "Tipo de documento",
      filterOperator: FilterOperator.AND,
      filterOptions: documentTypeOptions,
      filterMatchMode: FilterMatchMode.EQUALS,
      body: (rowData) => rowData.TipoDocumentoNombre,
    },
    {
      filter: true,
      sortable: true,
      field: "Fecha",
      header: "Fecha",
      dataType: "date",
      filterOperator: FilterOperator.AND,
      filterMatchMode: FilterMatchMode.DATE_IS,
      body: (rowData) => (
        <span title={Datejs(rowData.Fecha).format("LLL")}>
          {Datejs(rowData.Fecha).format("L")}
        </span>
      ),
    },
    {
      filter: true,
      sortable: true,
      dataType: "text",
      header: "Proveedor",
      field: "ProveedorNombre",
      filterMatchMode: FilterMatchMode.STARTS_WITH,
    },
    { hidden: true, header: "Estado", field: "EstadoNombre" },
    {
      filter: true,
      header: "Estado",
      field: "EstadoId",
      dataType: "select",
      filterOptions: statusesOptions,
      filterOperator: FilterOperator.AND,
      filterMatchMode: FilterMatchMode.EQUALS,
      body: (rowData) => <StatusTag status={rowData.EstadoNombre} />,
    },
    {
      filter: true,
      align: "center",
      dataType: "select",
      field: "Recurrencia",
      header: "Recurrencia",
      filterOperator: FilterOperator.AND,
      filterMatchMode: FilterMatchMode.EQUALS,
      body: (rowData) => renderCheckIcon(rowData.Recurrencia),
      filterOptions: [
        { value: true, label: "Sí" },
        { label: "No", value: false },
      ],
    },
  ];

  const value = React.useMemo(() => {
    if (!purchaseDocuments) return [];

    return purchaseDocuments.map((item) => ({
      ...item,
      Fecha: new Date(item.Fecha),
    }));
  }, [purchaseDocuments]);

  const renderEmptyMessage = React.useCallback(() => {
    return <EmptyMessage message="No hay documentos" />;
  }, []);

  const overlayOptions = React.useMemo(
    () => [
      {
        iconName: "invoice",
        label: "Factura de compra",
        onClick: createPurchaseInvoiceDraft,
      },
      {
        label: "Orden de compra",
        iconName: "shopping-cart",
        onClick: createPurchaseOrderDraft,
      },
      {
        label: "Conduce",
        onClick: createDeliveryNoteDraft,
        iconName: "circle-arrow-up-right",
      },
      {
        iconName: "repeat",
        label: "Factura recurrente",
        onClick: createRecurringPurchaseInvoiceDraft,
      },
    ],
    [
      createDeliveryNoteDraft,
      createPurchaseInvoiceDraft,
      createPurchaseOrderDraft,
      createRecurringPurchaseInvoiceDraft,
    ],
  );

  return (
    <>
      <CardTable<IPurchaseDocument>
        type="data"
        resizableColumns
        columns={columns}
        removableSort={true}
        dataKey="DocumentoId"
        title="Área de compras"
        columnResizeMode="expand"
        tableActions={tableActions}
        // valueFilter={filterSelected}
        onRefresh={() => refetch()}
        // filterOptions={filtersOptions}
        loading={purchaseDocumentsPending}
        emptyMessage={renderEmptyMessage()}
        value={sort(value, (item) => item.Fecha.getTime(), true)}
        // onSelectFilterOption={(value) => setFilterSelected(value)}
        // onClickFilterSettings={EventUtils.callEvent(
        //   setFilterSettingsVisible,
        //   true,
        // )}
        headActions={[
          <Button
            outlined
            size="small"
            key="RECURRENT_BTN"
            label="Documentos recurrentes"
            icon={<Icon size={20} name="repeat" className="mr-2" />}
            loading={purchaseDraftPending || purchaseDocumentsFetching}
            onClick={() =>
              navigate(
                `.${AccountsPayableRoutes[
                  AccountsPayableRouteNames.RECURRING
                ].template()}`,
              )
            }
          />,
          <OverlayButtonMenu
            size="small"
            key="ADD_BTN"
            label="Agregar"
            options={overlayOptions}
            loading={purchaseDraftPending}
            icon={<Icon size={20} name="plus" className="mr-2" />}
          />,
        ]}
      />
      <ConfirmDialog />
      {/* <FiltersDrawer
        area={AREA_FILTER_DEFAULT}
        visible={filterSettingsVisible}
        onHide={EventUtils.callEvent(setFilterSettingsVisible, false)}
      /> */}
    </>
  );
};

export default PurchaseDocuments;
