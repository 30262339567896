import React from "react";
import { sort } from "radash";
import { EmptyMessage } from "@components";
import { useNavigate } from "react-router";
import { Skeleton } from "primereact/skeleton";
import { Datejs, ArrayUtils } from "@nubeteck/utils";
import { FilterOperator, FilterMatchMode } from "primereact/api";
import { CardTable, StatusTag, IDataTableColumnsProps } from "@nubeteck/prime";

import { IRecurringInvoice } from "../../interfaces";
import { useAccountsPayableQuery } from "../../hooks";
import { AccountsPayableRoutes, AccountsPayableRouteNames } from "../../routes";

const recurringPurchaseStatuses = [
  { EstadoId: 1, EstadoNombre: "Activo" },
  { EstadoId: 2, EstadoNombre: "Inactivo" },
];

const RecurringPurchaseInvoices = () => {
  const navigate = useNavigate();
  const navigateToPurchaseInvoice = React.useCallback(
    (id: number) =>
      navigate(
        `..${AccountsPayableRoutes[
          AccountsPayableRouteNames.PURCHASE_INVOICE
        ].create({
          id: `${id}`,
        })}`,
      ),
    [navigate],
  );
  const navigateToPurchaseInvoiceDetail = React.useCallback(
    (id: number) =>
      navigate(
        `..${AccountsPayableRoutes[
          AccountsPayableRouteNames.PURCHASE_INVOICE_DETAIL
        ].create({
          id: `${id}`,
        })}`,
      ),
    [navigate],
  );
  const {
    refetch,
    data: recurringPurchaseInvoices,
    isPending: recurringPurchaseInvoicesPending,
  } = useAccountsPayableQuery("RecurringPurchaseInvoices", "getAll");

  // TODO: Fetch options from backend
  const statusesOptions = React.useMemo(() => {
    if (!recurringPurchaseStatuses?.length) return [];

    return ArrayUtils.selectLabelValue(
      recurringPurchaseStatuses,
      "EstadoNombre",
      "EstadoId",
    );
  }, []);

  const columns = React.useMemo<IDataTableColumnsProps<IRecurringInvoice>[]>(
    () => [
      {
        filter: true,
        sortable: true,
        dataType: "text",
        field: "Secuencia",
        header: "Secuencia",
        filterMatchMode: FilterMatchMode.STARTS_WITH,
        body: (rowData) => (
          <a
            className="no-underline text-blue-500 cursor-pointer"
            onClick={() => navigateToPurchaseInvoice(rowData.FacturaId)}
          >
            {rowData.Secuencia}
          </a>
        ),
      },
      { hidden: true, field: "FrecuenciaNombre" },
      {
        sortable: true,
        header: "Frecuencia",
        field: "FrecuenciaId",
        body: (rowData) => rowData.FrecuenciaNombre,
      },
      {
        sortable: true,
        field: "CantidadVeces",
        header: "Cantidad de veces",
      },
      {
        filter: true,
        sortable: true,
        dataType: "date",
        field: "FechaInicio",
        header: "Fecha inicio",
        filterOperator: FilterOperator.AND,
        filterMatchMode: FilterMatchMode.DATE_IS,
        body: (rowData) => (
          <span title={Datejs(rowData.FechaInicio).format("LLL")}>
            {Datejs(rowData.FechaInicio).format("L")}
          </span>
        ),
      },
      {
        filter: true,
        sortable: true,
        dataType: "date",
        field: "FechaProxima",
        header: "Fecha próxima",
        filterOperator: FilterOperator.AND,
        filterMatchMode: FilterMatchMode.DATE_IS,
        body: (rowData) => (
          <span title={Datejs(rowData.FechaProxima).format("LLL")}>
            {Datejs(rowData.FechaProxima).format("L")}
          </span>
        ),
      },
      {
        filter: true,
        sortable: true,
        dataType: "text",
        header: "Proveedor",
        field: "ProveedorNombre",
        filterMatchMode: FilterMatchMode.STARTS_WITH,
      },
      { hidden: true, header: "Estado", field: "EstadoNombre" },
      {
        filter: true,
        header: "Estado",
        field: "EstadoId",
        dataType: "select",
        filterOptions: statusesOptions,
        filterOperator: FilterOperator.AND,
        filterMatchMode: FilterMatchMode.EQUALS,
        body: (rowData) => <StatusTag status={rowData.EstadoNombre} />,
      },
    ],
    [navigateToPurchaseInvoice, statusesOptions],
  );

  const renderEmptyMessage = React.useCallback(() => {
    return <EmptyMessage message="No hay facturas de compras recurrentes" />;
  }, []);

  const value = React.useMemo(() => {
    if (!recurringPurchaseInvoices) return [];

    return recurringPurchaseInvoices.map((item) => ({
      ...item,
      FechaInicio: new Date(item.FechaInicio),
      FechaProxima: new Date(item.FechaProxima),
    }));
  }, [recurringPurchaseInvoices]);

  const tableActions = React.useCallback(
    (rowData: IRecurringInvoice) => [
      {
        icon: "eye-search",
        label: "Ver factura",
        onClick: () => navigateToPurchaseInvoiceDetail(rowData.FacturaId),
      },
      {
        icon: "plug-off",
        onClick: () => null,
        label: "Desactivar recurrencia",
      },
    ],
    [navigateToPurchaseInvoiceDetail],
  );

  return (
    <>
      {recurringPurchaseInvoicesPending && (
        <Skeleton width="100%" height="100%" />
      )}
      {!recurringPurchaseInvoicesPending && (
        <CardTable<IRecurringInvoice>
          rows={10}
          type="data"
          hideSelectFilter
          resizableColumns
          columns={columns}
          dataKey="FacturaId"
          removableSort={true}
          columnResizeMode="expand"
          tableActions={tableActions}
          onRefresh={() => refetch()}
          paginator={value.length > 10}
          emptyMessage={renderEmptyMessage()}
          title="Facturas de compras recurrentes"
          rowsPerPageOptions={[10, 20, 30, 50, 100]}
          loading={recurringPurchaseInvoicesPending}
          value={sort(value, (item) => item.FechaInicio.getTime(), true)}
        />
      )}
    </>
  );
};

export default RecurringPurchaseInvoices;
