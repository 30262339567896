import React from "react";
import { Reducers } from "@core";
import { FormatterUtils } from "@utils";
import { Calendar } from "primereact/calendar";
import { ColumnProps } from "primereact/column";
import { Datejs, EventUtils } from "@nubeteck/utils";
import { TreeNode, CardTable } from "@nubeteck/prime";

import { useAccountingQuery } from "../../hooks";
import { IFinancialStatementTreeNode } from "../../interfaces";
import { FinancialStatementDivisionDrawer } from "../../components";

const FinancialStatementSituationPage = () => {
  const [state, dispatch] = React.useReducer(Reducers.DialogReducer, {
    id: 0,
    open: false,
  });
  const [fechaCorte, setFechaCorte] = React.useState<Datejs.Dayjs>(Datejs());

  const statementSituation = useAccountingQuery(
    "FinancialStatement",
    "getEstadoSituacion",
    fechaCorte,
    { enabled: !!fechaCorte },
  );

  const transformToTreeNode = (
    data: IFinancialStatementTreeNode[],
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): TreeNode<any>[] => {
    return data.map((item) => ({
      key: item.Key,
      children: item.Children ? transformToTreeNode(item.Children) : [],
      data: {
        name: item.Data.Name,
        total: item.Data.Total,
      },
    }));
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const data: TreeNode<any>[] = statementSituation?.data
    ? transformToTreeNode(statementSituation.data)
    : [];

  const columns: ColumnProps[] = [
    {
      field: "name",
      expander: true,
      header: "Nombre",
    },
    {
      field: "total",
      header: "Total",
      body: (row) =>
        FormatterUtils.currency(row.data.total ?? 0, {
          currencyIso: "DOP",
        }),
    },
  ];

  return (
    <div style={{ width: "100%" }}>
      <CardTable
        type="tree"
        hideSelectFilter
        tableActions={[]}
        //value={data as never} // TODO: Vista mal implementada
        value={data ?? []}
        hideFilterSettingsIcon
        columns={columns as never} // TODO: Vista mal implementada
        title="Estados financieros"
        loading={statementSituation.isLoading}
        emptyMessage="No hay estados financieros"
        onRefresh={() => statementSituation.refetch()}
        headActions={[
          <Calendar
            key={1}
            placeholder="Fecha"
            style={{ height: "35px" }}
            value={fechaCorte ? fechaCorte.toDate() : new Date()}
            onChange={(e) =>
              setFechaCorte(e.value ? Datejs(e.value) : fechaCorte)
            }
          />,
        ]}
      />
      <FinancialStatementDivisionDrawer
        id={state.id}
        open={state.open}
        onHide={EventUtils.callEvent(dispatch, { type: "CLOSE_DIALOG" })}
      />
      {/* <FiltersDrawer
        onHide={function (): void {
          throw new Error("Function not implemented.");
        }}
      /> */}
    </div>
  );
};

export default FinancialStatementSituationPage;
