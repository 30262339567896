import clsx from "clsx";
import React from "react";
import { useFormState } from "react-hook-form";

//! ANTES DE MODIFICAR ESTE COMPONENTE CONSULTE CON SUS DESARROLLADORES.
//! ANTES DE MODIFICAR ESTE COMPONENTE CONSULTE CON SUS DESARROLLADORES.
//! ANTES DE MODIFICAR ESTE COMPONENTE CONSULTE CON SUS DESARROLLADORES.

export interface IFieldProps {
  name: string;
  label: string;
  className?: string;
  helperText?: string;
  isRequired?: boolean;
}

const Field = ({
  name,
  label,
  children,
  className,
  helperText,
  isRequired,
}: React.PropsWithChildren<IFieldProps>) => {
  const { errors } = useFormState();

  const error = errors[name]?.message;

  return (
    <div className={clsx("field", className)}>
      <div className="flex flex-column gap-1">
        <label
          htmlFor={name}
          className={clsx({ "p-error": error }, "font-medium text-700")}
        >
          {label}
          {isRequired && !!label && <i className="ml-1 text-red-500">*</i>}
        </label>
        {children}
        {helperText && !error && (
          <small id={`${name}-help`}>{helperText}</small>
        )}
      </div>
      {error && <small className="p-error">{`${error}`}</small>}
    </div>
  );
};

export default Field;
