import React from "react";
import { IInputProps } from "@interfaces";
import { useController } from "react-hook-form";
import { Editor, EditorProps } from "primereact/editor";

import { Field } from "../../field";

//! ANTES DE MODIFICAR ESTE COMPONENTE CONSULTE CON SUS DESARROLLADORES.
//! ANTES DE MODIFICAR ESTE COMPONENTE CONSULTE CON SUS DESARROLLADORES.
//! ANTES DE MODIFICAR ESTE COMPONENTE CONSULTE CON SUS DESARROLLADORES.

export interface IEditorInputProps
  extends IInputProps,
    Omit<EditorProps, "name" | "defaultValue"> {
  valueType?: "html" | "text";
}

const $EditorInput = ({
  name,
  label,
  rules,
  disabled,
  helperText,
  defaultValue,
  disabledInput,
  fieldClassName,
  shouldUnregister,
  valueType = "html",
  ...props
}: IEditorInputProps) => {
  const {
    field: { onChange, ...field },
  } = useController({
    name,
    rules,
    disabled,
    defaultValue,
    shouldUnregister,
  });

  return (
    <Field
      name={name}
      label={label}
      helperText={helperText}
      className={fieldClassName}
      isRequired={!!rules?.required}
    >
      <Editor
        id={field.name}
        onTextChange={(e) => {
          onChange(valueType === "html" ? e.htmlValue : e.textValue);
        }}
        {...field}
        {...props}
        disabled={disabledInput}
      />
    </Field>
  );
};

export default $EditorInput;
