import React from "react";
import { Icon } from "@nubeteck/icons";

export interface IDraggableWidgetCardProps {
  id?: string;
  title: string;
  iconName: string;
  onClick?: () => void;
}

const DraggableWidgetCard = ({
  id,
  title,
  iconName,
}: IDraggableWidgetCardProps) => {
  return (
    <a
      draggable
      title={title}
      style={{ cursor: "grabbing" }}
      onDragStart={(e) =>
        e.dataTransfer.setData("text/plain", JSON.stringify({ id, title }))
      }
      className="gap-1 flex flex-column overflow-hidden border-round-sm text-center align-items-center justify-content-center h-6rem w-6rem surface-100"
    >
      <Icon size={28} name={iconName} />
      <span className="text-xs font-medium">{title}</span>
    </a>
  );
};

export default DraggableWidgetCard;
