import { route } from "typesafe-react-router";

export enum AppRouteNames {
  HOME_PAGE = "HOME_PAGE",
  SIGN_IN_PAGE = "SIGN_IN_PAGE",
  SETTINGS_PAGE = "SETTINGS_PAGE",
  SIGN_OUT_PAGE = "SIGN_OUT_PAGE",
  VERIFY_AUTH_PAGE = "VERIFY_AUTH_PAGE",
  USERS_SETTINGS_PAGE = "USERS_SETTINGS_PAGE",
  FORGOT_PASSWORD_PAGE = "FORGOT_PASSWORD_PAGE",
  SETTINGS_BRANCHES_PAGE = "SETTINGS_BRANCHES_PAGE",
  SETTINGS_TEMPLATES_PAGE = "SETTINGS_TEMPLATES_PAGE",
  SETTINGS_PERMISSIONS_PAGE = "SETTINGS_PERMISSIONS_PAGE",
}

export const AppRoutes = {
  [AppRouteNames.HOME_PAGE]: route(""),
  [AppRouteNames.SIGN_IN_PAGE]: route("login"),
  [AppRouteNames.SETTINGS_PAGE]: route("settings"),
  [AppRouteNames.SIGN_OUT_PAGE]: route("sign-out"),
  [AppRouteNames.VERIFY_AUTH_PAGE]: route("verify"),
  [AppRouteNames.USERS_SETTINGS_PAGE]: route("users"),
  [AppRouteNames.SETTINGS_BRANCHES_PAGE]: route("branches"),
  [AppRouteNames.SETTINGS_TEMPLATES_PAGE]: route("templates"),
  [AppRouteNames.FORGOT_PASSWORD_PAGE]: route("forgot-password"),
  [AppRouteNames.SETTINGS_PERMISSIONS_PAGE]: route("permissions"),
};
