import React from "react";
import { Icon } from "@nubeteck/icons";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { DataTable } from "@nubeteck/prime";
import { EventUtils } from "@nubeteck/utils";
import { Dialog, IDialogProps } from "@components";

import { useAccountingQuery } from "../../hooks";
import { IDataEquivalencia } from "../../interfaces";

export interface IEquivalenciaDialogProps extends IDialogProps {
  catalogId: number;
  onDeshacer: (data: IDataEquivalencia) => void;
}

const EquivalenciaDialog = ({
  catalogId,
  onDeshacer,
  ...props
}: IEquivalenciaDialogProps) => {
  const getEquivalencias = useAccountingQuery(
    "AccountCatalog",
    "getEquivalencias",
  );

  const filteredValue = React.useMemo(() => {
    return (
      getEquivalencias.data
        ?.filter((item) => item.CatalogoId === catalogId)
        .map((item) => ({
          sePuedeDeshacer: item.SePuedeDesHacer,
          CuentaDesdeId: item.CuentaContableDesdeId,
          CuentaHastaId: item.CuentaContableHastaId,
          codigoCatalogoConsolidado: item.CatalogoId,
          cuentaCatalogoSimple: item.CuentaSimpleNombre,
          codigoCuentaCatalogoSimple: item.CuentaSimple,
          nombreCatalogoConsolidado: item.NombreCatalogo,
          nombreCatalogoSimple: item.NombreCatalogoSimple,
          cuentaCatalogoConsolidado: item.CuentaNombreDeConsolidado,
          codigoCuentaCatalogoConsolidado: item.CuentaDeConsolidado,
        })) || []
    );
  }, [catalogId, getEquivalencias.data]);

  return (
    <Dialog className="w-10" {...props}>
      <DataTable
        scrollable
        size="small"
        showGridlines
        sortOrder={1}
        sortMode="single"
        scrollHeight="500px"
        value={filteredValue}
        rowGroupMode="rowspan"
        sortField="codigoCatalogoConsolidado"
        groupRowsBy="codigoCuentaCatalogoConsolidado"
      >
        <Column
          field="codigoCuentaCatalogoConsolidado"
          header="Código de la Cuenta de consolidado"
        />
        <Column
          field="cuentaCatalogoConsolidado"
          header="Nombre de la Cuenta de consolidado"
        />
        <Column
          field="nombreCatalogoSimple"
          header="Nombre del catálogo simple"
        />
        <Column
          field="codigoCuentaCatalogoSimple"
          header="Código de la Cuenta de simple"
        />
        <Column
          field="cuentaCatalogoSimple"
          header="Nombre de cuenta de catálogo simple"
        />
        <Column
          header="Deshacer"
          className="flex justify-content-center"
          body={(data) => (
            <Button
              text
              size="small"
              aria-label="Filter"
              icon={<Icon name="x" size={20} />}
              onClick={EventUtils.callEvent(onDeshacer, data)}
            />
          )}
        />
      </DataTable>
    </Dialog>
  );
};

export default EquivalenciaDialog;
