import { Fallbacks } from "@core";
import { Avatar } from "primereact/avatar";

export const ImageOptionTemplate = (option: { label: string; src: string }) => {
  if (option && option.label) {
    return (
      <div className="flex align-items-center">
        <Avatar
          shape="circle"
          className="mr-2"
          image={option.src}
          imageAlt={option.label}
          style={{ width: 18, height: 18 }}
          imageFallback={Fallbacks.AVATAR_FALLBACK}
        />
        <div>{option.label}</div>
      </div>
    );
  }
  return null;
};
