import React from "react";
import { select } from "radash";
import { PathUtils } from "@utils";
import { useGlobalQuery } from "@hooks";
import { Button } from "primereact/button";
import { Skeleton } from "primereact/skeleton";
import { useForm, useWatch } from "react-hook-form";
import { JsxUtil, ArrayUtils } from "@nubeteck/utils";
import { Card, useHeaderActions } from "@nubeteck/prime";
import { Form, Dropdown, ReportInput } from "@components";

const StrategicReportForm = () => {
  const { dispatch } = useHeaderActions();

  const report = useGlobalQuery("Reports", "getById", 58);
  const strategicPlans = useGlobalQuery("StrategicPlans", "getAll");
  const strategicPlansRelations = useGlobalQuery(
    "StrategicPlans",
    "getAllRelations",
  );

  const form = useForm();

  const peiId = useWatch({ exact: true, name: "PeiId", control: form.control });
  const peuId = useWatch({ exact: true, name: "PeuId", control: form.control });

  React.useEffect(() => {
    dispatch({
      type: "SET_VARIABLES",
      payload: { reportName: report.data?.ReporteNombre || "..." },
    });
    return () => dispatch({ type: "CLEAR_VARIABLES" });
  }, [dispatch, report.data?.ReporteNombre]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSubmit = (values: Record<string, any>) => {
    const path = PathUtils.getReportViewUrl(report.data!.ReporteArchivoNombre, {
      "Record[PlanId]": values.PlanId,
      "Record[TipoId]": values.TipoId,
    });
    window.open(path);
  };

  const peuAllowed = React.useMemo(() => {
    return select(
      strategicPlansRelations.data ?? [],
      (item) => item.PlanHijoId,
      (item) => item.PlanEstrategicoPadreId === peiId,
    );
  }, [peiId, strategicPlansRelations.data]);

  const poaAllowed = React.useMemo(() => {
    return select(
      strategicPlansRelations.data ?? [],
      (item) => item.PlanHijoId,
      (item) => item.PlanEstrategicoPadreId === peuId,
    );
  }, [peuId, strategicPlansRelations.data]);

  const peiOptions = React.useMemo(() => {
    if (!strategicPlans.data?.length) return [];
    return ArrayUtils.selectLabelValue(
      strategicPlans.data.filter((plan) => plan.TipoId === 1),
      "Nombre",
      "PlanEstrategicoId",
    );
  }, [strategicPlans.data]);

  const peuOptions = React.useMemo(() => {
    if (!strategicPlans.data?.length) return [];
    return ArrayUtils.selectLabelValue(
      strategicPlans.data.filter(
        (plan) =>
          plan.TipoId === 2 && peuAllowed.includes(plan.PlanEstrategicoId),
      ),
      "Nombre",
      "PlanEstrategicoId",
    );
  }, [peuAllowed, strategicPlans.data]);

  const poaOptions = React.useMemo(() => {
    if (!strategicPlans.data?.length) return [];
    return ArrayUtils.selectLabelValue(
      strategicPlans.data.filter(
        (plan) =>
          plan.TipoId === 3 && poaAllowed.includes(plan.PlanEstrategicoId),
      ),
      "Nombre",
      "PlanEstrategicoId",
    );
  }, [poaAllowed, strategicPlans.data]);

  if (report.isFetched && report.isError) {
    throw new Error(
      "Hubo un error al cargar los datos del reporte. Compruebe su conexión o si existe este reporte.",
    );
  }

  return (
    <Card
      title={report.data?.ReporteNombre ?? ""}
      subtitle="Rellena los datos requeridos y luego haz clic en generar reporte."
      actions={[
        <Button
          size="small"
          key="ResetForm"
          severity="warning"
          label="Reiniciar formulario"
        />,
        <Button
          size="small"
          key="GenerateReport"
          label="Generar reporte"
          onClick={form.handleSubmit(onSubmit)}
        />,
      ]}
    >
      <Form form={form}>
        {JsxUtil.renderLoader(
          report.isPending,
          <div className="flex flex-column gap-2">
            <Skeleton width="100%" height="3.5rem" />
            <Skeleton width="100%" height="3.5rem" />
          </div>,
        )(
          <>
            <Dropdown
              name="PeiId"
              options={peiOptions}
              className="p-inputtext-sm"
              placeholder="Selecciona una opción"
              label="Planes Estratégicos Institucionales"
            />
            <Dropdown
              name="PeuId"
              options={peuOptions}
              disabledInput={!peiId}
              className="p-inputtext-sm"
              placeholder="Selecciona una opción"
              label="Planes Estratégicos De Unidad"
              helperText={
                !peiId
                  ? "Selecciona un plan estratégico institucional"
                  : undefined
              }
            />
            <Dropdown
              name="PlanId"
              options={poaOptions}
              disabledInput={!peuId}
              className="p-inputtext-sm"
              label="Planes Operativos Anuales"
              placeholder="Selecciona una opción"
              rules={{ required: "Campo Requerido" }}
              helperText={
                !peuId ? "Selecciona un plan estratégico de unidad" : undefined
              }
            />
            {!!report.data?.ReportesParametros?.length && (
              <ReportInput parameter={report.data.ReportesParametros[0]} />
            )}
          </>,
        )}
      </Form>
    </Card>
  );
};

export default StrategicReportForm;
