import { IRouter } from "@interfaces";
import { Outlet } from "react-router";

import { Permissions } from "./core";
import { GeneralAccountingRoutes, GeneralAccountingRouteNames } from "./routes";
import {
  CurrencyPage,
  AccountCatalogPage,
  MonthlyClosingPage,
  ReconciliationPage,
  CurrencyDetailsPage,
  AccountingMovementsPage,
  AccountCatalogSimplePage,
  AccountingMovementsEditPage,
  FinancialStatementResultPage,
  AccountCatalogConsolidatePage,
  AccountingMovementsCreatePage,
  AccountingMovementsDetailsPage,
  FinancialStatementDivisionPage,
  FinancialStatementSituationPage,
} from "./pages";

const router: IRouter = {
  sidebar: true,
  iconName: "abacus",
  element: <Outlet />,
  title: "Contabilidad General",
  id: "feature-general-accounting-root",
  permissions: [Permissions.SEE_GENERAL_ACCOUNTING_MODULE],
  path: GeneralAccountingRoutes[
    GeneralAccountingRouteNames.DASHBOARD_PAGE
  ].template(),
  children: [
    {
      path: "",
      index: true,
      sidebar: true,
      dashboardId: 3065,
      iconName: "dashboard",
      title: "Panel de control",
      id: "feature-general-accounting-page",
    },
    {
      sidebar: true,
      element: <Outlet />,
      iconName: "calculator",
      title: "Catálogos de cuentas",
      id: "feature-general-accounting-account-catalog-root",
      path: GeneralAccountingRoutes[
        GeneralAccountingRouteNames.ACCOUNT_CATALOG_PAGE
      ].template(),
      children: [
        {
          path: "",
          index: true,
          sidebar: false,
          title: "Catálogos de cuentas",
          element: <AccountCatalogPage />,
          id: "feature-general-accounting-account-catalog-page",
        },
        {
          sidebar: false,
          title: "Catálogo de cuentas (Simple)",
          element: <AccountCatalogSimplePage />,
          id: "feature-general-accounting-account-catalog-simple-page",
          path: GeneralAccountingRoutes[
            GeneralAccountingRouteNames.ACCOUNT_CATALOG_SIMPLE_PAGE
          ].template(),
        },
        {
          sidebar: false,
          element: <AccountCatalogConsolidatePage />,
          title: "Catálogo de cuentas (Consolidado)",
          id: "feature-general-accounting-account-catalog-consolidate-page",
          path: GeneralAccountingRoutes[
            GeneralAccountingRouteNames.ACCOUNT_CATALOG_CONSOLIDATE_PAGE
          ].template(),
        },
      ],
    },
    {
      sidebar: true,
      element: <Outlet />,
      iconName: "replace",
      title: "Movimientos contables",
      id: "feature-general-accounting-accounting-movements-root",
      path: GeneralAccountingRoutes[
        GeneralAccountingRouteNames.ACCOUNTING_MOVEMENTS
      ].template(),
      children: [
        {
          path: "",
          index: true,
          sidebar: false,
          title: "Movimientos contables",
          element: <AccountingMovementsPage />,
          id: "feature-general-accounting-accounting-movements-page",
        },
        {
          sidebar: false,
          title: "Movimiento contable #{sec}",
          element: <AccountingMovementsDetailsPage />,
          id: "feature-general-accounting-accounting-movements-details-page",
          path: GeneralAccountingRoutes[
            GeneralAccountingRouteNames.DETAILS
          ].template(),
        },
        {
          sidebar: false,
          title: "Nuevo movimiento contable",
          element: <AccountingMovementsCreatePage />,
          id: "feature-general-accounting-accounting-movements-create-page",
          path: GeneralAccountingRoutes[
            GeneralAccountingRouteNames.CREATE
          ].template(),
        },
        {
          sidebar: false,
          element: <AccountingMovementsEditPage />,
          title: "Editando movimiento contable #{sec}",
          id: "feature-general-accounting-accounting-movements-edit-page",
          path: GeneralAccountingRoutes[
            GeneralAccountingRouteNames.EDIT
          ].template(),
        },
      ],
    },
    {
      sidebar: true,
      iconName: "abacus",
      title: "Reconciliación",
      element: <ReconciliationPage />,
      id: "feature-general-accounting-reconciliation-page",
      path: GeneralAccountingRoutes[
        GeneralAccountingRouteNames.RECONCILIATION_PAGE
      ].template(),
    },
    {
      sidebar: true,
      element: <Outlet />,
      iconName: "file-analytics",
      title: "Estados financieros",
      id: "feature-general-accounting-financial-statement-root",
      path: GeneralAccountingRoutes[
        GeneralAccountingRouteNames.FINANCIAL_STATEMENT_PAGE
      ].template(),
      children: [
        {
          index: true,
          sidebar: true,
          iconName: "file-analytics",
          title: "Estados de situación",
          element: <FinancialStatementSituationPage />,
          id: "feature-general-accounting-financial-statement-situation-page",
          path: GeneralAccountingRoutes[
            GeneralAccountingRouteNames.FINANCIAL_STATEMENT_SITUATION_PAGE
          ].template(),
        },
        {
          sidebar: true,
          iconName: "file-analytics",
          title: "Estados de resultados",
          element: <FinancialStatementResultPage />,
          id: "feature-general-accounting-financial-statement-result-page",
          path: GeneralAccountingRoutes[
            GeneralAccountingRouteNames.FINANCIAL_STATEMENT_RESULTS_PAGE
          ].template(),
        },
      ],
    },
    {
      sidebar: true,
      iconName: "coin",
      title: "Monedas",
      element: <Outlet />,
      id: "feature-general-accounting-currency-root",
      path: GeneralAccountingRoutes[
        GeneralAccountingRouteNames.CURRENCY_PAGE
      ].template(),
      children: [
        {
          path: "",
          index: true,
          sidebar: false,
          title: "Monedas",
          element: <CurrencyPage />,
          id: "feature-general-accounting-currency-page",
        },
        {
          sidebar: false,
          title: "#{id}",
          element: <CurrencyDetailsPage />,
          id: "feature-general-accounting-currency-detail-page",
          path: GeneralAccountingRoutes[
            GeneralAccountingRouteNames.CURRENCY_DETAILS_PAGE
          ].template(),
        },
      ],
    },
    {
      sidebar: true,
      iconName: "clock-cancel",
      title: "Cierres mensuales",
      element: <MonthlyClosingPage />,
      id: "feature-general-accounting-monthly-closing-page",
      path: GeneralAccountingRoutes[
        GeneralAccountingRouteNames.MONTHLY_CLOSING_PAGE
      ].template(),
    },
    {
      sidebar: true,
      iconName: "zoom-money",
      title: "Divisiones de estados financieros",
      element: <FinancialStatementDivisionPage />,
      id: "feature-general-accounting-financial-statement-divisions-page",
      path: GeneralAccountingRoutes[
        GeneralAccountingRouteNames.FINANCIAL_STATEMENT_DIVISION_PAGE
      ].template(),
    },
  ],
};

export default router;
