import React from "react";
import { useGlobalQuery } from "@hooks";
import { HeaderIcon } from "@nubeteck/prime";
import { useFormContext } from "react-hook-form";
import { ArrayUtils, EventUtils } from "@nubeteck/utils";
import { IFilter, IFilterCreate, IFilterUpdate } from "@interfaces";

import {
  Calendar,
  Dropdown,
  InputText,
  InputNumber,
  SelectButton,
} from "../inputs";

export interface IFilterFormSectionProps {
  area: string;
  index: number;
  loading: boolean;
  filterId: number;
  fieldsLength: number;
  getCreate?: IFilterCreate;
  getUpdate?: IFilterUpdate;
  onRemoveSection: (index: number) => void;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const inputs: Record<string, (props: any) => React.JSX.Element> = {
  text: (props) => <InputText placeholder="Escribe un valor" {...props} />,
  int: (props) => <Dropdown placeholder="Seleccione un valor" {...props} />,
  varchar: (props) => <InputText placeholder="Escribe un valor" {...props} />,
  numeric: (props) => <InputNumber placeholder="Ingrese un valor" {...props} />,
  datetime: (props) => (
    <Calendar placeholder="Seleccione una fecha" {...props} />
  ),
  datetime2: (props) => (
    <Calendar placeholder="Seleccione una fecha" {...props} />
  ),
  bit: (props) => (
    <SelectButton
      {...props}
      optionLabel="name"
      className="w-15rem"
      defaultValue="True"
      options={[
        { value: "True", name: "Verdadero" },
        { name: "Falso", value: "False" },
      ]}
    />
  ),
};

const FilterFormSection = ({
  area,
  index,
  loading,
  filterId,
  getCreate,
  getUpdate,
  fieldsLength,
  onRemoveSection,
}: IFilterFormSectionProps) => {
  const form = useFormContext<IFilter>();

  const name = `FiltrosParametros.${index}`;
  const condition = form.watch(`FiltrosParametros.${index}.FiltroCondicionId`);

  const campos = React.useMemo(() => {
    if (filterId) return getUpdate?.CamposSelect ?? [];
    else return getCreate?.CamposSelect ?? [];
  }, [getCreate?.CamposSelect, getUpdate?.CamposSelect, filterId]);

  const campoSelected = React.useMemo(() => {
    const campo = form.watch(`FiltrosParametros.${index}.Campo`);
    return campos.find((item) => item.columnName === campo);
  }, [campos, form, index]);

  const optionsRelated = useGlobalQuery(
    "Filters",
    "getOptionsRelated",
    {
      areaName: area,
      columnName: campoSelected?.columnName ?? "",
    },
    { enabled: campoSelected?.columnType === "int" },
  );

  const validationsParameters = React.useMemo(() => {
    if (filterId) return getUpdate?.ValidacionesParametros;
    else return getCreate?.ValidacionesParametros;
  }, [
    getCreate?.ValidacionesParametros,
    getUpdate?.ValidacionesParametros,
    filterId,
  ]);

  const camposOptions = React.useMemo(() => {
    if (!campos?.length) return [];
    return ArrayUtils.selectLabelValue(campos, "columnAlias", "columnName");
  }, [campos]);

  const conditions = React.useMemo(() => {
    if (filterId) return getUpdate?.FiltrosCondicionesSelect ?? [];
    else return getCreate?.FiltrosCondicionesSelect ?? [];
  }, [
    getCreate?.FiltrosCondicionesSelect,
    getUpdate?.FiltrosCondicionesSelect,
    filterId,
  ]);

  const conditionsOptions = React.useMemo(() => {
    if (!conditions.length) return [];
    return ArrayUtils.selectLabelValue(
      conditions ?? [],
      (condition) =>
        `${condition.FiltroCondicionNombre} (${condition.FiltroCondicionSigno})`,
      "FiltroCondicionId",
    );
  }, [conditions]);

  const optionsRelatedFormatted = React.useMemo(() => {
    if (!optionsRelated.data?.length) return [];
    return ArrayUtils.selectLabelValue(
      optionsRelated.data ?? [],
      "label",
      ({ value }) => `${value}`,
    );
  }, [optionsRelated.data]);

  return (
    <div className="flex flex-1 align-items-center gap-2">
      {index !== 0 && (
        <SelectButton
          label="Operador"
          className="p-button-sm"
          options={["AND", "OR"]}
          name={`${name}.OperadorLogico`}
        />
      )}
      <Dropdown
        label="Campo"
        loading={loading}
        name={`${name}.Campo`}
        fieldClassName="flex-1"
        className="p-inputtext-sm"
        options={camposOptions.slice(1)}
        placeholder="Selecciona el campo"
        rules={validationsParameters?.["Campo"]}
      />
      <Dropdown
        label="Condición"
        loading={loading}
        fieldClassName="flex-1"
        className="p-inputtext-sm"
        options={conditionsOptions}
        name={`${name}.FiltroCondicionId`}
        placeholder="Selecciona la condición"
        rules={validationsParameters?.["FiltroCondicionId"]}
      />
      {(inputs[campoSelected?.columnType ?? "text"] || inputs["text"])({
        size: "small",
        label: "Valor",
        name: `${name}.Valor1`,
        fieldClassName: "flex-1",
        className: "p-inputtext-sm",
        options: optionsRelatedFormatted,
        loading: optionsRelated.isPending,
        rules: validationsParameters?.["Valor1"],
      })}
      {condition === 7
        ? (inputs[campoSelected?.columnType ?? "text"] || inputs["text"])({
            label: "Valor 2",
            name: `${name}.Valor2`,
            fieldClassName: "flex-1",
            className: "p-inputtext-sm",
            options: optionsRelatedFormatted,
            loading: optionsRelated.isPending,
            rules: validationsParameters?.["Valor2"],
          })
        : null}
      {fieldsLength >= 2 && (
        <div className="flex mt-2">
          <HeaderIcon
            name="trash"
            className="text-red-500"
            onClick={EventUtils.callEvent(onRemoveSection, index)}
          />
        </div>
      )}
    </div>
  );
};

export default FilterFormSection;
