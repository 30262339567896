import { capitalize } from "radash";
import { faker } from "@faker-js/faker";
import { Datejs } from "@nubeteck/utils";
import { ISupplierSelect } from "@interfaces";

import {
  IPurchaseDocument,
  IRecurringInvoice,
  IPurchaseInvoiceForm,
} from "../features/accounts-payable/interfaces";

import { getArrayOf } from "./array";

const generateDescendingYears = (startYear: number, count: number) => {
  return Array.from({ length: count }, (_, index) => startYear - index);
};

const createYearGenerator = () => {
  const currentYear = new Date().getFullYear();
  const years = generateDescendingYears(currentYear, 50);
  let index = 0;

  return () => {
    if (index >= years.length) {
      index = 0;
    }
    return years[index++];
  };
};

const getNextYear = createYearGenerator();

let isFirstEntry = true;

export const getMonthlyClosing = (id: number) => {
  const year = getNextYear();
  const date = Datejs(`${year}-${faker.date.month()}-01`);
  const estado = isFirstEntry ? "Abierto" : "Cerrado";
  isFirstEntry = false;

  return {
    id,
    Anio: year,
    EstadoNombre: estado,
    Mes: capitalize(date.format("MMMM")),
  };
};

export const getMonthlyClosingPage = () => {
  return {
    data: getArrayOf((_, i) => getMonthlyClosing(i), 20),
  };
};

// REFERENCE DOCUMENTS

const statuses = [
  { id: 1, nombre: "Activo" },
  { id: 2, nombre: "Inactivo" },
  { id: 3, nombre: "Eliminado" },
  { id: 4, nombre: "Incompleto" },
  { id: 5, nombre: "Anulado" },
];

export const getOneReferenceDocument = (id: number) => {
  const selectedStatus = faker.helpers.arrayElement(statuses);
  return {
    TipoDocumentoId: id,
    Fecha: faker.date.past(),
    Notas: faker.lorem.lines(1),
    EstadoId: selectedStatus.id,
    DocumentoEnlace: `documents/${id}`,
    EstadoNombre: selectedStatus.nombre,
    DocumentoId: faker.number.int(1000000),
    Secuencia: "B" + faker.string.numeric(11),
    TipoDocumentoNombre: faker.helpers.arrayElement([
      "Cotización",
      "Factura de venta",
      "Nota de crédito",
      "Nota de débito",
      "Conduce",
      "Factura de compra",
      "Orden de compra",
      "Movimiento de inventario",
      "Depósito",
      "Cheque",
      "Transferencia",
      "Pago de financiamiento",
    ]),
  };
};

export const getByAccountingMovementId = (_id: number) => {
  return {
    data: getArrayOf((_, i) => getOneReferenceDocument(i), 4),
  };
};

// REFERENCE DOCUMENTS - END

// PURCHASE DOCUMENTS

const purchaseDocumentTypes = [
  { id: 1, nombre: "Factura de compra" },
  { id: 2, nombre: "Orden de compra" },
  { id: 3, nombre: "Conduce" },
];

const purchaseStatuses = [
  { id: 1, nombre: "Activo" },
  { id: 2, nombre: "Borrador" },
  { id: 3, nombre: "Pendiente de aprobación" },
];

const documentTypePrefixes: Record<string, string> = {
  Conduce: "C",
  "Orden de compra": "O",
  "Factura de compra": "F",
};

const getSequencePrefix = (documentType: string): string =>
  documentTypePrefixes[documentType];

export const getOnePurchaseDocument = (id: number): IPurchaseDocument => {
  const status = faker.helpers.arrayElement(purchaseStatuses);
  const documentType = faker.helpers.arrayElement(purchaseDocumentTypes);
  return {
    DocumentoId: id,
    EstadoId: status.id,
    EstadoNombre: status.nombre,
    TipoDocumentoId: documentType.id,
    ProveedorId: faker.number.int(1000),
    ProveedorNombre: faker.company.name(),
    TipoDocumentoNombre: documentType.nombre,
    Fecha: Datejs(faker.date.past()).format(),
    Recurrencia: faker.datatype.boolean({ probability: 0.25 }),
    Secuencia:
      getSequencePrefix(documentType.nombre) + faker.string.numeric(11),
  };
};

export const getAllPurchaseDocuments = () => {
  return {
    data: getArrayOf((_, i) => getOnePurchaseDocument(i), 20),
  };
};

const paymentFrequencies = [
  { id: 1, nombre: "Diario" },
  { id: 2, nombre: "Semanal" },
  { id: 3, nombre: "Quincenal" },
  { id: 4, nombre: "Mensual" },
  { id: 5, nombre: "Bimestral" },
  { id: 6, nombre: "Trimestral" },
  { id: 7, nombre: "Cuatrimestral" },
  { id: 8, nombre: "Semestral" },
  { id: 9, nombre: "Anual" },
];

export const getOneRecurringInvoice = (id: number): IRecurringInvoice => {
  const status = faker.helpers.arrayElement([
    { id: 1, nombre: "Activo" },
    { id: 2, nombre: "Inactivo" },
  ]);
  const frequencies = faker.helpers.arrayElement(paymentFrequencies);
  return {
    FacturaId: id,
    EstadoId: status.id,
    EstadoNombre: status.nombre,
    FrecuenciaId: frequencies.id,
    ProveedorId: faker.number.int(1000),
    EsLimitado: faker.datatype.boolean(),
    CantidadVeces: faker.number.int(100),
    FrecuenciaNombre: frequencies.nombre,
    ProveedorNombre: faker.company.name(),
    EnviarPorCorreo: faker.datatype.boolean(),
    Secuencia: "F" + faker.string.numeric(11),
    CrearComoBorrador: faker.datatype.boolean(),
    FechaInicio: Datejs(faker.date.past()).format(),
    FechaProxima: Datejs(faker.date.future()).format(),
  };
};

export const getAllRecurringInvoices = () => {
  return {
    data: getArrayOf((_, i) => getOneRecurringInvoice(i), 20),
  };
};
const generateSuppliers = (count: number): ISupplierSelect[] =>
  Array.from({ length: count }, () => ({
    ProveedorId: faker.number.int(1000),
    ProveedorNombre: faker.company.name(),
    ProveedorFoto: faker.image.avatarGitHub(),
    ProveedorDNI: faker.number.int({ min: 10000000, max: 99999999 }).toString(),
  }));

export const getPurchaseInvoiceFormUpdateValues = (): {
  data: IPurchaseInvoiceForm;
} => {
  return {
    data: {
      Tasa: 1,
      Monto: 0,
      Total: 0,
      Notas: "",
      Importe: 0,
      MonedaId: 1, // Peso(s) dominicano(s)
      EstadoId: 6, // Borrador
      TipoNCFId: 1,
      AlmacenId: 1,
      Descuento: 0,
      FacturaId: 0,
      Impuestos: 0,
      Retenciones: 0,
      Referencia: "",
      ProveedorDNI: "",
      FacturaNumero: "",
      Observaciones: "",
      ProveedorNombre: "",
      MontoPropinaLegal: 0,
      ImpuestosIncluidos: false,
      FacturasComprasDetalles: [],
      TieneDescuentoPorFecha: false,
      FacturasComprasClonaciones: [],
      FacturasComprasProcesamientoFiles: [],
      Fecha: Datejs(faker.date.past()).format(),
      TasaFecha: Datejs(faker.date.past()).format(),
      FechaVencimientoNCF: Datejs(faker.date.past()).format(),
      FechaLimiteDescuento: Datejs(faker.date.past()).format(),
      SelectOptions: { ProveedoresSelect: generateSuppliers(10) },
    },
  };
};

// PURCHASE DOCUMENTS - END
