import clsx from "clsx";
import React from "react";
import { sort } from "radash";
import { Icon } from "@nubeteck/icons";
import { useGlobalQuery } from "@hooks";
import { Message } from "primereact/message";
import { StrategicReviewTypes } from "@core";
import { Timeline } from "primereact/timeline";
import { DialogProps } from "primereact/dialog";
import { Datejs, JsxUtil } from "@nubeteck/utils";
import { Dialog, EmptyMessage } from "@components";
import { ProgressSpinner } from "primereact/progressspinner";
import {
  IStrategicPlansReview,
  IStrategicPlansIndicatorReview,
} from "@interfaces";

export interface IExecutionPoaCommentsDialogProps extends DialogProps {
  indicatorId: number;
}

const ExecutionPoaCommentsDialog = ({
  indicatorId,
  ...props
}: IExecutionPoaCommentsDialogProps) => {
  const reviews = useGlobalQuery(
    "StrategicPlansIndicator",
    "getAllReviews",
    indicatorId,
    { enabled: !!indicatorId },
  );

  const customizedContent = (item: IStrategicPlansIndicatorReview) => {
    const isTypeApproved =
      item.TipoRevisionId === StrategicReviewTypes.APPROVED;

    return (
      <div className="flex flex-column mb-5">
        <div className="flex justify-content-between align-items-center gap-2 mb-3 font-medium">
          <span className="line-height-1">
            {Datejs(item.FechaCreacion).format("LLLL")}
          </span>
          <span className="line-height-1">
            Revisado por:{" "}
            <b className="text-primary-600 cursor-pointer">
              {item.CreadoPorNombre}
            </b>
          </span>
        </div>
        <Message
          className="justify-content-start"
          severity={isTypeApproved ? "success" : "error"}
          text={
            <span>
              <b>{item.TipoRevisionId}:</b>{" "}
              {item.Descripcion || "Sin observación"}
            </span>
          }
          icon={
            <Icon
              className="mr-2"
              touchable={false}
              name={isTypeApproved ? "eye-check" : "eye-x"}
            />
          }
        />
      </div>
    );
  };

  const customizedMarker = (item: IStrategicPlansReview) => (
    <div className="py-1">
      <div
        className={clsx(
          "p-1 border-circle",
          item.TipoRevisionId === StrategicReviewTypes.APPROVED
            ? "bg-primary"
            : "bg-red-500",
        )}
      />
    </div>
  );

  return (
    <Dialog
      {...props}
      className="w-full md:w-10"
      title="Comentarios de revision"
      subtitle="Rellena los datos del formulario"
      onHide={() => {
        props.onHide();
      }}
      // footer={() => (
      //   <div className="flex flex-1 justify-content-end gap-2">
      //     <Button
      //       size="small"
      //       severity="danger"
      //       label="Solicitud de cambios"
      //       onClick={EventUtils.callEvent(handleRequestReview, 1)}
      //     />
      //     <Button
      //       size="small"
      //       label="Aprobar"
      //       severity="success"
      //       onClick={EventUtils.callEvent(handleRequestReview, 2)}
      //     />
      //   </div>
      // )}
    >
      {JsxUtil.renderLoader(
        reviews.isFetching,
        <div className="h-12rem flex justify-content-center align-items-center">
          <ProgressSpinner
            strokeWidth="5"
            animationDuration="1s"
            className="w-full w-3rem h-3rem"
          />
        </div>,
      )(
        <div className="flex justify-content-center">
          {reviews.data?.length ? (
            <Timeline
              align="alternate"
              marker={customizedMarker}
              content={customizedContent}
              value={sort(
                reviews.data ?? [],
                (item) => Datejs(item.FechaCreacion).unix(),
                true,
              )}
            />
          ) : (
            <EmptyMessage message="No hay comentarios de revision" />
          )}
        </div>,
      )}
    </Dialog>
  );
};

export default ExecutionPoaCommentsDialog;
