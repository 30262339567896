import React from "react";
import { Reducers } from "@core";
import { useGlobalQuery } from "@hooks";
import { Button } from "primereact/button";
import { ConfirmDialog } from "@components";
import { Dropdown } from "primereact/dropdown";
import { FilterMatchMode } from "primereact/api";
import { useAuthPermissions } from "@nubeteck/auth";
import { ArrayUtils, EventUtils } from "@nubeteck/utils";
import { IStrategicPlansDetailsFields } from "@interfaces";
import { CardTable, IDataTableColumnsProps } from "@nubeteck/prime";

import { Permissions } from "../../core";
import { usePeiQuery } from "../../hooks";
import { SettingsDetailsFieldsDialog } from "../../components";

const SettingsDetailsFields = () => {
  const permissions = useAuthPermissions();

  const detailsTypes = usePeiQuery("StrategicPlansDetailsTypes", "getAll");

  const [detailTypeSelected, setDetailTypeSelected] = React.useState<
    null | number
  >(null);

  const fields = useGlobalQuery(
    "StrategicPlansFields",
    "getAll",
    detailTypeSelected ?? 0,
    { enabled: !!detailTypeSelected },
  );

  const [state, dispatch] = React.useReducer(Reducers.DialogReducer, {
    id: 0,
    open: false,
  });

  const columns: IDataTableColumnsProps<IStrategicPlansDetailsFields>[] = [
    { header: "#", field: "CampoId" },
    {
      filter: true,
      field: "Nombre",
      header: "Nombre",
      dataType: "text",
      filterMatchMode: FilterMatchMode.CONTAINS,
    },
    { header: "Tipo de dato", field: "TipoDatoNombre" },
    {
      filter: true,
      dataType: "text",
      field: "Descripcion",
      header: "Descripción",
      filterMatchMode: FilterMatchMode.CONTAINS,
      body: ({ Descripcion }) => Descripcion || "Sin descripción",
    },
    {
      field: "Requerido",
      header: "¿Es requerido?",
      body: ({ Requerido }) => {
        return Requerido ? "Requerido" : "No requerido";
      },
    },
  ];

  const detailsTypesOptions = React.useMemo(() => {
    if (!detailsTypes.data?.length) return [];
    return ArrayUtils.selectLabelValue(
      detailsTypes.data ?? [],
      "DetalleTipoNombre",
      "DetalleTipoId",
    );
  }, [detailsTypes]);

  return (
    <>
      <div className="w-full">
        <CardTable
          type="data"
          hideSelectFilter
          columns={columns}
          hideFilterSettingsIcon
          value={fields.data ?? []}
          title="Campos de los tipos de detalles"
          subtitle="Configuración de planes estratégicos"
          loading={!!detailTypeSelected && fields.isPending}
          emptyMessage={
            !detailTypeSelected
              ? "Seleccione un tipo detalle para ver sus campos"
              : undefined
          }
          headActions={[
            <Dropdown
              key="DropdownDetailsType"
              className="p-inputtext-sm"
              value={detailTypeSelected}
              options={detailsTypesOptions}
              loading={detailsTypes.isPending}
              placeholder="Selecciona un tipo de detalle"
              onChange={(e) => setDetailTypeSelected(e.value)}
            />,
            <Button
              size="small"
              key="AddButtonType"
              label="Agregar campo"
              onClick={EventUtils.callEvent(dispatch, {
                payload: 0,
                type: "OPEN_DIALOG",
              })}
              disabled={
                !detailTypeSelected ||
                !permissions.hasPermission(
                  Permissions.CREATE_DETAILS_FIELDS_SETTINGS,
                )
              }
            />,
          ]}
          tableActions={(data) => [
            {
              icon: "pencil",
              label: "Editar",
              disabled: !permissions.hasPermission(
                Permissions.EDIT_DETAILS_FIELDS_SETTINGS,
              ),
              onClick: EventUtils.callEvent(dispatch, {
                type: "OPEN_DIALOG",
                payload: data.CampoId,
              }),
            },
            // {
            //   icon: "ban",
            //   label: "Anular",
            //   iconClassName: "text-red-500",
            //   disabled: data.EstadoId === 2,
            //   onClick: EventUtils.callEvent(handleNullify, data.CampoId),
            // },
            // {
            //   icon: "plug",
            //   label: "Activar",
            //   disabled: data.EstadoId === 1,
            //   iconClassName: "text-primary-500",
            //   onClick: EventUtils.callEvent(handleRestore, data.CampoId),
            // },
          ]}
        />
      </div>
      <SettingsDetailsFieldsDialog
        fieldId={state.id}
        visible={state.open}
        detailTypeId={detailTypeSelected ?? 0}
        onHide={EventUtils.callEvent(dispatch, {
          type: "CLOSE_DIALOG",
        })}
      />
      <ConfirmDialog />
    </>
  );
};

export default SettingsDetailsFields;
