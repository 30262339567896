import React from "react";
import { Icon } from "@nubeteck/icons";
import { Button } from "primereact/button";
import { useGlobalMutation } from "@hooks";
import { useIsAuthenticated } from "@nubeteck/auth";
import { Form, Password, InputText } from "@components";
import { Config, AppRoutes, AppRouteNames } from "@core";
import { useForm, SubmitHandler } from "react-hook-form";
import { Link, Navigate, useNavigate } from "react-router";

type FormValues = {
  email: string;
  password: string;
  rememberMe?: boolean;
};

const SignInPage = () => {
  const navigate = useNavigate();
  const isAuthenticated = useIsAuthenticated();

  const login = useGlobalMutation("Auth", "login");

  const form = useForm<FormValues>({
    mode: "all",
    defaultValues: {
      email: "",
      password: "",
      rememberMe: false,
    },
  });

  const onSubmit: SubmitHandler<FormValues> = async (values) => {
    return login.mutateAsync(
      { Email: values.email, Password: values.password },
      {
        onSuccess: (data) => {
          localStorage.setItem("token", data.TokenInfo.Token);
          navigate(AppRoutes[AppRouteNames.VERIFY_AUTH_PAGE].create({}), {
            state: {
              data: data.TokenInfo,
              from: window.location.pathname,
            },
          });
        },
      },
    );
  };

  if (isAuthenticated()) {
    return (
      <Navigate replace to={AppRoutes[AppRouteNames.HOME_PAGE].create({})} />
    );
  }

  return (
    <div className="flex flex-1 align-items-center justify-content-center h-screen w-screen login-bg">
      <div className="surface-ground px-3 md:px-5 py-5 shadow-4 md:w-8 lg:w-5 xl:w-4 w-full">
        <div className="mb-3 flex flex-column gap-1">
          <img
            height={60}
            src={Config.app_logo}
            alt={Config.app_description}
            style={{ objectFit: "contain" }}
            className="mb-3 align-self-start"
          />
          <span className="text-800 text-xl font-bold">Inicia Sesión</span>
          <span className="text-600 text-md font-medium">
            Bienvenidos(as) a <b>{Config.app_title}</b>
          </span>
        </div>
        <Form form={form} onSubmit={form.handleSubmit(onSubmit)}>
          <div className="flex flex-column">
            <InputText
              type="text"
              name="email"
              autoComplete="email"
              fieldClassName="flex-1"
              label="Correo electrónico"
              className="p-inputtext-sm"
              placeholder="Ej: joe@doe.com"
              rules={{ required: "Correo electrónico requerido" }}
            />
            <Password
              name="password"
              type="password"
              feedback={false}
              label="Contraseña"
              fieldClassName="flex-1"
              className="p-inputtext-sm"
              autoComplete="current-password"
              placeholder="*****************"
              rules={{ required: "Contraseña requerido" }}
            />
            <div className="flex align-items-center justify-content-between mb-2">
              <Link
                to={AppRoutes[AppRouteNames.FORGOT_PASSWORD_PAGE].create({})}
                className="font-medium text-sm no-underline text-primary text-right cursor-pointer"
              >
                ¿Olvidaste la contraseña?
              </Link>
            </div>
            <Button
              size="small"
              type="submit"
              className="w-full"
              label="Iniciar Sesión"
              loading={login.isPending}
              icon={<Icon size={20} name="login" />}
            />
          </div>
        </Form>
      </div>
    </div>
  );
};

export default SignInPage;
