import React from "react";
import { Tag } from "primereact/tag";
import { Card } from "primereact/card";
import { Icon } from "@nubeteck/icons";

import { IProvider } from "../../interfaces";

interface ProviderCardProps {
  provider: IProvider;
  onEdit: (id: number) => void;
  onDetails: (id: number) => void;
  onDelete: (id: number, status: string) => void;
}

const ProviderCard: React.FC<ProviderCardProps> = ({
  onEdit,
  provider,
  onDelete,
  onDetails,
}) => {
  return (
    <Card
      style={{ width: "335px" }}
      title={provider.NombreCompleto}
      className="mt-2 border-round-xl p-0"
      header={
        <img
          alt={provider.NombreCompleto}
          style={{
            height: "200px",
            objectFit: "cover",
          }}
          className="flex justify-content-center responsive-img m-0 p-0 border-round-top-xl w-full"
          src={
            provider.Imagen
              ? provider.Imagen
              : "https://static.vecteezy.com/system/resources/previews/004/141/669/non_2x/no-photo-or-blank-image-icon-loading-images-or-missing-image-mark-image-not-available-or-image-coming-soon-sign-simple-nature-silhouette-in-frame-isolated-illustration-vector.jpg"
          }
        />
      }
    >
      <Tag
        className="mb-2"
        severity={provider.Estado === "Activo" ? "success" : "danger"}
      >
        {provider.Estado}
      </Tag>
      <p className="m-0 p-0">{provider.DNI}</p>
      <p className="m-0 p-0">{provider.Telefono}</p>
      <p className="m-0 p-0">{provider.Correo}</p>

      <div className="flex justify-content-end">
        <Icon
          name="pencil"
          className="mr-2 text-cyan-600"
          onClick={() => onEdit(provider.ProveedorId)}
        />
        <Icon
          name="list-details"
          className="mr-2 text-teal-800"
          onClick={() => onDetails(provider.ProveedorId)}
        />
        <Icon
          className="mr-2 text-red-500"
          name={provider.Estado === "Activo" ? "trash" : "plug"}
          onClick={() => onDelete(provider.ProveedorId, provider.Estado)}
        />
      </div>
    </Card>
  );
};

export default ProviderCard;
