import React from "react";
import { IInputProps } from "@interfaces";
import { useController } from "react-hook-form";
import { InputText, InputTextProps } from "primereact/inputtext";

import { Field } from "../../field";
import { InputGroup, IInputGroupProps } from "../../input-group";

//! ANTES DE MODIFICAR ESTE COMPONENTE CONSULTE CON SUS DESARROLLADORES.
//! ANTES DE MODIFICAR ESTE COMPONENTE CONSULTE CON SUS DESARROLLADORES.
//! ANTES DE MODIFICAR ESTE COMPONENTE CONSULTE CON SUS DESARROLLADORES.

export interface IInputTextProps
  extends IInputProps,
    Omit<InputTextProps, "name" | "defaultValue">,
    IInputGroupProps {}

const $InputText = ({
  name,
  label,
  rules,
  disabled,
  helperText,
  addonAfter,
  addonBefore,
  defaultValue,
  disabledInput,
  fieldClassName,
  shouldUnregister,
  ...props
}: IInputTextProps) => {
  const { field, fieldState } = useController({
    name,
    rules,
    disabled,
    defaultValue,
    shouldUnregister,
  });

  return (
    <Field
      name={name}
      label={label}
      helperText={helperText}
      className={fieldClassName}
      isRequired={!!rules?.required}
    >
      <InputGroup addonAfter={addonAfter} addonBefore={addonBefore}>
        <InputText
          id={field.name}
          {...field}
          {...props}
          disabled={disabledInput}
          invalid={fieldState.invalid}
        />
      </InputGroup>
    </Field>
  );
};

export default $InputText;
