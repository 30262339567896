// All permissions for this module.
// Example of the constant:
// export const EXAMPLE_PERM = "Perm Id"

export const SEE_COSTS_CENTER_MODULE = 12;

export const SEE_BUDGET_PAGE = 102;

export const SEE_MATERIALS_REQUEST_PAGE = 103;

export const SEE_COSTS_CENTER_SETTINGS_PAGE = 95;
export const EDIT_COSTS_CENTER_SETTINGS = 97;
export const CREATE_COSTS_CENTER_SETTINGS = 96;
export const NULLIFY_COSTS_CENTER_SETTINGS = 98;
export const RESTORE_COSTS_CENTER_SETTINGS = 99;

export const SEE_PEU_POA_PAGE = 11;
export const CREATE_PEU = 13;
export const CREATE_POA = 14;
export const EDIT_PEU = 15;
export const EDIT_POA = 16;
export const SEE_DETAILS_PEU = 17;
export const SEE_DETAILS_POA = 18;

export const SEE_PEU_DETAILS_PAGE = 31;
export const SEE_LOGS_PEU = 63;
export const EDIT_DETAILS_PEU = 34;
export const ORDER_DETAILS_PEU = 37;
export const CREATE_DETAILS_PEU = 33;
export const APPROVE_REJECT_PEU = 42;
export const NULLIFY_DETAILS_PEU = 35;
export const RESTORE_DETAILS_PEU = 36;
export const SEE_LOGS_DETAILS_PEU = 38;
export const SEE_REVIEWS_COMMENTS_PEU = 40;
export const REQUEST_REVIEWS_COMMENTS_PEU = 41;
export const SEE_ADDITIONAL_INFO_DETAILS_PEU = 39;

export const SEE_POA_DETAILS_PAGE = 32;
export const SEE_LOGS_POA = 62;
export const APPROVE_REJECT_POA = 50;
export const SEE_REVIEWS_COMMENTS_POA = 51;
export const REQUEST_REVIEWS_COMMENTS_POA = 52;

export const SEE_POA_ACTIVITIES_PAGE = 53;
export const EDIT_ACTIVITIES_POA = 56;
export const CREATE_ACTIVITIES_POA = 55;
export const NULLIFY_ACTIVITIES_POA = 57;
export const RESTORE_ACTIVITIES_POA = 58;
export const SEE_LOGS_ACTIVITIES_POA = 59;
export const COMPLETE_ACTIVITIES_POA = 70;
export const CONFIGURE_SCHEDULE_ACTIVITIES_POA = 60;

export const SEE_POA_ACTIVITIES_INDICATORS_TAB = 61;
export const EDIT_ACTIVITIES_INDICATOR_POA = 65;
export const ORDER_ACTIVITIES_INDICATOR_POA = 68;
export const CREATE_ACTIVITIES_INDICATOR_POA = 64;
export const NULLIFY_ACTIVITIES_INDICATOR_POA = 66;
export const RESTORE_ACTIVITIES_INDICATOR_POA = 67;
export const SEE_LOGS_ACTIVITIES_INDICATOR_POA = 69;

export const SEE_POA_EXECUTION_TAB = 71;
export const UPLOAD_FILES_EXECUTION_POA = 72;
export const APPROVE_REJECT_FILES_EXECUTION_POA = 73;
