import React from "react";
import { Dialog } from "@components";
import { Button } from "primereact/button";
import { DialogProps } from "primereact/dialog";
import { CardTable, IDataTableColumnsProps } from "@nubeteck/prime";

const concepts = [
  {
    Total: 0,
    Codigo: 3,
    Accion: true,
    Tipo: "Ingresos",
    Categoria: "Categoria C",
    Nombre: "Ingresos Numero 1",
    CuentaContable: "10102 Cuenta 2",
  },
  {
    Total: 10,
    Codigo: 1,
    Accion: true,
    Tipo: "Gastos",
    Categoria: "Categoria A",
    Nombre: "Gasto Numero 1",
    CuentaContable: "10102 Cuenta 2",
  },
  {
    Total: 70,
    Codigo: 2,
    Accion: true,
    Tipo: "Inversión",
    Categoria: "Categoria B",
    Nombre: "Inversión Numero 1",
    CuentaContable: "10102 Cuenta 2",
  },
  {
    Total: 0,
    Codigo: 4,
    Accion: true,
    Tipo: "Ingresos",
    Categoria: "Categoria C",
    Nombre: "Ingresos Numero 2",
    CuentaContable: "10102 Cuenta 2",
  },
  {
    Total: 0,
    Codigo: 5,
    Accion: true,
    Tipo: "Gastos",
    Categoria: "Categoria C",
    Nombre: "Gastos Numero 2",
    CuentaContable: "10102 Cuenta 2",
  },
];

export type IConcept = (typeof concepts)[0]; // TODO: Temporal

export interface IBudgetConceptsDialogProps extends DialogProps {
  selected: IConcept[];
  onAddComplete?: (selected: IConcept[]) => void;
}

const BudgetConceptsDialog = ({
  selected,
  onAddComplete,
  ...props
}: IBudgetConceptsDialogProps) => {
  const [conceptsSelected, setConceptsSelected] = React.useState<IConcept[]>(
    selected ?? [],
  );

  React.useEffect(() => {
    setConceptsSelected(selected);
  }, [selected]);

  const columns = React.useMemo<IDataTableColumnsProps<IConcept>[]>(
    () => [
      { field: "Codigo", selectionMode: "multiple" },
      { field: "Codigo", header: "Código" },
      { field: "Nombre", header: "Nombre" },
      { field: "Tipo", header: "Tipo" },
      { field: "CuentaContable", header: "Cuenta Contable" },
    ],
    [],
  );

  return (
    <Dialog
      {...props}
      header="Conceptos"
      className="w-8 p-custom-dialog"
      footer={
        <div className="flex justify-content-end gap-2">
          <Button
            size="small"
            label="Cancelar"
            severity="secondary"
            onClick={() => props.onHide()}
          />
          <Button
            size="small"
            label="Agregar"
            onClick={() => {
              onAddComplete?.(conceptsSelected);
            }}
          />
        </div>
      }
    >
      <div className="w-full">
        <CardTable<IConcept>
          type="data"
          value={concepts}
          hideBorderStyles
          hideSelectFilter
          columns={columns}
          hideFilterSettingsIcon
          tableActions={() => []}
          selectionMode="multiple"
          selection={conceptsSelected}
          title="Seleccione el concepto"
          isDataSelectable={(event) =>
            !selected.includes(event.data as IConcept)
          }
          onSelectionChange={(e: { value: React.SetStateAction<IConcept[]> }) =>
            setConceptsSelected(e.value)
          }
        />
      </div>
    </Dialog>
  );
};

export default BudgetConceptsDialog;
