import API from "@lib/api";
import { BaseApi } from "@api";
import { Messages } from "@interfaces";

import { IPurchaseDeliveryNote } from "../interfaces";

export class PurchaseDeliveryNotesApi extends BaseApi {
  protected static readonly api = API({ namespace: "ConducesCompras" });
  protected static readonly filterApi = API({ namespace: "Filtro" });
  protected static readonly messages: Messages<
    typeof PurchaseDeliveryNotesApi
  > = {
    createPurchaseDeliveryNote: {
      loading: "Creando un nuevo conduce de compra...",
      success: "Conduce de compra creado correctamente.",
      error: (error) => `Hubo un error al crear el conduce de compra: ${error}`,
    },
  };

  // public static async getAll(): Promise<IPurchaseDocument[]> {
  //   const result = await this.api.get("GetAll");
  //   return result.data;
  // }

  //   public static async getAll(): Promise<IPurchaseDocument[]> {
  //     const result = FakerUtils.getAllPurchaseOrders();
  //     return result.data;
  //   }

  //   public static async getAllByFilter(
  //     filterId: number,
  //   ): Promise<IPurchaseDocument[]> {
  //     const result = await this.filterApi.get<IPurchaseDocument[]>(
  //       `FiltroPorArea/${filterId}`,
  //     );
  //     return result.data;
  //   }

  public static async createPurchaseDeliveryNote(
    data: object,
  ): Promise<IPurchaseDeliveryNote> {
    const result = await this.api.post("Create", data);
    return result.data;
  }
}
