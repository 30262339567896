import { IRouter } from "@interfaces";
import { Outlet } from "react-router";
import { EmptyMessage } from "@nubeteck/prime";

import { Permissions } from "./core";
import { ReportingLayout, StrategicReportForm } from "./components";
import { CostsCenterRoutes, CostsCenterRouteNames } from "./routes";
import {
  PeuPoaPage,
  BudgetPage,
  PeuPoaDetails,
  BudgetDetails,
  ReportingPage,
  PoaReportingPage,
  PoaActivitiesPage,
  SettingsConceptPage,
  SettingsCostsCenterPage,
} from "./pages";

const router: IRouter = {
  sidebar: true,
  element: <Outlet />,
  iconName: "home-dollar",
  title: "Centros de costos",
  id: "feature-costs-center-root",
  permissions: [Permissions.SEE_COSTS_CENTER_MODULE],
  path: CostsCenterRoutes[CostsCenterRouteNames.COSTS_CENTER_PAGE].template(),
  children: [
    {
      path: "",
      index: true,
      sidebar: true,
      dashboardId: 3066,
      iconName: "dashboard",
      title: "Panel de control",
      id: "feature-costs-center-page",
    },
    {
      sidebar: true,
      element: <Outlet />,
      iconName: "moneybag",
      title: "Presupuestos",
      permissions: [Permissions.SEE_BUDGET_PAGE],
      id: "feature-costs-center-presupuesto-root",
      path: CostsCenterRoutes[CostsCenterRouteNames.BUDGET_PAGE].template(),
      children: [
        {
          path: "",
          index: true,
          title: "Presupuestos",
          element: <BudgetPage />,
          id: "feature-costs-center-presupuesto-page",
        },
        {
          title: "{name}",
          element: <BudgetDetails />,
          id: "feature-costs-center-presupuesto-detail-page",
          path: CostsCenterRoutes[
            CostsCenterRouteNames.BUDGET_DETAIL_PAGE
          ].template(),
        },
      ],
    },
    {
      sidebar: true,
      path: "/solicitudes",
      iconName: "truck-delivery",
      title: "Solicitudes de materiales",
      element: <>Solicitudes de materiales</>,
      id: "feature-costs-center-solicitudes-page",
      permissions: [Permissions.SEE_MATERIALS_REQUEST_PAGE],
    },
    {
      sidebar: true,
      title: "PEU y POA",
      iconName: "script",
      element: <Outlet />,
      id: "feature-costs-center-peu-poa-root",
      permissions: [Permissions.SEE_PEU_POA_PAGE],
      path: CostsCenterRoutes[CostsCenterRouteNames.PEU_POA_PAGE].template(),
      children: [
        {
          path: "",
          index: true,
          title: "PEU y POA",
          element: <PeuPoaPage />,
          id: "feature-costs-center-peu-poa-page-root",
        },
        {
          element: <Outlet />,
          title: "Detalles de {pageTitle}",
          id: "feature-costs-center-peu-poa-details-root",
          path: CostsCenterRoutes[
            CostsCenterRouteNames.PEU_POA_DETAILS_PAGE
          ].template(),
          children: [
            {
              path: "",
              permissionOperator: "OR",
              element: <PeuPoaDetails />,
              title: "Detalles de {pageTitle}",
              id: "feature-costs-center-peu-poa-details-page",
              permissions: [
                Permissions.SEE_PEU_DETAILS_PAGE,
                Permissions.SEE_POA_DETAILS_PAGE,
              ],
            },
            {
              title: "Actividades",
              element: <PoaActivitiesPage />,
              permissions: [Permissions.SEE_POA_ACTIVITIES_PAGE],
              id: "feature-costs-center-peu-poa-details-activities-page",
              path: CostsCenterRoutes[
                CostsCenterRouteNames.POA_ACTIVITIES_PAGE
              ].template(),
            },
            {
              sidebar: false,
              title: "Reporte",
              iconName: "report",
              element: <PoaReportingPage />,
              id: "feature-costs-center-peu-poa-details-reporting-page",
              path: CostsCenterRoutes[
                CostsCenterRouteNames.REPORTING_PAGE
              ].template(),
            },
          ],
        },
      ],
    },
    {
      sidebar: true,
      title: "Reportes",
      iconName: "report",
      id: "feature-costs-center-reports-root",
      element: <ReportingLayout categoryId={11} />,
      path: CostsCenterRoutes[CostsCenterRouteNames.REPORTING_PAGE].template(),
      children: [
        {
          path: "",
          sidebar: false,
          title: "Selecciona un reporte",
          id: "feature-costs-center-reports-page",
          element: (
            <EmptyMessage
              customWidth="300px"
              message="Selecciona un reporte para generar"
            />
          ),
        },
        {
          path: "58",
          sidebar: false,
          title: "{reportName}",
          element: <StrategicReportForm />,
          id: "feature-costs-center-custom-58-page",
        },
        {
          sidebar: false,
          title: "{reportName}",
          element: <ReportingPage />,
          id: "feature-costs-center-reports-form-page",
          path: CostsCenterRoutes[
            CostsCenterRouteNames.REPORTING_PAGE_ID
          ].template(),
        },
      ],
    },
  ],
};

const routerSettings: IRouter = {
  sidebar: true,
  element: <Outlet />,
  iconName: "settings",
  title: "Centro de costos",
  id: "feature-costs-center-settings-root",
  permissions: [Permissions.SEE_COSTS_CENTER_SETTINGS_PAGE],
  path: CostsCenterRoutes[CostsCenterRouteNames.COSTS_CENTER_PAGE].template(),
  children: [
    {
      path: "",
      sidebar: true,
      iconName: "settings",
      title: "Centro de costos",
      element: <SettingsCostsCenterPage />,
      id: "feature-costs-center-settings-page",
    },
    {
      sidebar: true,
      iconName: "bulb",
      title: "Conceptos",
      id: "feature-concepts-settings-page",
      element: <SettingsConceptPage></SettingsConceptPage>,
      path: CostsCenterRoutes[CostsCenterRouteNames.CONCEPT_PAGE].template(),
    },
  ],
};

export { router, routerSettings };
