import clsx from "clsx";
import React from "react";
import { Reducers } from "@core";
import { Icon } from "@nubeteck/icons";
import { Dropdown } from "@nubeteck/forms";
import { EventUtils } from "@nubeteck/utils";
import { ISupplierSelect } from "@interfaces";
import { SelectItemOptionsType } from "primereact/selectitem";
import {
  ContactInfoSidebar,
  ImageOptionTemplate,
  ImageWithLabelTemplate,
} from "@components";

interface IDropdownSupplierProps {
  loading: boolean;
  selectedSupplier?: ISupplierSelect;
  options: undefined | SelectItemOptionsType;
}

const DropdownSupplier = ({
  options,
  loading,
  selectedSupplier,
}: IDropdownSupplierProps) => {
  const [supplierSidebarState, supplierSidebarDispatch] = React.useReducer(
    Reducers.DialogReducer,
    {
      id: 0,
      open: false,
    },
  );

  const handleSupplierIconClick = () => {
    if (selectedSupplier) {
      supplierSidebarDispatch({
        type: "OPEN_DIALOG",
        payload: selectedSupplier.ProveedorId ?? 0,
      });
    }
  };

  return (
    <div className="flex-1 flex align-items-center">
      <Dropdown
        showClear
        label="Proveedor"
        options={options}
        loading={loading}
        name="ProveedorId"
        placeholder="Seleccione"
        className="p-inputtext-sm"
        fieldClassName="flex-1 mr-2"
        itemTemplate={ImageOptionTemplate}
        filter={(options ?? []).length > 5}
        valueTemplate={ImageWithLabelTemplate}
        rules={{ required: "Este campo es obligatorio" }}
        panelFooterTemplate={() => (
          <div className="py-2 px-3">
            {selectedSupplier ? (
              <span>
                Selección actual: <b>{selectedSupplier.ProveedorNombre}</b>.
              </span>
            ) : (
              "Sin proveedor seleccionado"
            )}
          </div>
        )}
      />
      <Icon
        name="eye-search"
        title="Ver proveedor"
        style={{ marginTop: ".7rem" }}
        onClick={handleSupplierIconClick}
        touchable={Boolean(selectedSupplier)}
        className={clsx({
          "text-400": !selectedSupplier,
          "text-primary": selectedSupplier,
        })}
      />
      <ContactInfoSidebar
        id={supplierSidebarState.id}
        title="Información del proveedor"
        visible={supplierSidebarState.open}
        onHide={EventUtils.callEvent(supplierSidebarDispatch, {
          type: "CLOSE_DIALOG",
        })}
      />
    </div>
  );
};

export default DropdownSupplier;
