/* eslint-disable @typescript-eslint/no-explicit-any */
import { BaseApi } from "@api";
import { AxiosError } from "axios";
import { template, isFunction } from "radash";
import {
  ReturnType,
  Parameters,
  StaticMethods,
  RemoveApiSuffix,
} from "@interfaces";
import {
  useMutation,
  useQueryClient,
  UseMutationOptions,
} from "@tanstack/react-query";

import useToast from "./useToast";

type Options<D = object, E = AxiosError, V = object> = Omit<
  UseMutationOptions<D, E, V>,
  "meta" | "onMutate" | "mutationFn" | "mutationKey"
>;

export interface IUseConfigMutationProps<
  T extends Record<string, typeof BaseApi>,
> {
  apis: T;
}

const useConfigMutation = <T extends Record<string, typeof BaseApi>>({
  apis,
}: IUseConfigMutationProps<T>) => {
  type Keys = keyof typeof apis;

  type ApiMethods = {
    [K in Keys as RemoveApiSuffix<K>]: StaticMethods<(typeof apis)[K]>;
  };

  return function Mutation<
    K extends keyof ApiMethods,
    F extends keyof ApiMethods[K],
  >(
    key: K,
    fn: F,
    options?: Options<
      Awaited<ReturnType<ApiMethods[K][F]>>,
      AxiosError,
      Parameters<ApiMethods[K][F]>[0]
    >,
    invalidate: boolean = true,
  ) {
    const toast = useToast();
    const queryClient = useQueryClient();
    const apiClass = (apis as any)[`${String(key)}Api`];
    const meta = apiClass?.getMessage(String(fn));
    return useMutation({
      ...options,
      meta,
      mutationKey: [key, fn],
      mutationFn: (variables) => apiClass[fn]?.(variables),
      onMutate: () => {
        if (meta?.loading) {
          toast.loading(meta?.loading ?? "Cargando...");
        }
      },
      onError: (error, variables) => {
        if (meta?.error) {
          const message = isFunction(meta.error)
            ? meta.error(error)
            : meta.error;
          toast.error(template(message, variables as Record<string, string>));
        }
      },
      onSuccess: async (data, variables) => {
        if (invalidate) queryClient.invalidateQueries({ queryKey: [key] });
        if (meta?.success) {
          const message = isFunction(meta.success)
            ? meta.success(data)
            : meta.success;
          toast.success(template(message, variables as Record<string, string>));
        }
      },
    });
  };
};

export default useConfigMutation;
