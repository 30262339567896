import React from "react";
import { Icon } from "@nubeteck/icons";
import { Ripple } from "primereact/ripple";
import { Divider } from "primereact/divider";
import { HeaderIcon } from "@nubeteck/prime";
import { StyleClass } from "primereact/styleclass";
import { useAuthPermissions } from "@nubeteck/auth";
import { Config, AppRoutes, Permissions, AppRouteNames } from "@core";

import { AppsMenuOverlay } from "../apps-menu-overlay";
import { UserMenuOptions } from "../user-menu-options";

export interface IHeaderProps {
  title: string;
}

const Header = ({ title }: IHeaderProps) => {
  const sidebarRef = React.useRef(null);
  const permissions = useAuthPermissions();

  return (
    <header className="flex align-items-center gap-1 h-3rem surface-section pl-3 surface-border border-bottom-1">
      <div className="flex lg:hidden align-items-center h-full">
        <StyleClass
          hideOnOutsideClick
          selector="#sidebar"
          nodeRef={sidebarRef}
          leaveToClassName="hidden"
          enterFromClassName="hidden"
          enterActiveClassName="fadeinleft"
          leaveActiveClassName="fadeoutleft"
        >
          <a
            ref={sidebarRef}
            style={{ height: 24 }}
            className="p-ripple text-primary-500 cursor-pointer"
          >
            <Icon name="menu-2" />
            <Ripple />
          </a>
        </StyleClass>
      </div>
      {/* <div className="hidden md:flex flex-1 text-sm text-primary font-medium">
        {`${Config.app_title} | ${title}`}
      </div>
      <div className="flex flex-1 md:flex-none justify-content-end">
        <div className="flex gap-2 align-items-center"> */}
      <div className="hidden lg:flex flex-1 text-sm text-primary font-semibold">
        {`${Config.app_title} | ${title}`}
      </div>
      <div className="flex flex-1 justify-content-end">
        <div className="flex gap-2 align-items-center py-2">
          {permissions?.hasPermission(Permissions.SEE_GENERAL_SETTINGS) && (
            <HeaderIcon
              name="settings"
              title="Configuraciones"
              to={AppRoutes[AppRouteNames.SETTINGS_PAGE].create({})}
            />
          )}
          <AppsMenuOverlay />
        </div>
        <Divider layout="vertical" className="p-0 ml-2 m-0" />
        <div className="flex h-3rem justify-content-end">
          <UserMenuOptions />
        </div>
      </div>
    </header>
  );
};

export default Header;
