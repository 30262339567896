import React from "react";
import { sort } from "radash";
import { Datejs } from "@nubeteck/utils";
import { EmptyMessage } from "@components";
import { FilterMatchMode } from "primereact/api";
import { CardTable, StatusTag, IDataTableColumnsProps } from "@nubeteck/prime";

import { useAccountingQuery } from "../../hooks";
import { IMovimientosBancarios } from "../../interfaces";

const BankingTransactionsTab = () => {
  const movimientosBancario = useAccountingQuery(
    "MovimientosBancarios",
    "getAllNoConciliados",
  );

  const columns: IDataTableColumnsProps<IMovimientosBancarios>[] = [
    {
      sortable: true,
      header: "Código",
      field: "MovimientoBancarioId",
      body: (data: IMovimientosBancarios) => `#${data.MovimientoBancarioId}`,
    },
    {
      header: "Cuenta bancaria",
      field: "BancoCuentaNombre",
    },
    {
      header: "Tipo documento",
      field: "TipoDocumentoNombre",
    },
    {
      field: "Fecha",
      header: "Fecha",
      body: (data: IMovimientosBancarios) =>
        Datejs(data.Fecha).format("DD/MM/YYYY"),
    },
    {
      field: "NumeroDocumento",
      header: "Número documento",
    },
    {
      field: "Importe",
      header: "Importe",
      body: (data: IMovimientosBancarios) =>
        data.MonedaNombreISO + " " + data.Importe,
    },
    {
      field: "Concepto",
      header: "Concepto",
    },
    {
      filter: true,
      dataType: "text",
      field: "Beneficiario",
      header: "Beneficiario",
      filterMatchMode: FilterMatchMode.STARTS_WITH,
    },
    {
      header: "Estado",
      field: "EstadoNombre",
      body: ({ EstadoNombre }) => <StatusTag status={EstadoNombre} />,
    },
    {
      header: "Estado conciliación",
      field: "EstadoConciliacionNombre",
    },
  ];

  const renderEmptyMessage = React.useCallback(() => {
    return (
      <EmptyMessage message="Sin movimientos bancarios pendientes de conciliación" />
    );
  }, []);

  const value = React.useMemo(() => {
    if (!movimientosBancario.data) return [];
    return movimientosBancario.data.map((item) => ({
      ...item,
      Fecha: new Date(item.Fecha),
    }));
  }, [movimientosBancario.data]);

  return (
    <CardTable<IMovimientosBancarios>
      scrollable
      type="data"
      dataKey="id"
      hideSelectFilter
      resizableColumns
      columns={columns}
      tableActions={[]}
      columnResizeMode="expand"
      emptyMessage={renderEmptyMessage()}
      title="Registro de movimientos bancarios sin conciliar"
      value={sort(value, (item) => item.Fecha.getTime(), true)}
    />
  );
};

export default BankingTransactionsTab;
