import { IRouter } from "@interfaces";
import { Outlet } from "react-router";

import { ExampleRoutes, ExampleRouteNames } from "./routes";

const router: IRouter = {
  sidebar: true,
  title: "Ejemplo",
  iconName: "apple",
  element: <Outlet />,
  id: "feature-example-root",
  path: ExampleRoutes[ExampleRouteNames.EXAMPLE].template(),
  children: [
    {
      path: "",
      title: "", // Title is not necessary since the parent has the same name.
      index: true,
      dashboardId: 2064,
      id: "feature-example-page",
    },
  ],
};

const routerSettings: IRouter = {
  sidebar: true,
  element: <Outlet />,
  iconName: "settings",
  title: "Configuraciones de ejemplo",
  id: "feature-example-settings-root",
  path: ExampleRoutes[ExampleRouteNames.EXAMPLE].template(),
  children: [
    {
      path: "",
      sidebar: true,
      title: "Ajustes 1",
      iconName: "settings",
      element: <>Settings 1</>,
      id: "feature-example-settings-1-page",
    },
  ],
};

export { router, routerSettings };
