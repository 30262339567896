import clsx from "clsx";
import React from "react";
import { FieldValues, FormProvider, UseFormReturn } from "react-hook-form";

//! ANTES DE MODIFICAR ESTE COMPONENTE CONSULTE CON SUS DESARROLLADORES.
//! ANTES DE MODIFICAR ESTE COMPONENTE CONSULTE CON SUS DESARROLLADORES.
//! ANTES DE MODIFICAR ESTE COMPONENTE CONSULTE CON SUS DESARROLLADORES.

export interface IFormProps<T extends FieldValues>
  extends React.ComponentProps<"form"> {
  form: UseFormReturn<T>;
}

const Form = <T extends FieldValues>({
  form,
  children,
  className,
  ...props
}: React.PropsWithChildren<IFormProps<T>>) => {
  return (
    <FormProvider {...form}>
      <form {...props} className={clsx("p-fluid", className)}>
        {children}
      </form>
    </FormProvider>
  );
};

export default Form;
