import React from "react";
import { useForm } from "react-hook-form";
import { Button } from "primereact/button";
import { ArrayUtils } from "@nubeteck/utils";
import { Message } from "primereact/message";
import { CostsCenterFeature } from "@features";
import { useQueryClient } from "@tanstack/react-query";

import { Form } from "../../form";
import { MultiSelect } from "../../inputs";
import { Dialog, IDialogProps } from "../dialog";

export interface IUserCostsCenterFormDialogProps extends IDialogProps {
  userId: number;
}

type FormValues = {
  UserId: number;
  CentrosCostos: number[];
};

const UserCostsCenterFormDialog = ({
  userId,
  ...props
}: IUserCostsCenterFormDialogProps) => {
  const queryClient = useQueryClient();

  const assignUser = CostsCenterFeature.useCostCenterMutation(
    "CostsCenter",
    "assignUser",
  );
  const centerCosts = CostsCenterFeature.useCostCenterQuery(
    "CostsCenter",
    "getAll",
  );
  const centerCostsByUser = CostsCenterFeature.useCostCenterQuery(
    "CostsCenter",
    "getAllByUser",
    userId,
    { enabled: !!userId },
  );

  const form = useForm<FormValues>();
  const { reset } = form;

  React.useEffect(() => {
    if (userId && centerCostsByUser.data) {
      reset(
        {
          CentrosCostos: centerCostsByUser.data.map(
            (centerCost) => centerCost.CentroCostoId,
          ),
        },
        { keepDefaultValues: true },
      );
    }
  }, [userId, centerCostsByUser.data, reset]);

  const handleSuccess = () => {
    form.reset();
    props.onHide();
    queryClient.invalidateQueries({ queryKey: ["Users"] });
  };

  const onSubmit = (values: FormValues) => {
    return assignUser.mutateAsync(
      { ...values, UserId: userId },
      { onSuccess: handleSuccess },
    );
  };

  const centerCostsOptions = React.useMemo(() => {
    if (!centerCosts.data?.length) return [];
    return ArrayUtils.selectLabelValue(
      centerCosts.data,
      "Nombre",
      "CentroCostoId",
    );
  }, [centerCosts.data]);

  return (
    <Dialog
      {...props}
      className="w-full md:w-10 lg:w-7"
      title="Asignando centros de costos"
      subtitle="Rellena los datos del formulario"
      onHide={() => {
        if (userId) form.reset();
        props.onHide();
      }}
      footer={
        <div className="flex flex-1 justify-content-end gap-2">
          <Button
            size="small"
            label="Cancelar"
            severity="secondary"
            onClick={() => {
              form.reset();
              props.onHide();
            }}
          />
          <Button
            size="small"
            onClick={form.handleSubmit(onSubmit)}
            label={userId ? "Guardar cambios" : "Crear usuario"}
            // loading={loading || createUser.isPending || updateUser.isPending}
          />
        </div>
      }
    >
      <Form form={form}>
        <Message
          className="mb-2"
          text="Todos los seleccionados serán asignados, si necesitas remover uno solo no lo selecciones."
        />
        <MultiSelect
          name="CentrosCostos"
          options={centerCostsOptions}
          label="Centros de costos asociados"
          placeholder="Seleccionar centro de costos"
          loading={centerCosts.isPending || centerCostsByUser.isPending}
        />
      </Form>
    </Dialog>
  );
};

export default UserCostsCenterFormDialog;
