import API from "@lib/api";
import { IFile, Messages } from "@interfaces";

import { BaseApi } from "./base-api";

export class FilesApi extends BaseApi {
  protected static api = API({ namespace: "Files" });
  protected static messages: Messages<typeof FilesApi> = {
    download: {
      error: (error) => error,
      success: "Archivo descargado con exito.",
      loading: "Descargando archivo (puede que tarde mucho tiempo)...",
    },
  };

  public static async download(id: number) {
    const filters = await this.api.get<IFile>(`DownloadFile/${id}`);
    return filters.data;
  }
}
