import React from "react";
import { Form, Dialog } from "@components";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { ColumnEditorOptions } from "primereact/column";
import { useWatch, useFieldArray } from "react-hook-form";
import { CardTable, HeaderIcon, IDataTableColumnsProps } from "@nubeteck/prime";

import { useDashboardQuery } from "../../hooks";
import { DataSourcesDropdown } from "../data-sources-dropdown";
import {
  IDataSourceField,
  IDashboardWidgetDialogProps,
} from "../../interfaces";

type FormValues = {
  title: string;
  subtitle: string;
  FuenteId: null | number;
  CamposId: IDataSourceField[];
};

const DashboardDialogSources = ({
  form,
  onSubmitClick,
  ...props
}: IDashboardWidgetDialogProps<FormValues>) => {
  const sourceId = useWatch({ name: "FuenteId", control: form.control });

  const { data, isPending } = useDashboardQuery(
    "DataSources",
    "getCampos",
    sourceId ?? 0,
    { enabled: !!sourceId },
  );
  const { update } = useFieldArray({
    name: "CamposId",
    control: form.control,
  });

  const fieldsId = useWatch({ name: "CamposId", control: form.control });

  const allowEdit = React.useCallback(
    (rowData: IDataSourceField) =>
      (fieldsId ?? [])?.some(
        (field) => field.NombreCampo === rowData.NombreCampo,
      ),
    [fieldsId],
  );

  const textEditor = (options: ColumnEditorOptions) => {
    return (
      <InputText
        type="text"
        value={options.rowData?.Label}
        placeholder="Escriba un alias"
        className="p-inputtext-sm w-14rem"
        onChange={(e) => options.editorCallback!(e.target.value)}
      />
    );
  };

  const columns = React.useMemo<IDataTableColumnsProps<IDataSourceField>[]>(
    () => [
      { field: "TipoDato", selectionMode: "multiple" },
      { header: "Campo", field: "NombreCampo" },
      {
        field: "Label",
        header: "Alias",
        editor: textEditor,
        body: (rowData) => {
          const fieldSelected = (fieldsId ?? [])?.find(
            (field) => field.NombreCampo === rowData.NombreCampo,
          );
          return fieldSelected?.Label
            ? fieldSelected?.Label
            : "Sin alias asociado";
        },
      },
      {
        field: "" as never,
        rowEditor: allowEdit,
        bodyStyle: {
          width: "1px",
          textAlign: "center",
          whiteSpace: "nowrap",
        },
      },
    ],
    [allowEdit, fieldsId],
  );

  const value = React.useMemo(() => {
    return data?.map((field, index) => ({ ...field, Id: index + 1 }));
  }, [data]);

  return (
    <Dialog
      {...props}
      title="Fuentes de datos"
      className="w-full md:w-10"
      footer={
        <div className="flex flex-wrap justify-content-end gap-2">
          <Button
            size="small"
            type="button"
            label="Cancelar"
            severity="secondary"
            onClick={() => {
              form.reset();
              props.onHide();
            }}
          />
          <Button
            size="small"
            label="Guardar cambios"
            onClick={form.handleSubmit(onSubmitClick)}
          />
        </div>
      }
    >
      <Form form={form}>
        <DataSourcesDropdown
          label="Fuente"
          name="FuenteId"
          placeholder="Selecciona una fuente"
          onSelect={() => form.setValue("CamposId", [])}
        />
        <CardTable<IDataSourceField>
          type="data"
          editMode="row"
          hideGlobalSearch
          hideSelectFilter
          hideBorderStyles
          paginator={false}
          tableActions={[]}
          columns={columns}
          selectionKey="Id"
          value={value ?? []}
          hideFilterSettingsIcon
          loading={fieldsId ? isPending : false}
          rowEditorCancelIcon={<HeaderIcon name="x" />}
          rowEditorSaveIcon={<HeaderIcon name="check" />}
          rowEditorInitIcon={<HeaderIcon name="pencil" />}
          customSelection={fieldsId?.map((field) => field.Id ?? 0)}
          emptyMessage={
            !fieldsId ? "Selecciona una fuente de datos" : undefined
          }
          onRowEditComplete={(e) =>
            update(e.index, e.newData as IDataSourceField)
          }
          onSelectionChange={(e) => {
            form.setValue("CamposId", e.value as unknown as IDataSourceField[]);
          }}
        />
      </Form>
    </Dialog>
  );
};

export default DashboardDialogSources;
