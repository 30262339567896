import React from "react";

// TODO: UPDATE a OrdenesCompras y otras tablas relacionadas n:n
// OrdenesCompras
// OrdenesComprasDetalle
// OrdenesComprasArchivos
// OrdenesComprasClonaciones
// OrdenesComprasFacturasCompras
const PurchaseOrderEditPage = () => {
  return <div>PurchaseOrderEditPage</div>;
};

export default PurchaseOrderEditPage;
