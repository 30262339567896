import API from "@lib/api";
import { BaseApi } from "@api";
import {
  Messages,
  IStrategicPlansTemplates,
  IStrategicPlansTemplatesDetails,
  IStrategicPlansTemplatesGetForCreate,
  IStrategicPlansTemplatesGetForUpdate,
} from "@interfaces";

export class StrategicPlansTemplatesApi extends BaseApi {
  protected static api = API({ namespace: "PlanesEstrategicos" });
  protected static messages: Messages<typeof StrategicPlansTemplatesApi> = {
    create: {
      error: (error) => error,
      loading: "Creando nueva plantilla...",
      success: "Plantilla creada correctamente.",
    },
    update: {
      error: (error) => error,
      loading: "Actualizando plantilla...",
      success: "Plantilla actualizada correctamente.",
    },
    changeState: {
      error: (error) => error,
      loading: "Actualizando estado de la plantilla...",
      success: "Estado de la plantilla actualizada correctamente.",
    },
  };

  public static async getAll() {
    const result =
      await this.api.get<IStrategicPlansTemplates[]>("GetAllPlantilla");
    return result.data;
  }

  public static async getAllDetails(id: number) {
    const result = await this.api.get<IStrategicPlansTemplatesDetails[]>(
      `GetAllPlantillaDetalle/${id}`,
    );
    return result.data;
  }

  public static async getById(id: number) {
    const result = await this.api.get<IStrategicPlansTemplates>(
      `GetPlantillaById/${id}`,
    );
    return result.data;
  }

  public static async create(data: IStrategicPlansTemplates) {
    const result = await this.api.post<IStrategicPlansTemplates>(
      "AddPlanEstrategicoPlantilla",
      data,
    );
    return result.data;
  }

  public static async getCreate() {
    const result = await this.api.get<IStrategicPlansTemplatesGetForCreate>(
      "GetCreatePlanEstrategicoPlantilla",
    );
    return result.data;
  }

  public static async update(data: Partial<IStrategicPlansTemplates>) {
    const result = await this.api.put(
      `UpdatePlanEstrategicoPlantilla/${data.PlantillaId}`,
      data,
    );
    return result.data;
  }

  public static async getUpdate(id: number) {
    const result = await this.api.get<IStrategicPlansTemplatesGetForUpdate>(
      `GetUpdatePlanEstrategicoPlantilla/${id}`,
    );
    return result.data;
  }

  public static async changeState(data: {
    PlantillaId: number;
    EstadoId: number;
  }) {
    const result = await this.api.post(
      "ChangeStatePlanEstrategicoPlantilla",
      data,
    );
    return result.data;
  }
}
