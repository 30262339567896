import React from "react";
import { useGlobalQuery } from "@hooks";
import { useAuthPermissions } from "@nubeteck/auth";
import { IStrategicPlansDetails } from "@interfaces";
import { StrategicPlansTreeTable } from "@components";

import { Permissions } from "../../core";

export interface IPeuDetailsTableProps {
  peiId: number;
  stateId: number;
  templateId: number;
  detailsChildren: IStrategicPlansDetails[];
}

const PeuDetailsTable = ({
  peiId,
  stateId,
  templateId,
  detailsChildren,
}: IPeuDetailsTableProps) => {
  const permissions = useAuthPermissions();

  const planPei = useGlobalQuery("StrategicPlans", "getById", peiId, {
    enabled: !!peiId,
  });

  const details = React.useMemo(() => {
    if (!planPei.data?.Detalles?.length) return [];
    return planPei.data?.Detalles;
  }, [planPei.data?.Detalles]);

  return (
    <StrategicPlansTreeTable
      stateId={stateId}
      templateId={templateId}
      customActionAllowed={[]}
      loading={planPei.isPending}
      typesAllowed={[3, 6, 7, 8]}
      onRefresh={() => planPei.refetch()}
      details={[...details, ...detailsChildren]}
      permissions={{
        canEdit: permissions.hasPermission(Permissions.EDIT_DETAILS_PEU),
        canOrder: permissions.hasPermission(Permissions.ORDER_DETAILS_PEU),
        canCreate: permissions.hasPermission(Permissions.CREATE_DETAILS_PEU),
        canNullify: permissions.hasPermission(Permissions.NULLIFY_DETAILS_PEU),
        canRestore: permissions.hasPermission(Permissions.RESTORE_DETAILS_PEU),
        canSeeLogs: permissions.hasPermission(Permissions.SEE_LOGS_DETAILS_PEU),
        canSeeAdditionalInfo: permissions.hasPermission(
          Permissions.SEE_ADDITIONAL_INFO_DETAILS_PEU,
        ),
      }}
    />
  );
};

export default PeuDetailsTable;
