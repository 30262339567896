import API from "@lib/api";
import {
  IFilter,
  Messages,
  IFilterArea,
  IFilterCreate,
  IFilterUpdate,
} from "@interfaces";

import { BaseApi } from "./base-api";

export class FiltersApi extends BaseApi {
  protected static api = API({ namespace: "Filtro" });
  protected static messages: Messages<typeof FiltersApi> = {
    create: {
      loading: "Creando un nuevo filtro...",
      success: "Filtro creado correctamente.",
      error: (error) => `Hubo un error al crear el filtro: ${error}`,
    },
    delete: {
      loading: "Eliminando filtro...",
      success: "Filtro eliminado correctamente.",
      error: (error) => `Hubo un error al eliminar el filtro: ${error}`,
    },
    update: {
      loading: "Actualizando filtro...",
      success: "Filtro actualizado correctamente.",
      error: (error) => `Hubo un error al actualizar el filtro: ${error}`,
    },
  };

  public static async getAllByArea(area: string) {
    const filters = await this.api.get<IFilter[]>(`GetByArea/${area}`);
    return filters.data;
  }

  public static async getAreaByArea(area: string) {
    const filters = await this.api.get<IFilterArea[]>(`GetAreaByArea/${area}`);
    return filters.data[0];
  }

  public static async getById(id: number) {
    const filter = await this.api.get<IFilter>(`GetById/${id}`);
    return filter.data;
  }

  public static async create(data: IFilter) {
    const filter = await this.api.post("Create", data);
    return filter.data;
  }

  public static async getCreate(area: string) {
    const result = await this.api.get<IFilterCreate>(`GetForCreate/${area}`);
    return result.data;
  }

  public static async getUpdate(id: number) {
    const result = await this.api.get<IFilterUpdate>(`GetForUpdate/${id}`);
    return result.data;
  }

  public static async update(data: Partial<IFilter>) {
    const filter = await this.api.put(`Update/${data.FiltroId}`, data);
    return filter.data;
  }

  public static async delete(id: number) {
    return await this.api.post("ChangeState", { EstadoId: 3, FiltroId: id });
  }

  public static async getOptionsRelated({
    areaName,
    columnName,
  }: {
    areaName: string;
    columnName: string;
  }): Promise<{ label: string; value: number }[]> {
    const result = await this.api.get(
      `GetOptionsByRelatedProperty/${areaName}/${columnName}`,
    );
    return result.data;
  }
}
