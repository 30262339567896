import React from "react";
import { ConfirmDialog, ConfirmDialogProps } from "primereact/confirmdialog";

//! ANTES DE MODIFICAR ESTE COMPONENTE CONSULTE CON SUS DESARROLLADORES
//! ANTES DE MODIFICAR ESTE COMPONENTE CONSULTE CON SUS DESARROLLADORES
//! ANTES DE MODIFICAR ESTE COMPONENTE CONSULTE CON SUS DESARROLLADORES

const ConfirmDialogCustom = (props: ConfirmDialogProps) => {
  return (
    <ConfirmDialog
      headerClassName="pb-2"
      rejectLabel="Cancelar"
      contentClassName="pb-3"
      acceptClassName="p-button-danger p-button-sm"
      rejectClassName="p-button-sm mr-2 p-button-secondary"
      {...props}
    />
  );
};

export default ConfirmDialogCustom;
