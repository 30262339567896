import API from "@lib/api";
import { BaseApi } from "@api";
import {
  Messages,
  ICurrency,
  QueryFilter,
  IExchangeRates,
  ICurrencyCreate,
} from "@interfaces";

export class CurrencyApi extends BaseApi {
  protected static api = API({ namespace: "Moneda" });
  protected static filterApi = API({ namespace: "Filtro" });
  protected static messages: Messages<typeof CurrencyApi> = {
    create: {
      loading: "Creando nueva moneda...",
      success: "Moneda creada correctamente.",
      error: (error) => `Hubo un error al crear la moneda: ${error}`,
    },
    update: {
      loading: "Actualizando moneda...",
      success: "Moneda actualizada correctamente.",
      error: (error) => `Hubo un error al actualizar la moneda: ${error}`,
    },
  };

  public static async getAll(): Promise<ICurrency[]> {
    const result = await this.api.get("GetAll");
    return result.data;
  }

  public static async getAllByFilter(params: {
    filterId: number;
    page: number;
    pageSize: number;
  }) {
    const result = await this.filterApi.get<QueryFilter<ICurrency[]>>(
      `FiltroPorArea/${params.filterId}?PageSize=${params.pageSize}&Page=${params.page}`,
    );
    return result.data;
  }

  public static async create(data: ICurrency): Promise<ICurrency> {
    const result = await this.api.post("Create", data);
    return result.data;
  }

  public static async getCreate(): Promise<ICurrencyCreate> {
    const result = await this.api.get("Create");
    return result.data;
  }

  public static async update(data: ICurrency): Promise<ICurrency> {
    const result = await this.api.put(`Update/${data.MonedaId}`, data);
    return result.data;
  }

  public static async getById(id: number): Promise<ICurrency> {
    const result = await this.api.get(`GetById/${id}`);
    return result.data;
  }

  public static async exchangesRates(id: number) {
    const result = await this.api.get<IExchangeRates[]>(
      `GetTasaCambioByMonedaId/${id}`,
    );
    return result.data;
  }
}
