import React from "react";
import { Statuses } from "@core";
import { Icon } from "@nubeteck/icons";
import { useGlobalQuery } from "@hooks";
import { FormatterUtils } from "@utils";
import { unique, isEmpty } from "radash";
import { Button } from "primereact/button";
import { ConfirmDialog } from "@components";
import { FilterMatchMode } from "primereact/api";
import { confirmDialog } from "primereact/confirmdialog";
import { useNavigate, generatePath } from "react-router";
import { Datejs, ArrayUtils, EventUtils } from "@nubeteck/utils";
import {
  CardTable,
  StatusTag,
  PaginatorState,
  IDataTableColumnsProps,
} from "@nubeteck/prime";
import {
  EmptyMessage,
  FiltersDrawer,
  DateFilterTemplate,
  MultiSelectFilterTemplate,
} from "@components";

import { IAccountingMovement } from "../../interfaces";
import { EDIT_ROUTE, CREATE_ROUTE } from "../../routes";
import { useAccountingQuery, useAccountingMutation } from "../../hooks";

const AREA_FILTER_DEFAULT = "fcMovimientosContablesBuscar";

const AccountingMovements = () => {
  const navigate = useNavigate();
  const navigateToDetails = React.useCallback(
    (id: number) => navigate(`${id}`, { relative: "route" }),
    [navigate],
  );

  const { data: filters } = useGlobalQuery(
    "Filters",
    "getAllByArea",
    AREA_FILTER_DEFAULT,
  );

  const [filterSelected, setFilterSelected] = React.useState<
    number | undefined
  >();
  const [paginator, setPaginator] = React.useState<PaginatorState>({
    page: 0,
    first: 0,
    rows: 10,
  });

  const accountingMovements = useAccountingQuery(
    "AccountingMovements",
    "getAllByFilter",
    {
      page: paginator.page,
      pageSize: paginator.rows,
      filterId: filterSelected ?? 0,
    },
    { enabled: !!filterSelected },
  );
  const nullifyAccountingMovement = useAccountingMutation(
    "AccountingMovements",
    "delete",
  );

  const [filterSettingsVisible, setFilterSettingsVisible] =
    React.useState(false);

  React.useEffect(() => {
    const defaultFilterId = filters?.find(
      (filter) => filter.EsPorDefecto,
    )?.FiltroId;

    setFilterSelected(defaultFilterId);
  }, [filters]);

  const filtersOptions = React.useMemo(() => {
    if (!filters?.length) return [];
    return ArrayUtils.selectLabelValue(filters ?? [], "Nombre", "FiltroId");
  }, [filters]);

  const accountingMovementsTypes = React.useMemo(() => {
    if (!accountingMovements.data?.Data?.length) return [];
    return unique(
      accountingMovements.data?.Data.map(
        (accountingMovement) => accountingMovement.TipoAsientoNombre,
      ),
    );
  }, [accountingMovements]);

  const statusesTypes = React.useMemo(() => {
    if (!accountingMovements.data?.Data?.length) return [];
    return unique(
      accountingMovements.data.Data.map(
        (accountingMovement) => accountingMovement.EstadoNombre,
      ),
    );
  }, [accountingMovements]);

  const columns = React.useMemo<IDataTableColumnsProps<IAccountingMovement>[]>(
    () => [
      {
        sortable: true,
        field: "Secuencia",
        header: "Secuencia",
        body: (rowData) => (
          <a
            onClick={() => navigateToDetails(rowData.AsientoId)}
            className="no-underline text-blue-500 cursor-pointer"
          >
            {rowData.Secuencia}
          </a>
        ),
      },
      {
        filter: true,
        sortable: true,
        dataType: "select",
        header: "Tipo de Asiento",
        field: "TipoAsientoNombre",
        showFilterMenuOptions: false,
        filterMenuClassName: "custom",
        filterMatchMode: FilterMatchMode.CONTAINS,
        // body: ({ TipoAsientoNombre }) => TipoAsientoNombre || "-",
        filterElement: (options) =>
          MultiSelectFilterTemplate(options, accountingMovementsTypes),
      },
      {
        filter: true,
        sortable: true,
        field: "Fecha",
        header: "Fecha",
        dataType: "date",
        showAddButton: false,
        showFilterOperator: false,
        filterMenuClassName: "custom",
        filterElement: DateFilterTemplate,
        filterMatchMode: FilterMatchMode.DATE_IS,
        body: (rowData) => (
          <span title={Datejs(rowData.Fecha).format("LL")}>
            {Datejs(rowData.Fecha).format("DD/MM/YYYY")}
          </span>
        ),
      },
      {
        sortable: true,
        field: "Total",
        header: "Total",
        body: (rowData) =>
          FormatterUtils.currency(rowData.Total ?? 0, {
            currencyIso: rowData.MonedaNombreISO ?? "DOP",
          }),
      },
      {
        sortable: true,
        field: "Referencia",
        header: "Referencia",
      },
      {
        filter: true,
        sortable: true,
        header: "Estado",
        dataType: "text",
        field: "EstadoNombre",
        showFilterMenuOptions: false,
        filterMenuClassName: "custom",
        filterMatchMode: FilterMatchMode.CONTAINS,
        body: (rowData) => <StatusTag status={rowData.EstadoNombre} />,
        filterElement: (options) =>
          MultiSelectFilterTemplate(options, statusesTypes),
      },
      {
        sortable: true,
        field: "Observaciones",
        header: "Observaciones",
        className: "text-overflow-ellipsis max-w-25rem",
        body: (rowData) => (
          <span title={rowData.Observaciones}>
            {!isEmpty(rowData.Observaciones?.trim() ?? "")
              ? rowData.Observaciones
              : "Sin observaciones"}
          </span>
        ),
      },
    ],
    [accountingMovementsTypes, navigateToDetails, statusesTypes],
  );

  const renderEmptyMessage = React.useCallback(() => {
    return <EmptyMessage message="No hay movimientos" />;
  }, []);

  const tableActions = React.useCallback(
    (rowData: IAccountingMovement) => [
      {
        label: "Detalles",
        icon: "list-details",
        onClick: () => navigateToDetails(rowData.AsientoId),
      },
      {
        icon: "edit",
        label: "Editar",
        disabled: rowData.EstadoId !== Statuses.DRAFT_CODE,
        onClick: () =>
          navigate(generatePath(EDIT_ROUTE, { id: `${rowData.AsientoId}` }), {
            relative: "route",
          }),
      },
      {
        icon: "trash",
        label: "Anular",
        disabled: rowData.EstadoId === Statuses.NULLIFIED_CODE,
        onClick: () => {
          confirmDialog({
            acceptLabel: "Anular",
            rejectLabel: "Cancelar",
            message: "Esta acción no podrá deshacerse.",
            header: `¿Estás seguro(a) de anular el asiento ${rowData.Secuencia}?`,
            accept: async () => {
              await nullifyAccountingMovement.mutateAsync(rowData.AsientoId);
            },
          });
        },
      },
    ],
    [navigate, navigateToDetails, nullifyAccountingMovement],
  );

  return (
    <>
      <CardTable<IAccountingMovement>
        type="data"
        resizableColumns
        columns={columns}
        paginator={false}
        removableSort={true}
        rows={paginator.rows}
        enableFilterPagination
        first={paginator.first}
        columnResizeMode="expand"
        tableActions={tableActions}
        valueFilter={filterSelected}
        filterOptions={filtersOptions}
        emptyMessage={renderEmptyMessage()}
        loading={accountingMovements.isFetching}
        onSelectFilterOption={setFilterSelected}
        value={accountingMovements.data?.Data ?? []}
        onRefresh={() => accountingMovements.refetch()}
        onPaginationChange={(event) => setPaginator(event)}
        totalRecords={accountingMovements.data?.TotalRecords}
        onClickFilterSettings={EventUtils.callEvent(
          setFilterSettingsVisible,
          true,
        )}
        headActions={[
          <Button
            size="small"
            key="ADD_BTN"
            label="Agregar movimiento contable"
            loading={accountingMovements.isFetching}
            icon={<Icon name="replace" className="mr-2" />}
            onClick={() => navigate(CREATE_ROUTE, { relative: "route" })}
          />,
        ]}
      />
      <ConfirmDialog />
      <FiltersDrawer
        area={AREA_FILTER_DEFAULT}
        visible={filterSettingsVisible}
        onHide={EventUtils.callEvent(setFilterSettingsVisible, false)}
      />
    </>
  );
};

export default AccountingMovements;
