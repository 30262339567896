import React from "react";
import { Link } from "react-router";
import { Icon } from "@nubeteck/icons";
import { useGlobalQuery } from "@hooks";
import { Reducers, Statuses } from "@core";
import { Button } from "primereact/button";
import { FiltersDrawer } from "@components";
import { ConfirmDialog } from "@components";
import { FilterMatchMode } from "primereact/api";
import { ArrayUtils, EventUtils } from "@nubeteck/utils";
import { confirmDialog } from "primereact/confirmdialog";
import {
  CardTable,
  StatusTag,
  PaginatorState,
  IDataTableColumnsProps,
} from "@nubeteck/prime";

import { IAccountCatalogCreate } from "../../interfaces";
import { AccountCatalogListForm } from "../../components";
import { useAccountingQuery, useAccountingMutation } from "../../hooks";
import {
  GeneralAccountingRoutes,
  GeneralAccountingRouteNames,
} from "../../routes";

const FILTER_AREA = "fcCatalogosCuentasBuscar";

const AccountCatalogPage = () => {
  const { data: filters } = useGlobalQuery(
    "Filters",
    "getAllByArea",
    FILTER_AREA,
  );
  const changeStatusCatalogo = useAccountingMutation(
    "AccountCatalog",
    "changeState",
  );

  const [state, dispatch] = React.useReducer(Reducers.DialogReducer, {
    id: 0,
    open: false,
  });
  const [filterSettingsVisible, setFilterSettingsVisible] =
    React.useState(false);
  const [filterSelected, setFilterSelected] = React.useState<
    number | undefined
  >();
  const [paginator, setPaginator] = React.useState<PaginatorState>({
    page: 0,
    first: 0,
    rows: 10,
  });

  const accountCatalogs = useAccountingQuery(
    "AccountCatalog",
    "getAllByFilter",
    {
      page: paginator.page,
      pageSize: paginator.rows,
      filterId: filterSelected ?? 0,
    },
    { enabled: !!filterSelected },
  );

  React.useEffect(() => {
    const defaultFilterId = filters?.find(
      (filter) => filter.EsPorDefecto,
    )?.FiltroId;
    setFilterSelected(defaultFilterId);
  }, [filters]);

  const handleRestore = React.useCallback(
    (id: number) => {
      confirmDialog({
        acceptLabel: "Activar",
        acceptClassName: "p-button-sm",
        header: "Activar catalogo de cuenta",
        message: "¿Está seguro de que desea activar este catalogo de cuenta?",
        accept: async () => {
          await changeStatusCatalogo.mutateAsync({
            CatalogoId: id,
            EstadoId: Statuses.ACTIVE_CODE,
          });
        },
      });
    },
    [changeStatusCatalogo],
  );

  const handleNullify = React.useCallback(
    (id: number) => {
      confirmDialog({
        acceptLabel: "Anular",
        header: "Anular catalogo de cuenta",
        message: "¿Está seguro de que desea anular este catalogo de cuenta?",
        accept: async () => {
          await changeStatusCatalogo.mutateAsync({
            CatalogoId: id,
            EstadoId: Statuses.NULLIFIED_CODE,
          });
        },
      });
    },
    [changeStatusCatalogo],
  );

  const columns: IDataTableColumnsProps<IAccountCatalogCreate>[] = [
    {
      header: "No.",
      field: "CatalogoId",
    },
    {
      field: "Nombre",
      header: "Nombre",
      body: (rowData) =>
        rowData.CatalogoTipoId !== 2 ? (
          <Link
            className="no-underline"
            to={`.${GeneralAccountingRoutes[
              GeneralAccountingRouteNames.ACCOUNT_CATALOG_SIMPLE_PAGE
            ].create({ id: `${rowData.CatalogoId}` })}`}
          >
            {rowData.Nombre}
          </Link>
        ) : (
          <Link
            className="no-underline"
            to={`.${GeneralAccountingRoutes[
              GeneralAccountingRouteNames.ACCOUNT_CATALOG_CONSOLIDATE_PAGE
            ].create({ id: `${rowData.CatalogoId}` })}`}
          >
            {rowData.Nombre}
          </Link>
        ),
    },
    {
      filter: true,
      sortable: true,
      dataType: "numeric",
      field: "CatalogoTipoId",
      header: "Tipo de Catálogo",
      filterMatchMode: FilterMatchMode.STARTS_WITH,
      body: (rowData) =>
        rowData.CatalogoTipoId === 1 ? "Simple" : "Consolidado",
    },
    {
      header: "Estado",
      field: "EstadoNombre",
      body: (data) => <StatusTag status={data.EstadoNombre ?? "Activo"} />,
    },
  ];

  const filtersOptions = React.useMemo(() => {
    if (!filters?.length) return [];
    return ArrayUtils.selectLabelValue(filters ?? [], "Nombre", "FiltroId");
  }, [filters]);

  return (
    <div>
      <CardTable
        type="data"
        columns={columns}
        paginator={false}
        dataKey="AsientoId"
        removableSort={true}
        rows={paginator.rows}
        first={paginator.first}
        columnResizeMode="expand"
        valueFilter={filterSelected}
        title="Catálogos de cuentas"
        filterOptions={filtersOptions}
        loading={accountCatalogs.isFetching}
        value={accountCatalogs.data?.Data ?? []}
        totalRecords={accountCatalogs.data?.TotalRecords}
        onPaginationChange={(event) => setPaginator(event)}
        onSelectFilterOption={(value) => setFilterSelected(value)}
        onClickFilterSettings={EventUtils.callEvent(
          setFilterSettingsVisible,
          true,
        )}
        headActions={[
          <Button
            size="small"
            key="NewCatalog"
            label="Nuevo catalogo de cuentas"
            icon={<Icon size={20} name="plus" className="mr-1" />}
            onClick={EventUtils.callEvent(dispatch, {
              payload: 0,
              type: "OPEN_DIALOG",
            })}
          />,
        ]}
        tableActions={(data) => [
          {
            icon: "pencil",
            label: "Editar",
            onClick: EventUtils.callEvent(dispatch, {
              type: "OPEN_DIALOG",
              payload: data.CatalogoId,
            }),
          },
          {
            icon: "ban",
            label: "Anular",
            iconClassName: "text-red-500",
            disabled: data.EstadoId === Statuses.NULLIFIED_CODE,
            onClick: EventUtils.callEvent(handleNullify, data.CatalogoId),
          },
          {
            icon: "plug",
            label: "Activar",
            iconClassName: "text-primary-500",
            disabled: data.EstadoId === Statuses.ACTIVE_CODE,
            onClick: EventUtils.callEvent(handleRestore, data.CatalogoId),
          },
        ]}
      />
      <FiltersDrawer
        area={FILTER_AREA}
        visible={filterSettingsVisible}
        onHide={EventUtils.callEvent(setFilterSettingsVisible, false)}
      />
      <AccountCatalogListForm
        open={state.open}
        cuentaId={state.id}
        onClose={EventUtils.callEvent(dispatch, { type: "CLOSE_DIALOG" })}
      />
      <ConfirmDialog />
    </div>
  );
};

export default AccountCatalogPage;
