import React from "react";
import { Constants } from "@core";
import { Icon } from "@nubeteck/icons";
import { Card } from "@nubeteck/prime";
import { JsxUtil } from "@nubeteck/utils";
import { Button } from "primereact/button";
import { useWatch } from "react-hook-form";
import { Divider } from "primereact/divider";
import { Skeleton } from "primereact/skeleton";
import { TabView, TabPanel } from "primereact/tabview";
import { InputText, InputSwitch, RadioButton } from "@components";

import { useDashboardQuery } from "../../hooks";
import { DraggableWidgetCard } from "../draggable-widget-card";

const devicesOptions = [
  { value: "xs", name: "Celulares pequeños (xs)" },
  { value: "sm", name: "Celulares (sm)" },
  { value: "md", name: "Tablet (md)" },
  { value: "lg", name: "Laptops (lg)" },
  { value: "xl", name: "Escritorios (xl)" },
];

const DashboardDrawerOptions = () => {
  const widgetsTypes = useDashboardQuery("Widgets", "getAll");

  const breakpoint = useWatch({ name: "breakpoint" });

  const widgetsTypesSorted = React.useMemo(() => {
    return widgetsTypes.data?.toSorted((a, b) => {
      if (a.NombreWidget?.includes("Chart")) return 1;
      if (b.NombreWidget?.includes("Chart")) return -1;
      return 0;
    });
  }, [widgetsTypes]);

  return (
    <Card
      title="Opciones"
      hideBorderStyles
      hideContentPadding
      className="w-20rem h-full border-1 surface-border"
      footer={
        <div className="flex justify-content-end">
          <Button
            size="small"
            type="submit"
            className="w-auto"
            label="Guardar cambios"
          />
        </div>
      }
    >
      <TabView scrollable className="p-custom-tabview">
        <TabPanel
          header="Edición"
          leftIcon={<Icon size={18} name="pencil" className="mr-1" />}
        >
          <InputText
            name="name"
            className="p-inputtext-sm"
            placeholder="Escriba un nombre..."
            label="Nombre del panel de control"
          />
          <InputSwitch name="editable" label="Habilitar edición" />
          <RadioButton
            mode="vertical"
            name="breakpoint"
            options={devicesOptions}
            placeholder="Selecciona"
            className="p-inputtext-sm"
            label="Selecciona el tamaño"
            helperText={`Ancho actual: ${Constants.breakpoints[breakpoint] ?? 0}px`}
          />
        </TabPanel>
        <TabPanel
          header="Widgets"
          leftIcon={<Icon size={18} name="category" className="mr-1" />}
        >
          <div
            style={{ gap: "3px" }}
            className="flex flex-wrap justify-content-start"
          >
            <DraggableWidgetCard id="image" title="Imagen" iconName="photo" />
            <DraggableWidgetCard id="table" title="Tabla" iconName="table" />
            <DraggableWidgetCard
              id="kpi"
              title="KPI"
              iconName="device-desktop-analytics"
            />
            <Divider className="m-2">
              <span className="text-sm font-semibold">Gráficos</span>
            </Divider>
            {!widgetsTypesSorted?.length && !widgetsTypes.isPending && (
              <div className="flex flex-column flex-1 gap-2 align-items-center">
                <span className="text-sm font-medium">
                  No hay widgets para mostrar
                </span>
                <div>
                  <Button
                    size="small"
                    type="button"
                    label="Refrescar"
                    className="w-auto"
                    onClick={() => widgetsTypes.refetch()}
                    icon={<Icon size={20} name="reload" className="mr-1" />}
                  />
                </div>
              </div>
            )}
            {JsxUtil.renderLoader(
              widgetsTypes.isPending,
              <div style={{ gap: "3px" }} className="flex flex-wrap">
                <Skeleton size="6rem"></Skeleton>
                <Skeleton size="6rem"></Skeleton>
                <Skeleton size="6rem"></Skeleton>
                <Skeleton size="6rem"></Skeleton>
                <Skeleton size="6rem"></Skeleton>
                <Skeleton size="6rem"></Skeleton>
                <Skeleton size="6rem"></Skeleton>
                <Skeleton size="6rem"></Skeleton>
                <Skeleton size="6rem"></Skeleton>
              </div>,
            )(
              widgetsTypesSorted?.map((widgetType) => {
                return (
                  <DraggableWidgetCard
                    key={widgetType.WidgetId}
                    id={widgetType.WidgetIdentificador}
                    title={widgetType.NombreWidget ?? ""}
                    iconName={widgetType.NombreIcon ?? "plus"}
                  />
                );
              }),
            )}
          </div>
        </TabPanel>
      </TabView>
    </Card>
  );
};

export default DashboardDrawerOptions;
