import { IInputProps } from "@interfaces";
import { useController } from "react-hook-form";
import { RadioButton, RadioButtonProps } from "primereact/radiobutton";

import { Field } from "../../field";

//! ANTES DE MODIFICAR ESTE COMPONENTE CONSULTE CON SUS DESARROLLADORES.
//! ANTES DE MODIFICAR ESTE COMPONENTE CONSULTE CON SUS DESARROLLADORES.
//! ANTES DE MODIFICAR ESTE COMPONENTE CONSULTE CON SUS DESARROLLADORES.

export interface IRadioButtonProps
  extends IInputProps,
    Omit<RadioButtonProps, "name" | "defaultValue"> {
  mode: "vertical" | "horizontal";
  options: { name: string; value: number | string; disabled?: boolean }[];
}

const $RadioButton = ({
  name,
  mode,
  rules,
  label,
  options,
  disabled,
  helperText,
  defaultValue,
  disabledInput,
  fieldClassName,
  shouldUnregister,
  ...props
}: IRadioButtonProps) => {
  const { field, fieldState } = useController({
    name,
    rules,
    disabled,
    defaultValue,
    shouldUnregister,
  });

  return (
    // style={{ cursor: disabledInput ? "not-allowed" : "inherit" }}
    <Field
      name={name}
      label={label}
      helperText={helperText}
      className={fieldClassName}
      isRequired={!!rules?.required}
    >
      {mode === "horizontal"
        ? options.map((option) => (
            <div key={option.value} className="flex align-items-center">
              <RadioButton
                {...props}
                name={field.name}
                value={option.value}
                invalid={fieldState.invalid}
                checked={field.value === option.value}
                onChange={(e) => field.onChange(e.value)}
                disabled={disabledInput || option.disabled}
              />
              <label className="ml-2" htmlFor={option.name}>
                {option.name}
              </label>
            </div>
          ))
        : options.map((option) => (
            <div key={option.value} className="flex align-items-center">
              <RadioButton
                {...props}
                name={field.name}
                value={option.value}
                invalid={fieldState.invalid}
                checked={field.value === option.value}
                onChange={(e) => field.onChange(e.value)}
                disabled={disabledInput || option.disabled}
              />
              <label className="ml-2" htmlFor={option.name}>
                {option.name}
              </label>
            </div>
          ))}
    </Field>
  );
};

export default $RadioButton;
