import React from "react";
import millify from "millify";
import CountUp from "react-countup";
import { CurrencyCode } from "@core";
import { Icon } from "@nubeteck/icons";
import { Card } from "@nubeteck/prime";
import { FormatterUtils } from "@utils";
import { useGlobalQuery } from "@hooks";
import { MenuItem } from "primereact/menuitem";
import { useForm, useWatch } from "react-hook-form";
import { ContextMenu } from "primereact/contextmenu";

import { useDashboardQuery } from "../../../hooks";
import { Widget, IDashboardComponent } from "../../../interfaces";
import { DashboardKPIDialogSources } from "../../dashboard-kpi-dialog-sources";
import { DashboardKPIDialogSettings } from "../../dashboard-kpi-dialog-settings";

interface IDashboardProps {
  dates: Date[];
  title: string;
  iconName?: string;
  animated?: boolean;
  description: string;
  formatLocale: string;
  CampoId: null | string;
  formatCurrency: number;
  formatPrecision: number;
  FuenteId: null | number;
  formatAbbreviate: boolean;
  formatType: "number" | "currency" | "percentage";
}

export type IDashboardKPICardProps = {
  (props: IDashboardProps): React.JSX.Element;
  Dashboard: React.FC<IDashboardProps & IDashboardComponent>;
};

type FormValues = {
  title: string;
  iconName: string;
  description: string;
  formatLocale: string;
  CampoId: null | string;
  formatCurrency: number;
  FuenteId: null | number;
  formatPrecision: number;
  formatAbbreviate: boolean;
  formatType: "number" | "currency" | "percentage";
};

const KPICard: IDashboardKPICardProps = ({
  title,
  dates,
  CampoId,
  FuenteId,
  animated,
  formatType,
  description,
  formatLocale,
  formatAbbreviate,
  iconName = "dashboard",
  formatPrecision: precision,
  formatCurrency: currencyId,
}) => {
  const currency = useGlobalQuery("Currencies", "getById", currencyId, {
    enabled: !!currencyId,
  });
  const { data, isFetching } = useDashboardQuery(
    "DataSources",
    "getData",
    { date1: dates[0], date2: dates[1], id: FuenteId ?? 0 },
    { enabled: !!FuenteId },
  );

  const formattingFn = React.useMemo(() => {
    return (count: number) => {
      const formatter = FormatterUtils[formatType] || FormatterUtils.number;
      let formattedValue = formatter(count, {
        precision,
        locale: currency.data?.Locale ?? formatLocale,
        currencyIso: (currency.data?.NombreISO ??
          "DOP") as keyof typeof CurrencyCode,
      });

      if (formatAbbreviate) {
        if (formatType === "currency") {
          const numericValue = parseFloat(
            formattedValue.replace(/[^0-9.-]+/g, ""),
          );
          const millifiedValue = millify(numericValue, {
            precision: precision || 2,
          });
          formattedValue = formattedValue.replace(/[0-9.,]+/, millifiedValue);
        } else {
          formattedValue = millify(count, { precision: precision || 2 });
        }
      }

      return formattedValue;
    };
  }, [
    currency.data?.Locale,
    currency.data?.NombreISO,
    formatAbbreviate,
    formatLocale,
    formatType,
    precision,
  ]);

  const formatWithoutAbbreviation = React.useMemo(() => {
    return (count: number) => {
      const formatter = FormatterUtils[formatType] || FormatterUtils.number;
      return formatter(count, {
        precision,
        locale: currency.data?.Locale ?? formatLocale,
        currencyIso: (currency.data?.NombreISO ??
          "DOP") as keyof typeof CurrencyCode,
      });
    };
  }, [
    currency.data?.Locale,
    currency.data?.NombreISO,
    formatLocale,
    formatType,
    precision,
  ]);

  const value = React.useMemo(() => {
    if (isFetching) return "Cargando...";
    return !FuenteId ? "Sin fuente de datos" : data?.[0]?.[CampoId as string];
  }, [CampoId, FuenteId, data, isFetching]);

  return (
    <Card>
      <div className="flex justify-content-between align-items-center mb-1">
        <h3 className="text-lg font-medium m-0">{title}</h3>
        {iconName && <Icon name={iconName} touchable={false} />}
      </div>
      <span
        className="text-2xl font-semibold"
        title={formatWithoutAbbreviation(value)}
      >
        {typeof value === "string" ? (
          value
        ) : (
          <>
            {animated ? (
              <CountUp end={value} duration={2} formattingFn={formattingFn} />
            ) : (
              formattingFn(value)
            )}
          </>
        )}
      </span>
      {description && <p className="mt-2 m-0 text-600">{description}</p>}
    </Card>
  );
};

KPICard.Dashboard = function Dashboard({
  i,
  title,
  CampoId,
  FuenteId,
  iconName,
  onEditable,
  formatType,
  description,
  formatLocale,
  onRemoveWidget,
  onUpdateWidget,
  formatCurrency,
  formatPrecision,
  formatAbbreviate,
}) {
  const contextMenuRef = React.useRef<ContextMenu>(null);

  const form = useForm<FormValues>();
  const { reset } = form;

  const widgets = useWatch({ name: "widgets" }) as Widget[];

  const [dialogSourcesOpen, setDialogSourcesOpen] = React.useState(false);
  const [dialogSettingsOpen, setDialogSettingsOpen] = React.useState(false);

  React.useEffect(() => {
    reset(
      {
        title,
        CampoId,
        FuenteId,
        iconName,
        formatType,
        description,
        formatLocale,
        formatCurrency,
        formatPrecision,
        formatAbbreviate,
      },
      { keepDefaultValues: false },
    );
  }, [
    CampoId,
    FuenteId,
    iconName,
    formatLocale,
    description,
    reset,
    formatAbbreviate,
    formatCurrency,
    formatPrecision,
    formatType,
    title,
  ]);

  const onRightClick = React.useCallback((event: React.MouseEvent) => {
    if (contextMenuRef.current) {
      contextMenuRef.current.show(event);
    }
  }, []);

  const onSubmit = (values: FormValues) => {
    const widgetIndex = widgets.findIndex((widget) => widget.i === i);
    onUpdateWidget?.(widgetIndex, { i, type: "kpi", ...values });
    setDialogSettingsOpen(false);
    setDialogSourcesOpen(false);
    onEditable?.(true);
  };

  const items: MenuItem[] = [
    {
      label: "Ajustes",
      icon: <Icon size={20} name="settings" className="mr-2" />,
      command: () => {
        onEditable?.(false);
        setDialogSettingsOpen(true);
      },
    },
    {
      label: "Fuente de datos",
      icon: <Icon size={20} name="database" className="mr-2" />,
      command: () => {
        onEditable?.(false);
        setDialogSourcesOpen(true);
      },
    },
    {
      label: "Eliminar",
      command: () => onRemoveWidget?.(i),
      icon: <Icon size={20} name="trash" className="mr-2" />,
    },
  ];

  return (
    <>
      <div className="flex flex-1" onContextMenu={onRightClick}>
        <KPICard
          dates={[]}
          title={title}
          animated={false}
          CampoId={CampoId}
          FuenteId={FuenteId}
          iconName={iconName}
          formatType={formatType}
          description={description}
          formatLocale={formatLocale}
          formatCurrency={formatCurrency}
          formatPrecision={formatPrecision}
          formatAbbreviate={formatAbbreviate}
        />
      </div>
      {/* ContextMenu */}
      <ContextMenu model={items} breakpoint="767px" ref={contextMenuRef} />
      <DashboardKPIDialogSettings
        form={form}
        onSubmitClick={onSubmit}
        visible={dialogSettingsOpen}
        onHide={() => {
          onEditable?.(true);
          setDialogSettingsOpen(false);
        }}
      />
      <DashboardKPIDialogSources
        form={form}
        onSubmitClick={onSubmit}
        visible={dialogSourcesOpen}
        onHide={() => {
          onEditable?.(true);
          setDialogSourcesOpen(false);
        }}
      />
    </>
  );
};

KPICard.Dashboard.displayName = "KPICard.Dashboard";

export default KPICard;
