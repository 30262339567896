import Cookies from "js-cookie";
import axios, { CreateAxiosDefaults } from "axios";

const SERVER_URL = process.env.VITE_NUBETECK_SERVER_URL ?? "";

if (!SERVER_URL) {
  throw new Error("Please add your Server URL to environment variables.");
}

type Options = Omit<CreateAxiosDefaults, "baseUrl" | "headers"> & {
  namespace?: string;
};

const API = ({ namespace, ...options }: Options) => {
  const api = axios.create({
    ...options,
    headers: {
      "Content-Type": "application/json",
    },
    baseURL: namespace ? `${SERVER_URL}/${namespace}/` : SERVER_URL,
  });

  api.interceptors.request.use(
    (config) => {
      const authToken = Cookies.get("_auth");
      const token = localStorage.getItem("token");
      if (authToken || token) {
        config.headers.Authorization = `Bearer ${authToken || token}`;
      }

      return config;
    },
    (error) => Promise.reject(error),
  );

  api.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response) {
        return Promise.reject(error.response.data.detail);
      }
      return Promise.reject(error);
    },
  );

  return api;
};

export default API;
