import React from "react";
import { EmptyMessage } from "@components";
import { GeneralAccountingFeature } from "@features";
import { Datejs, ArrayUtils } from "@nubeteck/utils";
import { FilterOperator, FilterMatchMode } from "primereact/api";
import { CardTable, StatusTag, IDataTableColumnsProps } from "@nubeteck/prime";

interface IReferenceDocumentsTableProps {
  id: number;
}

const ReferenceDocumentsTable = ({ id }: IReferenceDocumentsTableProps) => {
  const { data: referenceDocuments, isPending: referenceDocumentsPending } =
    GeneralAccountingFeature.useAccountingQuery(
      "ReferenceDocument",
      "getByAccountingMovementId",
      id,
    );

  const documentTypeOptions = React.useMemo(() => {
    if (!referenceDocuments?.length) return [];

    return ArrayUtils.selectLabelValue(
      referenceDocuments,
      "TipoDocumentoNombre",
      "TipoDocumentoId",
    );
  }, [referenceDocuments]);

  const statusesOptions = React.useMemo(() => {
    if (!referenceDocuments?.length) return [];

    return ArrayUtils.selectLabelValue(
      referenceDocuments,
      "EstadoNombre",
      "EstadoId",
    );
  }, [referenceDocuments]);

  const columns = React.useMemo<
    IDataTableColumnsProps<GeneralAccountingFeature.IReferenceDocument>[]
  >(
    () => [
      {
        filter: true,
        sortable: true,
        dataType: "text",
        field: "Secuencia",
        header: "Secuencia",
        filterMatchMode: FilterMatchMode.STARTS_WITH,
        body: (rowData) => (
          <a
            target="_blank"
            rel="noreferrer"
            href={rowData.DocumentoEnlace}
            className="no-underline text-blue-500"
          >
            {rowData.Secuencia}
          </a>
        ),
      },
      {
        filter: true,
        sortable: true,
        dataType: "select",
        field: "TipoDocumentoId",
        header: "Tipo de documento",
        filterOperator: FilterOperator.AND,
        filterOptions: documentTypeOptions,
        filterMatchMode: FilterMatchMode.EQUALS,
        body: (rowData) => rowData.TipoDocumentoNombre,
      },
      {
        filter: true,
        sortable: true,
        field: "Fecha",
        header: "Fecha",
        dataType: "date",
        filterOperator: FilterOperator.AND,
        filterMatchMode: FilterMatchMode.DATE_IS,
        body: (rowData) => Datejs(rowData.Fecha).format("DD/MM/YYYY"),
      },
      {
        filter: true,
        sortable: true,
        field: "Notas",
        header: "Notas",
        dataType: "text",
        filterMatchMode: FilterMatchMode.STARTS_WITH,
      },
      {
        filter: true,
        sortable: true,
        header: "Estado",
        field: "EstadoId",
        dataType: "select",
        filterOptions: statusesOptions,
        filterOperator: FilterOperator.AND,
        filterMatchMode: FilterMatchMode.EQUALS,
        body: (rowData) => <StatusTag status={rowData.EstadoNombre} />,
      },
    ],
    [documentTypeOptions, statusesOptions],
  );

  const renderEmptyMessage = React.useCallback(() => {
    return <EmptyMessage message="No hay documentos de referencia" />;
  }, []);

  return (
    <CardTable
      type="data"
      hideSelectFilter
      hideBorderStyles
      columns={columns}
      tableActions={[]}
      scrollable={true}
      paginatorLeft={true}
      removableSort={true}
      dataKey="DocumentoId"
      hideFilterSettingsIcon
      value={referenceDocuments ?? []}
      // title="Documentos de referencia"
      emptyMessage={renderEmptyMessage}
      loading={referenceDocumentsPending}
      rowsPerPageOptions={[4, 8, 16, 32, 64]}
      paginator={(referenceDocuments?.length ?? 0) > 4}
    />
  );
};

export default ReferenceDocumentsTable;
