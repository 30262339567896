import React from "react";
import { Icon } from "@nubeteck/icons";
import { renderTooltip } from "@utils";
import { useParams } from "react-router";
import { GeneralAccountingFeature } from "@features";
import {
  FileUpload,
  FileUploadProps,
  FileUploadHandlerEvent,
} from "primereact/fileupload";

type AttachmentButtonProps = Omit<
  FileUploadProps,
  "mode" | "accept" | "multiple" | "customUpload" | "chooseOptions"
> & {
  currentFiles?: number[];
  setFiles?: (files: number[]) => void;
};

const AttachmentButton = ({
  setFiles,
  currentFiles,
  ...rest
}: AttachmentButtonProps) => {
  const { id } = useParams();

  const { data: updateFormValues } =
    GeneralAccountingFeature.useAccountingQuery(
      "AccountingMovements",
      "getUpdate",
      Number(id ?? 0),
      {
        enabled: !!id,
      },
    );

  const fileUploadRef = React.useRef<FileUpload>(null);

  const { mutate: createFile } = GeneralAccountingFeature.useAccountingMutation(
    "AccountingMovements",
    "createFile",
    undefined,
    false,
  );
  const { mutate: updateAccountingMovement } =
    GeneralAccountingFeature.useAccountingMutation(
      "AccountingMovements",
      "update",
    );

  const customUploader = React.useCallback(
    (event: FileUploadHandlerEvent) => {
      const file = event.files[0];
      const formData = new FormData();
      formData.append("formFile", file);

      createFile(formData, {
        onSuccess: (data) => {
          if (fileUploadRef.current) {
            fileUploadRef.current.clear();
          }

          const newFileId = Number(data.FileId);

          if (updateFormValues && id) {
            updateAccountingMovement({
              ...updateFormValues,
              MovimientosContablesAdjuntos: [
                ...(updateFormValues.MovimientosContablesAdjuntos ?? []),
                newFileId,
              ],
            });
          } else {
            setFiles?.([...(currentFiles ?? []), newFileId]);
          }
        },
      });
    },
    [
      createFile,
      updateFormValues,
      id,
      updateAccountingMovement,
      setFiles,
      currentFiles,
    ],
  );

  return (
    <div className="attachment_btn align-self-center">
      {renderTooltip("Adjuntar archivos", "attachment_btn", "left")}
      <FileUpload
        {...rest}
        auto
        accept="*"
        mode="basic"
        customUpload
        multiple={false}
        ref={fileUploadRef}
        uploadHandler={customUploader}
        chooseOptions={{
          icon: <Icon size={24} name="paperclip" />,
          className:
            "p-0 w-fit p-button p-button-sm p-button-icon-only p-button-text mx-1",
        }}
      />
    </div>
  );
};

export default AttachmentButton;
