import React from "react";
import { Button } from "primereact/button";
import { useGlobalMutation } from "@hooks";
import { useNavigate } from "react-router";
import { Form, InputText } from "@components";
import { Config, AppRoutes, AppRouteNames } from "@core";
import { useForm, SubmitHandler } from "react-hook-form";

type FormValues = { email: string };

const ForgotPasswordPage = () => {
  const navigate = useNavigate();
  const mutation = useGlobalMutation("Auth", "forgotPassword");

  const form = useForm<FormValues>({
    mode: "all",
    defaultValues: { email: "" },
  });

  const onSubmit: SubmitHandler<FormValues> = async (values) => {
    return mutation.mutateAsync(values);
  };

  return (
    <div className="surface-ground flex h-screen w-screen justify-content-center align-items-center">
      <div className="surface-card p-2 m-2 lg:p-4 shadow-2 border-round w-full md:w-10 lg:w-5">
        <div className="text-center mb-4">
          <img
            height={70}
            className="mb-2"
            src={Config.app_logo}
            alt={Config.app_description}
          />
          <div className="text-800 text-3xl font-semibold">
            Olvidar contraseña
          </div>
          <span className="text-600 text-md">
            Ingresa tu correo para solicitar un restablecimiento de tu
            contraseña
          </span>
        </div>
        <Form form={form} onSubmit={form.handleSubmit(onSubmit)}>
          <div className="flex flex-column">
            <InputText
              type="text"
              name="email"
              fieldClassName="flex-1"
              className="p-inputtext-sm"
              label="Correo electrónico"
              placeholder="Correo electrónico"
              rules={{ required: "Campo requerido" }}
            />
            <div className="flex gap-2">
              <Button
                size="small"
                type="submit"
                className="w-full"
                label="Enviar solicitud"
              />
              <Button
                size="small"
                type="button"
                label="Cancelar"
                className="w-full"
                severity="secondary"
                onClick={() => {
                  navigate(AppRoutes[AppRouteNames.SIGN_IN_PAGE].create({}));
                }}
              />
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default ForgotPasswordPage;
