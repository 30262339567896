import React from "react";
import { Icon } from "@nubeteck/icons";
import { OverlayPanel } from "primereact/overlaypanel";
import { Button, ButtonProps } from "primereact/button";

/**
 * Represents an option in the overlay button menu.
 */
interface MenuOption {
  /** The label text for the option. */
  label: string;
  /** The icon name for the option. */
  iconName: string;
  /** Optional click handler for the option. */
  onClick?: () => void;
}

/**
 * Props for the OverlayButtonMenu component.
 */
export interface IOverlayButtonMenuProps extends Omit<ButtonProps, "onClick"> {
  /** An array of MenuOption objects to be displayed in the overlay. */
  options: MenuOption[];
}

/**
 * A component that displays a button which, when clicked, shows an overlay panel with a list of options.
 *
 * @param {OverlayButtonMenuProps} props - The props for the OverlayButtonMenu component.
 * @returns {React.ReactElement} The rendered OverlayButtonMenu component.
 */
const OverlayButtonMenu = ({
  options,
  ...props
}: IOverlayButtonMenuProps): React.ReactElement => {
  const op = React.useRef<OverlayPanel>(null);

  return (
    <React.Fragment>
      <Button {...props} onClick={(e) => op.current?.toggle(e)} />
      <OverlayPanel ref={op} className="p-0">
        <div className="flex flex-column gap-2">
          {options.map((option, index) => (
            <Button
              outlined
              key={index}
              size="small"
              severity="contrast"
              className="hover:bg-primary"
              icon={<Icon size={20} className="mr-2" name={option.iconName} />}
              onClick={() => {
                option.onClick?.();
                op.current?.hide();
              }}
            >
              {option.label}
            </Button>
          ))}
        </div>
      </OverlayPanel>
    </React.Fragment>
  );
};

export default OverlayButtonMenu;
