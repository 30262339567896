import clsx from "clsx";
import React from "react";
import { toast } from "sonner";
import { Icon } from "@nubeteck/icons";
import { renderTooltip } from "@utils";
import { useExchangeRate } from "@hooks";
import { Button } from "primereact/button";
import { Message } from "primereact/message";
import { useResizeObserver } from "usehooks-ts";
import { useHeaderActions } from "@nubeteck/prime";
import SplitterLayout from "react-splitter-layout";
import { ButtonGroup } from "primereact/buttongroup";
import { SplitButton } from "primereact/splitbutton";
import { useParams, useNavigate } from "react-router";
import { Reducers, Statuses, LogEntities } from "@core";
import { ArrayUtils, EventUtils } from "@nubeteck/utils";
import { ConfirmPopup, confirmPopup } from "primereact/confirmpopup";
import {
  Form,
  useForm,
  Calendar,
  Dropdown,
  useWatch,
  InputText,
  InputNumber,
} from "@nubeteck/forms";
import {
  FormRow,
  ResetButton,
  CancelButton,
  ChangeLogDialog,
  AttachmentButton,
  ToastDiscrepanciesTemplate,
} from "@components";

import { IAccountingMovementForm } from "../../../interfaces";
import { AccountingMovementModel } from "../../../../general-accounting/models";
import {
  useAccountingQuery,
  useAccountingMutation,
  useAccountingMovementLogic,
} from "../../../hooks";

import { AccountingMovementsFormTabs } from "./tabs";
export interface FileHandler {
  (filteredFiles: number[]): void;
}

const AccountingMovementsForm = () => {
  // **Import Hooks and Utilities**
  const navigate = useNavigate();
  const { dispatch } = useHeaderActions();
  const { id } = useParams();

  // **State Variables**
  const [disableTooltip, setDisableTooltip] = React.useState(false);

  const [changeLogState, changeLogDispatch] = React.useReducer(
    Reducers.DialogReducer,
    {
      id: 0,
      open: false,
    },
  );

  // **Refs**
  const firstRowRef = React.useRef<HTMLDivElement>(null);
  const containerRef = React.useRef<HTMLDivElement>(null);

  // **Resize Observers**
  const { height: firstRowHeight = 0 } = useResizeObserver({
    ref: firstRowRef,
  });
  const { height: containerHeight = 0 } = useResizeObserver({
    ref: containerRef,
  });

  // **Queries**
  const createFormValues = useAccountingQuery(
    "AccountingMovements",
    "getCreate",
    undefined,
    { enabled: !id },
  );
  const updateFormValues = useAccountingQuery(
    "AccountingMovements",
    "getUpdate",
    Number(id ?? 0),
    {
      enabled: !!id,
    },
  );
  const { data: exchangeRates } = useAccountingQuery("ExchangeRates", "getAll");

  // **Mutations**
  const { isPending: createPending, mutateAsync: createAccountingMovement } =
    useAccountingMutation("AccountingMovements", "create");
  const { isPending: updatePending, mutateAsync: updateAccountingMovement } =
    useAccountingMutation("AccountingMovements", "update");

  // **Form Initialization**
  const initialValues = React.useMemo(() => {
    if (id || !createFormValues.data) return;
    return AccountingMovementModel.from?.(createFormValues.data) ?? undefined;
  }, [id, createFormValues.data]);
  const form = useForm<Omit<IAccountingMovementForm, "SelectOptions">>({
    values: initialValues,
  });

  // **Derived State and Watchers**
  const details = useWatch({
    control: form.control,
    name: "MovimientosContablesDetalles",
  });
  const monedaId = useWatch({ name: "MonedaId", control: form.control });
  const sucursalId = useWatch({ name: "SucursalId", control: form.control });
  const files = useWatch({
    control: form.control,
    name: "MovimientosContablesAdjuntos",
  });
  const options = createFormValues.data?.SelectOptions;
  const selectedCurrency = React.useMemo(() => {
    return options?.MonedasSelect.find(
      (currency) => currency.MonedaId === monedaId,
    );
  }, [monedaId, options?.MonedasSelect]);

  // **Drowpdown options**
  const accountCatalogsOptions = React.useMemo(() => {
    if (!options?.CatalogosCuentasSelect?.length) return [];
    const filteredAccountCatalogs = options.CatalogosCuentasSelect.filter(
      (item) => item.SucursalId === sucursalId,
    );
    return ArrayUtils.selectLabelValue(
      filteredAccountCatalogs,
      "Nombre",
      "CatalogoId",
    );
  }, [options?.CatalogosCuentasSelect, sucursalId]);

  const branchesOptions = React.useMemo(() => {
    if (!options?.SucursalesSelect?.length) return [];
    return ArrayUtils.selectLabelValue(
      options.SucursalesSelect,
      "SucursalNombre",
      "SucursalId",
    );
  }, [options?.SucursalesSelect]);

  const currenciesOptions = React.useMemo(() => {
    if (!options?.MonedasSelect?.length) return [];
    return ArrayUtils.selectLabelValue(
      options.MonedasSelect,
      "MonedaNombre",
      "MonedaId",
    );
  }, [options?.MonedasSelect]);

  // **Custom Hooks**
  useExchangeRate(monedaId, exchangeRates, form as never);

  const { accountingSummary, accountingDiscrepancies } =
    useAccountingMovementLogic(details, selectedCurrency?.NombreISO as never);

  // **Functions**
  const resetAccountIds = () => {
    const currentDetails = form.getValues("MovimientosContablesDetalles");
    currentDetails.forEach((_, index) => {
      form.setValue(
        `MovimientosContablesDetalles.${index}.CuentaContableId`,
        0,
        {
          shouldValidate: true,
        },
      );
    });
  };

  const resetFormWithUpdateValues = React.useCallback(() => {
    if (!updateFormValues.data) return;
    const updateValues = AccountingMovementModel.from?.(updateFormValues.data);
    if (!updateValues) return;
    form.reset(updateValues, { keepDefaultValues: true });
  }, [updateFormValues.data, form]);

  const onSubmit = React.useCallback(
    (values: IAccountingMovementForm, estadoId?: number) => {
      const modeledValues = AccountingMovementModel.to?.({
        ...values,
        EstadoId: estadoId ? estadoId : values.EstadoId,
      });
      if (!modeledValues) return;
      if (
        accountingDiscrepancies.length > 0 &&
        modeledValues.EstadoId !== Statuses.DRAFT_CODE
      ) {
        toast.error(
          ToastDiscrepanciesTemplate(
            "El asiento no puede ser guardado:",
            accountingDiscrepancies,
          ),
          {
            duration: 5000,
            dismissible: true,
            closeButton: true,
            position: "top-right",
          },
        );
        return;
      }

      if (id) {
        return updateAccountingMovement(modeledValues).then(() => {
          if (modeledValues?.EstadoId === Statuses.ACTIVE_CODE) navigate(-1);
        });
      }

      return createAccountingMovement(modeledValues, {
        onSuccess: () => {
          form.reset(initialValues);
          navigate(-1);
        },
      });
    },
    [
      accountingDiscrepancies,
      createAccountingMovement,
      form,
      id,
      initialValues,
      navigate,
      updateAccountingMovement,
    ],
  );

  // **Effects**
  React.useEffect(() => {
    resetFormWithUpdateValues();
  }, [resetFormWithUpdateValues]);

  React.useEffect(() => {
    dispatch({
      type: "SET_VARIABLES",
      payload: { sec: updateFormValues.data?.Secuencia ?? "" },
    });
    return () => dispatch({ type: "CLEAR_VARIABLES" });
  }, [dispatch, updateFormValues.data?.Secuencia]);

  React.useEffect(() => {
    const validateAndSubmit = async (submitFunction: () => void) => {
      const isValid = await form.trigger();
      if (!isValid) {
        toast.error("Algunos campos necesitan ser revisados", {
          duration: 1500,
          position: "top-center",
          style: { fontSize: 16 },
        });
        return;
      }
      submitFunction();
    };

    const handleSave = () =>
      validateAndSubmit(() =>
        form.handleSubmit((values) => onSubmit(values, Statuses.ACTIVE_CODE))(),
      );

    const handleSaveAsDraft = () =>
      validateAndSubmit(() =>
        form.handleSubmit((values) => onSubmit(values, Statuses.DRAFT_CODE))(),
      );

    const handleUpdate = () =>
      validateAndSubmit(() =>
        form.handleSubmit((values) => onSubmit(values))(),
      );

    const handleActivate = () =>
      validateAndSubmit(() =>
        form.handleSubmit((values) =>
          onSubmit({ ...values, EstadoId: Statuses.ACTIVE_CODE }),
        )(),
      );

    const handleCancel = () => {
      if (id) {
        resetFormWithUpdateValues();
      } else {
        form.reset(initialValues);
      }
      navigate(-1);
    };

    dispatch({
      type: "SET_ACTIONS",
      payload: [
        <React.Fragment key="CHANGE_LOG_BUTTON">
          {renderTooltip("Historial de cambios", "changelog")}
          {id && (
            <Icon
              name="book"
              className="changelog align-self-center text-primary"
              onClick={EventUtils.callEvent(changeLogDispatch, {
                type: "OPEN_DIALOG",
                payload: Number(id ?? 0),
              })}
            />
          )}
        </React.Fragment>,
        <AttachmentButton
          currentFiles={files}
          key="ATTACHMENT_BUTTON"
          setFiles={(files) =>
            form.setValue("MovimientosContablesAdjuntos", files)
          }
        />,
        <ButtonGroup key="FORM_BUTTONS">
          <CancelButton onClick={handleCancel} />
          {!id && (
            <ResetButton
              accept={() => form.reset(initialValues)}
              className={clsx(!id && "border-noround-right")}
            />
          )}
          {!id ? (
            <SplitButton
              size="small"
              label="Guardar"
              key="SAVE_BUTTON"
              onClick={handleSave}
              className="custom-split"
              model={[
                {
                  command: handleSaveAsDraft,
                  label: "Guardar como borrador",
                  icon: <Icon size={20} name="notes" className="mr-2" />,
                },
              ]}
            />
          ) : (
            <React.Fragment>
              <Button
                outlined
                size="small"
                label="Actualizar"
                key="UPDATE_BUTTON"
                onClick={handleUpdate}
              />
              <Button
                size="small"
                label="Activar"
                key="ACTIVATE_BUTTON"
                onClick={(event) => {
                  confirmPopup({
                    accept: handleActivate,
                    defaultFocus: "accept",
                    target: event.currentTarget,
                    icon: <Icon name="exclamation-circle" />,
                    message: "¿Confirmar? Ya no podrás hacer más cambios.",
                  });
                }}
              />
            </React.Fragment>
          )}
        </ButtonGroup>,
      ],
    });

    return () => dispatch({ type: "CLEAR_ACTIONS" });
  }, [
    dispatch,
    files,
    form,
    id,
    initialValues,
    navigate,
    onSubmit,
    resetFormWithUpdateValues,
  ]);

  const isAccountingMovementPending = createPending || updatePending;

  return (
    <div ref={containerRef} className="h-full flex">
      <Form form={form} className="flex-1 overflow-hidden">
        {renderTooltip(
          "Desliza para ver más",
          "layout-splitter",
          "mouse",
          disableTooltip,
        )}
        {Boolean(containerHeight) && Boolean(firstRowHeight) && (
          <SplitterLayout
            vertical
            customClassName="relative hide-scrollbar"
            onDragEnd={() => setDisableTooltip(false)}
            onDragStart={() => setDisableTooltip(true)}
            secondaryInitialSize={containerHeight - firstRowHeight}
          >
            <div className="flex flex-column">
              <FormRow>
                <Calendar
                  name="Fecha"
                  label="Fecha"
                  mask="99/99/9999"
                  showButtonBar={true}
                  dateFormat="dd/mm/yy"
                  className="p-inputtext-sm"
                  disabledInput={isAccountingMovementPending}
                />
                <InputText
                  name="Referencia"
                  label="Referencia"
                  className="p-inputtext-sm"
                  fieldClassName="md:w-16rem"
                  placeholder="Número o código de referencia"
                  rules={{ required: "Este campo es obligatorio" }}
                />
                <Dropdown
                  label="Sucursal"
                  name="SucursalId"
                  fieldClassName="flex-1"
                  placeholder="Seleccione"
                  options={branchesOptions}
                  className="p-inputtext-sm"
                  filter={branchesOptions.length > 5}
                  rules={{ required: "Este campo es obligatorio" }}
                  loading={
                    createFormValues.isFetching || isAccountingMovementPending
                  }
                />
                <Dropdown
                  name="CatalogoId"
                  fieldClassName="flex-1"
                  placeholder="Seleccione"
                  className="p-inputtext-sm"
                  label="Catálogo de cuentas"
                  options={accountCatalogsOptions}
                  filter={accountCatalogsOptions.length > 5}
                  rules={{ required: "Este campo es obligatorio" }}
                  loading={
                    createFormValues.isFetching || isAccountingMovementPending
                  }
                  onChange={(e) => {
                    form.setValue("CatalogoId", e.value);
                    resetAccountIds();
                  }}
                />
              </FormRow>
              <div>
                <FormRow>
                  <Dropdown
                    label="Moneda"
                    name="MonedaId"
                    fieldClassName="flex-1"
                    placeholder="Seleccione"
                    className="p-inputtext-sm"
                    options={currenciesOptions}
                    filter={currenciesOptions.length > 5}
                    rules={{ required: "Este campo es obligatorio" }}
                    loading={
                      createFormValues.isFetching || isAccountingMovementPending
                    }
                  />
                  <InputNumber
                    name="Tasa"
                    label="Tasa"
                    mode="currency"
                    disabledInput={true}
                    minFractionDigits={2}
                    fieldClassName="flex-1"
                    className="p-inputtext-sm"
                    locale={selectedCurrency?.Locale ?? "es-DO"}
                    currency={selectedCurrency?.NombreISO ?? "DOP"}
                    rules={{ required: "Este campo es obligatorio" }}
                  />
                  <Calendar
                    mask="99/99/9999"
                    showButtonBar={true}
                    disabledInput={true}
                    dateFormat="dd/mm/yy"
                    name="TasaCambioFecha"
                    fieldClassName="flex-1"
                    label="Fecha de la tasa"
                    className="p-inputtext-sm"
                    rules={{
                      required: "Debe seleccionar una fecha",
                    }}
                  />
                </FormRow>
                {!!id && (
                  <FormRow>
                    <InputText
                      readOnly
                      label="Creado por"
                      name="CreadoPorNombre"
                      fieldClassName="flex-1"
                      className="p-inputtext-sm"
                    />
                    <InputText
                      readOnly
                      name="FechaCreacion"
                      fieldClassName="flex-1"
                      label="Fecha de creación"
                      className="p-inputtext-sm"
                    />
                    <Message
                      severity="success"
                      text="Datos históricos (no modificables)"
                      className="flex-1 h-full mt-4 justify-content-start bg-primary-100 text-primary-600"
                      icon={
                        <Icon
                          filled
                          className="mr-2"
                          touchable={false}
                          name="info-circle"
                        />
                      }
                    />
                  </FormRow>
                )}
              </div>
            </div>
            <AccountingMovementsFormTabs
              summary={accountingSummary}
              isFormLoading={isAccountingMovementPending}
              fileProps={{
                files: files ?? [],
                setFiles: (files) =>
                  form.setValue("MovimientosContablesAdjuntos", files),
              }}
            />
          </SplitterLayout>
        )}
      </Form>
      <ChangeLogDialog
        entityId={changeLogState.id}
        visible={changeLogState.open}
        entityName={LogEntities.ACCOUNTING_MOVEMENT}
        onHide={EventUtils.callEvent(changeLogDispatch, {
          type: "CLOSE_DIALOG",
        })}
      />
      <ConfirmPopup />
      <div
        ref={firstRowRef}
        style={{ zIndex: -1 }}
        className="absolute opacity-0"
      >
        <div className="h-5rem mb-1" />
      </div>
    </div>
  );
};

export default AccountingMovementsForm;
