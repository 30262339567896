import API from "@lib/api";
import { BaseApi } from "@api";
import { Messages, ICostsCenter } from "@interfaces";

import {
  ICostsCenterGetForCreate,
  ICostsCenterGetForUpdate,
} from "../interfaces";

export class CostsCenterApi extends BaseApi {
  protected static filterApi = API({ namespace: "Filtro" });
  protected static api = API({ namespace: "CentrosCostos" });
  protected static messages: Messages<typeof CostsCenterApi> = {
    create: {
      error: (error) => error,
      loading: "Creando centro de costo...",
      success: "Centro de costo creado correctamente.",
    },
    update: {
      error: (error) => error,
      loading: "Actualizando centro de costo...",
      success: "Centro de costo actualizado correctamente.",
    },
    changeState: {
      error: (error) => error,
      loading: "Actualizando estado del centro de costo...",
      success: "Estado del centro de costo actualizado correctamente.",
    },
    assignUser: {
      error: (error) => error,
      loading: "Actualizando centros de costos del usuario...",
      success: "Centros de costos del usuario actualizados correctamente.",
    },
  };

  public static async getAll() {
    const result = await this.api.get<ICostsCenter[]>("GetAll");
    return result.data;
  }

  public static async getAllByUser(userId?: number) {
    const result = await this.api.get<ICostsCenter[]>(
      "GetAllByUser".concat(userId ? `?userId=${userId}` : ""),
    );
    return result.data;
  }

  public static async getById(id: number) {
    const result = await this.api.get<ICostsCenter>(`GetById/${id}`);
    return result.data;
  }

  public static async create(data: ICostsCenter) {
    const result = await this.api.post("Create", data);
    return result.data;
  }

  public static async getCreate() {
    const result = await this.api.get<ICostsCenterGetForCreate>("GetForCreate");
    return result.data;
  }

  public static async update(data: Partial<ICostsCenter>) {
    const result = await this.api.put(`Update/${data.CentroCostoId}`, data);
    return result.data;
  }

  public static async getUpdate(id: number) {
    const strategic = await this.api.get<ICostsCenterGetForUpdate>(
      `GetForUpdate/${id}`,
    );
    return strategic.data;
  }

  public static async changeState(data: {
    CentroCostoId: number;
    EstadoId: number;
  }) {
    const result = await this.api.post("ChangeState", data);
    return result.data;
  }

  public static async assignUser(data: {
    UserId: number;
    CentrosCostos: number[];
  }) {
    const result = await this.api.post("AssignUser", data);
    return result.data;
  }
}
