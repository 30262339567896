import React from "react";
import { sort, group } from "radash";
import { TreeNode } from "@nubeteck/prime";
import { TreeDetail, IStrategicPlansDetails } from "@interfaces";

type Tree = TreeNode<TreeDetail>;

const useTreeStrategicDetails = (
  details: IStrategicPlansDetails[],
  selectable?: number,
) => {
  const plansByParent = group(details, (plan) => plan.PadreId ?? 0);

  const buildTree = React.useCallback(
    (
      plansByParentId: Partial<Record<number, IStrategicPlansDetails[]>>,
      parentId: number = 0,
    ): Tree[] =>
      (plansByParentId[parentId] || []).map((plan) => {
        return {
          key: plan.DetalleId,
          data: { ...plan, Codigo: "" },
          children: buildTree(plansByParentId, plan.DetalleId),
          ...(selectable ? { selectable: plan.Tipo === 8 } : {}),
        };
      }),
    [selectable],
  );

  const sortTree = React.useCallback((tree: Tree[]): Tree[] => {
    return sort(
      tree.map((item) => ({ ...item, children: sortTree(item.children) })),
      (item) => item.data.Orden ?? 0,
    );
  }, []);

  const formatter = React.useCallback(
    (trees: Tree[], prefix: string = ""): Tree[] => {
      return trees.map((tree, index) => {
        const code = prefix ? `${prefix}.${index + 1}` : `${index + 1}`;
        const children = formatter(tree.children, code);
        return {
          ...tree,
          children,
          id: code,
          data: { ...tree.data, Codigo: code },
        };
      });
    },
    [],
  );

  const nodes = React.useMemo(() => {
    return formatter(sortTree(buildTree(plansByParent)));
  }, [buildTree, formatter, plansByParent, sortTree]);

  const findChildrenNode = React.useCallback(
    (tree: Tree[], key: number): null | Tree[] => {
      if (!key) return tree;
      return (
        tree
          .map((root) =>
            root.key === key
              ? root.children
              : findChildrenNode(root.children, key),
          )
          .find((result) => result !== null) || null
      );
    },
    [],
  );

  const findNode = React.useCallback(
    (tree: Tree[], key: number): null | Tree => {
      if (!key) return tree[0];
      return (
        tree
          .map((root) =>
            root.key === key ? root : findNode(root.children, key),
          )
          .find((result) => result !== null) || null
      );
    },
    [],
  );

  const searchNode = React.useCallback(
    (searchText: string, trees: Tree[] = nodes): Tree | null => {
      if (!searchText) return null;
      for (const node of trees) {
        const hasMatch = Object.values(node.data).some((value) => {
          if (typeof value === "string") {
            return value.toLowerCase().includes(searchText.toLowerCase());
          }
          return false;
        });
        if (hasMatch) return node;
        const childMatch = searchNode(searchText, node.children);
        if (childMatch) return childMatch;
      }
      return null;
    },
    [nodes],
  );

  return {
    findNode,
    searchNode,
    value: nodes,
    findChildrenNode,
  };
};

export default useTreeStrategicDetails;
