import React from "react";
import { useNavigate } from "react-router";

import { AccountsPayableRoutes, AccountsPayableRouteNames } from "../routes";

import useAccountsPayableMutation from "./useAccountsPayableMutation";

const useCreatePurchaseDrafts = () => {
  const navigate = useNavigate();

  const {
    mutate: createPurchaseInvoice,
    isPending: createPurchaseInvoicePending,
  } = useAccountsPayableMutation("PurchaseInvoices", "createPurchaseInvoice");
  const { mutate: createPurchaseOrder, isPending: createPurchaseOrderPending } =
    useAccountsPayableMutation("PurchaseOrders", "createPurchaseOrder");
  const {
    mutate: createPurchaseDeliveryNote,
    isPending: createPurchaseDeliveryNotePending,
  } = useAccountsPayableMutation(
    "PurchaseDeliveryNotes",
    "createPurchaseDeliveryNote",
  );
  const {
    mutate: createRecurringPurchaseInvoice,
    isPending: createRecurringPurchaseInvoicePending,
  } = useAccountsPayableMutation(
    "RecurringPurchaseInvoices",
    "createRecurringPurchaseInvoice",
  );

  const createPurchaseInvoiceDraft = React.useCallback(() => {
    createPurchaseInvoice(
      {},
      {
        onSuccess: (data) =>
          navigate(
            `.${AccountsPayableRoutes[AccountsPayableRouteNames.PURCHASE_INVOICE].create({ id: `${data.FacturaId}` })}`,
          ),
      },
    );
  }, [createPurchaseInvoice, navigate]);

  const createPurchaseOrderDraft = React.useCallback(() => {
    createPurchaseOrder(
      {},
      {
        onSuccess: (data) =>
          navigate(
            `.${AccountsPayableRoutes[AccountsPayableRouteNames.PURCHASE_ORDER].create({ id: `${data.OrdenId}` })}`,
          ),
      },
    );
  }, [createPurchaseOrder, navigate]);

  const createDeliveryNoteDraft = React.useCallback(() => {
    createPurchaseDeliveryNote(
      {},
      {
        onSuccess: (data) =>
          navigate(
            `.${AccountsPayableRoutes[AccountsPayableRouteNames.PURCHASE_DELIVERY_NOTE].create({ id: `${data.ConduceId}` })}`,
          ),
      },
    );
  }, [createPurchaseDeliveryNote, navigate]);

  const createRecurringPurchaseInvoiceDraft = React.useCallback(() => {
    createRecurringPurchaseInvoice(
      {},
      {
        onSuccess: (data) =>
          navigate(
            `.${AccountsPayableRoutes[AccountsPayableRouteNames.PURCHASE_INVOICE].create({ id: `${data.FacturaId}` })}`,
          ),
      },
    );
  }, [createRecurringPurchaseInvoice, navigate]);

  const purchaseDraftPending =
    createPurchaseInvoicePending ||
    createPurchaseOrderPending ||
    createPurchaseDeliveryNotePending ||
    createRecurringPurchaseInvoicePending;

  return {
    purchaseDraftPending,
    createDeliveryNoteDraft,
    createPurchaseOrderDraft,
    createPurchaseInvoiceDraft,
    createRecurringPurchaseInvoiceDraft,
  };
};

export default useCreatePurchaseDrafts;
