import React from "react";
import { Icon } from "@nubeteck/icons";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Divider } from "primereact/divider";
import { ButtonGroup } from "primereact/buttongroup";
import { useParams, useNavigate } from "react-router";
import { Dropdown as PrimeDropdown } from "primereact/dropdown";
import { FileUpload, FileUploadHandlerEvent } from "primereact/fileupload";
import {
  Form,
  useForm,
  Dropdown,
  InputText,
  InputTextarea,
} from "@nubeteck/forms";
import {
  CardTable,
  Description,
  useHeaderActions,
  IDataTableColumnsProps,
} from "@nubeteck/prime";

interface FileDataType {
  EstadoId: number;
  ArchivoId: number;
  Archivo: IArchivo;
  ProveedorId: number;
  TipoArchivoId: number;
}

interface IArchivo {
  FileId: number;
  FileName: string;
  FileSize: number;
  ContentType: string;
}

import { Tag } from "primereact/tag";
import { renderTooltip } from "@utils";
import { PickNullable } from "@interfaces";
import { EventUtils } from "@nubeteck/utils";
import { Reducers, LogEntities } from "@core";
import { TabView, TabPanel } from "primereact/tabview";
import { Dialog, ConfirmDialog, ChangeLogDialog } from "@components";

import { UserCard } from "../../components";
import { IProveedorDirecciones } from "../../interfaces";
import { AccountsPayableRoutes, AccountsPayableRouteNames } from "../../routes";
import {
  useAccountsPayableQuery,
  useAccountsPayableMutation,
} from "../../hooks";

type FormValues = PickNullable<
  IProveedorDirecciones,
  | "PaisId"
  | "Latitud"
  | "SectorId"
  | "Longitud"
  | "EstadoId"
  | "Direccion"
  | "DireccionId"
  | "ProveedorId"
  | "MunicipioId"
  | "ProvinciaId"
  | "Observaciones"
  | "TiempoEnSector"
  | "TipoDireccionId"
  | "TiempoEnDireccion"
  | "TiempoEnDireccionId"
>;

const model = {
  from: (data: IProveedorDirecciones): FormValues => {
    return {
      PaisId: data.PaisId,
      Latitud: data.Latitud,
      EstadoId: data.EstadoId,
      SectorId: data.SectorId,
      Longitud: data.Longitud,
      Direccion: data.Direccion,
      DireccionId: data.DireccionId,
      ProveedorId: data.ProveedorId,
      MunicipioId: data.MunicipioId,
      ProvinciaId: data.ProvinciaId,
      Observaciones: data.Observaciones,
      TiempoEnSector: data.TiempoEnSector,
      TipoDireccionId: data.TipoDireccionId,
      TiempoEnDireccion: data.TiempoEnDireccion,
    };
  },
  to: (data: FormValues): IProveedorDirecciones => {
    return {
      EstadoId: 1,
      PaisId: data.PaisId ?? 0,
      Latitud: data.Latitud ?? "",
      SectorId: data.SectorId ?? 0,
      Longitud: data.Longitud ?? "",
      Direccion: data.Direccion ?? "",
      DireccionId: data.DireccionId ?? 0,
      ProveedorId: data.ProveedorId ?? 0,
      MunicipioId: data.MunicipioId ?? 0,
      ProvinciaId: data.ProvinciaId ?? 0,
      Observaciones: data.Observaciones ?? "",
      TiempoEnSector: data.TiempoEnSector ?? "",
      TipoDireccionId: data.TipoDireccionId ?? 0,
      TiempoEnDireccion: data.TiempoEnDireccion ?? "",
    };
  },
};

const ProviderDetailPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { dispatch } = useHeaderActions();
  const [editDireccion, setEditDireccion] = React.useState(0);
  const [visible, setVisible] = React.useState(false);
  const [anular, setAnular] = React.useState(false);
  const [anularDireccion, setAnularDireccion] = React.useState(false);
  const [anularProveedor, setAnularProveedor] = React.useState(false);
  const [showDeletePicture, setShowDeletePicture] = React.useState(false);
  const [file, setFile] = React.useState<number>();
  const [status, setStatus] = React.useState<number>();
  const [selectFile, setSelectFile] = React.useState(0);
  const [selectProveedor, setSelectProveedor] = React.useState(0);
  const [selectDireccion, setSelectDireccion] = React.useState(0);
  const [hide, setHide] = React.useState(false);
  const [tipoArchivoId, setTipoArchivoId] = React.useState(0);
  const [formDataFile, setFormDataFile] = React.useState<FormData>();
  const form = useForm<FormValues>();
  const toast = React.useRef<null | Toast>(null);
  const fileUploadRef = React.useRef<FileUpload>(null);
  const [changeLogState, changeLogDispatch] = React.useReducer(
    Reducers.DialogReducer,
    {
      id: 0,
      open: false,
    },
  );
  const changeState = useAccountsPayableMutation("Provider", "changeState");
  const changeStateFile = useAccountsPayableMutation(
    "Provider",
    "changeStateFile",
  );
  const changeStateDireccion = useAccountsPayableMutation(
    "Provider",
    "changeStateDireccion",
  );
  const createDireccion = useAccountsPayableMutation(
    "Provider",
    "createDireccion",
  );
  const updateDireccion = useAccountsPayableMutation(
    "Provider",
    "updateDireccion",
  );
  const createArchivo = useAccountsPayableMutation("Provider", "addFile");
  const deleteProfilePicture = useAccountsPayableMutation(
    "Provider",
    "deleteProfilePicture",
  );
  const downloadFile = useAccountsPayableMutation("Files", "uploadFile");
  const providersForCreate = useAccountsPayableQuery(
    "Provider",
    "getForCreate",
  );
  const getByIdProvider = useAccountsPayableQuery(
    "Provider",
    "getById",
    Number(id),
    {
      enabled: !!id,
    },
  );
  const countries = useAccountsPayableQuery("Country", "getAll");
  const provinces = useAccountsPayableQuery("Province", "getAll");
  const municipalities = useAccountsPayableQuery("Municipality", "getAll");
  const sectors = useAccountsPayableQuery("Sector", "getAll");
  const paisId = form.watch("PaisId");
  const provinciaId = form.watch("ProvinciaId");
  const municipioId = form.watch("MunicipioId");
  const getDownloadFile = useAccountsPayableQuery(
    "Files",
    "getDownloadFile",
    Number(file),
    {
      enabled: !!file,
    },
  );
  const getProvinciasByPaisId = useAccountsPayableQuery(
    "Country",
    "getProvinciasByPaisId",
    Number(paisId),
    {
      enabled: !!paisId,
    },
  );
  const getMunicipiosByProvinciaId = useAccountsPayableQuery(
    "Country",
    "getMunicipiosByProvinciaId",
    Number(provinciaId),
    {
      enabled: !!provinciaId,
    },
  );
  const getSectoresByMunicipioId = useAccountsPayableQuery(
    "Country",
    "getSectoresByMunicipioId",
    Number(municipioId),
    {
      enabled: !!municipioId,
    },
  );

  const getLocalization = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      navigator.geolocation.getCurrentPosition((position) => {
        form.setValue("Latitud", `${position.coords.latitude}`);
        form.setValue("Longitud", `${position.coords.longitude}`);
        form.clearErrors(["Latitud", "Longitud"]);
      });
    },
    [form],
  );

  React.useEffect(() => {
    const actions = (
      <>
        {renderTooltip("Historial de cambios", "changelog")}
        <Icon
          name="book"
          className="changelog align-self-center text-primary mr-1"
          onClick={EventUtils.callEvent(changeLogDispatch, {
            type: "OPEN_DIALOG",
            payload: Number(id ?? 0),
          })}
        ></Icon>
        <ButtonGroup key={1}>
          <Button
            size="small"
            label="Eliminar foto"
            onClick={() => setShowDeletePicture(true)}
            disabled={getByIdProvider.data?.Imagen === ""}
            icon={<Icon className="mr-2" name="photo-off"></Icon>}
          />
          <Button className="mr-0 ml-0 pr-0 pl-0">
            <div
              className="card flex justify-content-center"
              style={{
                height: "22px",
                alignItems: "center",
              }}
            >
              <FileUpload
                auto
                customUpload
                mode="basic"
                ref={fileUploadRef}
                //name="demo[]"
                //accept="image/*"
                //onUpload={onUpload}
                maxFileSize={1000000}
                uploadHandler={upload}
                chooseLabel="Archivos"
                url="/api/Files/UploadFile"
              />
            </div>
          </Button>
          <Button
            size="small"
            label="Agregar nueva dirección"
            icon={<Icon name="plus" className="mr-2"></Icon>}
            onClick={() => {
              setVisible(true);
            }}
          ></Button>
          <Button
            size="small"
            label="Editar"
            icon={<Icon name="pencil" className="mr-2"></Icon>}
            onClick={() => {
              navigate(
                `..${AccountsPayableRoutes[
                  AccountsPayableRouteNames.PROVIDER_FORM_PAGE
                ].create({ id: `${Number(id)}` })}`,
              );
            }}
          />
          <Button
            size="small"
            label={getByIdProvider.data?.EstadoId === 1 ? "Anular" : "Activar"}
            severity={
              getByIdProvider.data?.EstadoId === 1 ? "danger" : undefined
            }
            icon={
              <Icon
                className="mr-2"
                name={getByIdProvider.data?.EstadoId === 1 ? "trash" : "plug"}
              ></Icon>
            }
            onClick={() => {
              setAnularProveedor(true),
                setStatus(getByIdProvider.data?.EstadoId),
                setSelectProveedor(Number(id));
            }}
          />
        </ButtonGroup>
      </>
    );
    dispatch({
      payload: [actions],
      type: "SET_ACTIONS",
    });
    return () => dispatch({ type: "CLEAR_ACTIONS" });
  }, [
    dispatch,
    getByIdProvider.data?.EstadoId,
    getByIdProvider.data?.Imagen,
    id,
    navigate,
  ]);

  React.useEffect(() => {
    if (getByIdProvider.data?.ProveedorNombre) {
      dispatch({
        type: "SET_VARIABLES",
        payload: {
          name: `${getByIdProvider.data?.ProveedorNombre || ""} ${getByIdProvider.data?.ProveedorApellido || ""}`,
        },
      });
    }
    () => dispatch({ type: "CLEAR_VARIABLES" });
  }, [
    dispatch,
    getByIdProvider.data?.ProveedorApellido,
    getByIdProvider.data?.ProveedorNombre,
  ]);

  const upload = async (event: FileUploadHandlerEvent) => {
    const file = event.files[0];
    const formData = new FormData();
    formData.append("formFile", file);

    setHide(true);
    if (formData) {
      setFormDataFile(formData);
    }
  };

  const columns: IDataTableColumnsProps<FileDataType>[] = [
    {
      header: "Código",
      field: "ArchivoId",
    },
    {
      field: "Archivo",
      header: "Nombre del archivo",
      body: (rowData) => {
        return rowData.Archivo.FileName;
      },
    },
    {
      field: "Archivo",
      header: "Peso (MB)",
      body: (rowData) => {
        return rowData.Archivo.FileSize;
      },
    },
    {
      field: "TipoArchivoId",
      header: "Tipo de archivo",
      body: (rowData) =>
        providersForCreate.data?.SelectOptions.TiposArchivosSelect.find(
          (tipoArchivo) => tipoArchivo.TipoArchivoId === rowData.TipoArchivoId,
        )?.NombreArchivo,
    },
    {
      header: "Estado",
      field: "EstadoId",
      body: (rowData) => (
        <Tag severity={rowData.EstadoId === 1 ? "info" : "danger"}>
          {rowData.EstadoId === 1 ? "Activo" : "Anulado"}
        </Tag>
      ),
    },
  ];

  const columns1: IDataTableColumnsProps<IProveedorDirecciones>[] = [
    {
      header: "#",
      field: "DireccionId",
    },
    {
      field: "TipoDireccionId",
      header: "Tipo de dirección",
      body: (rowData) =>
        providersForCreate.data?.SelectOptions.TiposDireccionSelect.find(
          (direccion) =>
            direccion.ContactoTipoDireccionId === rowData.TipoDireccionId,
        )?.ContactoTipoDireccionNombre,
    },
    {
      header: "País",
      field: "PaisId",
      body: (rowData) =>
        countries.data?.find((country) => country.PaisId === rowData.PaisId)
          ?.PaisNombre,
    },
    {
      header: "Provincia",
      field: "ProvinciaId",
      body: (rowData) =>
        provinces.data?.find(
          (province) => province.PaisProvinciaId === rowData.ProvinciaId,
        )?.PaisProvinciaNombre,
    },
    {
      header: "Municipio",
      field: "MunicipioId",
      body: (rowData) =>
        municipalities.data?.find(
          (municipality) =>
            municipality.PaisProvinciaMunicipioId === rowData.MunicipioId,
        )?.PaisProvinciaMunicipioNombre,
    },
    {
      header: "Sector",
      field: "SectorId",
      body: (rowData) =>
        sectors.data?.find(
          (sector) =>
            sector.PaisProvinciaMunicipioSectorId === rowData.SectorId,
        )?.PaisProvinciaMunicipioSectorNombre,
    },
    {
      field: "Direccion",
      header: "Dirección",
    },
    // {
    //   field: "Latitud",
    //   header: "Latitud",
    // },
    // {
    //   field: "Longitud",
    //   header: "Longitud",
    // },
    // {
    //   field: "Observaciones",
    //   header: "Observaciones",
    // },
    {
      header: "Estado",
      field: "EstadoId",
      body: (rowData) => (
        <Tag severity={rowData.EstadoId === 1 ? "info" : "danger"}>
          {rowData.EstadoId === 1 ? "Activo" : "Anulado"}
        </Tag>
      ),
    },
  ];

  React.useEffect(() => {
    if (editDireccion !== 0) {
      const proveedorDirecciones =
        getByIdProvider.data?.ProveedorDirecciones?.find(
          (direccion) => direccion.DireccionId === editDireccion,
        );
      if (proveedorDirecciones) {
        const formData = model.from(proveedorDirecciones);
        form.reset(formData, { keepDefaultValues: true });
      } else {
        toast.current?.show({
          life: 3000,
          severity: "warn",
          summary: "Warning",
          detail: "Este proveedor no contiene direcciones",
        });
      }
    }
    if (editDireccion === 0) {
      form.reset();
      form.setValue("TipoDireccionId", null);
      form.setValue("PaisId", null);
      form.setValue("Direccion", null);
      form.setValue("Longitud", null);
      form.setValue("Latitud", null);
      form.setValue("Observaciones", null);
    }
  }, [editDireccion, form, getByIdProvider.data?.ProveedorDirecciones]);

  //Selects
  const tipoDireccionOptions = React.useMemo(() => {
    const tipoDireccionSelect =
      providersForCreate.data?.SelectOptions?.TiposDireccionSelect;

    if (!tipoDireccionSelect || !tipoDireccionSelect.length) return [];

    return tipoDireccionSelect.map((tipoDireccion) => ({
      value: tipoDireccion.ContactoTipoDireccionId,
      label: tipoDireccion.ContactoTipoDireccionNombre,
    }));
  }, [providersForCreate.data?.SelectOptions?.TiposDireccionSelect]);

  const paisesOptions = React.useMemo(() => {
    return countries.data?.map((pais) => ({
      value: pais.PaisId,
      label: pais.PaisNombre,
    }));
  }, [countries.data]);

  const provinciasOptions = React.useMemo(() => {
    return getProvinciasByPaisId.data?.map((provincia) => ({
      value: provincia.PaisProvinciaId,
      label: provincia.PaisProvinciaNombre,
    }));
  }, [getProvinciasByPaisId.data]);

  const municipiosOptions = React.useMemo(() => {
    return getMunicipiosByProvinciaId.data?.map((provincia) => ({
      value: provincia.PaisProvinciaMunicipioId,
      label: provincia.PaisProvinciaMunicipioNombre,
    }));
  }, [getMunicipiosByProvinciaId.data]);

  const sectoresOptions = React.useMemo(() => {
    return getSectoresByMunicipioId.data?.map((sector) => ({
      value: sector.PaisProvinciaMunicipioSectorId,
      label: sector.PaisProvinciaMunicipioSectorNombre,
    }));
  }, [getSectoresByMunicipioId.data]);

  const archivosTiposOptions = React.useMemo(() => {
    const archivoTiposSelect =
      providersForCreate.data?.SelectOptions?.TiposArchivosSelect;

    if (!archivoTiposSelect || !archivoTiposSelect.length) return [];

    return archivoTiposSelect.map((archivoTipo) => ({
      value: archivoTipo.TipoArchivoId,
      label: archivoTipo.NombreArchivo,
    }));
  }, [providersForCreate.data?.SelectOptions?.TiposArchivosSelect]);

  const filteredFiles = getByIdProvider.data?.ProveedoresArchivosFotos.filter(
    (file) => {
      return !(file.TipoArchivoId === 8 && file.EstadoId !== 1);
    },
  );

  const onSubmit = (values: FormValues) => {
    if (editDireccion !== 0) {
      return updateDireccion.mutateAsync(
        model.to({ ...values, ProveedorId: Number(id) }),
        {
          onSuccess: () => {
            form.reset();
            setVisible(false);
            setEditDireccion(0);
          },
        },
      );
    } else {
      return createDireccion.mutateAsync(
        model.to({ ...values, ProveedorId: Number(id) }),
        {
          onSuccess: () => {
            form.reset();
            setVisible(false);
            setEditDireccion(0);
          },
        },
      );
    }
  };

  const renderConfirmDialog = (isFile: boolean) => (
    <ConfirmDialog
      visible={isFile ? anular : anularDireccion}
      message={`¿Está seguro de que desea ${status === 1 ? "anular" : "activar"} ${isFile ? "este archivo" : "esta dirección"}?`}
      onHide={() => {
        isFile
          ? (setSelectFile(0), setAnular(false))
          : (setSelectDireccion(0), setAnularDireccion(false));
      }}
      header={
        <div className="flex flex-row align-items-center mt-0 mb-0">
          <Icon size={"small"} className="mr-2" name="alert-circle" />
          <p className="text-base">{`${status === 1 ? "Anular" : "Activar"} ${isFile ? "archivo" : "dirección"}`}</p>
        </div>
      }
      accept={() =>
        isFile
          ? changeStateFile.mutateAsync({
              Id: selectFile,
              EstadoId: status === 1 ? 2 : 1,
            })
          : changeStateDireccion.mutateAsync({
              Id: selectDireccion,
              EstadoId: status === 1 ? 2 : 1,
            })
      }
    />
  );

  React.useEffect(() => {
    if (file) {
      if (getDownloadFile.isLoading) {
        toast.current?.show({
          life: 3000,
          severity: "warn",
          summary: "Warning",
          detail: "Cargando el archivo...",
        });
        return;
      }
      if (getDownloadFile.isError) {
        toast.current?.show({
          life: 3000,
          severity: "warn",
          summary: "Warning",
          detail: `Error al obtener el archivo: ${getDownloadFile.error}`,
        });
        return;
      }

      const base64Data = getDownloadFile.data?.Data.replace(
        /^data:image\/png;base64,/,
        "",
      ) as string;
      const contentTYpe =
        getDownloadFile.data?.ContentType ?? "application/octet-stream";
      const downloadLink = document.createElement("a");
      downloadLink.href = `data:${contentTYpe};base64,${base64Data}`;
      downloadLink.target = "_blank";
      downloadLink.download = getDownloadFile.data?.FileName ?? "download";
      downloadLink.click();
    } else {
      toast.current?.show({
        life: 3000,
        severity: "warn",
        summary: "Warning",
        detail: "No se pudo obtener el archivo.",
      });
    }
  }, [
    file,
    getDownloadFile.data?.ContentType,
    getDownloadFile.data?.Data,
    getDownloadFile.data?.FileName,
    getDownloadFile.error,
    getDownloadFile.isError,
    getDownloadFile.isLoading,
  ]);

  return (
    <>
      <UserCard
        telefono={getByIdProvider.data?.Telefono ?? ""}
        email={getByIdProvider.data?.CorreoElectronico ?? ""}
        name={`${getByIdProvider.data?.ProveedorNombre || ""} ${getByIdProvider.data?.ProveedorApellido || ""}`}
        imageSrc={
          getByIdProvider.data?.Imagen
            ? getByIdProvider.data?.Imagen
            : "https://static.vecteezy.com/system/resources/previews/004/141/669/non_2x/no-photo-or-blank-image-icon-loading-images-or-missing-image-mark-image-not-available-or-image-coming-soon-sign-simple-nature-silhouette-in-frame-isolated-illustration-vector.jpg"
        }
      ></UserCard>
      <Divider></Divider>
      <Description
        columns={2}
        className="mb-4"
        title="Informaciones generales"
        items={[
          {
            label: "Nombre(s)",
            value: getByIdProvider.data?.ProveedorNombre ?? "",
          },
          {
            label: "Nacionalidad",
            value:
              providersForCreate.data?.SelectOptions.NacionalidadSelect.find(
                (nacionalidad) =>
                  nacionalidad.NacionalidadId ===
                  getByIdProvider.data?.NacionalidadId,
              )?.NacionalidadNombre,
          },
          {
            label: "Tipo de documento de identidad",
            value: providersForCreate.data?.SelectOptions.TiposDNISelect.find(
              (tipoDNI) =>
                tipoDNI.TipoDNIId === getByIdProvider.data?.DNITipoId,
            )?.TipoDNINombre,
          },
          {
            label: "Documento de identidad",
            value: getByIdProvider.data?.DNI ?? "",
          },
          {
            label: "Teléfono",
            value: getByIdProvider.data?.Telefono ?? "",
          },
          {
            label: "Celular",
            value: getByIdProvider.data?.Celular ?? "",
          },
          {
            label: "Correo electrónico",
            value: getByIdProvider.data?.CorreoElectronico ?? "",
          },
          {
            label: "Estado",
            value: (
              <Tag
                severity={
                  getByIdProvider.data?.EstadoId === 1 ? "info" : "danger"
                }
              >
                {getByIdProvider.data?.EstadoId === 1 ? "Activo" : "Inactivo"}
              </Tag>
            ),
          },
          {
            label: "Representante",
            value: getByIdProvider.data?.Representante ?? "N/A",
          },
          {
            label: "¿Es empresa?",
            value: getByIdProvider.data?.EsEmpresa === true ? "Si" : "No",
          },
        ]}
      ></Description>
      <TabView panelContainerClassName="p-0">
        <TabPanel header="Archivos">
          <CardTable
            key={1}
            type="data"
            hideSelectFilter
            hideGlobalSearch
            columns={columns}
            hideFilterSettingsIcon
            value={filteredFiles as FileDataType[]}
            //title={activeTab}
            tableActions={(rowData) => [
              {
                icon: rowData.EstadoId === 1 ? "trash" : "plug",
                label: rowData.EstadoId === 1 ? "Anular" : "Activar",
                onClick: () => {
                  setAnular(true);
                  setStatus(rowData.EstadoId);
                  setSelectFile(rowData.ArchivoId);
                },
              },
              {
                icon: "download",
                label: "Descargar",
                onClick: () => setFile(rowData.ArchivoId),
              },
            ]}
          />
        </TabPanel>
        <TabPanel header="Direcciones">
          <CardTable
            key={2}
            type="data"
            hideSelectFilter
            hideGlobalSearch
            columns={columns1}
            hideFilterSettingsIcon
            value={getByIdProvider?.data?.ProveedorDirecciones ?? []}
            //title={activeTab}
            tableActions={(rowData) => [
              {
                icon: "pencil",
                label: "Editar",
                onClick: () => {
                  setVisible(true);
                  setEditDireccion(rowData.DireccionId);
                },
              },
              {
                icon: rowData.EstadoId === 1 ? "trash" : "plug",
                label: rowData.EstadoId === 1 ? "Anular" : "Activar",
                onClick: () => {
                  setAnularDireccion(true);
                  setStatus(rowData.EstadoId);
                  setSelectDireccion(rowData.DireccionId);
                },
              },
            ]}
          />
        </TabPanel>
      </TabView>
      <Dialog
        visible={visible}
        className="flex justify-content-center w-8 z-5"
        onHide={() => {
          setVisible(false), setEditDireccion(0);
        }}
        title={
          editDireccion !== 0 ? "Editar dirección" : "Agregar nueva dirección"
        }
      >
        <Form form={form}>
          <div className="flex flex-row formgrid grid">
            <div className="flex flex-column field col-12 pl-3 pr-3">
              <Dropdown
                name="TipoDireccionId"
                label="Tipo de dirección"
                options={tipoDireccionOptions}
                placeholder="Selecciona tipo de dirección"
                rules={{ required: "Este campo es requerido" }}
              />
            </div>

            <div className="flex flex-row field col-12">
              <div className="flex flex-column field col-6">
                <Dropdown
                  label="País"
                  name="PaisId"
                  options={paisesOptions}
                  placeholder="Selecciona el país"
                  rules={{ required: "Este campo es requerido" }}
                  onChange={() => {
                    form.setValue("ProvinciaId", null);
                    form.setValue("MunicipioId", null);
                    form.setValue("SectorId", null);
                  }}
                />
              </div>
              <div className="flex flex-column field col-6">
                <Dropdown
                  label="Provincia"
                  name="ProvinciaId"
                  disabled={!paisId}
                  options={provinciasOptions}
                  placeholder="Selecciona una provincia"
                  rules={{ required: "Este campo es requerido" }}
                  onChange={() => {
                    form.setValue("MunicipioId", null);
                    form.setValue("SectorId", null);
                  }}
                />
              </div>
            </div>

            <div className="flex flex-row field col-12">
              <div className="flex flex-column field col-6">
                <Dropdown
                  label="Municipio"
                  name="MunicipioId"
                  disabled={!provinciaId}
                  options={municipiosOptions}
                  placeholder="Selecciona el municipio"
                  rules={{ required: "Este campo es requerido" }}
                  onChange={() => {
                    form.setValue("SectorId", null);
                  }}
                />
              </div>
              <div className="flex flex-column field col-6">
                <Dropdown
                  label="Sector"
                  name="SectorId"
                  disabled={!municipioId}
                  options={sectoresOptions}
                  placeholder="Selecciona un sector"
                  rules={{ required: "Este campo es requerido" }}
                />
              </div>
            </div>

            <div className="flex flex-column field col-12 pl-3 pr-3">
              <InputText
                name="Direccion"
                label="Dirección"
                placeholder="Dirección"
                rules={{ required: "Este campo es requerido" }}
              ></InputText>
            </div>

            <div className="flex flex-row field col-12 align-items-center">
              <div className="flex flex-column field col-6">
                <InputText
                  name="Latitud"
                  label="Latitud"
                  placeholder="XX.XXXX"
                  rules={{ required: "Este campo es requerido" }}
                ></InputText>
              </div>
              <div className="flex flex-column field col-5">
                <InputText
                  name="Longitud"
                  label="Longitud"
                  placeholder="YY.YYYY"
                  rules={{ required: "Este campo es requerido" }}
                ></InputText>
              </div>
              <div className="flex flex-column field col-1">
                <Button
                  outlined
                  className="w-full mt-2"
                  onClick={getLocalization}
                  style={{ height: "43px" }}
                  icon={<Icon name="current-location"></Icon>}
                ></Button>
              </div>
            </div>

            <div className="flex flex-column field col-12 pl-3 pr-3">
              <InputTextarea
                name="Observaciones"
                label="Observaciones"
                placeholder="Escribe una observación"
                rules={{ required: "Este campo es requerido" }}
              ></InputTextarea>
            </div>
          </div>
        </Form>
        <div className="flex justify-content-end pb-6 ">
          <Button text className="mr-4" severity="danger">
            Cancelar
          </Button>
          <Button onClick={form.handleSubmit(onSubmit)}>
            {editDireccion !== 0 ? "Editar" : "Guardar"}
          </Button>
        </div>
      </Dialog>
      <ConfirmDialog
        visible={showDeletePicture}
        onHide={() => setShowDeletePicture(false)}
        accept={() => deleteProfilePicture.mutateAsync(Number(id))}
        message={`¿Está seguro de que desea eliminar la foto de perfil del proveedor?`}
        header={
          <div className="flex flex-row align-items-center mt-0 mb-0">
            <Icon size={"small"} className="mr-2" name="alert-circle"></Icon>
            <p className="text-base">Eliminar foto del proveedor</p>
          </div>
        }
      />
      <ConfirmDialog
        visible={anularProveedor}
        onHide={() => setAnularProveedor(false)}
        message={`¿Está seguro de que desea cambiarle el estado al proveedor?`}
        accept={() =>
          changeState.mutateAsync({
            Id: selectProveedor,
            EstadoId: status === 1 ? 2 : 1,
          })
        }
        header={
          <div className="flex flex-row align-items-center mt-0 mb-0">
            <Icon size={"small"} className="mr-2" name="alert-circle"></Icon>
            <p className="text-base">Cambio de estado proveedor</p>
          </div>
        }
      />
      <ChangeLogDialog
        entityId={changeLogState.id}
        visible={changeLogState.open}
        entityName={LogEntities.ACCOUNTING_MOVEMENT}
        onHide={EventUtils.callEvent(changeLogDispatch, {
          type: "CLOSE_DIALOG",
        })}
      />
      {anular ? renderConfirmDialog(true) : null}
      {anularDireccion ? renderConfirmDialog(false) : null}
      <Dialog
        visible={hide}
        onHide={() => setHide(false)}
        header="Seleccionar tipo de archivo"
        footer={
          <Button
            onClick={() => {
              if (!tipoArchivoId) {
                toast.current?.show({
                  life: 3000,
                  severity: "warn",
                  summary: "Warning",
                  detail: "Seleccione un tipo de archivo",
                });
                return;
              }
              setHide(false);

              if (formDataFile) {
                downloadFile.mutateAsync(formDataFile, {
                  onSuccess: (data) => {
                    if (fileUploadRef.current) {
                      fileUploadRef.current.clear();
                    }
                    if (data.FileId) {
                      createArchivo.mutateAsync(
                        {
                          EstadoId: 1,
                          ArchivoId: data.FileId,
                          ProveedorId: Number(id),
                          TipoArchivoId: tipoArchivoId,
                        },
                        {
                          onSuccess: () => {
                            setTipoArchivoId(0);
                          },
                        },
                      );
                    }
                  },
                });
              }
            }}
          >
            Ok
          </Button>
        }
      >
        <PrimeDropdown
          className="w-full"
          name="TipoArchivoId"
          value={tipoArchivoId}
          options={archivosTiposOptions}
          onChange={(e) => setTipoArchivoId(e.value)}
          placeholder="Seleccione un tipo de archivo"
        />
      </Dialog>
    </>
  );
};

export default ProviderDetailPage;
