import React from "react";
import { pick } from "radash";
import { Dialog } from "@components";
import { Button } from "primereact/button";
import { DialogProps } from "primereact/dialog";
import { IStrategicPlansDetailsTypes } from "@interfaces";
import { Form, useForm, InputText } from "@nubeteck/forms";

import { usePeiQuery, usePeiMutation } from "../../hooks";

export interface ISettingsTypesDialogProps extends DialogProps {
  typeId?: number;
}

type FormValues = IStrategicPlansDetailsTypes;

const model = {
  to: (data: FormValues): IStrategicPlansDetailsTypes => {
    return {
      DetalleTipoId: data.DetalleTipoId,
      DetalleTipoNombre: data.DetalleTipoNombre,
    };
  },
  from: (data: IStrategicPlansDetailsTypes): FormValues => {
    return {
      DetalleTipoId: data.DetalleTipoId,
      DetalleTipoNombre: data.DetalleTipoNombre,
    };
  },
};

const SettingsTypesDialog = ({
  typeId,
  ...props
}: ISettingsTypesDialogProps) => {
  const createDetailType = usePeiMutation(
    "StrategicPlansDetailsTypes",
    "create",
  );
  const updateDetailType = usePeiMutation(
    "StrategicPlansDetailsTypes",
    "update",
  );
  const getCreate = usePeiQuery(
    "StrategicPlansDetailsTypes",
    "getCreate",
    undefined,
    { enabled: !typeId },
  );
  const getUpdate = usePeiQuery(
    "StrategicPlansDetailsTypes",
    "getUpdate",
    typeId,
    { enabled: !!typeId },
  );

  const form = useForm<FormValues>({});

  React.useEffect(() => {
    if (!typeId && getCreate.data) {
      form.reset(pick(getCreate.data, ["DetalleTipoNombre", "DetalleTipoId"]), {
        keepDefaultValues: false,
      });
    }
  }, [form, getCreate.data, typeId]);

  React.useEffect(() => {
    if (typeId && getUpdate.data) {
      form.reset(model.from(getUpdate.data), { keepDefaultValues: true });
    }
  }, [form, getUpdate.data, typeId]);

  const onSubmit = (values: FormValues) => {
    if (typeId) {
      return updateDetailType.mutateAsync(model.to(values), {
        onSuccess: () => {
          form.reset();
          props.onHide();
        },
      });
    }
    return createDetailType.mutateAsync(model.to(values), {
      onSuccess: () => {
        form.reset();
        props.onHide();
      },
    });
  };

  const validations = React.useMemo(() => {
    if (typeId) return getUpdate.data?.Validaciones;
    else return getCreate.data?.Validaciones;
  }, [getCreate.data?.Validaciones, getUpdate.data?.Validaciones, typeId]);

  return (
    <Dialog
      {...props}
      className="w-full md:w-8 lg:w-5"
      subtitle="Rellena los datos del formulario"
      title={typeId ? `Editando tipo de detalle` : "Creando nuevo tipo"}
      onHide={() => {
        if (typeId) form.reset();
        props.onHide();
      }}
      footer={(props) => (
        <div className="flex flex-1 justify-content-end gap-2">
          <Button
            size="small"
            label="Cancelar"
            severity="secondary"
            onClick={() => {
              form.reset();
              props.onHide();
            }}
          />
          <Button
            size="small"
            onClick={form.handleSubmit(onSubmit)}
            label={typeId ? "Guardar cambios" : "Crear tipo"}
            loading={createDetailType.isPending || updateDetailType.isPending}
          />
        </div>
      )}
    >
      <Form form={form}>
        <InputText
          label="Nombre"
          name="DetalleTipoNombre"
          className="p-inputtext-sm"
          placeholder="Escribe un nombre..."
          rules={validations?.["DetalleTipoNombre"]}
        />
      </Form>
    </Dialog>
  );
};

export default SettingsTypesDialog;
