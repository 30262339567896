import React from "react";
import { select } from "radash";
import { Reducers } from "@core";
import { Tag } from "primereact/tag";
import { Icon } from "@nubeteck/icons";
import { useGlobalQuery } from "@hooks";
import { useParams } from "react-router";
import { FiltersDrawer } from "@components";
import { ConfirmDialog } from "@components";
import { ArrayUtils, EventUtils } from "@nubeteck/utils";
import {
  TreeNode,
  CardTable,
  PaginatorState,
  ITreeTableColumnsProps,
} from "@nubeteck/prime";

import { AccountCatalogForm } from "../../components";
import { useAccountingQuery, useAccountingMutation } from "../../hooks";

interface IData {
  Nombre: string;
  Cuenta: string;
  EstadoId: number;
  CuentaId: number;
  Descripcion: string;
  CuentaNombre: string;
  CuentaPadreId: number;
  CuentaPadreNombre: string;
}

const FILTER_AREA = "fcCatalogosCuentasDetalleBuscar";

const AccountCatalogSimplePage = () => {
  const { id } = useParams();

  const catalogId = parseInt(`${id}`, 10);

  const { data: filters } = useGlobalQuery(
    "Filters",
    "getAllByArea",
    FILTER_AREA,
  );
  const changeStatuCuentaDetalle = useAccountingMutation(
    "AccountCatalog",
    "changeStateDetalle",
  );

  const [edit, setEdit] = React.useState(false);
  const [anular, setAnular] = React.useState(false);
  const [status, setStatus] = React.useState<number>();
  const [searchTerm, setSearchTerm] = React.useState("");
  const [deleteId, setDeleteId] = React.useState<number>();
  const [filterSettingsVisible, setFilterSettingsVisible] =
    React.useState(false);
  const [filterSelected, setFilterSelected] = React.useState<
    number | undefined
  >();

  const [state, dispatch] = React.useReducer(Reducers.DialogReducer, {
    id: 0,
    open: false,
  });

  const [paginator, setPaginator] = React.useState<PaginatorState>({
    page: 0,
    first: 0,
    rows: 10,
  });

  const accountCatalogs = useAccountingQuery(
    "AccountCatalog",
    "getAllByFilter",
    {
      page: 0,
      pageSize: 0,
      filterId: filterSelected ?? 0,
    },
    { enabled: !!filterSelected },
  );

  React.useEffect(() => {
    const defaultFilterId = filters?.find(
      (filter) => filter.EsPorDefecto,
    )?.FiltroId;

    setFilterSelected(defaultFilterId);
  }, [filters]);

  const data = React.useMemo(() => {
    const mappedDetails = (accountCatalogs.data?.Data || []).flatMap(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (detalle: any) => {
        const cuentaPadreId =
          typeof detalle.CuentaPadreId === "object"
            ? null
            : detalle.CuentaPadreId;
        const cuentaPadreNombre =
          typeof detalle.CuentaPadreNombre === "object"
            ? null
            : detalle.CuentaPadreNombre;
        return {
          key: detalle.CuentaId?.toString() || "",
          data: {
            Cuenta: detalle.Cuenta,
            CuentaId: detalle.CuentaId,
            EstadoId: detalle.EstadoId,
            Nombre: detalle.CuentaNombre,
            CuentaPadreId: cuentaPadreId,
            Descripcion: detalle.Descripcion,
            CuentaPadreNombre: cuentaPadreNombre,
          },
        };
      },
    );

    const nodesMap = new Map();
    mappedDetails.forEach((node) =>
      nodesMap.set(node.key, { ...node, children: [] }),
    );

    mappedDetails.forEach((node) => {
      const parentNode = nodesMap.get(node.data.CuentaPadreId?.toString());
      if (parentNode) {
        parentNode.children.push(nodesMap.get(node.key));
      }
    });

    return Array.from(nodesMap.values()).filter(
      (node) => !node.data.CuentaPadreId,
    );
  }, [accountCatalogs.data]);

  const columns: ITreeTableColumnsProps<IData>[] = [
    {
      expander: true,
      field: "Cuenta",
      header: "Cuenta",
    },
    {
      field: "Nombre",
      header: "Nombre",
    },
    {
      field: "Descripcion",
      header: "Descripcion",
    },
    {
      header: "Estado",
      field: "EstadoId",
      body: ({ data }) => (
        <Tag severity={data.EstadoId === 1 ? "info" : "danger"}>
          {data.EstadoId === 1 ? "Activo" : "Inactivo"}
        </Tag>
      ),
    },
  ];

  const handleSearch = (term: string) => {
    setSearchTerm(term.toLowerCase());
  };

  const filterNodes = React.useCallback(
    (node: TreeNode<IData>): null | TreeNode<IData> => {
      const searchTermLowerCase = searchTerm.toLowerCase();
      const filteredChildren = select(
        node.children,
        filterNodes,
        (node) => node !== null,
      ) as TreeNode<IData>[];

      if (
        node.data.Cuenta.toLowerCase().includes(searchTermLowerCase) ||
        node.data.Nombre.toLowerCase().includes(searchTermLowerCase) ||
        node.data.Descripcion.toLowerCase().includes(searchTermLowerCase) ||
        filteredChildren.length > 0
      ) {
        return {
          ...node,
          children: filteredChildren,
        };
      }

      return null;
    },
    [searchTerm],
  );

  const dataFiltered = React.useMemo(() => {
    return select(
      data,
      filterNodes,
      (node) => node !== null,
    ) as TreeNode<IData>[];
  }, [data, filterNodes]);

  const filtersOptions = React.useMemo(() => {
    if (!filters?.length) return [];
    return ArrayUtils.selectLabelValue(filters ?? [], "Nombre", "FiltroId");
  }, [filters]);

  return (
    <>
      <CardTable
        type="tree"
        columns={columns} // TODO: Vista mal implementada
        paginator={false}
        removableSort={true}
        rows={paginator.rows}
        onSearch={handleSearch}
        first={paginator.first}
        columnResizeMode="expand"
        value={dataFiltered ?? []}
        valueFilter={filterSelected}
        filterOptions={filtersOptions}
        loading={accountCatalogs.isPending}
        onPaginationChange={(event) => setPaginator(event)}
        onSelectFilterOption={(value) => setFilterSelected(value)}
        onClickFilterSettings={EventUtils.callEvent(
          setFilterSettingsVisible,
          true,
        )}
        title={
          accountCatalogs.data?.Data.find(
            (catalog) => catalog.CatalogoId === catalogId,
          )?.Nombre ?? ""
        }
        tableActions={({ key, data }) => {
          return [
            {
              icon: "plus",
              label: "Agregar cuenta",
              disabled: data.CuentaPadreId !== null,
              onClick: () => {
                dispatch({
                  type: "OPEN_DIALOG",
                  payload: parseInt(`${key}`, 10),
                });
              },
            },
            {
              icon: "pencil",
              label: "Editar",
              onClick: () => {
                setEdit(true);
                dispatch({
                  type: "OPEN_DIALOG",
                  payload: parseInt(`${key}`, 10),
                });
              },
            },
            {
              icon: data.EstadoId === 1 ? "ban" : "chart-arcs",
              label: data.EstadoId === 1 ? "Anular" : "Activar",
              iconClassName:
                data.EstadoId === 1 ? "text-red-600" : "text-cyan-600",
              onClick: () => {
                setAnular(true);
                setStatus(Number(data.EstadoId));
                setDeleteId(Number(data.CuentaId));
              },
            },
          ];
        }}
      />
      <FiltersDrawer
        area={FILTER_AREA}
        visible={filterSettingsVisible}
        onHide={EventUtils.callEvent(setFilterSettingsVisible, false)}
      />
      <AccountCatalogForm
        edit={edit}
        open={state.open}
        cuentaPadreId={state.id}
        onClose={() => {
          setEdit(false);
          dispatch({ type: "CLOSE_DIALOG" });
        }}
      />
      <ConfirmDialog
        visible={anular}
        onHide={() => setAnular(false)}
        message={`¿Está seguro de que desea ${status === 1 ? "anular" : "activar"} esta cuenta?`}
        accept={() =>
          changeStatuCuentaDetalle.mutateAsync({
            CuentaId: Number(deleteId),
            EstadoId: status === 1 ? 2 : 1,
          })
        }
        header={
          <div className="flex flex-row align-items-center mt-0 mb-0">
            <Icon size={"small"} className="mr-2" name="alert-circle"></Icon>
            <p className="text-base">{`${status === 1 ? "Anular" : "Activar"} cuenta`}</p>
          </div>
        }
      />
    </>
  );
};

export default AccountCatalogSimplePage;
