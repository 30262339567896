import API from "@lib/api";
import { BaseApi } from "@api";
import { QueryUtils } from "@utils";
import {
  Messages,
  QueryFilter,
  IStrategicPlans,
  IStrategicPlansTypes,
  IStrategicPlansReview,
  IStrategicPlansRelations,
  IStrategicPlansGetForCreate,
  IStrategicPlansGetForUpdate,
} from "@interfaces";

export class StrategicPlansApi extends BaseApi {
  protected static filterApi = API({ namespace: "Filtro" });
  protected static api = API({ namespace: "PlanesEstrategicos" });
  protected static messages: Messages<typeof StrategicPlansApi> = {
    create: {
      error: (error) => error,
      loading: "Creando nuevo plan estratégico...",
      success: "Plan estratégico creado correctamente.",
    },
    update: {
      error: (error) => error,
      loading: "Actualizando plan estratégico...",
      success: "Plan estratégico actualizado correctamente.",
    },
    changeState: {
      error: (error) => error,
      loading: "Actualizando plan estratégico...",
      success: "Plan estratégico actualizado correctamente.",
    },
    requestReview: {
      error: (error) => error,
      loading: "Enviando comentarios de revision...",
      success: "Comentarios de revision enviada correctamente.",
    },
    sendRequestReview: {
      error: (error) => error,
      loading: "Enviando solicitud de revision...",
      success: "Solicitud de revision enviada correctamente.",
    },
  };

  public static async getAll() {
    const strategics = await this.api.get<IStrategicPlans[]>("GetAll");
    return strategics.data;
  }

  public static async getAllRelations() {
    const result =
      await this.api.get<IStrategicPlansRelations[]>("GetAllRelaciones");
    return result.data;
  }

  public static async getAllTypes() {
    const result = await this.api.get<IStrategicPlansTypes[]>("GetAllTipo");
    return result.data;
  }

  public static async getAllByFilter(data: {
    filterId: number;
    pageSize: number;
    page: number;
  }) {
    const result = await this.filterApi.get<QueryFilter<IStrategicPlans[]>>(
      `FiltroPorArea/${data.filterId}${QueryUtils.filter({ Page: data.page, PageSize: data.pageSize })}`,
    );
    return result.data;
  }

  public static async getById(id: number) {
    const strategic = await this.api.get<IStrategicPlans>(`GetById/${id}`);
    return strategic.data;
  }

  public static async create(data: IStrategicPlans) {
    const strategic = await this.api.post<{ PlanId: number }>("Create", data);
    return strategic.data;
  }

  public static async getCreate(typeId: number) {
    const strategic = await this.api.get<IStrategicPlansGetForCreate>(
      `Create/${typeId}`,
    );
    return strategic.data;
  }

  public static async update(data: Partial<IStrategicPlans>) {
    const strategic = await this.api.put(
      `Update/${data.PlanEstrategicoId}`,
      data,
    );
    return strategic.data;
  }

  public static async getUpdate(id: number) {
    const strategic = await this.api.get<IStrategicPlansGetForUpdate>(
      `Update/${id}`,
    );
    return strategic.data;
  }

  public static async changeState(data: { PlanId: number; EstadoId: number }) {
    const strategic = await this.api.post("ChangeStatePlanEstrategico", data);
    return strategic.data;
  }

  public static async requestReview(data: {
    Observacion: string;
    TipoRevisionId: number;
    PlanEstrategicoId: number;
  }) {
    const result = await this.api.post("RequestReview", data);
    return result.data;
  }

  public static async sendRequestReview(id: number) {
    const result = await this.api.post(`SendRequestReview/${id}`);
    return result.data;
  }

  public static async getAllReviews(id: number) {
    const result = await this.api.get<IStrategicPlansReview[]>(
      `GetAllRevisionesComentarios/${id}`,
    );
    return result.data;
  }
}
