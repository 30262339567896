import React from "react";
import { toast } from "sonner";
import { Reducers } from "@core";
import { Icon } from "@nubeteck/icons";
import { EventUtils } from "@nubeteck/utils";
import { Skeleton } from "primereact/skeleton";
import { confirmDialog } from "primereact/confirmdialog";
import { Sidebar, SidebarProps } from "primereact/sidebar";
import { useGlobalQuery, useGlobalMutation } from "@hooks";
import { FilterCard, NoResultsSVG } from "@nubeteck/prime";

import { FiltersFormDialog } from "../dialogs";
import { ConfirmDialog } from "../confirm-dialog";

export interface IFiltersDrawerProps extends SidebarProps {
  area: string;
}

const FiltersDrawer = ({ area, ...props }: IFiltersDrawerProps) => {
  const deleteFilter = useGlobalMutation("Filters", "delete");
  const filterArea = useGlobalQuery("Filters", "getAreaByArea", area, {
    enabled: !!area,
  });
  const filters = useGlobalQuery("Filters", "getAllByArea", area, {
    enabled: !!area,
  });

  const [state, dispatch] = React.useReducer(Reducers.DialogReducer, {
    id: 0,
    open: false,
  });

  const handleDeleteFilter = async (id: number) => {
    return deleteFilter.mutateAsync(id);
  };

  return (
    <Sidebar
      {...props}
      position="right"
      className="w-full md:w-8 lg:w-6 xl:w-4 relative p-sidebar-custom"
      header={
        <div className="flex align-items-center justify-content-between w-full">
          <div className="flex align-items-center gap-2">
            <Icon name="filter-cog" touchable={false} />
            <span className="font-semibold text-lg">
              Ajustes de filtros (
              {`${filterArea.data?.FiltroAreaAlias ?? "..."}`})
            </span>
          </div>
        </div>
      }
    >
      <div className="flex flex-column flex-1 overflow-auto">
        <div className="flex px-3 w-full">
          <div
            onClick={EventUtils.callEvent(dispatch, {
              payload: 0,
              type: "OPEN_DIALOG",
            })}
            className="border-dashed surface-ground cursor-pointer p-1 border-300 hover:border-primary border-2 border-round flex flex-column w-full justify-content-center align-items-center"
          >
            <Icon
              size={22}
              name="filter-plus"
              className="text-color-secondary"
            />
            <div className="text-color-secondary font-semibold text-xs">
              Agregar filtro
            </div>
          </div>
        </div>
        <div className="flex flex-column flex-1 w-full gap-2 overflow-auto px-3 py-2">
          {filters.isPending ? (
            <>
              <Skeleton width="100%" height="3.5rem" />
              <Skeleton width="100%" height="3.5rem" />
              <Skeleton width="100%" height="3.5rem" />
              <Skeleton width="100%" height="3.5rem" />
            </>
          ) : (
            <>
              {filters.data?.map((filter) => (
                <div
                  className="w-full"
                  key={`${filter.FiltroId}${filter.Nombre}`}
                >
                  <FilterCard
                    subtitle={filter.FiltroAreaAlias ?? ""}
                    title={`${filter.Nombre} ${filter.EsPorDefecto ? `(Por defecto)` : ""}`}
                    actions={[
                      {
                        iconName: "pencil",
                        className: "text-blue-500",
                        onClick: EventUtils.callEvent(dispatch, {
                          type: "OPEN_DIALOG",
                          payload: filter.FiltroId ?? 0,
                        }),
                      },
                      {
                        className: filter.EsPorDefecto ? "" : "text-red-500",
                        iconName: filter.EsPorDefecto ? "trash-off" : "trash",
                        onClick: () => {
                          if (!filter.EsPorDefecto) {
                            confirmDialog({
                              acceptLabel: "Eliminar",
                              rejectLabel: "Cancelar",
                              header: "Eliminando filtro",
                              message: `¿Deseas borrar el filtro '${filter.Nombre}'?`,
                              accept: () => {
                                handleDeleteFilter(filter.FiltroId ?? 0);
                              },
                            });
                          } else {
                            toast.warning(
                              "Este filtro no se puede eliminar porque esta seleccionado por defecto.",
                            );
                          }
                        },
                      },
                    ]}
                  />
                </div>
              ))}
              {!filters.data?.length && (
                <div className="flex flex-column align-items-center gap-4 flex-1 py-6">
                  <NoResultsSVG width="300" />
                  No hay filtros disponibles
                </div>
              )}
            </>
          )}
        </div>
        <ConfirmDialog rejectLabel="Cancelar" />
        <FiltersFormDialog
          area={area}
          filterId={state.id}
          visible={state.open}
          onHide={EventUtils.callEvent(dispatch, { type: "CLOSE_DIALOG" })}
        />
      </div>
    </Sidebar>
  );
};

export default FiltersDrawer;
