import React from "react";
import { pick } from "radash";
import { Form } from "@components";
import { Button } from "primereact/button";
import { IndicatorGoalTypes } from "@core";
import { Message } from "primereact/message";
import { EventUtils } from "@nubeteck/utils";
import { DialogProps } from "primereact/dialog";
import { FileUpload } from "primereact/fileupload";
import { useForm, useFieldArray } from "react-hook-form";
import { confirmDialog } from "primereact/confirmdialog";
import { Dialog, InputNumber, RadioButton } from "@components";
import { CardTable, IDataTableColumnsProps } from "@nubeteck/prime";
import { useToast, useGlobalQuery, useGlobalMutation } from "@hooks";
import {
  IIndicatorExecution,
  IIndicatorExecutionGetForUpdate,
} from "@interfaces";

export interface IExecutionPoaDialogProps extends DialogProps {
  indicatorId: number;
}

type FormValues = Omit<IIndicatorExecution, "Resultado"> & {
  Resultado: number;
};

const model = {
  to: (data: FormValues): IIndicatorExecution => {
    return {
      IndicadorId: data.IndicadorId,
      Resultado: `${data.Resultado}`,
      ArchivosIds:
        data.MediosVerificaciones?.map((medio) => medio.ArchivoId) ?? [],
    };
  },
  from: (data: IIndicatorExecution): FormValues => {
    return {
      ArchivosIds: [],
      IndicadorId: data.IndicadorId ?? 0,
      MediosVerificaciones: data.MediosVerificaciones,
      Resultado: data.Resultado?.length ? parseInt(`${data.Resultado}`, 10) : 0,
    };
  },
};

const ExecutionPoaDialog = ({
  visible,
  indicatorId,
  ...props
}: IExecutionPoaDialogProps) => {
  const toast = useToast();

  const downloadFile = useGlobalMutation("Files", "download");
  const createExecution = useGlobalMutation(
    "StrategicPlansIndicator",
    "createExecution",
  );
  const updateExecution = useGlobalMutation(
    "StrategicPlansIndicator",
    "updateExecution",
  );
  const getUpdateExecution = useGlobalQuery(
    "StrategicPlansIndicator",
    "getUpdateExecution",
    indicatorId,
    { networkMode: "always", enabled: !!indicatorId && visible },
  );

  const form = useForm<FormValues>();
  const { reset } = form;

  const { append, remove, fields } = useFieldArray({
    control: form.control,
    name: "MediosVerificaciones",
  });

  const metaType = getUpdateExecution.data?.Cuantitativo?.TipoMetaId ?? 0;

  React.useEffect(() => {
    if (indicatorId && getUpdateExecution.data) {
      reset(
        model.from(
          pick(getUpdateExecution.data, [
            "Resultado",
            "MediosVerificaciones",
          ]) as never,
        ),
      );
    }
  }, [reset, getUpdateExecution.data, indicatorId]);

  const handleDownloadFile = React.useCallback(
    (id: number) => {
      confirmDialog({
        acceptLabel: "Descargar",
        header: "Descargar archivo",
        acceptClassName: "p-button-sm",
        message:
          "¿Está seguro de que desea descargar este archivo? (Puede que tarde mucho en descargarse por su tamaño)",
        accept: async () => {
          await downloadFile.mutateAsync(id, {
            onSuccess: (file) => {
              const linkSource = file.Data as string;
              const downloadLink = document.createElement("a");
              downloadLink.href = linkSource;
              downloadLink.target = "_blank";
              downloadLink.download = file.FileName;
              downloadLink.click();
            },
          });
        },
      });
    },
    [downloadFile],
  );

  const handleSuccess = () => {
    form.reset();
    props.onHide();
  };

  const onSubmit = (values: FormValues) => {
    if (indicatorId) {
      if (getUpdateExecution.data?.Resultado.length) {
        return updateExecution.mutateAsync(
          model.to({ ...values, IndicadorId: indicatorId }),
          { onSuccess: handleSuccess },
        );
      }

      return createExecution.mutateAsync(
        model.to({ ...values, IndicadorId: indicatorId }),
        { onSuccess: handleSuccess },
      );
    }
  };

  const columns: IDataTableColumnsProps<
    IIndicatorExecutionGetForUpdate["MediosVerificaciones"][0]
  >[] = [
    { header: "Nombre", field: "ArchivoNombre" },
    {
      header: "Tamaño",
      field: "ArchivoSize",
      body: ({ ArchivoSize }) => `${((ArchivoSize ?? 0) / 1000).toFixed(2)} kB`,
    },
  ];

  const options = React.useMemo(() => {
    return (
      getUpdateExecution.data?.OpcionesCualitativas?.split("\n").map(
        (option) => {
          const [name, value] = option.split(",");
          return { name, value: parseInt(value, 10) };
        },
      ) ?? []
    );
  }, [getUpdateExecution.data?.OpcionesCualitativas]);

  const validations = React.useMemo(() => {
    return getUpdateExecution.data?.Validaciones;
  }, [getUpdateExecution.data?.Validaciones]);

  return (
    <Dialog
      {...props}
      visible={visible}
      className="w-full md:w-8"
      loading={getUpdateExecution.isPending}
      subtitle="Rellena los datos del formulario"
      title={`Subir evidencias de ${getUpdateExecution.data?.Indicador || "..."}`}
      onHide={() => {
        if (indicatorId) form.reset();
        props.onHide();
      }}
      footer={
        <div className="flex flex-1 justify-content-end gap-2">
          <Button
            size="small"
            severity="warning"
            label="Reiniciar formulario"
            onClick={() => {
              form.reset();
            }}
          />
          <Button
            size="small"
            severity="success"
            label="Solicitar revision"
            onClick={form.handleSubmit(onSubmit)}
            loading={createExecution.isPending || updateExecution.isPending}
          />
        </div>
      }
    >
      <Form form={form}>
        <Message
          className="mb-2 justify-content-start"
          text={
            <>
              <b>Descripción:</b> {getUpdateExecution.data?.Indicador ?? ""}
            </>
          }
        />
        {getUpdateExecution.data?.TipoMedicionResultadoId === 2 ? (
          <InputNumber
            min={0}
            name="Resultado"
            fieldClassName="flex-1"
            className="p-inputtext-sm"
            rules={validations?.Resultado}
            label="Resultado del indicador"
            placeholder="Ingrese el resultado del indicador"
            helperText="Ingrese el resultado que obtuvo este indicador."
            max={metaType === IndicatorGoalTypes.PERCENTAGE ? 100 : undefined}
            minFractionDigits={metaType === IndicatorGoalTypes.NUMERIC ? 2 : 0}
            suffix={
              metaType === IndicatorGoalTypes.PERCENTAGE ? "%" : undefined
            }
          />
        ) : (
          <RadioButton
            mode="vertical"
            name="Resultado"
            options={options}
            label="Respuesta del indicador"
          />
        )}
        <CardTable
          type="data"
          hideSelectFilter
          hideBorderStyles
          hideGlobalSearch
          paginator={false}
          columns={columns}
          value={fields ?? []}
          hideFilterSettingsIcon
          title="Archivos subidos"
          tableActions={(data) => [
            {
              icon: "download",
              label: "Descargar archivo",
              onClick: EventUtils.callEvent(handleDownloadFile, data.ArchivoId),
            },
            {
              icon: "trash",
              label: "Borrar archivo",
              iconClassName: "text-red-500",
              onClick: () => {
                const index = fields.findIndex((field) => field.id === data.id);
                remove(index);
              },
            },
          ]}
          headActions={[
            <FileUpload
              multiple
              mode="basic"
              name="formFiles"
              key="FiledUpload"
              uploadLabel="Subir archivo(s)"
              chooseLabel="Seleccionar archivo"
              url={`${import.meta.env.VITE_NUBETECK_SERVER_URL}/Files/UploadMultipleFiles`}
              maxFileSize={
                getUpdateExecution.data?.PesoMaximoArchivo ?? 1000000
              }
              onValidationFail={(file) => {
                toast.error(`Este archivo '${file.name}' no puede ser subido.`);
              }}
              onBeforeUpload={() => {
                toast.loading(
                  "Subiendo archivos al servidor...",
                  "file-upload",
                );
              }}
              onError={() => {
                toast.error(
                  "Hubo un error al subir los archivos al servidor.",
                  "file-upload",
                );
              }}
              onUpload={(event) => {
                toast.success(
                  "Archivos subidos al servidor y agregados al formulario con éxito.",
                  "file-upload",
                );
                const filesIds = (JSON.parse(event.xhr.response).FileIds ??
                  []) as number[];
                filesIds.forEach((file, index) => {
                  append({
                    ArchivoId: file,
                    MedioDeVerificacionId: 0,
                    ArchivoSize: event.files[index].size,
                    ArchivoNombre: event.files[index].name,
                  });
                });
              }}
            />,
          ]}
        />
      </Form>
    </Dialog>
  );
};

export default ExecutionPoaDialog;
