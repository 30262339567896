import React from "react";
import { Button } from "primereact/button";
import { Form, Dialog, InputText } from "@components";

import { IDashboardWidgetDialogProps } from "../../interfaces";

const DashboardImageDialog = ({
  form,
  onSubmitClick,
  ...props
}: IDashboardWidgetDialogProps<{ source: string }>) => {
  return (
    <Dialog
      {...props}
      style={{ width: "50vw" }}
      title="Ajustes del widget"
      className="w-full md:w-10 lg:w-5"
      footer={
        <div className="flex justify-content-end gap-2">
          <Button
            size="small"
            type="button"
            label="Cancelar"
            severity="secondary"
            onClick={() => {
              form.reset();
              props.onHide();
            }}
          />
          <Button
            size="small"
            label="Guardar cambios"
            onClick={form.handleSubmit(onSubmitClick)}
          />
        </div>
      }
    >
      <Form form={form}>
        <InputText
          autoFocus
          name="source"
          label="Fuente (URL)"
          className="p-inputtext-sm"
          helperText="Ingrese un enlace de la imagen a mostrar"
        />
      </Form>
    </Dialog>
  );
};

export default DashboardImageDialog;
