import React from "react";
import { Link } from "react-router";
import { IUser } from "@interfaces";
import { Icon } from "@nubeteck/icons";
import { Menu } from "primereact/menu";
import { Avatar } from "primereact/avatar";
import { useAuthUser } from "@nubeteck/auth";
import { MenuItem } from "primereact/menuitem";
import { AppRoutes, AppRouteNames } from "@core";

const UserMenuOptions = () => {
  const userData = useAuthUser<IUser>()();
  const menuLeft = React.useRef<Menu>(null);

  const itemRenderer = React.useCallback((item: MenuItem) => {
    const className = "flex align-items-center p-menuitem-link";

    const renderContent = (
      <>
        <Icon size={20} name={item.icon} />
        <span className="mx-2">{item.label}</span>
      </>
    );

    return (
      <div className="p-menuitem-content">
        {item.url ? (
          <Link to={item.url} className={className}>
            {renderContent}
          </Link>
        ) : (
          <a className={className}>{renderContent}</a>
        )}
      </div>
    );
  }, []);

  const items: MenuItem[] = [
    {
      template: () => {
        return (
          <div className="w-full flex align-items-center px-2 text-color">
            <Avatar
              shape="circle"
              className="mr-2"
              image="/avatar-placeholder.webp"
            />
            <div className="flex flex-column align">
              <span className="font-bold text-sm">
                {userData?.FullName ?? "Usuario"}
              </span>
              <span className="text-xs">
                {userData?.Email ?? "joe@doe.com"}
              </span>
            </div>
          </div>
        );
      },
    },
    {
      separator: true,
    },
    {
      icon: "logout",
      label: "Cerrar sesión",
      template: itemRenderer,
      url: AppRoutes[AppRouteNames.SIGN_OUT_PAGE].create({}),
    },
  ];

  return (
    <>
      <a
        aria-haspopup
        aria-controls="popup_menu_left"
        onClick={(event) => menuLeft.current?.toggle(event)}
        className="flex h-3rem align-items-center cursor-pointer hover:bg-gray-100"
      >
        <div className="flex align-items-center gap-2 px-3 text-color">
          <Avatar shape="circle" image="/avatar-placeholder.webp" />
          <div className="flex-column hidden md:flex">
            <span className="font-bold text-sm">
              {userData?.FullName ?? "Usuario"}
            </span>
            <span className="text-xs">{userData?.Email ?? "joe@doe.com"}</span>
          </div>
        </div>
      </a>
      <Menu popup model={items} ref={menuLeft} className="w-full md:w-15rem" />
    </>
  );
};

export default UserMenuOptions;
