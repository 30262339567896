import React from "react";
import { toast } from "sonner";
import { Reducers } from "@core";
import { Icon } from "@nubeteck/icons";
import { Button } from "primereact/button";
import { IPermissions } from "@interfaces";
import { Dropdown } from "primereact/dropdown";
import { useCopyToClipboard } from "usehooks-ts";
import { ArrayUtils, EventUtils } from "@nubeteck/utils";
import { useGlobalQuery, useGlobalMutation } from "@hooks";
import { ChangeLogDialog, PermissionsFormDialog } from "@components";
import {
  CardTable,
  useHeaderActions,
  IDataTableColumnsProps,
} from "@nubeteck/prime";

const PermissionsPage = () => {
  const [, copy] = useCopyToClipboard();
  const { dispatch: dispatchHeaderActions } = useHeaderActions();

  const roles = useGlobalQuery("Roles", "getAll");
  const modules = useGlobalQuery("Modules", "getAll");
  const updateValidity = useGlobalMutation("Permissions", "updateValidity");

  const [selectedPermissions, setSelectedPermissions] = React.useState<
    IPermissions[]
  >([]);
  const [roleSelected, setRoleSelected] = React.useState<null | number>(null);
  const [moduleSelected, setModuleSelected] = React.useState<null | number>(
    null,
  );
  const [state, dispatch] = React.useReducer(Reducers.DialogReducer, {
    id: 0,
    open: false,
  });
  const [changeLogState, changeLogDispatch] = React.useReducer(
    Reducers.DialogReducer,
    { id: 0, open: false },
  );

  const permissions = useGlobalQuery(
    "Modules",
    "getPermissions",
    {
      RolId: roleSelected ?? 0,
      ModuloId: moduleSelected ?? 0,
    },
    { enabled: !!roleSelected && !!moduleSelected },
  );

  const fetchData = React.useCallback(() => {
    if (permissions.data) {
      setSelectedPermissions(
        permissions.data.filter((permission) => permission.EstaVigente),
      );
    }
  }, [permissions.data]);

  React.useEffect(() => {
    fetchData();
  }, [fetchData, permissions.data]);

  const rolesOptions = React.useMemo(() => {
    if (!roles.data?.length) return [];
    return ArrayUtils.selectLabelValue(roles.data, "RolName", "RolId");
  }, [roles.data]);

  const modulesOptions = React.useMemo(() => {
    if (!modules.data?.length) return [];
    return ArrayUtils.selectLabelValue(
      modules.data,
      "ModuloNombre",
      "ModuloId",
    );
  }, [modules.data]);

  React.useEffect(() => {
    dispatchHeaderActions({
      type: "SET_ACTIONS",
      payload: [
        <div className="flex gap-2" key="RoleModuleDropdowns">
          <Dropdown
            key="RoleDropdown"
            value={roleSelected}
            options={rolesOptions}
            placeholder="Selecciona un rol"
            className="p-inputtext-sm w-18rem"
            onChange={(event) => setRoleSelected(event.value)}
          />
          <Dropdown
            key="ModuleDropdown"
            value={moduleSelected}
            options={modulesOptions}
            className="p-inputtext-sm"
            placeholder="Selecciona un modulo"
            onChange={(event) => setModuleSelected(event.value)}
          />
        </div>,
      ],
    });
    return () => dispatchHeaderActions({ type: "CLEAR_ACTIONS" });
  }, [
    dispatchHeaderActions,
    moduleSelected,
    modulesOptions,
    roleSelected,
    rolesOptions,
  ]);

  const onSubmit = () => {
    const newPermissions = permissions.data?.map((permission) => {
      return {
        PermisoId: permission.PermisoId,
        EstaVigente: selectedPermissions.includes(permission),
      };
    });
    return updateValidity.mutateAsync({
      RolId: roleSelected ?? 0,
      Permisos: newPermissions ?? [],
    });
  };

  const columns = React.useMemo<IDataTableColumnsProps<IPermissions>[]>(
    () => [
      {
        header: "",
        body: () => <></>,
        field: "PermisoId",
        selectionMode: "multiple",
      },
      {
        header: "Permiso",
        field: "PermisoNombre",
        body: (data) => (
          <div className="flex flex-column flex-wrap">
            <span className="font-bold">{data.PermisoNombre}</span>
            <span>{data.Descripcion}</span>
          </div>
        ),
      },
    ],
    [],
  );

  const emptyMessage = React.useMemo(() => {
    if (!moduleSelected)
      return "Selecciona un modulo para visualizar sus permisos";
    if (!roleSelected) return "Selecciona un rol para visualizar sus permisos";
  }, [moduleSelected, roleSelected]);

  const roleSelectedLabel = React.useMemo(() => {
    return rolesOptions.find(
      (role) => parseInt(role.value, 10) === roleSelected,
    )?.label;
  }, [roleSelected, rolesOptions]);

  const areBothSelected = !!roleSelected && !!moduleSelected;

  return (
    <>
      <CardTable<IPermissions>
        type="data"
        hideSelectFilter
        columns={columns}
        hideFilterSettingsIcon
        selectionMode="checkbox"
        emptyMessage={emptyMessage}
        value={permissions.data ?? []}
        selection={selectedPermissions}
        loading={permissions.isFetching}
        onRefresh={() => permissions.refetch()}
        subtitle="Selecciona los permisos vigentes"
        title={`Permisos del ${roleSelectedLabel ?? "..."}`}
        onSelectionChange={(e) => setSelectedPermissions(e.value)}
        headActions={[
          <Button
            size="small"
            key="RoleDropdown"
            disabled={!moduleSelected}
            label="Crear nuevo permiso"
            icon={<Icon size={20} className="mr-1" name="shield-plus" />}
            onClick={EventUtils.callEvent(dispatch, {
              payload: 0,
              type: "OPEN_DIALOG",
            })}
          />,
        ]}
        footer={
          <div className="p-2 flex justify-content-end gap-2">
            <Button
              size="small"
              label="Cancelar"
              severity="secondary"
              disabled={!areBothSelected}
              onClick={() => {
                fetchData();
              }}
            />
            <Button
              size="small"
              severity="success"
              onClick={onSubmit}
              label="Guardar cambios"
              disabled={!areBothSelected}
              icon={<Icon size={20} className="mr-1" name="device-floppy" />}
            />
          </div>
        }
        tableActions={(data) => [
          {
            icon: "copy",
            label: "Copiar ID",
            onClick: async () => {
              const result = await copy(`${data.PermisoId}`);
              if (result) toast.success("ID copiado con exito.");
              else toast.error("Hubo un error al copiar el ID");
            },
          },
          {
            icon: "pencil",
            label: "Editar",
            onClick: EventUtils.callEvent(dispatch, {
              type: "OPEN_DIALOG",
              payload: data.PermisoId,
            }),
          },
          {
            icon: "book",
            label: "Historial de cambios",
            onClick: EventUtils.callEvent(changeLogDispatch, {
              type: "OPEN_DIALOG",
              payload: data.PermisoId,
            }),
          },
        ]}
      />
      <ChangeLogDialog
        entityName="RolId"
        entityName2="PermisoId"
        entityId={roleSelected ?? 0}
        entityId2={changeLogState.id}
        visible={changeLogState.open}
        onHide={EventUtils.callEvent(changeLogDispatch, {
          type: "CLOSE_DIALOG",
        })}
      />
      <PermissionsFormDialog
        visible={state.open}
        permissionsId={state.id}
        moduleId={moduleSelected ?? 0}
        onHide={EventUtils.callEvent(dispatch, { type: "CLOSE_DIALOG" })}
      />
    </>
  );
};

export default PermissionsPage;
