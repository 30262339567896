import API from "@lib/api";
import { BaseApi } from "@api";
import { Messages } from "@interfaces";

import { IFiles } from "../interfaces";

export class FilesApi extends BaseApi {
  protected static api = API({ namespace: "Files" });
  protected static filterApi = API({ namespace: "Filtro" });
  protected static messages: Messages<typeof FilesApi> = {
    uploadFile: {
      loading: "Cargando archivo...",
      success: "Archivo cargado correctamente.",
      error: (error) => `Hubo un error al cargar el proveedor: ${error}`,
    },
    getDownloadFile: {
      loading: "Cargando archivo...",
      success: "Archivo cargado correctamente.",
      error: (error) => `Hubo un error al cargar el proveedor: ${error}`,
    },
  };

  public static async uploadFile(formFile: FormData) {
    const result = await this.api.post("UploadFile", formFile, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    return result.data;
  }

  public static async getDownloadFile(id: number): Promise<IFiles> {
    const result = await this.api.get(`DownloadFile/${id}`);
    return result.data;
  }
}
