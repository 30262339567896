import React from "react";
import { Reducers } from "@core";
import { Button } from "primereact/button";
import { ConfirmDialog } from "@components";
import { EventUtils } from "@nubeteck/utils";
import { useAuthPermissions } from "@nubeteck/auth";
import { IStrategicPlansDetailsTypes } from "@interfaces";
import { CardTable, IDataTableColumnsProps } from "@nubeteck/prime";

import { Permissions } from "../../core";
import { usePeiQuery } from "../../hooks";
import { SettingsTypesDialog } from "../../components";

const SettingsDetailsTypesPage = () => {
  const permissions = useAuthPermissions();

  const detailsTypes = usePeiQuery("StrategicPlansDetailsTypes", "getAll");

  const [state, dispatch] = React.useReducer(Reducers.DialogReducer, {
    id: 0,
    open: false,
  });

  const columns: IDataTableColumnsProps<IStrategicPlansDetailsTypes>[] = [
    { header: "#", field: "DetalleTipoId" },
    { header: "Nombre", field: "DetalleTipoNombre" },
  ];

  return (
    <>
      <div className="w-full">
        <CardTable
          type="data"
          hideSelectFilter
          columns={columns}
          hideFilterSettingsIcon
          title="Tipos de detalles"
          value={detailsTypes.data ?? []}
          loading={detailsTypes.isPending}
          subtitle="Configuración de planes estratégicos"
          headActions={[
            <Button
              size="small"
              key="AddButtonType"
              label="Agregar tipo"
              onClick={EventUtils.callEvent(dispatch, {
                payload: 0,
                type: "OPEN_DIALOG",
              })}
              disabled={
                !permissions.hasPermission(
                  Permissions.CREATE_DETAILS_TYPES_SETTINGS,
                )
              }
            />,
          ]}
          tableActions={(data) => [
            {
              icon: "pencil",
              label: "Editar",
              disabled: !permissions.hasPermission(
                Permissions.EDIT_DETAILS_TYPES_SETTINGS,
              ),
              onClick: EventUtils.callEvent(dispatch, {
                type: "OPEN_DIALOG",
                payload: data.DetalleTipoId,
              }),
            },
            // {
            //   icon: "ban",
            //   label: "Anular",
            //   iconClassName: "text-red-500",
            // disabled: data.EstadoId === 2,
            //   onClick: EventUtils.callEvent(handleNullify, data.DetalleTipoId),
            // },
            // {
            //   icon: "plug",
            //   label: "Activar",
            // disabled: data.EstadoId === 1,
            //   iconClassName: "text-primary-500",
            //   onClick: EventUtils.callEvent(handleRestore, data.DetalleTipoId),
            // },
          ]}
        />
      </div>
      <SettingsTypesDialog
        typeId={state.id}
        visible={state.open}
        onHide={EventUtils.callEvent(dispatch, {
          type: "CLOSE_DIALOG",
        })}
      />
      <ConfirmDialog />
    </>
  );
};

export default SettingsDetailsTypesPage;
