import React from "react";
import { Reducers, Statuses } from "@core";
import { Button } from "primereact/button";
import { ConfirmDialog } from "@components";
import { EventUtils } from "@nubeteck/utils";
import { useAuthPermissions } from "@nubeteck/auth";
import { confirmDialog } from "primereact/confirmdialog";
import { useGlobalQuery, useGlobalMutation } from "@hooks";
import { IStrategicPlansTemplatesIndicator } from "@interfaces";
import { CardTable, StatusTag, IDataTableColumnsProps } from "@nubeteck/prime";

import { Permissions } from "../../core";
import { SettingsTemplatesIndicatorDialog } from "../../components";

const SettingsTemplatesIndicatorPage = () => {
  const permissions = useAuthPermissions();

  const templates = useGlobalQuery(
    "StrategicPlansTemplatesIndicator",
    "getAll",
  );
  const changeState = useGlobalMutation(
    "StrategicPlansTemplatesIndicator",
    "changeState",
  );

  const [state, dispatch] = React.useReducer(Reducers.DialogReducer, {
    id: 0,
    open: false,
  });

  const handleRestore = React.useCallback(
    (id: number) => {
      confirmDialog({
        acceptLabel: "Activar",
        header: "Activar plantilla",
        acceptClassName: "p-button-sm",
        message: "¿Está seguro de que desea activar esta plantilla?",
        accept: async () => {
          await changeState.mutateAsync({
            PlantillaId: id,
            EstadoId: Statuses.ACTIVE_CODE,
          });
        },
      });
    },
    [changeState],
  );

  const handleNullify = React.useCallback(
    (id: number) => {
      confirmDialog({
        acceptLabel: "Anular",
        header: "Anular plantilla",
        message: "¿Está seguro de que desea anular esta plantilla?",
        accept: async () => {
          await changeState.mutateAsync({
            PlantillaId: id,
            EstadoId: Statuses.NULLIFIED_CODE,
          });
        },
      });
    },
    [changeState],
  );

  const columns: IDataTableColumnsProps<IStrategicPlansTemplatesIndicator>[] = [
    { header: "#", field: "PlantillaId" },
    { field: "Nombre", header: "Nombre" },
    { field: "Descripcion", header: "Descripción" },
    {
      header: "Estado",
      field: "EstadoNombre",
      body: ({ EstadoNombre }) => <StatusTag status={EstadoNombre} />,
    },
  ];

  return (
    <>
      <div className="w-full">
        <CardTable
          type="data"
          hideSelectFilter
          columns={columns}
          actionMenu="non-float"
          hideFilterSettingsIcon
          value={templates.data ?? []}
          loading={templates.isPending}
          onRefresh={() => templates.refetch()}
          subtitle="Configuración de planes estratégicos"
          title="Plantillas de los indicadores cualitativos"
          headActions={[
            <Button
              size="small"
              key="AddButtonTemplate"
              label="Agregar plantilla"
              onClick={EventUtils.callEvent(dispatch, {
                payload: 0,
                type: "OPEN_DIALOG",
              })}
              disabled={
                !permissions.hasPermission(
                  Permissions.CREATE_TEMPLATES_INDICATOR_SETTINGS,
                )
              }
            />,
          ]}
          tableActions={(data) => [
            {
              icon: "pencil",
              label: "Editar",
              disabled: !permissions.hasPermission(
                Permissions.EDIT_TEMPLATES_INDICATOR_SETTINGS,
              ),
              onClick: EventUtils.callEvent(dispatch, {
                type: "OPEN_DIALOG",
                payload: data.PlantillaId,
              }),
            },
            {
              icon: "ban",
              label: "Anular",
              iconClassName: "text-red-500",
              onClick: EventUtils.callEvent(handleNullify, data.PlantillaId),
              disabled:
                data.EstadoId === Statuses.NULLIFIED_CODE ||
                !permissions.hasPermission(
                  Permissions.NULLIFY_TEMPLATES_INDICATOR_SETTINGS,
                ),
            },
            {
              icon: "plug",
              label: "Activar",
              iconClassName: "text-primary-500",
              onClick: EventUtils.callEvent(handleRestore, data.PlantillaId),
              disabled:
                data.EstadoId === Statuses.ACTIVE_CODE ||
                !permissions.hasPermission(
                  Permissions.RESTORE_TEMPLATES_INDICATOR_SETTINGS,
                ),
            },
          ]}
        />
      </div>
      <SettingsTemplatesIndicatorDialog
        visible={state.open}
        templateId={state.id}
        onHide={EventUtils.callEvent(dispatch, { type: "CLOSE_DIALOG" })}
      />
      <ConfirmDialog />
    </>
  );
};

export default SettingsTemplatesIndicatorPage;
