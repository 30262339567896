import React from "react";
import { useGlobalQuery } from "@hooks";
import { Reducers, Statuses } from "@core";
import { EventUtils } from "@nubeteck/utils";
import { useAuthPermissions } from "@nubeteck/auth";
import { IStrategicPlansActivityIndicator } from "@interfaces";
import { CardTable, StatusTag, IDataTableColumnsProps } from "@nubeteck/prime";

import { Permissions } from "../../core";
import { ExecutionPoaDialog } from "../execution-poa-dialog";
import { ExecutionPoaFilesDialog } from "../execution-poa-files-dialog";
import { ExecutionPoaCommentsDialog } from "../execution-poa-comments-dialog";

export interface IExecutionPoaIndicatorsProps {
  activityId: number;
}

const ExecutionPoaIndicators = ({
  activityId,
}: IExecutionPoaIndicatorsProps) => {
  const permissions = useAuthPermissions();

  const indicators = useGlobalQuery(
    "StrategicPlansIndicator",
    "getAll",
    { IndicadorTipoId: 0, ActividadId: activityId },
    { enabled: !!activityId },
  );

  const [state, dispatch] = React.useReducer(Reducers.DialogReducer, {
    id: 0,
    open: false,
  });
  const [stateFiles, dispatchFiles] = React.useReducer(Reducers.DialogReducer, {
    id: 0,
    open: false,
  });
  const [stateComments, dispatchComments] = React.useReducer(
    Reducers.DialogReducer,
    { id: 0, open: false },
  );

  const columns: IDataTableColumnsProps<IStrategicPlansActivityIndicator>[] = [
    {
      style: { width: "200px" },
      header: "Tipo de indicador",
      field: "TipoIndicadorNombre",
    },
    {
      style: { width: "200px" },
      header: "Tipo de medición",
      field: "TipoMedicionResultadoNombre",
    },
    { field: "Indicador", header: "Indicador" },
    {
      header: "Estado",
      field: "EstadoId",
      style: { width: "250px" },
      body: (data) => <StatusTag status={data.EstadoNombre ?? "Activo"} />,
    },
  ];

  return (
    <>
      <div>
        <CardTable<IStrategicPlansActivityIndicator>
          type="data"
          hideSelectFilter
          columns={columns}
          dataKey="IndicadorId"
          hideFilterSettingsIcon
          actionMenu="non-float"
          skeletonLoadingRows={3}
          value={indicators.data ?? []}
          loading={indicators.isFetching}
          title="Indicadores de la actividad"
          onRefresh={() => indicators.refetch()}
          tableActions={(data) => {
            const isPendingApproval =
              data.EstadoId === Statuses.PENDING_APPROVAL_CODE;
            return [
              {
                icon: "message-report",
                label: "Comentarios de revision",
                onClick: EventUtils.callEvent(dispatchComments, {
                  type: "OPEN_DIALOG",
                  payload: data.IndicadorId,
                }),
              },
              {
                icon: "upload",
                label: "Subir evidencias",
                onClick: EventUtils.callEvent(dispatch, {
                  type: "OPEN_DIALOG",
                  payload: data.IndicadorId,
                }),
                disabled:
                  isPendingApproval ||
                  data.EstadoId === Statuses.COMPLETED_CODE ||
                  !permissions.hasPermission(
                    Permissions.UPLOAD_FILES_EXECUTION_POA,
                  ),
              },
              {
                icon: "file-search",
                label: "Revisar evidencias",
                onClick: EventUtils.callEvent(dispatchFiles, {
                  type: "OPEN_DIALOG",
                  payload: data.IndicadorId,
                }),
                disabled:
                  !isPendingApproval ||
                  !permissions.hasPermission(
                    Permissions.APPROVE_REJECT_FILES_EXECUTION_POA,
                  ),
              },
            ];
          }}
        />
      </div>
      <ExecutionPoaDialog
        visible={state.open}
        indicatorId={state.id}
        onHide={EventUtils.callEvent(dispatch, {
          type: "CLOSE_DIALOG",
        })}
      />
      <ExecutionPoaFilesDialog
        visible={stateFiles.open}
        indicatorId={stateFiles.id}
        onHide={EventUtils.callEvent(dispatchFiles, {
          type: "CLOSE_DIALOG",
        })}
      />
      <ExecutionPoaCommentsDialog
        visible={stateComments.open}
        indicatorId={stateComments.id}
        onHide={EventUtils.callEvent(dispatchComments, {
          type: "CLOSE_DIALOG",
        })}
      />
    </>
  );
};

export default ExecutionPoaIndicators;
