import { IInputProps } from "@interfaces";
import { useController } from "react-hook-form";
import { TreeSelect, TreeSelectProps } from "primereact/treeselect";

import { Field } from "../../field";

//! ANTES DE MODIFICAR ESTE COMPONENTE CONSULTE CON SUS DESARROLLADORES.
//! ANTES DE MODIFICAR ESTE COMPONENTE CONSULTE CON SUS DESARROLLADORES.
//! ANTES DE MODIFICAR ESTE COMPONENTE CONSULTE CON SUS DESARROLLADORES.

export interface ITreeSelectProps
  extends IInputProps,
    Omit<TreeSelectProps, "name" | "defaultValue"> {}

const $TreeSelect = ({
  name,
  label,
  rules,
  disabled,
  helperText,
  defaultValue,
  disabledInput,
  fieldClassName,
  shouldUnregister,
  ...props
}: ITreeSelectProps) => {
  const { field, fieldState } = useController({
    name,
    rules,
    disabled,
    defaultValue,
    shouldUnregister,
  });

  return (
    <Field
      name={name}
      label={label}
      helperText={helperText}
      className={fieldClassName}
      isRequired={!!rules?.required}
    >
      <TreeSelect
        {...field}
        {...props}
        disabled={disabledInput}
        invalid={fieldState.invalid}
      />
    </Field>
  );
};

export default $TreeSelect;
