import React from "react";
import { ParametersTypes } from "@core";
import { IReportParameter } from "@interfaces";

import { Calendar } from "../calendar";
import { Dropdown } from "../dropdown";
import { InputText } from "../input-text";
import { InputNumber } from "../input-number";
import { SelectButton } from "../select-button";

export interface IReportInputProps {
  parameter: IReportParameter;
}

const ReportInput = ({ parameter }: IReportInputProps) => {
  const options = React.useMemo(() => {
    return parameter.ReporteParametroOpcion?.split("\n").reduce(
      (acc, config) => {
        if (!config) return acc;

        const configSplitted = config?.split(",") ?? [];

        return [
          ...acc,
          { label: configSplitted[1], value: parseInt(configSplitted[0]) },
        ];
      },
      [] as { label: string; value: number }[],
    );
  }, [parameter.ReporteParametroOpcion]);

  const inputProps = React.useMemo(
    () => ({
      className: "p-inputtext-sm",
      name: parameter.ReporteParametroCampo,
      label: parameter.ReporteParametroNombre,
      helperText: parameter.ReporteParametroDescripcion ?? "",
      rules: {
        required: parameter.ReporteParametroRequerido
          ? "Campo requerido"
          : undefined,
      },
    }),
    [
      parameter.ReporteParametroCampo,
      parameter.ReporteParametroDescripcion,
      parameter.ReporteParametroNombre,
      parameter.ReporteParametroRequerido,
    ],
  );

  const mapping = React.useMemo<Record<number, React.JSX.Element>>(
    () => ({
      [ParametersTypes.DATE]: (
        <Calendar placeholder="Ingrese una fecha" {...inputProps} />
      ),
      [ParametersTypes.NUMBER]: (
        <InputNumber placeholder="Ingresa un número" {...inputProps} />
      ),
      [ParametersTypes.TEXT]: (
        <InputText {...inputProps} type="text" placeholder="Escribe un texto" />
      ),
      [ParametersTypes.OPTIONS]: (
        <Dropdown
          {...inputProps}
          options={options}
          placeholder="Selecciona una opción"
        />
      ),
      [ParametersTypes.MONTH]: (
        <Calendar
          view="year"
          dateFormat="yy"
          placeholder="Seleccione un mes"
          {...inputProps}
        />
      ),
      [ParametersTypes.YEAR]: (
        <Calendar
          view="month"
          dateFormat="mm/yy"
          placeholder="Seleccione un año"
          {...inputProps}
        />
      ),
      [ParametersTypes.BOOLEAN]: (
        <SelectButton
          {...inputProps}
          defaultValue="False"
          options={[
            { value: "True", label: "Habilitar" },
            { value: "False", label: "Deshabilitar" },
          ]}
        />
      ),
    }),
    [inputProps, options],
  );

  return (
    mapping[parameter.ReporteParametroTipoId] ??
    `[Este tipo de parámetro no esta siendo manejado]`
  );
};

export default ReportInput;
