import API from "@lib/api";
import { BaseApi } from "@api";
import { Messages } from "@interfaces";

import { IWidget } from "../interfaces";

export class WidgetsApi extends BaseApi {
  protected static api = API({ namespace: "Widgets" });
  protected static messages: Messages<typeof WidgetsApi> = {};

  public static async getAll() {
    const widgets = await this.api.get<IWidget[]>("GetAll");
    return widgets.data;
  }

  public static async getById(id: number) {
    const widget = await this.api.get<IWidget>(`GetById/${id}`);
    return widget.data;
  }

  public static async create(data: IWidget) {
    const widget = await this.api.post("Create", data);
    return widget.data;
  }

  public static async delete(id: number) {
    return await this.api.delete(`Delete/${id}`);
  }

  public static async update(data: Partial<IWidget>) {
    const widget = await this.api.put(`Update/${data.WidgetId}`, data);
    return widget.data;
  }
}
