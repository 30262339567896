import React from "react";
import { ReportsTypes } from "@core";
import { Outlet } from "react-router";
import { useGlobalQuery } from "@hooks";
import { NavLink } from "@nubeteck/prime";
import { Divider } from "primereact/divider";

export interface IReportingLayoutProps {
  categoryId: number;
}

const ReportingLayout = ({ categoryId }: IReportingLayoutProps) => {
  const reports = useGlobalQuery("Reports", "getAllByCategory", categoryId, {
    enabled: !!categoryId,
  });

  const renderIcon = React.useCallback((reportTypeId: number) => {
    if (!reportTypeId) return "ban";
    return reportTypeId === ReportsTypes.TICKET ? "receipt" : "report";
  }, []);

  return (
    <div className="flex flex-column lg:flex-row gap-2 flex-1 h-full">
      <div className="flex w-full flex-column lg:w-16rem surface-card border-round shadow-2 select-none">
        <Divider className="mb-0 mt-1 z-0">
          <span className="text-sm font-semibold text-color">
            Menu de reportes
          </span>
        </Divider>
        <ul className="list-none w-full p-2 m-0 overflow-auto">
          {reports.data?.map((report) => {
            return (
              <NavLink
                items={[]}
                key={report.ReporteId}
                to={`${report.ReporteId}`}
                title={report.ReporteNombre ?? ""}
                icon={renderIcon(report.ReporteTipoId)}
              />
            );
          })}
        </ul>
      </div>
      <div className="flex flex-1 h-full">
        <Outlet />
      </div>
    </div>
  );
};

export default ReportingLayout;
