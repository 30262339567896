import React from "react";
import { group } from "radash";
import { useGlobalQuery } from "@hooks";
import { IStrategicPlans } from "@interfaces";
import { Dropdown } from "primereact/dropdown";
import { Link, useNavigate } from "react-router";
import { useAuthPermissions } from "@nubeteck/auth";
import { Reducers, Statuses, StrategicPlansTypes } from "@core";
import { EmptyMessage, ConfirmDialog, FiltersDrawer } from "@components";
import { Datejs, ArrayUtils, EventUtils, ObjectUtils } from "@nubeteck/utils";
import {
  TreeNode,
  StatusTag,
  CardTable,
  PaginatorState,
  ITreeTableColumnsProps,
} from "@nubeteck/prime";

import { Permissions } from "../../core";
import { PeuPoaDialog } from "../../components";
import { useCostCenterQuery } from "../../hooks";
import { CostsCenterRoutes, CostsCenterRouteNames } from "../../routes";

const AREA_FILTER_DEFAULT = "fcPlanesEstrategicosBuscar";

const PeuPoaPage = () => {
  const navigate = useNavigate();
  const permissions = useAuthPermissions();

  const relations = useGlobalQuery("StrategicPlans", "getAllRelations");
  const plansTypes = useGlobalQuery("StrategicPlans", "getAllTypes");
  const costCenters = useCostCenterQuery("CostsCenter", "getAllByUser");
  const filters = useGlobalQuery(
    "Filters",
    "getAllByArea",
    AREA_FILTER_DEFAULT,
  );

  const [filterSelected, setFilterSelected] = React.useState<
    number | undefined
  >();
  const [paginator, setPaginator] = React.useState<PaginatorState>({
    page: 0,
    first: 0,
    rows: 100,
  });

  const strategicPlans = useGlobalQuery(
    "StrategicPlans",
    "getAllByFilter",
    {
      page: paginator.page,
      pageSize: paginator.rows,
      filterId: filterSelected ?? 0,
    },
    { enabled: !!filterSelected },
  );

  const [planTypeId, setPlanTypeId] = React.useState<null | number>(null);
  const [parentPlanId, setParentPlanId] = React.useState<null | number>(null);
  const [filterSettingsVisible, setFilterSettingsVisible] =
    React.useState(false);
  const [costsCenterSelected, setCostsCenterSelected] = React.useState<
    null | number
  >(null);
  const [state, dispatch] = React.useReducer(Reducers.DialogReducer, {
    id: 0,
    open: false,
  });

  React.useEffect(() => {
    setFilterSelected(4209); // TODO: Fix this
  }, [filters.data]);

  const renderEmptyMessage = React.useCallback(() => {
    return <EmptyMessage message="No hay planes estratégicos agregados" />;
  }, []);

  const columns: ITreeTableColumnsProps<IStrategicPlans>[] = [
    {
      expander: true,
      field: "Nombre",
      header: "Nombre",
      style: { minWidth: "200px" },
      body: ({ data, label }) => {
        const isPeu = data?.TipoId === StrategicPlansTypes.PEU;
        if (
          data?.TipoId === StrategicPlansTypes.PEI ||
          !permissions.hasPermission(
            isPeu
              ? Permissions.SEE_PEU_DETAILS_PAGE
              : Permissions.SEE_POA_DETAILS_PAGE,
          )
        )
          return label;
        return (
          <Link
            className="no-underline"
            to={`.${CostsCenterRoutes[
              CostsCenterRouteNames.PEU_POA_DETAILS_PAGE
            ].create({
              id: `${data?.PlanEstrategicoId}`,
            })}${
              !isPeu &&
              data.EstadoId === Statuses.DRAFT_CODE &&
              permissions.hasPermission(Permissions.SEE_POA_ACTIVITIES_PAGE)
                ? CostsCenterRoutes[
                    CostsCenterRouteNames.POA_ACTIVITIES_PAGE
                  ].create({})
                : ""
            }`}
          >
            {label}
          </Link>
        );
      },
    },
    {
      header: "Tipo",
      field: "TipoNombre",
      style: { minWidth: "200px" },
    },
    {
      header: "Responsable",
      field: "ResponsableNombre",
      style: { minWidth: "200px" },
      body: ({ data }) => {
        return data?.TipoId === StrategicPlansTypes.PEI
          ? "-"
          : data?.ResponsableNombre;
      },
    },
    {
      sortable: true,
      field: "FechaInicio",
      header: "Fecha de Inicio",
      style: { minWidth: "200px" },
      body: ({ data }) =>
        Datejs(data?.FechaInicio).format(
          data.TipoId !== StrategicPlansTypes.POA ? "L" : "YYYY",
        ),
    },
    {
      sortable: true,
      field: "FechaFin",
      header: "Fecha final",
      style: { minWidth: "200px" },
      body: ({ data }) =>
        Datejs(data?.FechaFin).format(
          data.TipoId !== StrategicPlansTypes.POA ? "L" : "YYYY",
        ),
    },
    {
      header: "Estado",
      field: "EstadoNombre",
      style: { minWidth: "200px" },
      body: ({ data }) => <StatusTag status={data?.EstadoNombre ?? "Activo"} />,
    },
  ];

  const filtersOptions = React.useMemo(() => {
    if (!filters.data?.length) return [];
    return ArrayUtils.selectLabelValue(
      filters.data ?? [],
      "Nombre",
      "FiltroId",
    );
  }, [filters.data]);

  const costCentersOptions = React.useMemo(() => {
    if (!costCenters.data?.length) return [];
    return ArrayUtils.selectLabelValue(
      costCenters.data ?? [],
      "Nombre",
      "CentroCostoId",
    );
  }, [costCenters]);

  const strategicPlansFiltered = React.useMemo(() => {
    if (!strategicPlans.data?.Data?.length) return [];
    return strategicPlans.data?.Data.filter(
      (plan) => plan.CentroCostoId === costsCenterSelected,
    );
  }, [costsCenterSelected, strategicPlans.data?.Data]);

  const haveRelationPei = React.useCallback(
    (peiId: number) => {
      const items = relations.data?.filter(
        (relation) => relation.PlanEstrategicoPadreId === peiId,
      );
      const exist = items?.some((item) => {
        return strategicPlansFiltered.some(
          (plan) => plan.PlanEstrategicoId === item.PlanHijoId,
        );
      });
      return !!exist;
    },
    [relations.data, strategicPlansFiltered],
  );

  const haveEnoughRelationPeu = React.useCallback(
    (peuId: number) => {
      const peu = strategicPlans.data?.Data.find(
        (plan) => plan.PlanEstrategicoId === peuId,
      );
      const years = Datejs(peu?.FechaFin).diff(peu?.FechaInicio, "year");
      const items = relations.data?.filter(
        (relation) => relation.PlanEstrategicoPadreId === peuId,
      );
      const itemsCenterCost = strategicPlansFiltered.filter((plan) =>
        items?.some((item) => item.PlanHijoId === plan.PlanEstrategicoId),
      );

      return (itemsCenterCost.length ?? 0) > years;
    },
    [relations.data, strategicPlans.data?.Data, strategicPlansFiltered],
  );

  const value = React.useMemo(() => {
    const plansById = ObjectUtils.keyBy(
      strategicPlansFiltered ?? [],
      "PlanEstrategicoId",
    );
    const grouped = group(
      relations.data ?? [],
      (item) => item.PlanEstrategicoPadreId,
    );

    const build = (
      plan: IStrategicPlans,
    ): undefined | TreeNode<IStrategicPlans> => {
      if (!plan) return undefined;
      return {
        data: plan,
        key: plan?.PlanEstrategicoId ?? 0,
        children: grouped[plan?.PlanEstrategicoId]
          ?.map((relation) => build(plansById[relation.PlanHijoId]))
          .filter((plan) => !!plan) as TreeNode<IStrategicPlans>[],
      };
    };

    const formatter = (
      trees: TreeNode<IStrategicPlans>[],
      prefix: string = "",
    ): TreeNode<IStrategicPlans>[] => {
      return trees?.map((tree, index) => {
        const id = prefix ? `${prefix}.${index + 1}` : `${index + 1}`;
        const label = `${id}. ${tree.data.Nombre}`;
        const children = formatter(tree.children, id);
        return {
          ...tree,
          id,
          label,
          children,
          data: {
            ...tree.data,
            FechaFin: Datejs(tree.data.FechaFin).toDate(),
            FechaInicio: Datejs(tree.data.FechaInicio).toDate(),
          },
        };
      });
    };

    return formatter(
      strategicPlans.data?.Data.reduce<TreeNode<IStrategicPlans>[]>(
        (acc, plan) => {
          if (plan.TipoId !== StrategicPlansTypes.PEI) return acc;
          if (plan.EstadoId !== Statuses.ACTIVE_CODE) return acc;
          return [...acc, build(plan) as TreeNode<IStrategicPlans>];
        },
        [],
      ) ?? [],
    );
  }, [relations.data, strategicPlans.data?.Data, strategicPlansFiltered]);

  return (
    <>
      <div>
        <CardTable
          scrollable
          type="tree"
          columns={columns}
          rows={paginator.rows}
          first={paginator.first}
          valueFilter={filterSelected}
          filterOptions={filtersOptions}
          isLoadingSearchText={filters.isPending}
          onRefresh={() => strategicPlans.refetch()}
          value={costsCenterSelected ? (value ?? []) : []}
          onPaginationChange={(event) => setPaginator(event)}
          loading={strategicPlans.isFetching || filters.isPending}
          onSelectFilterOption={(value) => setFilterSelected(value)}
          actionColumnProps={{
            style: { width: "2.5rem" },
          }}
          onClickFilterSettings={EventUtils.callEvent(
            setFilterSettingsVisible,
            true,
          )}
          emptyMessage={
            costsCenterSelected
              ? renderEmptyMessage()
              : "Selecciona un centro de costo para visualizar los planes estratégicos"
          }
          headActions={[
            <Dropdown
              showClear
              key="DropdownCentroCosto"
              className="p-inputtext-sm"
              value={costsCenterSelected}
              options={costCentersOptions}
              loading={costCenters.isPending}
              placeholder="Selecciona un centro de costo"
              onChange={(e) => setCostsCenterSelected(e.value)}
            />,
          ]}
          tableActions={({ data }) => {
            const item = plansTypes.data?.find(
              (item) => item.PlanesEstrategicosTipoId === data.TipoId,
            );
            const isPei = data.TipoId === StrategicPlansTypes.PEI;
            const isPeu = data.TipoId === StrategicPlansTypes.PEU;
            const isActive = data.EstadoId === Statuses.ACTIVE_CODE;
            const relationPei = isPei
              ? haveRelationPei(data.PlanEstrategicoId)
              : false;
            const relationPeu = isPeu
              ? haveEnoughRelationPeu(data.PlanEstrategicoId)
              : false;
            return [
              ...(plansTypes.data ?? []).slice(1).map((planType) => {
                return {
                  icon: "script-plus",
                  label: `Agregar ${planType.Nombre}`,
                  onClick: () => {
                    setParentPlanId(data.PlanEstrategicoId);
                    setPlanTypeId(planType.PlanesEstrategicosTipoId);
                    dispatch({ payload: 0, type: "OPEN_DIALOG" });
                  },
                  disabled:
                    (item?.Nivel ?? 0) + 1 !== planType.Nivel ||
                    relationPei ||
                    relationPeu ||
                    !isActive ||
                    !permissions.hasPermission(
                      planType.PlanesEstrategicosTipoId ===
                        StrategicPlansTypes.PEU
                        ? Permissions.CREATE_PEU
                        : Permissions.CREATE_POA,
                    ),
                };
              }),
              {
                icon: "pencil",
                label: "Editar",
                disabled:
                  isActive ||
                  !permissions.hasPermission(
                    isPeu ? Permissions.EDIT_PEU : Permissions.EDIT_POA,
                  ),
                onClick: () => {
                  const relation = relations.data?.find(
                    (relation) =>
                      relation.PlanHijoId === data.PlanEstrategicoId,
                  );
                  dispatch({
                    type: "OPEN_DIALOG",
                    payload: data.PlanEstrategicoId,
                  });
                  setParentPlanId(relation?.PlanEstrategicoPadreId ?? 0);
                },
              },
              {
                icon: "activity",
                label: "Actividades",
                disabled:
                  isPeu ||
                  isActive ||
                  !permissions.hasPermission(
                    Permissions.SEE_POA_ACTIVITIES_PAGE,
                  ),
                onClick: () => {
                  navigate(
                    `.${CostsCenterRoutes[
                      CostsCenterRouteNames.PEU_POA_DETAILS_PAGE
                    ].create({
                      id: `${data.PlanEstrategicoId}`,
                    })}${CostsCenterRoutes[
                      CostsCenterRouteNames.POA_ACTIVITIES_PAGE
                    ].create({})}`,
                  );
                },
              },
              {
                label: "Detalles",
                icon: "list-details",
                disabled:
                  isPei ||
                  !permissions.hasPermission(
                    isPeu
                      ? Permissions.SEE_PEU_DETAILS_PAGE
                      : Permissions.SEE_POA_DETAILS_PAGE,
                  ),
                onClick: () => {
                  navigate(
                    `.${CostsCenterRoutes[
                      CostsCenterRouteNames.PEU_POA_DETAILS_PAGE
                    ].create({ id: `${data.PlanEstrategicoId}` })}`,
                  );
                },
              },
            ];
          }}
        />
      </div>
      <PeuPoaDialog
        visible={state.open}
        planTypeId={planTypeId}
        strategicPlanId={state.id}
        parentPlanId={parentPlanId}
        costsCenterId={costsCenterSelected}
        onHide={() => {
          setPlanTypeId(null);
          setParentPlanId(null);
          dispatch({ type: "CLOSE_DIALOG" });
        }}
      />
      <FiltersDrawer
        area={AREA_FILTER_DEFAULT}
        visible={filterSettingsVisible}
        onHide={EventUtils.callEvent(setFilterSettingsVisible, false)}
      />
      <ConfirmDialog />
    </>
  );
};

export default PeuPoaPage;
