import React from "react";
import { Icon } from "@nubeteck/icons";
import { Card } from "@nubeteck/prime";
import { useGlobalQuery } from "@hooks";
import { BlockUI } from "primereact/blockui";
import { TabView, TabPanel } from "primereact/tabview";
import { ProgressSpinner } from "primereact/progressspinner";

import { IndicatorTab } from "../indicator-tab";
import { SchedulesTab } from "../schedules-tab";

export interface IActivityDetailsTabsProps {
  activityId: number;
}

const ActivityDetailsTabs = ({ activityId }: IActivityDetailsTabsProps) => {
  const indicatorTypes = useGlobalQuery(
    "StrategicPlansIndicator",
    "getAllTypes",
  );
  const activity = useGlobalQuery(
    "StrategicPlansActivities",
    "getById",
    activityId,
    { enabled: !!activityId },
  );

  const loading = activity.data
    ? activity.isFetching || indicatorTypes.isFetching
    : false;

  return (
    <BlockUI
      blocked={!activityId}
      template={
        loading ? (
          <ProgressSpinner
            strokeWidth="5"
            animationDuration="1s"
            className="w-full w-3rem h-3rem"
          />
        ) : undefined
      }
    >
      <Card
        hideContentPadding
        title={
          activity.data
            ? `Actividad '${activity.data?.Actividad ?? ""}' seleccionada.`
            : "Selecciona una actividad"
        }
      >
        <TabView className="p-custom-tabview" panelContainerClassName="p-0">
          <TabPanel
            header="Cronogramas"
            leftIcon={<Icon size={20} name="calendar" className="mr-1" />}
          >
            <SchedulesTab activity={activity.data} />
          </TabPanel>
          {indicatorTypes.data?.map((type) => {
            const title = `${type.TipoIndicadorNombre?.replace("Indicador", "Indicadores")}`;
            return (
              <TabPanel
                header={title}
                key={type.TipoIndicadorId}
                leftIcon={
                  <Icon size={20} className="mr-1" name="report-search" />
                }
              >
                <IndicatorTab
                  title={title}
                  indicatorType={type}
                  activityId={activity.data?.ActividadId ?? 0}
                />
              </TabPanel>
            );
          })}
        </TabView>
      </Card>
    </BlockUI>
  );
};

export default ActivityDetailsTabs;
