import React from "react";
import { FormRow, DropdownSupplier } from "@components";
import { Form, useForm, Calendar, useWatch } from "@nubeteck/forms";

import { PurchaseInvoiceModel } from "../../models";
import { useAccountsPayableQuery } from "../../hooks";
import { IPurchaseInvoiceForm } from "../../interfaces";

const PurchaseInvoiceEdit = () => {
  // **State Variables**
  //Empty

  // **Queries**
  const updateFormValues = useAccountsPayableQuery(
    "PurchaseInvoices",
    "getUpdate",
  );

  // **Form Initialization**
  const form = useForm<Omit<IPurchaseInvoiceForm, "SelectOptions">>();

  // **Derived State and Watchers**
  const options = updateFormValues.data?.SelectOptions;
  const supplierId = useWatch({
    name: "ProveedorId",
    control: form.control,
  });
  const selectedSupplier = React.useMemo(() => {
    return options?.ProveedoresSelect.find(
      (supplier) => supplier.ProveedorId === supplierId,
    );
  }, [options?.ProveedoresSelect, supplierId]);

  // **Functions**
  const resetFormWithUpdateValues = React.useCallback(() => {
    if (!updateFormValues.data) return;
    const updateValues = PurchaseInvoiceModel.from?.(updateFormValues.data);
    if (!updateValues) return;
    form.reset(updateValues, { keepDefaultValues: true });
  }, [updateFormValues.data, form]);

  const suppliersOptions = React.useMemo(() => {
    if (!options?.ProveedoresSelect?.length) return [];
    return options.ProveedoresSelect.map((supplier) => ({
      value: supplier.ProveedorId,
      src: supplier.ProveedorFoto,
      label: supplier.ProveedorNombre,
    }));
  }, [options?.ProveedoresSelect]);

  // **Effects**
  React.useEffect(() => {
    resetFormWithUpdateValues();
  }, [resetFormWithUpdateValues]);

  return (
    <div className="h-full flex">
      <Form form={form} className="flex-1 overflow-hidden">
        <FormRow>
          <DropdownSupplier
            options={suppliersOptions}
            selectedSupplier={selectedSupplier}
            // loading={updateFormValues.isFetching || isPurchaseInvoicePending}
            loading={updateFormValues.isFetching}
          />
          <Calendar
            name="Fecha"
            label="Fecha"
            mask="99/99/9999"
            showButtonBar={true}
            dateFormat="dd/mm/yy"
            fieldClassName="flex-1"
            className="p-inputtext-sm"
            // disabledInput={updateFormValues.isFetching || isPurchaseInvoicePending}
            disabledInput={updateFormValues.isFetching}
          />
        </FormRow>
      </Form>
    </div>
  );
};

export default PurchaseInvoiceEdit;
