import API from "@lib/api";
import { BaseApi } from "@api";
import { Messages } from "@interfaces";
import { Datejs } from "@nubeteck/utils";

import { IDataSource, IDataSourceField } from "../interfaces";

export class DataSourcesApi extends BaseApi {
  protected static api = API({ namespace: "Fuentes" });
  protected static messages: Messages<typeof DataSourcesApi> = {};

  public static async getAll() {
    const dataSources = await this.api.get<IDataSource[]>("GetAll");
    return dataSources.data;
  }

  public static async getById(id: number) {
    const dataSource = await this.api.get<IDataSource>(`GetById/${id}`);
    return dataSource.data;
  }

  public static async create(data: IDataSource) {
    const dataSource = await this.api.post("Create", data);
    return dataSource.data;
  }

  public static async delete(id: number) {
    return await this.api.delete(`Delete/${id}`);
  }

  public static async update(data: Partial<IDataSource>) {
    const dataSource = await this.api.put(`Update/${data.FuenteId}`, data);
    return dataSource.data;
  }

  public static async getCampos(id: number) {
    const fields = await this.api.get<IDataSourceField[]>(
      `GetCamposByFuente/${id}`,
    );
    return fields.data;
  }

  public static async getData(props: { id: number; date1: Date; date2: Date }) {
    const date1Formatted = Datejs(props.date1).format("YYYY-MM-DD");
    const date2Formatted = Datejs(props.date2).format("YYYY-MM-DD");
    const result = await this.api.get(
      `GetDatosByFuente?FuenteId=${props.id}&Fecha1=${date1Formatted}&Fecha2=${date2Formatted}`,
    );
    return result.data;
  }
}
