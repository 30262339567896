import { param, route } from "typesafe-react-router";

export enum AccountsPayableRouteNames {
  DETAILS = "DETAILS",
  EDIT = "EDIT",
  DASHBOARD_PAGE = "DASHBOARD_PAGE",
  ACCOUNTS_PAYABLE = "ACCOUNTS_PAYABLE",
  PAYMENT_REQUESTS = "PAYMENT_REQUESTS",
  PURCHASE_DOCUMENTS = "PURCHASE_DOCUMENTS",
  RECURRING = "RECURRING",
  PURCHASE_INVOICE = "PURCHASE_INVOICE",
  PURCHASE_ORDER = "PURCHASE_ORDER",
  PURCHASE_DELIVERY_NOTE = "PURCHASE_DELIVERY_NOTE",
  PURCHASE_INVOICE_DETAIL = "PURCHASE_INVOICE_DETAIL",
  PURCHASE_ORDER_DETAIL = "PURCHASE_ORDER_DETAIL",
  PURCHASE_DELIVERY_NOTE_DETAIL = "PURCHASE_DELIVERY_NOTE_DETAIL",
  DOCUMENT_UPLOAD = "DOCUMENT_UPLOAD",
  PROVIDER_PAGE = "PROVIDER_PAGE",
  PROVIDER_DETAILS_PAGE = "PROVIDER_DETAILS_PAGE",
  PROVIDER_FORM_PAGE = "PROVIDER_FORM_PAGE",
}

export const AccountsPayableRoutes = {
  [AccountsPayableRouteNames.DETAILS]: route(param("id")),
  [AccountsPayableRouteNames.RECURRING]: route("recurring"),
  [AccountsPayableRouteNames.ACCOUNTS_PAYABLE]: route("pay"),
  [AccountsPayableRouteNames.EDIT]: route("edit", param("id")),
  [AccountsPayableRouteNames.PROVIDER_PAGE]: route("provider"),
  [AccountsPayableRouteNames.DASHBOARD_PAGE]: route("accounts-payable"),
  [AccountsPayableRouteNames.DOCUMENT_UPLOAD]: route("document-upload"),
  [AccountsPayableRouteNames.PAYMENT_REQUESTS]: route("payment-requests"),
  [AccountsPayableRouteNames.PURCHASE_DOCUMENTS]: route("purchase-documents"),
  [AccountsPayableRouteNames.PROVIDER_FORM_PAGE]: route(
    "provider-form",
    param("id"),
  ),
  [AccountsPayableRouteNames.PURCHASE_ORDER_DETAIL]: route(
    "purchase-order",
    param("id"),
  ),
  [AccountsPayableRouteNames.PROVIDER_DETAILS_PAGE]: route(
    "provider-details",
    param("id"),
  ),
  [AccountsPayableRouteNames.PURCHASE_INVOICE_DETAIL]: route(
    "purchase-invoice",
    param("id"),
  ),
  [AccountsPayableRouteNames.PURCHASE_ORDER]: route(
    "purchase-order",
    param("id"),
    "edit",
  ),
  [AccountsPayableRouteNames.PURCHASE_INVOICE]: route(
    "purchase-invoice",
    param("id"),
    "edit",
  ),
  [AccountsPayableRouteNames.PURCHASE_DELIVERY_NOTE_DETAIL]: route(
    "purchase-delivery-note",
    param("id"),
  ),
  [AccountsPayableRouteNames.PURCHASE_DELIVERY_NOTE]: route(
    "purchase-delivery-note",
    param("id"),
    "edit",
  ),
};

export const EDIT_ROUTE = AccountsPayableRoutes[AccountsPayableRouteNames.EDIT]
  .template()
  .slice(1);

export const DETAILS_ROUTE = AccountsPayableRoutes[
  AccountsPayableRouteNames.DETAILS
]
  .template()
  .slice(1);
