import React from "react";
import { omit } from "radash";
import { IUser } from "@interfaces";
import { useForm } from "react-hook-form";
import { Button } from "primereact/button";
import { ArrayUtils } from "@nubeteck/utils";
import { useQueryClient } from "@tanstack/react-query";
import { useGlobalQuery, useGlobalMutation } from "@hooks";

import { Form } from "../../form";
import { Dialog, IDialogProps } from "../dialog";
import {
  Password,
  Dropdown,
  InputText,
  MultiSelect,
  InputSwitch,
  SelectButton,
} from "../../inputs";

export interface IUserFormDialogProps extends IDialogProps {
  userId: number;
}

type FormValues = IUser & {
  RolesIds: number[];
  Password: string;
};

const model = {
  from: (data: IUser): FormValues => {
    return {
      Password: "",
      Email: data.Email,
      UserId: data.UserId,
      FullName: data.FullName,
      IsActive: data.IsActive,
      Username: data.Username,
      RolesIds: data.RolesIds ?? [],
      IsSystemUser: data.IsSystemUser,
      SucursalActual: data.SucursalActual,
    };
  },
  to: (data: FormValues): IUser => {
    return {
      Email: data.Email,
      RolesIds: data.RolesIds,
      FullName: data.FullName,
      Password: data.Password,
      Username: data.Username,
      UserId: data.UserId ?? 0,
      IsActive: data.IsActive ?? 0,
      IsSystemUser: data.IsSystemUser,
      SucursalActual: data.SucursalActual ?? 0,
    };
  },
};

const UserFormDialog = ({ userId, ...props }: IUserFormDialogProps) => {
  const queryClient = useQueryClient();

  const createUser = useGlobalMutation("Users", "create");
  const updateUser = useGlobalMutation("Users", "update");
  const getCreate = useGlobalQuery("Users", "getCreate");
  const getUpdate = useGlobalQuery("Users", "getUpdate", userId, {
    enabled: !!userId,
  });

  const form = useForm<FormValues>();
  const { reset } = form;

  React.useEffect(() => {
    if (userId && getUpdate.data) {
      reset(model.from(getUpdate.data), { keepDefaultValues: true });
    }
  }, [userId, getUpdate.data, reset]);

  React.useEffect(() => {
    if (!userId && getCreate.data) {
      reset(
        omit(getCreate.data, [
          "Validaciones",
          "RolesSelect",
          "SucursalesSelect",
        ]),
        { keepDefaultValues: false },
      );
    }
  }, [getCreate.data, reset, userId]);

  const handleSuccess = () => {
    form.reset();
    props.onHide();
    queryClient.invalidateQueries({ queryKey: ["Users"] });
  };

  const onSubmit = (values: FormValues) => {
    if (userId) {
      return updateUser.mutateAsync(model.to(values), {
        onSuccess: handleSuccess,
      });
    }
    return createUser.mutateAsync(model.to(values), {
      onSuccess: handleSuccess,
    });
  };

  const roles = React.useMemo(() => {
    if (userId) return getUpdate.data?.RolesSelect;
    else return getCreate.data?.RolesSelect;
  }, [getCreate.data?.RolesSelect, getUpdate.data?.RolesSelect, userId]);

  const rolesOptions = React.useMemo(() => {
    if (!roles?.length) return [];
    return ArrayUtils.selectLabelValue(roles, "RolName", "RolId");
  }, [roles]);

  const branches = React.useMemo(() => {
    if (userId) return getUpdate.data?.SucursalesSelect;
    else return getCreate.data?.SucursalesSelect;
  }, [
    getCreate.data?.SucursalesSelect,
    getUpdate.data?.SucursalesSelect,
    userId,
  ]);

  const branchesOptions = React.useMemo(() => {
    if (!branches?.length) return [];
    return ArrayUtils.selectLabelValue(
      branches,
      "SucursalNombre",
      "SucursalId",
    );
  }, [branches]);

  const loading = React.useMemo(() => {
    if (userId) return getUpdate.isPending;
    return getCreate.isPending;
  }, [userId, getCreate.isPending, getUpdate.isPending]);

  const validations = React.useMemo(() => {
    if (userId) return getUpdate.data?.Validaciones;
    else return getCreate.data?.Validaciones;
  }, [getCreate.data?.Validaciones, getUpdate.data?.Validaciones, userId]);

  return (
    <Dialog
      {...props}
      loading={loading}
      className="w-full md:w-10 lg:w-8"
      subtitle="Rellena los datos del formulario"
      onHide={() => {
        if (userId) form.reset();
        props.onHide();
      }}
      title={
        userId
          ? `Editando usuario '${getUpdate.data?.FullName ?? ""}'`
          : "Creando nuevo usuario"
      }
      footer={
        <div className="flex flex-1 justify-content-end gap-2">
          <Button
            size="small"
            label="Cancelar"
            severity="secondary"
            onClick={() => {
              form.reset();
              props.onHide();
            }}
          />
          <Button
            size="small"
            onClick={form.handleSubmit(onSubmit)}
            label={userId ? "Guardar cambios" : "Crear usuario"}
            loading={loading || createUser.isPending || updateUser.isPending}
          />
        </div>
      }
    >
      <Form form={form}>
        <div className="flex flex-column lg:flex-row lg:gap-3">
          <InputText
            name="FullName"
            fieldClassName="flex-1"
            label="Nombre completo"
            className="p-inputtext-sm"
            rules={validations?.FullName}
            placeholder="Escribe el nombre completo del usuario"
          />
          <InputText
            name="Username"
            fieldClassName="flex-1"
            autoComplete="username"
            label="Nombre de usuario"
            className="p-inputtext-sm"
            rules={validations?.Username}
            placeholder="Escribe el nombre de usuario"
          />
        </div>
        <div className="flex flex-column lg:flex-row lg:gap-3">
          <InputText
            name="Email"
            autoComplete="email"
            fieldClassName="flex-1"
            label="Correo electrónico"
            className="p-inputtext-sm"
            rules={validations?.Email}
            placeholder="Escribe el correo electrónico"
          />
          {!userId && (
            <Password
              name="Password"
              label="Contraseña"
              fieldClassName="flex-1"
              className="p-inputtext-sm"
              rules={validations?.Password}
              placeholder="*****************"
            />
          )}
        </div>
        <MultiSelect
          label="Roles"
          name="RolesIds"
          options={rolesOptions}
          fieldClassName="flex-1"
          className="p-inputtext-sm"
          rules={validations?.RolesIds}
          placeholder="Selecciona uno o varios roles"
        />
        <Dropdown
          label="Sucursal"
          name="SucursalActual"
          fieldClassName="flex-1"
          options={branchesOptions}
          className="p-inputtext-sm"
          placeholder="Selecciona una sucursal"
          helperText="Esta sera la sucursal por defecto del usuario."
        />
        <div className="flex flex-column lg:flex-row lg:gap-3">
          <SelectButton
            name="IsActive"
            fieldClassName="flex-1"
            label="Estado del usuario"
            className="p-inputtext-sm"
            rules={validations?.IsActive}
            placeholder="Escribe el correo electrónico"
            options={[
              { value: true, label: "Activo" },
              { value: false, label: "Inactivo" },
            ]}
          />
          <InputSwitch
            name="IsSystemUser"
            fieldClassName="flex-1"
            label="¿Usuario del sistema?"
            rules={validations?.IsSystemUser}
          />
        </div>
      </Form>
    </Dialog>
  );
};

export default UserFormDialog;
