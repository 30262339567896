import clsx from "clsx";
import React from "react";

//! ANTES DE MODIFICAR ESTE COMPONENTE CONSULTE CON SUS DESARROLLADORES.
//! ANTES DE MODIFICAR ESTE COMPONENTE CONSULTE CON SUS DESARROLLADORES.
//! ANTES DE MODIFICAR ESTE COMPONENTE CONSULTE CON SUS DESARROLLADORES.

export interface IInputGroupProps {
  addonAfter?: React.ReactNode;
  addonBefore?: React.ReactNode;
}

const InputGroup = ({
  children,
  addonAfter,
  addonBefore,
}: React.PropsWithChildren<IInputGroupProps>) => {
  const renderAddon = (addon: React.ReactNode) =>
    addon &&
    React.isValidElement<React.HTMLAttributes<HTMLElement>>(addon) &&
    React.cloneElement<React.HTMLAttributes<HTMLElement>>(addon, {
      className: clsx(addon.props.className, "p-inputgroup-addon"),
    });

  return (
    <div className="p-inputgroup">
      {renderAddon(addonBefore)}
      {children}
      {renderAddon(addonAfter)}
    </div>
  );
};

export default InputGroup;
