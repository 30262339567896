// All permissions for this module.
// Example of the constant:
// export const EXAMPLE_PERM = "Perm Id"

export const EDIT_PEI = 9;
export const CREATE_PEI = 8;
export const NULLIFY_PEI = 10;
export const RESTORE_PEI = 30;
export const SEE_LOGS_PEI = 21;
export const SEE_PEI_MODULE = 7;
export const EDIT_DETAILS_PEI = 23;
export const ORDER_DETAILS_PEI = 27;
export const APPROVE_REJECT_PEI = 20;
export const CREATE_DETAILS_PEI = 24;
export const NULLIFY_DETAILS_PEI = 25;
export const RESTORE_DETAILS_PEI = 26;
export const SEE_PEI_DETAILS_PAGE = 19;
export const SEE_REVIEWS_COMMENTS = 22;
export const SEE_LOGS_DETAILS_PEI = 28;
export const SEE_ADDITIONAL_INFO_DETAILS_PEI = 29;

export const SEE_PEI_SETTINGS = 75;

export const SEE_TEMPLATES_SETTINGS_PAGE = 74;
export const EDIT_TEMPLATES_SETTINGS = 77;
export const CREATE_TEMPLATES_SETTINGS = 76;
export const NULLIFY_TEMPLATES_SETTINGS = 78;
export const RESTORE_TEMPLATES_SETTINGS = 79;

export const SEE_TEMPLATES_INDICATOR_SETTINGS_PAGE = 80;
export const EDIT_TEMPLATES_INDICATOR_SETTINGS = 82;
export const CREATE_TEMPLATES_INDICATOR_SETTINGS = 81;
export const NULLIFY_TEMPLATES_INDICATOR_SETTINGS = 83;
export const RESTORE_TEMPLATES_INDICATOR_SETTINGS = 84;

export const SEE_DETAILS_TYPES_SETTINGS_PAGE = 85;
export const EDIT_DETAILS_TYPES_SETTINGS = 87;
export const CREATE_DETAILS_TYPES_SETTINGS = 86;
// export const NULLIFY_DETAILS_TYPES_SETTINGS = 88;
// export const RESTORE_DETAILS_TYPES_SETTINGS = 89;

export const SEE_DETAILS_FIELDS_SETTINGS_PAGE = 90;
export const EDIT_DETAILS_FIELDS_SETTINGS = 92;
export const CREATE_DETAILS_FIELDS_SETTINGS = 91;
// export const NULLIFY_DETAILS_FIELDS_SETTINGS = 93;
// export const RESTORE_DETAILS_FIELDS_SETTINGS = 94;
