import React from "react";
import { group } from "radash";
import { SettingTypes } from "@core";
import { Card } from "@nubeteck/prime";
import { Button } from "primereact/button";
import { Form, ConfigurationInput } from "@components";
import { TabView, TabPanel } from "primereact/tabview";
import { useForm, SubmitHandler } from "react-hook-form";
import { useGlobalQuery, useGlobalMutation } from "@hooks";

const GeneralSettingsPage = () => {
  const { data: settings } = useGlobalQuery("GeneralSettings", "getAll");
  const categories = useGlobalQuery("GeneralSettings", "getAllCategories");
  const updateSettings = useGlobalMutation("GeneralSettings", "updateRange");

  const form = useForm({ mode: "all" });
  const { reset } = form;

  React.useEffect(() => {
    if (settings?.length) {
      reset(
        {
          ...settings?.reduce((acc, setting) => {
            const value = setting.Valor ?? setting.ValorDefecto;

            const mapping: Record<number, unknown> = {
              [SettingTypes.RADIO]: parseInt(value),
              [SettingTypes.OPTIONS]: parseInt(value),
              [SettingTypes.MULTI_OPTIONS]: value.split(",").map(parseInt),
            };

            if (mapping[setting.TipoDatoId as number]) {
              return {
                [setting.ConfiguracionId]:
                  mapping[setting.TipoDatoId as number],
                ...acc,
              };
            }

            return { [setting.ConfiguracionId]: value, ...acc };
          }, {}),
        },
        { keepDefaultValues: false },
      );
    }
  }, [settings, reset]);

  const handleReset = () => {
    form.reset();
  };

  const onSubmit: SubmitHandler<Record<string, string>> = React.useCallback(
    (configs) => {
      const result = Object.entries(configs).map(([id, value]) => ({
        Valor: `${value}`,
        ConfiguracionId: parseInt(id, 10),
      }));
      return updateSettings.mutateAsync(result);
    },
    [updateSettings],
  );

  const settingsGrouped = group(settings ?? [], (item) => item.CategoriaId);

  return (
    <Card
      hideContentPadding
      title="Configuraciones"
      subtitle="Configuraciones generales de la aplicación"
      actions={[
        <Button
          size="small"
          type="submit"
          key="UndoChanges"
          severity="secondary"
          onClick={handleReset}
          label="Deshacer cambios"
        />,
        <Button
          size="small"
          type="submit"
          key="SaveSettings"
          label="Guardar cambios"
          onClick={form.handleSubmit(onSubmit)}
        />,
      ]}
    >
      <TabView scrollable className="p-custom-tabview">
        {categories.data?.map((category) => {
          const inputs = settingsGrouped[category.CategoriaId];
          return (
            <TabPanel
              key={category.CategoriaId}
              header={category.CategoriaNombre ?? ""}
            >
              <Form form={form}>
                {inputs?.map((input) => {
                  return (
                    <ConfigurationInput
                      data={input}
                      key={input.ConfiguracionId}
                    />
                  );
                })}
              </Form>
            </TabPanel>
          );
        })}
      </TabView>
    </Card>
  );
};

export default GeneralSettingsPage;
