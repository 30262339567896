import React from "react";
import { Icon } from "@nubeteck/icons";
import { Button } from "primereact/button";
import { EventUtils } from "@nubeteck/utils";
import { debounce, isFunction } from "radash";
import { useQueryClient } from "@tanstack/react-query";
import { confirmDialog } from "primereact/confirmdialog";
import { Reducers, Statuses, StrategicPlansTypes } from "@core";
import { TreeDetail, IStrategicPlansDetails } from "@interfaces";
import {
  useGlobalQuery,
  useGlobalMutation,
  useTreeStrategicDetails,
} from "@hooks";
import {
  TreeNode,
  CardTable,
  StatusTag,
  ActionMenu,
  ActionMenuItem,
  ICardTableProps,
  ITreeTableColumnsProps,
} from "@nubeteck/prime";

import { ConfirmDialog } from "../confirm-dialog";
import {
  ChangeLogDialog,
  DetailsFieldsDialog,
  StrategicPlansDetailsDialog,
} from "../dialogs";

type CardTableProps = ICardTableProps<IStrategicPlansDetails>;

type Permissions = {
  canEdit: boolean;
  canOrder: boolean;
  canCreate: boolean;
  canNullify: boolean;
  canRestore: boolean;
  canSeeLogs: boolean;
  canSeeAdditionalInfo: boolean;
};

export interface IStrategicPlansTreeTableProps {
  title?: string;
  stateId?: number;
  templateId: number;
  typesAllowed: number[];
  firstTypeLevel?: boolean;
  permissions: Permissions;
  customActionAllowed: number[];
  details: IStrategicPlansDetails[];
  loading: CardTableProps["loading"];
  onRefresh: CardTableProps["onRefresh"];
  headActions?: CardTableProps["headActions"];
  tableActions?: (rowData: TreeNode<TreeDetail>) => ActionMenuItem[];
}

type ExpanderKey = Record<string, boolean>;

const StrategicPlansTreeTable = ({
  details,
  stateId,
  templateId,
  permissions,
  firstTypeLevel,
  typesAllowed = [],
  customActionAllowed = [],
  ...props
}: IStrategicPlansTreeTableProps) => {
  const queryClient = useQueryClient();
  const { value, searchNode, findChildrenNode } =
    useTreeStrategicDetails(details);

  const isDraft = stateId === Statuses.DRAFT_CODE;

  const changeState = useGlobalMutation("StrategicPlansDetails", "changeState");
  const detailsTypes = useGlobalQuery("StrategicPlansDetails", "getAllTypes");
  const changeOrder = useGlobalMutation("StrategicPlansDetails", "changeOrder");
  const template = useGlobalQuery(
    "StrategicPlansTemplates",
    "getById",
    templateId,
    { enabled: !!templateId },
  );

  const [searchText, setSearchText] = React.useState("");
  const [parentDetail, setParentDetail] = React.useState<null | TreeDetail>(
    null,
  );
  const [expandedKeys, setExpandedKeys] = React.useState<ExpanderKey>({});
  const [levelTypeId, setLevelTypeId] = React.useState<null | number>(null);
  const [state, dispatch] = React.useReducer(Reducers.DialogReducer, {
    id: 0,
    open: false,
  });
  const [stateField, dispatchField] = React.useReducer(Reducers.DialogReducer, {
    id: 0,
    open: false,
  });
  const [changeLogState, changeLogDispatch] = React.useReducer(
    Reducers.DialogReducer,
    { id: 0, open: false },
  );

  const invalidateDetails = React.useCallback(() => {
    queryClient.invalidateQueries({ queryKey: ["StrategicPlans"] });
  }, [queryClient]);

  const handleRestore = React.useCallback(
    (id: number) => {
      confirmDialog({
        acceptLabel: "Activar",
        header: "Activar detalle",
        acceptClassName: "p-button-sm",
        message: "¿Está seguro de que desea activar este detalle?",
        accept: async () => {
          await changeState.mutateAsync(
            { DetalleId: id, EstadoId: Statuses.ACTIVE_CODE },
            { onSuccess: invalidateDetails },
          );
        },
      });
    },
    [changeState, invalidateDetails],
  );

  const handleNullify = React.useCallback(
    (id: number) => {
      confirmDialog({
        acceptLabel: "Anular",
        header: "Anular detalle",
        message: "¿Está seguro de que desea anular este detalle?",
        accept: async () => {
          await changeState.mutateAsync(
            { DetalleId: id, EstadoId: Statuses.NULLIFIED_CODE },
            { onSuccess: invalidateDetails },
          );
        },
      });
    },
    [changeState, invalidateDetails],
  );

  const moveItem = React.useCallback(
    (detailsId: number, direction: "up" | "down", items: typeof value) => {
      const index = items.findIndex(
        (item) => item.data.DetalleId === detailsId,
      );
      if (index === -1) return items;
      const newIndex = direction === "up" ? index - 1 : index + 1;
      if (newIndex < 0 || newIndex >= items.length) return items;
      const updatedItems = [...items];
      updatedItems[index].data.Orden = newIndex + 1;
      updatedItems[newIndex].data.Orden = index + 1;
      return updatedItems;
    },
    [],
  );

  const handleOrderRows = React.useCallback(
    (parentId: null | number, detailId: number, type: "up" | "down") => {
      const nodesModeled = moveItem(
        detailId,
        type,
        findChildrenNode(value, parentId ?? 0) ?? [],
      ).map((node) => ({
        Orden: node.data.Orden,
        DetalleId: node.data.DetalleId,
      }));
      return changeOrder.mutateAsync(nodesModeled, {
        onSuccess: invalidateDetails,
      });
    },
    [changeOrder, findChildrenNode, invalidateDetails, moveItem, value],
  );

  const columns: ITreeTableColumnsProps<TreeDetail>[] = [
    {
      expander: true,
      sortable: true,
      field: "Codigo",
      header: "Código",
      style: { width: "200px" },
    },
    {
      field: "Descripcion",
      header: "Descripción",
      body: ({ data }) => (
        <>
          <b>{data.TipoNombre}:</b> {data.Descripcion}
        </>
      ),
    },
    {
      header: "Estado",
      field: "EstadoNombre",
      style: { width: "100px" },
      body: ({ data }) => <StatusTag status={data?.EstadoNombre ?? "Activo"} />,
    },
    {
      header: "Ordenar",
      field: "DetalleId",
      style: { width: "100px" },
      body: ({ data }) => {
        if (
          !isDraft ||
          data.Tipo === StrategicPlansTypes.PEI ||
          !permissions.canOrder
        )
          return null;
        const count = findChildrenNode(value, data?.PadreId ?? 0)?.length ?? 0;
        return (
          <ActionMenu
            items={[
              {
                label: "",
                icon: "arrow-up",
                disabled: data?.Orden === 1,
                onClick: () =>
                  handleOrderRows(data?.PadreId, data?.DetalleId, "up"),
              },
              {
                label: "",
                icon: "arrow-down",
                disabled: count === data?.Orden,
                onClick: () =>
                  handleOrderRows(data?.PadreId, data?.DetalleId, "down"),
              },
            ]}
          />
        );
      },
    },
  ];

  const detailsTemplates = React.useMemo(() => {
    if (!template.data?.Detalles?.length) return [];
    return template.data?.Detalles;
  }, [template.data?.Detalles]);

  const levels = React.useMemo(() => {
    if (!template.data?.Detalles?.length) return [];
    return template.data.Detalles?.map((detail) => detail.TipoDeNivelId);
  }, [template.data?.Detalles]);

  const orderCount = React.useMemo(() => {
    if (state.open) {
      return findChildrenNode(value, parentDetail?.DetalleId ?? 0)?.length ?? 0;
    }
  }, [findChildrenNode, parentDetail?.DetalleId, state.open, value]);

  const renderFirstTypeLevel = React.useCallback(() => {
    return (
      <Button
        size="small"
        key="NewStrategicPlanDetail"
        icon={<Icon size={20} name="plus" className="mr-1" />}
        label={`Agregar ${detailsTemplates[0]?.TipoNombre?.toLowerCase() || "detalle"}`}
        onClick={() => {
          setLevelTypeId(detailsTemplates[0].TipoDeNivelId);
          dispatch({ payload: 0, type: "OPEN_DIALOG" });
        }}
      />
    );
  }, [detailsTemplates]);

  const generateLevels = React.useCallback(
    (key: string) => {
      const parts = key.split(".");
      return parts.reduce<ExpanderKey>((acc, _, index) => {
        const node = searchNode(parts.slice(0, index + 1).join("."));
        acc[node?.key ?? "0"] = true;
        return acc;
      }, {});
    },
    [searchNode],
  );

  const searchExpandedKeys = React.useMemo(() => {
    const node = searchNode(searchText);
    if (node) {
      return generateLevels(`${node.id}`);
    }
  }, [generateLevels, searchNode, searchText]);

  return (
    <>
      <div className="relative">
        <CardTable<TreeDetail>
          {...props}
          type="tree"
          showGridlines
          value={value}
          loading={false}
          hideSelectFilter
          columns={columns}
          scrollable={false}
          hideFilterSettingsIcon
          onToggle={(e) => setExpandedKeys(e.value)}
          actionColumnProps={{ style: { width: 40 } }}
          onSearch={debounce({ delay: 100 }, setSearchText)}
          expandedKeys={{ ...expandedKeys, ...searchExpandedKeys }}
          headActions={[
            firstTypeLevel && isDraft ? renderFirstTypeLevel() : undefined,
            ...(props.headActions ?? []),
          ]}
          tableActions={({ data, ...treeNode }) => {
            const index =
              detailsTypes.data?.findIndex(
                (item) => item.DetalleTipoId === data.Tipo,
              ) ?? 0;
            const next = detailsTypes.data?.[index + 1];
            return [
              ...detailsTemplates.map((detail) => {
                return {
                  icon: "plus",
                  label: `Agregar ${detail.TipoNombre?.toLowerCase() || "detalle"}`,
                  onClick: () => {
                    setParentDetail(data);
                    setLevelTypeId(detail.TipoDeNivelId);
                    dispatch({ payload: 0, type: "OPEN_DIALOG" });
                  },
                  disabled:
                    data.Tipo === detail.TipoDeNivelId ||
                    next?.DetalleTipoId !== detail.TipoDeNivelId ||
                    !typesAllowed.includes(data.Tipo) ||
                    !isDraft ||
                    !permissions.canCreate,
                };
              }),
              {
                icon: "pencil",
                label: "Editar",
                disabled:
                  !levels.includes(data.Tipo) ||
                  !isDraft ||
                  !permissions.canEdit,
                onClick: () => {
                  setParentDetail(data);
                  dispatch({
                    type: "OPEN_DIALOG",
                    payload: data.DetalleId ?? 0,
                  });
                },
              },
              {
                icon: "ban",
                label: "Anular",
                iconClassName: "text-red-500",
                onClick: EventUtils.callEvent(
                  handleNullify,
                  data.DetalleId ?? 0,
                ),
                disabled:
                  data.EstadoId === Statuses.NULLIFIED_CODE ||
                  !levels.includes(data.Tipo) ||
                  !isDraft ||
                  !permissions.canNullify,
              },
              {
                icon: "plug",
                label: "Activar",
                iconClassName: "text-green-500",
                onClick: EventUtils.callEvent(
                  handleRestore,
                  data.DetalleId ?? 0,
                ),
                disabled:
                  data.EstadoId !== Statuses.NULLIFIED_CODE ||
                  !levels.includes(data.Tipo) ||
                  !isDraft ||
                  !permissions.canRestore,
              },
              {
                icon: "info-circle",
                label: "Información adicional",
                disabled: !permissions.canSeeAdditionalInfo,
                onClick: EventUtils.callEvent(dispatchField, {
                  type: "OPEN_DIALOG",
                  payload: data.DetalleId,
                }),
              },
              {
                icon: "book",
                label: "Historial de cambios",
                disabled: !permissions.canSeeLogs,
                onClick: EventUtils.callEvent(changeLogDispatch, {
                  type: "OPEN_DIALOG",
                  payload: data.DetalleId,
                }),
              },
              ...(isFunction(props?.tableActions)
                ? (props.tableActions?.({ data, ...treeNode }) ?? [])
                : (props.tableActions ?? [])
              ).map((action) => ({
                ...action,
                disabled:
                  action.disabled || !customActionAllowed.includes(data.Tipo),
              })),
            ];
          }}
        />
      </div>
      <ChangeLogDialog
        entityName="DetalleId"
        entityId={changeLogState.id}
        visible={changeLogState.open}
        onHide={EventUtils.callEvent(changeLogDispatch, {
          type: "CLOSE_DIALOG",
        })}
      />
      <StrategicPlansDetailsDialog
        visible={state.open}
        orderCount={orderCount}
        levelTypeId={levelTypeId}
        parentDetail={parentDetail}
        strategicDetailsId={state.id}
        onHide={() => {
          dispatch({ type: "CLOSE_DIALOG" });
          setParentDetail(null);
          setLevelTypeId(null);
        }}
      />
      <DetailsFieldsDialog
        detailId={stateField.id}
        visible={stateField.open}
        onHide={EventUtils.callEvent(dispatchField, {
          type: "CLOSE_DIALOG",
        })}
      />
      <ConfirmDialog />
    </>
  );
};

export default StrategicPlansTreeTable;
