import React from "react";
import { Dialog } from "@components";
import { useGlobalQuery } from "@hooks";
import { Button } from "primereact/button";
import { DialogProps } from "primereact/dialog";
import { IStrategicPlansActivitySchedule } from "@interfaces";
import { CardTable, StatusTag, IDataTableColumnsProps } from "@nubeteck/prime";

export interface IExecutionScheduleDialogProps extends DialogProps {
  activityId: number;
}

const ExecutionScheduleDialog = ({
  visible,
  activityId,
  ...props
}: IExecutionScheduleDialogProps) => {
  const activity = useGlobalQuery(
    "StrategicPlansActivities",
    "getById",
    activityId,
    { enabled: !!activityId && visible },
  );

  const schedules = useGlobalQuery(
    "StrategicPlansSchedules",
    "getAllByActivity",
    activityId,
    { enabled: !!activityId && visible },
  );

  const columns: IDataTableColumnsProps<IStrategicPlansActivitySchedule>[] = [
    { header: "Año", field: "Anio" },
    { header: "Nombre", field: "MesNombre" },
    {
      header: "Estado",
      field: "EstadoNombre",
      body: (data) => <StatusTag status={data?.EstadoNombre ?? "Activo"} />,
    },
  ];

  return (
    <Dialog
      {...props}
      visible={visible}
      className="w-full md:w-8"
      title="Cronograma de la actividad"
      subtitle={`${activity.data?.Actividad ?? "..."}`}
      footer={() => (
        <div className="flex flex-1 justify-content-end gap-2">
          <Button
            size="small"
            label="Aceptar"
            onClick={() => {
              props.onHide();
            }}
          />
        </div>
      )}
    >
      <CardTable<IStrategicPlansActivitySchedule>
        type="data"
        hideSelectFilter
        hideBorderStyles
        hideActionColumn
        paginator={false}
        columns={columns}
        tableActions={[]}
        hideFilterSettingsIcon
        value={schedules.data ?? []}
      />
    </Dialog>
  );
};

export default ExecutionScheduleDialog;
