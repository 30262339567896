import React from "react";
import { Reducers } from "@core";
import { Icon } from "@nubeteck/icons";
import { useGlobalQuery } from "@hooks";
import { ICurrency } from "@interfaces";
import { Button } from "primereact/button";
import { useNavigate } from "react-router";
import { FiltersDrawer } from "@components";
import { FilterMatchMode } from "primereact/api";
import { ArrayUtils, EventUtils } from "@nubeteck/utils";
import {
  CardTable,
  PaginatorState,
  IDataTableColumnsProps,
} from "@nubeteck/prime";

import { useAccountingQuery } from "../../hooks";
import { CurrencyDialog } from "../../components";
import {
  GeneralAccountingRoutes,
  GeneralAccountingRouteNames,
} from "../../routes";

const CurrencyPage = () => {
  const navigate = useNavigate();

  const { data: filters } = useGlobalQuery(
    "Filters",
    "getAllByArea",
    "Monedas",
  );

  const [filterSelected, setFilterSelected] = React.useState<
    number | undefined
  >();

  const [paginator, setPaginator] = React.useState<PaginatorState>({
    page: 0,
    first: 0,
    rows: 10,
  });

  const currencies = useAccountingQuery(
    "Currency",
    "getAllByFilter",
    {
      page: paginator.page,
      pageSize: paginator.rows,
      filterId: filterSelected ?? 0,
    },
    { enabled: !!filterSelected },
  );

  const [state, dispatch] = React.useReducer(Reducers.DialogReducer, {
    id: 0,
    open: false,
  });

  const [filterSettingsVisible, setFilterSettingsVisible] =
    React.useState(false);

  React.useEffect(() => {
    const defaultFilterId = filters?.find(
      (filter) => filter.EsPorDefecto,
    )?.FiltroId;
    setFilterSelected(defaultFilterId);
  }, [filters]);

  const columns: IDataTableColumnsProps<ICurrency>[] = [
    {
      filter: true,
      sortable: true,
      header: "Nombre",
      dataType: "text",
      field: "MonedaNombre",
      filterMatchMode: FilterMatchMode.STARTS_WITH,
    },
    {
      header: "ISO",
      sortable: true,
      field: "NombreISO",
    },
    {
      field: "Simbolo",
      header: "Símbolo",
    },
    {
      sortable: true,
      field: "Locale",
      header: "Locale",
    },
  ];

  const filtersOptions = React.useMemo(() => {
    if (!filters?.length) return [];
    return ArrayUtils.selectLabelValue(filters ?? [], "Nombre", "FiltroId");
  }, [filters]);

  return (
    <>
      <CardTable<ICurrency>
        type="data"
        title="Monedas"
        columns={columns}
        dataKey="MonedaId"
        rows={paginator.rows}
        first={paginator.first}
        valueFilter={filterSelected}
        filterOptions={filtersOptions}
        loading={currencies.isFetching}
        value={currencies.data?.Data ?? []}
        onRefresh={() => currencies.refetch()}
        emptyMessage="No hay monedas disponibles."
        totalRecords={currencies.data?.TotalRecords}
        onPaginationChange={(event) => setPaginator(event)}
        onSelectFilterOption={(value) => setFilterSelected(value)}
        onClickFilterSettings={EventUtils.callEvent(
          setFilterSettingsVisible,
          true,
        )}
        headActions={[
          <Button
            size="small"
            key="ButtonNewRate"
            label="Nueva moneda"
            icon={<Icon size={20} name="plus" className="mr-1" />}
            onClick={EventUtils.callEvent(dispatch, {
              payload: 0,
              type: "OPEN_DIALOG",
            })}
          />,
        ]}
        tableActions={(data) => [
          {
            icon: "pencil",
            label: "Editar",
            iconClassName: "text-primary",
            onClick: EventUtils.callEvent(dispatch, {
              type: "OPEN_DIALOG",
              payload: data.MonedaId ?? 0,
            }),
          },
          {
            label: "Detalle",
            icon: "list-details",
            disabled: data.EsPrincipal,
            iconClassName: "text-secondary",
            onClick: () => {
              navigate(
                `.${GeneralAccountingRoutes[
                  GeneralAccountingRouteNames.CURRENCY_DETAILS_PAGE
                ].create({ id: `${data.MonedaId}` })}`,
              );
            },
          },
        ]}
      />
      <CurrencyDialog
        visible={state.open}
        currencyId={state.id}
        onHide={EventUtils.callEvent(dispatch, { type: "CLOSE_DIALOG" })}
      />
      <FiltersDrawer
        area="Monedas"
        visible={filterSettingsVisible}
        onHide={EventUtils.callEvent(setFilterSettingsVisible, false)}
      />
    </>
  );
};

export default CurrencyPage;
