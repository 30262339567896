import React from "react";
import { sleep } from "radash";
import { Dialog } from "@components";
import { useForm } from "react-hook-form";
import { Button } from "primereact/button";
import { useNavigate } from "react-router";
import { StrategicPlansTypes } from "@core";
import { IStrategicPlans } from "@interfaces";
import { DialogProps } from "primereact/dialog";
import { useAuthPermissions } from "@nubeteck/auth";
import { Datejs, ArrayUtils } from "@nubeteck/utils";
import { useGlobalQuery, useGlobalMutation } from "@hooks";
import { Form, Calendar, Dropdown, InputText } from "@components";

import { Permissions } from "../../core";
import { CostsCenterRoutes, CostsCenterRouteNames } from "../../routes";

export interface IPeuPoaDialogProps extends DialogProps {
  strategicPlanId: number;
  planTypeId: null | number;
  parentPlanId: null | number;
  costsCenterId: null | number;
}

type FormValues = Omit<
  IStrategicPlans,
  "TipoId" | "FechaFin" | "FechaInicio" | "PlantillaId" | "ResponsableId"
> & {
  FechaFin?: Date;
  FechaInicio?: Date;
  TipoId: null | number;
  PlantillaId: null | number;
  PadrePlanId: null | number;
  ResponsableId: null | number;
};

const model = {
  from: (data: IStrategicPlans): FormValues => {
    return {
      Nombre: data.Nombre,
      TipoId: data.TipoId,
      EstadoId: data.EstadoId,
      PlantillaId: data.PlantillaId,
      CentroCostoId: data.CentroCostoId,
      ResponsableId: data.ResponsableId,
      PlanEstrategicoId: data.PlanEstrategicoId,
      PadrePlanId: data.PlanEstrategicoPadreId ?? 0,
      FechaFin: data.FechaFin ? Datejs(data.FechaFin).toDate() : undefined,
      FechaInicio: data.FechaInicio
        ? Datejs(data.FechaInicio).toDate()
        : undefined,
    };
  },
  to: (data: FormValues): IStrategicPlans => {
    const isPoa = data.TipoId === StrategicPlansTypes.POA;
    const endDate = Datejs(data.FechaInicio)
      .endOf("year")
      .subtract(1, "day")
      .toISOString();
    const startDate = Datejs(data.FechaInicio).startOf("year").toISOString();

    return {
      Nombre: data.Nombre,
      EstadoId: data.EstadoId,
      TipoId: data.TipoId ?? 0,
      PlantillaId: data.PlantillaId ?? 0,
      ResponsableId: data.ResponsableId ?? 0,
      PlanEstrategicoId: data.PlanEstrategicoId ?? 0,
      FechaFin: isPoa ? endDate : Datejs(data.FechaFin).toISOString(),
      FechaInicio: isPoa ? startDate : Datejs(data.FechaInicio).toISOString(),
      Relacion: {
        PlanHijoId: data.PlanEstrategicoId ?? 0,
        PlanEstrategicoPadreId: data.PadrePlanId ?? 0,
      },
      CentroCosto: {
        CentroCostoId: data.CentroCostoId ?? 0,
        PlanEstrategicoId: data.PlanEstrategicoId ?? 0,
      },
    };
  },
};

const PeuPoaDialog = ({
  visible,
  planTypeId,
  parentPlanId,
  costsCenterId,
  strategicPlanId,
  ...props
}: IPeuPoaDialogProps) => {
  const navigate = useNavigate();
  const permissions = useAuthPermissions();

  const strategicPlan = useGlobalQuery(
    "StrategicPlans",
    "getById",
    parentPlanId ?? 0,
    { enabled: !!parentPlanId },
  );
  const createStrategicPlan = useGlobalMutation("StrategicPlans", "create");
  const updateStrategicPlan = useGlobalMutation("StrategicPlans", "update");
  const getCreate = useGlobalQuery(
    "StrategicPlans",
    "getCreate",
    planTypeId ?? 0,
    { enabled: !strategicPlanId && !!planTypeId },
  );
  const getUpdate = useGlobalQuery(
    "StrategicPlans",
    "getUpdate",
    strategicPlanId,
    { enabled: !!strategicPlanId },
  );

  const planType = getUpdate.data?.TipoId || planTypeId;
  const isPoa = planType === StrategicPlansTypes.POA;

  const form = useForm<FormValues>();
  const { reset } = form;

  React.useEffect(() => {
    if (!strategicPlanId && getCreate.data) {
      reset(model.from(getCreate.data));
    }
  }, [reset, getCreate.data, strategicPlanId]);

  React.useEffect(() => {
    if (strategicPlanId && getUpdate.data) {
      reset(model.from(getUpdate.data));
    }
  }, [reset, getUpdate.data, strategicPlanId]);

  React.useEffect(() => {
    const fn = async () => {
      if (!isPoa && visible) {
        await sleep(200);
        form.setValue(
          "FechaInicio",
          Datejs(strategicPlan.data?.FechaInicio).toDate(),
        );
        form.setValue(
          "FechaFin",
          Datejs(strategicPlan.data?.FechaFin).toDate(),
        );
      }
    };

    fn();
  }, [
    form,
    isPoa,
    strategicPlan.data?.FechaFin,
    strategicPlan.data?.FechaInicio,
    visible,
  ]);

  const onSubmit = async (values: FormValues) => {
    if (!isPoa) {
      const unix = (date?: Date | string) => Datejs(date).unix();

      if (unix(strategicPlan.data?.FechaFin) !== unix(values.FechaFin)) {
        form.setError("FechaFin", {
          type: "value",
          message: "Esta fecha no esta correcta.",
        });
        return;
      }

      if (unix(strategicPlan.data?.FechaInicio) !== unix(values.FechaInicio)) {
        form.setError("FechaFin", {
          type: "value",
          message: "Esta fecha no esta correcta.",
        });
        return;
      }
    }

    if (strategicPlanId) {
      return updateStrategicPlan.mutateAsync(model.to(values), {
        onSuccess: ({ PlanId }) => {
          form.reset();
          props.onHide();

          if (
            permissions.hasPermission(
              !isPoa
                ? Permissions.SEE_PEU_DETAILS_PAGE
                : Permissions.SEE_POA_DETAILS_PAGE,
            )
          ) {
            navigate(
              `.${CostsCenterRoutes[
                CostsCenterRouteNames.PEU_POA_DETAILS_PAGE
              ].create({ id: `${PlanId}` })}${
                planTypeId === StrategicPlansTypes.POA
                  ? CostsCenterRoutes[
                      CostsCenterRouteNames.POA_ACTIVITIES_PAGE
                    ].create({})
                  : ""
              }`,
              { state: { reload: true } },
            );
          }
        },
      });
    }

    return createStrategicPlan.mutateAsync(
      model.to({
        ...values,
        PadrePlanId: parentPlanId,
        CentroCostoId: costsCenterId,
      }),
      {
        onSuccess: ({ PlanId }) => {
          form.reset();
          props.onHide();
          if (
            permissions.hasPermission(
              !isPoa
                ? Permissions.SEE_PEU_DETAILS_PAGE
                : Permissions.SEE_POA_DETAILS_PAGE,
            )
          ) {
            navigate(
              `.${CostsCenterRoutes[
                CostsCenterRouteNames.PEU_POA_DETAILS_PAGE
              ].create({ id: `${PlanId}` })}${
                isPoa
                  ? CostsCenterRoutes[
                      CostsCenterRouteNames.POA_ACTIVITIES_PAGE
                    ].create({})
                  : ""
              }`,
              { state: { reload: true } },
            );
          }
        },
      },
    );
  };

  const validations = React.useMemo(() => {
    if (strategicPlanId) return getUpdate.data?.Validaciones;
    else return getCreate.data?.Validaciones;
  }, [
    getCreate.data?.Validaciones,
    getUpdate.data?.Validaciones,
    strategicPlanId,
  ]);

  const responsables = React.useMemo(() => {
    if (strategicPlanId) return getUpdate.data?.ResponsablesSelect ?? [];
    else return getCreate.data?.ResponsablesSelect ?? [];
  }, [
    getCreate.data?.ResponsablesSelect,
    getUpdate.data?.ResponsablesSelect,
    strategicPlanId,
  ]);

  const responsablesOptions = React.useMemo(() => {
    if (!responsables.length) return [];
    return ArrayUtils.selectLabelValue(
      responsables ?? [],
      "Nombre",
      "ResponsableId",
    );
  }, [responsables]);

  const types = React.useMemo(() => {
    if (strategicPlanId) return getUpdate.data?.TipoSelect ?? [];
    else return getCreate.data?.TipoSelect ?? [];
  }, [getCreate.data?.TipoSelect, getUpdate.data?.TipoSelect, strategicPlanId]);

  const typesOptions = React.useMemo(() => {
    if (!types.length) return [];
    return ArrayUtils.selectLabelValue(types ?? [], "Nombre", "TipoId");
  }, [types]);

  const loading = React.useMemo(() => {
    if (strategicPlanId) return getUpdate.isPending;
    else return getCreate.isPending;
  }, [getCreate.isPending, getUpdate.isPending, strategicPlanId]);

  return (
    <Dialog
      {...props}
      visible={visible}
      loading={loading}
      className="w-full md:w-10 lg:w-6"
      subtitle="Rellena los datos del formulario"
      onHide={() => {
        if (strategicPlanId) form.reset();
        props.onHide();
      }}
      title={
        strategicPlanId
          ? `Editando plan estratégico`
          : "Creando nuevo plan estratégico"
      }
      footer={() => (
        <div className="flex flex-1 justify-content-end gap-2">
          <Button
            size="small"
            severity="warning"
            label="Reiniciar formulario"
            disabled={!form.formState.isDirty}
            onClick={() => {
              form.reset();
            }}
            loading={
              createStrategicPlan.isPending || updateStrategicPlan.isPending
            }
          />
          <Button
            size="small"
            disabled={!form.formState.isDirty}
            onClick={form.handleSubmit(onSubmit)}
            label={
              strategicPlanId ? "Guardar cambios" : "Crear plan estratégico"
            }
            loading={
              createStrategicPlan.isPending || updateStrategicPlan.isPending
            }
          />
        </div>
      )}
    >
      <Form form={form}>
        <div className="flex gap-2 flex-1">
          <InputText
            name="Nombre"
            fieldClassName="flex-1"
            className="p-inputtext-sm"
            rules={validations?.["Nombre"]}
            label="Nombre del plan estratégico"
            placeholder="Ej: Plan de la nueva gestión."
          />
          <Dropdown
            label="Tipo"
            name="TipoId"
            disabledInput
            loading={loading}
            options={typesOptions}
            className="p-inputtext-sm"
            placeholder="Selecciona un tipo"
          />
        </div>
        <Dropdown
          loading={loading}
          label="Responsable"
          name="ResponsableId"
          className="p-inputtext-sm"
          options={responsablesOptions}
          rules={validations?.["ResponsableId"]}
          placeholder="Selecciona un responsable"
        />
        <div className="flex gap-2 flex-1">
          <Calendar
            name="FechaInicio"
            disabledInput={!isPoa}
            fieldClassName="flex-1"
            className="p-inputtext-sm"
            view={isPoa ? "year" : "date"}
            placeholder="Selecciona una fecha"
            rules={validations?.["FechaInicio"]}
            dateFormat={isPoa ? "yy" : "dd/mm/yy"}
            label={isPoa ? "Periodo" : "Fecha de inicio"}
            maxDate={Datejs(strategicPlan.data?.FechaFin).toDate()}
            minDate={Datejs(strategicPlan.data?.FechaInicio).toDate()}
          />
          {!isPoa && (
            <Calendar
              disabledInput
              name="FechaFin"
              dateFormat="dd/mm/yy"
              fieldClassName="flex-1"
              className="p-inputtext-sm"
              label="Fecha de finalización"
              rules={validations?.["FechaFin"]}
              placeholder="Selecciona una fecha final"
            />
          )}
        </div>
      </Form>
    </Dialog>
  );
};

export default PeuPoaDialog;
