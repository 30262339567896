import React from "react";
import { Tag } from "primereact/tag";
import { Icon } from "@nubeteck/icons";
import { Button } from "primereact/button";
import { PickNullable } from "@interfaces";
import { Dialog, ConfirmDialog } from "@components";
import { CardTable, IDataTableColumnsProps } from "@nubeteck/prime";
import { Form, useForm, Dropdown, InputText } from "@nubeteck/forms";

export interface IConceptos {
  Tipo: string;
  Estado: string;
  Nombre: string;
  Categoria: string;
  ConceptoId: number;
  CuentaContable: string;
}

type FormValues = PickNullable<
  IConceptos,
  "Tipo" | "Estado" | "Nombre" | "Categoria" | "ConceptoId" | "CuentaContable"
>;

const SettingsConceptPage = () => {
  const form = useForm<FormValues>();
  const [concept, setConcept] = React.useState(false);
  const [anular, setAnular] = React.useState(false);
  const [status, setStatus] = React.useState<string>();

  const value = [
    {
      ConceptoId: 1,
      Tipo: "Tipo 1",
      Estado: "Activo",
      Nombre: "Concepto 1",
      Categoria: "Categoría 1",
      CuentaContable: "10102 Cuenta 1",
    },
    {
      ConceptoId: 2,
      Tipo: "Tipo 2",
      Estado: "Activo",
      Nombre: "Concepto 2",
      Categoria: "Categoría 2",
      CuentaContable: "10102 Cuenta 2",
    },
    {
      ConceptoId: 3,
      Tipo: "Tipo 3",
      Estado: "Activo",
      Nombre: "Concepto 3",
      Categoria: "Categoría 3",
      CuentaContable: "10102 Cuenta 3",
    },
  ];

  const columns: IDataTableColumnsProps<IConceptos>[] = [
    {
      header: "#",
      field: "ConceptoId",
    },
    {
      field: "Nombre",
      header: "Nombre",
    },
    {
      field: "Tipo",
      header: "Tipo",
    },
    {
      field: "Categoria",
      header: "Categoría",
    },
    {
      field: "CuentaContable",
      header: "Cuenta contable",
    },
    {
      field: "Estado",
      header: "Estado",
      body: (rowData) => (
        <Tag severity={rowData.Estado === "Activo" ? "info" : "danger"}>
          {rowData.Estado}
        </Tag>
      ),
    },
  ];

  return (
    <>
      <CardTable
        type="data"
        value={value}
        hideSelectFilter
        title="Conceptos"
        columns={columns}
        hideFilterSettingsIcon
        headActions={[
          <Button
            key={1}
            label="Nuevo concepto"
            style={{ height: "40px" }}
            onClick={() => setConcept(true)}
            icon={<Icon name="plus"></Icon>}
          ></Button>,
        ]}
        tableActions={(rowData) => [
          {
            icon: "pencil",
            label: "Editar",
            onClick: () => {
              setConcept(true), setStatus(rowData.Estado);
            },
          },
          {
            onClick: () => setAnular(true),
            icon: rowData.Estado === "Activo" ? "trash" : "plug",
            label: rowData.Estado === "Activo" ? "Anular" : "Activar",
          },
        ]}
      ></CardTable>

      <ConfirmDialog
        visible={anular}
        accept={() => {
          setAnular(false), setStatus("");
        }}
        onHide={() => {
          setAnular(false), setStatus("");
        }}
        message={`¿Está seguro de que desea ${status === "Activo" ? "anular" : "activar"} este proveedor?`}
        header={
          <div className="flex flex-row align-items-center mt-0 mb-0">
            <Icon
              size={"small"}
              className="mr-2"
              name={status === "Activo" ? "trash" : "plug"}
            ></Icon>
            <p className="text-base">{`${status === "Activo" ? "Anular" : "Activar"} proveedor`}</p>
          </div>
        }
      />

      <Dialog
        visible={concept}
        title={"Concepto"}
        className="flex justify-content-center w-8 z-5"
        onHide={() => {
          setConcept(false);
        }}
        footer={[
          <div key={1} className="flex flex-1 justify-content-end gap-2">
            <Button label="Cancelar" onClick={() => setConcept(false)}></Button>
            <Button label="Guardar" onClick={() => setConcept(false)}></Button>
          </div>,
        ]}
      >
        <Form form={form}>
          <InputText
            name="Nombre"
            label="Nombre del concepto"
            placeholder="Escriba el nombre del concepto"
            rules={{ required: "Este campo es requerido" }}
          ></InputText>

          <Dropdown
            name="CuentaContable"
            label="Cuenta contable"
            placeholder="Selecciona una cuenta contable"
            rules={{ required: "Este campo es requerido" }}
            options={[{ value: "CuentaContable1", label: "Cuenta contable 1" }]}
          />

          <Dropdown
            name="Tipo"
            label="Tipo"
            placeholder="Selecciona un tipo"
            rules={{ required: "Este campo es requerido" }}
            options={[{ value: "tipo1", label: "Tipo 1" }]}
          />

          <Dropdown
            name="Categoria"
            label="Categoría"
            placeholder="Selecciona una categoría"
            rules={{ required: "Este campo es requerido" }}
            options={[{ value: "Categoria1", label: "Categoría 1" }]}
          />
        </Form>
      </Dialog>
    </>
  );
};

export default SettingsConceptPage;
