import clsx from "clsx";
import React from "react";
import { Icon } from "@nubeteck/icons";
import { BlockUI } from "primereact/blockui";
import { Dialog, DialogProps } from "primereact/dialog";
import { ProgressSpinner } from "primereact/progressspinner";

//! ANTES DE MODIFICAR ESTE COMPONENTE CONSULTE CON SUS DESARROLLADORES.
//! ANTES DE MODIFICAR ESTE COMPONENTE CONSULTE CON SUS DESARROLLADORES.
//! ANTES DE MODIFICAR ESTE COMPONENTE CONSULTE CON SUS DESARROLLADORES.

export interface IDialogProps extends DialogProps {
  title?: string;
  subtitle?: string;
  loading?: boolean;
}

const CustomDialog = ({
  title,
  subtitle,
  children,
  className,
  loading = false,
  ...props
}: IDialogProps) => {
  const header = React.useMemo(
    () => (
      <div className="flex flex-column gap-2 flex-wrap md:flex-row md:align-items-center justify-content-between surface-border">
        <div>
          <div className="text-xl font-semibold text-800">{title}</div>
          <div className="font-medium text-sm p-text-secondary">{subtitle}</div>
        </div>
      </div>
    ),
    [subtitle, title],
  );

  return (
    <Dialog
      modal
      keepInViewport
      header={header}
      draggable={false}
      closeIcon={<Icon name="x" />}
      className={clsx("p-dialog-custom", className)}
      {...props}
    >
      <div className="relative">
        <BlockUI blocked={loading}>
          {loading && (
            <div
              style={{ zIndex: 1, transform: "translate(-50%, -50%)" }}
              className="fixed flex align-items-center justify-content-center top-50 left-50"
            >
              <ProgressSpinner
                strokeWidth="3"
                style={{ width: "3rem", height: "3rem" }}
              />
            </div>
          )}
          <div className="p-3">{children}</div>
        </BlockUI>
      </div>
    </Dialog>
  );
};

export default CustomDialog;
