import API from "@lib/api";
import {
  IUser,
  Messages,
  IUserGetMe,
  IUserGetCreate,
  IUserGetUpdate,
} from "@interfaces";

import { BaseApi } from "./base-api";

export class UsersApi extends BaseApi {
  protected static api = API({ namespace: "User" });
  protected static messages: Messages<typeof UsersApi> = {
    update: {
      error: (error) => error,
      loading: "Actualizando usuario...",
      success: "Usuario actualizado correctamente.",
    },
    create: {
      error: (error) => error,
      loading: "Creando una nuevo usuario...",
      success: "Usuario creado correctamente.",
    },
    changeIsActive: {
      error: (error) => error,
      loading: "Actualizando estado del usuario...",
      success: "Estado del usuario actualizado correctamente.",
    },
  };

  public static async getAll() {
    const result = await this.api.get<IUser[]>("GetAll");
    return result.data;
  }

  public static async create(data: IUser) {
    const result = await this.api.post("Create", data);
    return result.data;
  }

  public static async getCreate() {
    const result = await this.api.get<IUserGetCreate>("Create");
    return result.data;
  }

  public static async getUpdate(id: number) {
    const result = await this.api.get<IUserGetUpdate>(`Update/${id}`);
    return result.data;
  }

  public static async update(data: Partial<IUser>) {
    const result = await this.api.put(`Update/${data.UserId}`, data);
    return result.data;
  }

  public static async getMe() {
    const result = await this.api.get<IUserGetMe>("GetMe");
    return result.data;
  }

  public static async changeIsActive(data: {
    UserId: number;
    IsActive: boolean;
  }) {
    const result = await this.api.put("ChangeIsActive", data);
    return result.data;
  }
}
