import React from "react";
import { toast } from "sonner";
import { select } from "radash";
import { Statuses } from "@core";
import { confirmDialog } from "primereact/confirmdialog";

import { Constants } from "../core";
import { IPurchaseDocument } from "../interfaces";

import useNavigateToPurchaseEdit from "./useNavigateToPurchaseEdit";
import useNavigateToPurchaseDetails from "./useNavigateToPurchaseDetails";

const usePurchaseDocumentActions = () => {
  const navigateToPurchaseDetails = useNavigateToPurchaseDetails();
  const navigateToPurchaseEdit = useNavigateToPurchaseEdit();

  return React.useCallback(
    (rowData: IPurchaseDocument) => {
      const allActionsWithConditions = [
        {
          // Común en todos los documentos
          condition: true,
          action: {
            label: "Detalles",
            icon: "list-details",
            onClick: () => navigateToPurchaseDetails(rowData),
          },
        },
        {
          // Editable solo si es borrador
          condition: rowData.EstadoId === Statuses.DRAFT_CODE,
          action: {
            icon: "pencil",
            label: "Editar",
            onClick: () => navigateToPurchaseEdit(rowData),
          },
        },
        {
          action: {
            icon: "printer",
            label: "Imprimir",
            onClick: () => null,
          },
          // Si es Conduce o Factura de compra con estado Activo
          condition:
            [
              Constants.PURCHASE_DOC_TYPES.INVOICE,
              Constants.PURCHASE_DOC_TYPES.DELIVERY_NOTE,
            ].includes(rowData.TipoDocumentoId) &&
            rowData.EstadoId === Statuses.ACTIVE_CODE,
        },
        {
          action: {
            icon: "copy",
            label: "Clonar",
            onClick: () => null,
          },
          // Si es Conduce con estado Activo
          condition:
            rowData.TipoDocumentoId ===
              Constants.PURCHASE_DOC_TYPES.DELIVERY_NOTE &&
            rowData.EstadoId === Statuses.ACTIVE_CODE,
        },
        {
          action: {
            icon: "repeat-once",
            onClick: () => null,
            label: "Hacer recurrente",
          },
          // Si es Factura de compra con estado Activo
          condition:
            rowData.TipoDocumentoId === Constants.PURCHASE_DOC_TYPES.INVOICE &&
            rowData.EstadoId === Statuses.ACTIVE_CODE,
        },
        {
          action: {
            onClick: () => null,
            icon: "square-minus",
            label: "Aplicar nota de crédito",
          },
          // Si es Factura de compra con estado Activo
          condition:
            rowData.TipoDocumentoId === Constants.PURCHASE_DOC_TYPES.INVOICE &&
            rowData.EstadoId === Statuses.ACTIVE_CODE,
        },
        {
          action: {
            icon: "square-plus",
            onClick: () => null,
            label: "Aplicar nota de débito",
          },
          // Si es Factura de compra con estado Activo
          condition:
            rowData.TipoDocumentoId === Constants.PURCHASE_DOC_TYPES.INVOICE &&
            rowData.EstadoId === Statuses.ACTIVE_CODE,
        },
        {
          // Común en todos los documentos excepto los anulados
          condition: rowData.EstadoId !== Statuses.NULLIFIED_CODE,
          action: {
            icon: "trash",
            label: "Anular",
            onClick: () => {
              confirmDialog({
                acceptLabel: "Anular",
                rejectLabel: "Cancelar",
                message: "Esta acción no podrá deshacerse.",
                header: `¿Estás seguro(a) de anular el documento ${rowData.Secuencia}?`,
                accept: () =>
                  toast.success(
                    `El documento ${rowData.Secuencia} ha sido anulado.`,
                    {
                      dismissible: true,
                      closeButton: true,
                      style: { fontSize: 14 },
                    },
                  ),
              });
            },
          },
        },
      ];

      return select(
        allActionsWithConditions,
        (item) => item.action,
        (item) => item.condition,
      );
    },
    [navigateToPurchaseDetails, navigateToPurchaseEdit],
  );
};

export default usePurchaseDocumentActions;
