import { SelectItemOptionsType } from "primereact/selectitem";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import { ColumnFilterElementTemplateOptions } from "primereact/column";
import { MultiSelect, MultiSelectChangeEvent } from "primereact/multiselect";

/**
 * Creates a multi-select or dropdown filter template for use in PrimeReact DataTable columns.
 * It automatically switches between MultiSelect and Dropdown components based on the number of options.
 *
 * @param {ColumnFilterElementTemplateOptions} options - The options provided by PrimeReact for column filtering.
 * @param {SelectItemOptionsType} [selectedOptions] - The array of selectable options for the filter.
 * @returns {JSX.Element} Either a MultiSelect or Dropdown component configured for filtering.
 *
 * @example
 * {
 *   filter: true,
 *   sortable: true,
 *   header: "Tipo de Asiento",
 *   field: "TipoAsientoNombre",
 *   filterMatchMode: "contains", // Note: Use "in" when there are more than 3 selectedOptions
 *   showFilterMenuOptions: false,
 *   filterMenuClassName: "custom",
 *   filterElement: (options) =>
 *     MultiSelectFilterTemplate(options, types),
 * }
 */
export const MultiSelectFilterTemplate = (
  options: ColumnFilterElementTemplateOptions,
  selectedOptions?: SelectItemOptionsType,
) => {
  const isMultiSelect = selectedOptions && selectedOptions.length > 2;
  if (isMultiSelect) {
    return (
      <MultiSelect
        value={options.value}
        maxSelectedLabels={1}
        showSelectAll={false}
        placeholder="Seleccione"
        options={selectedOptions}
        className="p-column-filter"
        style={{ minWidth: "12rem" }}
        onChange={(e: MultiSelectChangeEvent) => {
          options.filterCallback(e.value);
        }}
      />
    );
  }

  return (
    <Dropdown
      value={options.value}
      placeholder="Seleccione"
      options={selectedOptions}
      className="p-column-filter"
      style={{ minWidth: "12rem" }}
      onChange={(e: DropdownChangeEvent) => {
        options.filterCallback(e.value);
      }}
    />
  );
};
