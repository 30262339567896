import React from "react";
import { omit, isNumber } from "radash";
import { useParams } from "react-router";
import { useForm } from "react-hook-form";
import { Button } from "primereact/button";
import { ArrayUtils } from "@nubeteck/utils";
import { Divider } from "primereact/divider";
import { Message } from "primereact/message";
import { DialogProps } from "primereact/dialog";
import { useQueryClient } from "@tanstack/react-query";
import { useGlobalQuery, useGlobalMutation } from "@hooks";
import { Form, Dialog, Dropdown, InputTextarea } from "@components";
import {
  TreeDetail,
  IStrategicPlansDetails,
  IStrategicPlansDetailsFields,
  IStrategicPlansDetailsFieldsValues,
} from "@interfaces";

import { AdditionalFieldInput } from "../../additional-field-input";

export interface IStrategicPlansDetailsDialogProps extends DialogProps {
  orderCount?: number;
  levelTypeId: null | number;
  strategicDetailsId: number;
  parentDetail: null | TreeDetail;
}

type FormValues = Omit<IStrategicPlansDetails, "Tipo" | "CamposValores"> & {
  Tipo: null | number;
  CamposValores: Record<string, unknown>;
};

const model = {
  from: (data: IStrategicPlansDetails): FormValues => {
    return {
      Tipo: data.Tipo,
      Orden: data.Orden,
      PadreId: data.PadreId,
      EstadoId: data.EstadoId,
      DetalleId: data.DetalleId,
      Descripcion: data.Descripcion,
      PlanEstrategicoId: data.PlanEstrategicoId,
      CamposValores:
        data.CamposValores?.reduce<Record<string, unknown>>((acc, field) => {
          const name = `Campo${field.CampoId}`;

          if (field.TipoDatoId === 5) {
            if (field.PermitirVarios) {
              acc[name] = field.Valor.includes(",")
                ? field.Valor.split(",").map(Number)
                : [parseInt(field.Valor)];
            } else {
              acc[name] = parseInt(field.Valor);
            }
          } else {
            acc[name] = field.Valor;
          }

          return acc;
        }, {}) ?? {},
    };
  },
  to: (
    data: FormValues,
    fields: IStrategicPlansDetailsFields[],
    values: IStrategicPlansDetailsFieldsValues[],
  ): IStrategicPlansDetails => {
    return {
      Tipo: data.Tipo ?? 0,
      PadreId: data.PadreId,
      Orden: data.Orden ?? 0,
      EstadoId: data.EstadoId,
      Descripcion: data.Descripcion,
      DetalleId: data.DetalleId ?? 0,
      PlanEstrategicoId: data.PlanEstrategicoId,
      CamposValores: Object.entries(data.CamposValores ?? {}).map(
        ([key, value]) => {
          const fieldId = parseInt(key.replace("Campo", ""), 10);
          const Valor = Array.isArray(value)
            ? value.join(",")
            : `${value ?? ""}`;

          const field = fields.find((field) => field.CampoId === fieldId);
          const valueId = values.find((item) => item.CampoId === fieldId);

          return {
            Valor,
            CampoId: fieldId,
            DetalleId: data.DetalleId ?? 0,
            TipoDatoId: field?.TipoDatoId ?? 0,
            CampoDetalleId: valueId?.CampoDetalleId ?? 0,
          };
        },
      ),
    };
  },
};

const StrategicPlansDetailsDialog = ({
  orderCount,
  levelTypeId,
  parentDetail,
  strategicDetailsId,
  ...props
}: IStrategicPlansDetailsDialogProps) => {
  const { id } = useParams();
  const queryClient = useQueryClient();

  const strategicPlanId = parseInt(`${id}`, 10);

  const createDetails = useGlobalMutation("StrategicPlansDetails", "create");
  const updateDetails = useGlobalMutation("StrategicPlansDetails", "update");
  const getCreate = useGlobalQuery(
    "StrategicPlansDetails",
    "getCreate",
    strategicPlanId,
    { enabled: !strategicDetailsId && !!strategicPlanId },
  );
  const getUpdate = useGlobalQuery(
    "StrategicPlansDetails",
    "getUpdate",
    strategicDetailsId,
    { enabled: !!strategicDetailsId },
  );
  const fields = useGlobalQuery(
    "StrategicPlansFields",
    "getAll",
    strategicDetailsId ? (getUpdate.data?.Tipo ?? 0) : (levelTypeId ?? 0),
    { enabled: !!levelTypeId || !!getUpdate.data?.Tipo },
  );

  const form = useForm<FormValues>();
  const { reset } = form;

  React.useEffect(() => {
    if (!strategicDetailsId && getCreate.data) {
      reset(
        omit(getCreate.data, [
          "Actividades",
          "CamposValores",
          "Validaciones",
          "tiposSelect",
        ]),
      );
    }
  }, [reset, getCreate.data, strategicDetailsId]);

  React.useEffect(() => {
    if (strategicDetailsId && getUpdate.data) {
      reset(model.from(getUpdate.data));
    }
  }, [reset, getUpdate.data, strategicDetailsId]);

  React.useEffect(() => {
    if (levelTypeId && !strategicDetailsId) form.setValue("Tipo", levelTypeId);
  }, [form, levelTypeId, strategicDetailsId]);

  const handleSuccess = () => {
    form.reset();
    props.onHide();
    queryClient.invalidateQueries({ queryKey: ["StrategicPlans"] });
  };

  const onSubmit = (values: FormValues) => {
    if (strategicDetailsId) {
      return updateDetails.mutateAsync(
        model.to(
          values,
          fields.data ?? [],
          getUpdate.data?.CamposValores ?? [],
        ),
        { onSuccess: handleSuccess },
      );
    }
    return createDetails.mutateAsync(
      model.to(
        {
          ...values,
          PadreId: parentDetail?.DetalleId ?? null,
          Orden: (isNumber(orderCount) ? orderCount : 1) + 1,
        },
        fields.data ?? [],
        getUpdate.data?.CamposValores ?? [],
      ),
      { onSuccess: handleSuccess },
    );
  };

  const validations = React.useMemo(() => {
    if (strategicDetailsId) return getUpdate.data?.Validaciones;
    else return getCreate.data?.Validaciones;
  }, [
    getCreate.data?.Validaciones,
    getUpdate.data?.Validaciones,
    strategicDetailsId,
  ]);

  const tipos = React.useMemo(() => {
    if (strategicDetailsId) return getUpdate.data?.tiposSelect ?? [];
    else return getCreate.data?.tiposSelect ?? [];
  }, [
    getCreate.data?.tiposSelect,
    getUpdate.data?.tiposSelect,
    strategicDetailsId,
  ]);

  const tiposOptions = React.useMemo(() => {
    if (!tipos.length) return [];
    return ArrayUtils.selectLabelValue(tipos ?? [], "Nombre", "DetalleTipoId");
  }, [tipos]);

  const loading = React.useMemo(() => {
    if (strategicDetailsId) return getUpdate.isPending;
    else return getCreate.isPending;
  }, [getCreate.isPending, getUpdate.isPending, strategicDetailsId]);

  return (
    <Dialog
      {...props}
      loading={loading}
      className="w-full lg:w-8 xl:w-6"
      subtitle="Rellena los datos del formulario"
      title={strategicDetailsId ? "Editando detalle" : "Creando nuevo detalle"}
      onHide={() => {
        if (strategicDetailsId) form.reset();
        props.onHide();
      }}
      footer={() => (
        <div className="flex flex-1 justify-content-end gap-2">
          <Button
            size="small"
            severity="warning"
            label="Reiniciar formulario"
            onClick={() => {
              form.reset();
            }}
            loading={
              strategicDetailsId
                ? updateDetails.isPending
                : createDetails.isPending
            }
          />
          <Button
            size="small"
            onClick={form.handleSubmit(onSubmit)}
            label={strategicDetailsId ? "Guardar cambios" : "Crear detalle"}
            loading={
              strategicDetailsId
                ? updateDetails.isPending
                : createDetails.isPending
            }
          />
        </div>
      )}
    >
      <Form form={form}>
        <Message
          severity="info"
          className="justify-content-start mb-2"
          text={
            <>
              Se asociara a{" "}
              <b>
                {parentDetail?.TipoNombre ?? ""}:{" "}
                {parentDetail?.Descripcion ?? "-"}
              </b>
            </>
          }
        />
        <Dropdown
          name="Tipo"
          label="Tipo"
          disabledInput
          loading={loading}
          options={tiposOptions}
          className="p-inputtext-sm"
          rules={validations?.["Tipo"]}
          placeholder="Selecciona un tipo"
        />
        <InputTextarea
          rows={3}
          name="Descripcion"
          className="p-inputtext-sm"
          label="Descripcion del detalle"
          rules={validations?.["Descripcion"]}
          placeholder="Escribe una descripción..."
        />
        {!!fields.data?.length && (
          <Divider className="m-0">
            <span className="font-semibold text-sm">Campos adicionales</span>
          </Divider>
        )}
        {fields.data?.map((field) => {
          return (
            <AdditionalFieldInput
              data={field}
              key={field.CampoId}
              prefix="CamposValores"
            />
          );
        })}
      </Form>
    </Dialog>
  );
};

export default StrategicPlansDetailsDialog;
