import React from "react";
import { Button } from "primereact/button";
import { FieldValues } from "react-hook-form";
import { Form, Dialog, InputText } from "@components";

import { IDashboardWidgetDialogProps } from "../../interfaces";

const DashboardTableDialogSettings = <T extends FieldValues>({
  form,
  onSubmitClick,
  ...props
}: IDashboardWidgetDialogProps<T>) => {
  return (
    <Dialog
      {...props}
      title="Ajustes del widget"
      className="w-full md:w-10 lg:w-5"
      footer={
        <div className="flex justify-content-end gap-2">
          <Button
            size="small"
            type="button"
            label="Cancelar"
            severity="secondary"
            onClick={() => {
              form.reset();
              props.onHide();
            }}
          />
          <Button
            size="small"
            label="Guardar cambios"
            onClick={form.handleSubmit(onSubmitClick)}
          />
        </div>
      }
    >
      <Form form={form}>
        <InputText name="title" label="Titulo" className="p-inputtext-sm" />
        <InputText
          name="subtitle"
          label="Subtítulo"
          className="p-inputtext-sm"
        />
      </Form>
    </Dialog>
  );
};

export default DashboardTableDialogSettings;
