import API from "@lib/api";
import {
  Messages,
  IPermissions,
  IPermissionsCreate,
  IPermissionsUpdate,
} from "@interfaces";

import { BaseApi } from "./base-api";

export class PermissionsApi extends BaseApi {
  protected static api = API({ namespace: "Permisos" });
  protected static messages: Messages<typeof PermissionsApi> = {
    update: {
      error: (error) => error,
      loading: "Actualizando permiso...",
      success: "Permiso actualizado correctamente.",
    },
    create: {
      error: (error) => error,
      loading: "Creando una nuevo permiso...",
      success: "Permiso creado correctamente.",
    },
    updateValidity: {
      error: (error) => error,
      loading: "Actualizando los permisos del rol...",
      success: "Permisos del rol actualizados con exito.",
    },
  };

  public static async getAll() {
    const filters = await this.api.get<IPermissions[]>("GetAll");
    return filters.data;
  }

  public static async getById(id: number) {
    const filter = await this.api.get<IPermissions>(`GetById/${id}`);
    return filter.data;
  }

  public static async create(data: IPermissions) {
    const filter = await this.api.post("Create", data);
    return filter.data;
  }

  public static async getCreate(moduleId: number) {
    const result = await this.api.get<IPermissionsCreate>(
      `GetForCreate/${moduleId}`,
    );
    return result.data;
  }

  public static async getUpdate(id: number) {
    const result = await this.api.get<IPermissionsUpdate>(`GetForUpdate/${id}`);
    return result.data;
  }

  public static async update(data: Partial<IPermissions>) {
    const filter = await this.api.put(`Update/${data.PermisoId}`, data);
    return filter.data;
  }

  public static async updateValidity(data: {
    RolId: number;
    Permisos: Partial<IPermissions>[];
  }) {
    const filter = await this.api.put("UpdateVigencia", data);
    return filter.data;
  }
}
