import React from "react";
import { toast } from "sonner";
import { Constants } from "@core";
import { uid, omit } from "radash";
import { Form } from "@components";
import { Icon } from "@nubeteck/icons";
import { EventUtils } from "@nubeteck/utils";
import { Sidebar, SidebarProps } from "primereact/sidebar";
import { useForm, useWatch, useFieldArray } from "react-hook-form";
import { Layout, Responsive, WidthProvider } from "react-grid-layout";
import { useForceUpdate, useGlobalQuery, useGlobalMutation } from "@hooks";

import { Widget, Breakpoints } from "../../interfaces";
import { DashboardDrawerOptions } from "../dashboard-drawer-options";
import {
  DashboardKPICard,
  DashboardImageCard,
  DashboardTableCard,
  DashboardChartCard,
} from "../widgets";

const ResponsiveGridLayout = WidthProvider(Responsive);

const components: Record<
  string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  React.FC<any>
> = {
  kpi: DashboardKPICard.Dashboard,
  table: DashboardTableCard.Dashboard,
  image: DashboardImageCard.Dashboard,
  chart: DashboardChartCard.Dashboard,
};

type WidgetType = keyof typeof components;

type FormValues = {
  name: string;
  widgets: Widget[];
  editable: boolean;
  breakpoint: Breakpoints;
  layouts: Record<Breakpoints, Layout[]>;
};

const functions: Record<
  string,
  (
    x: number,
    y: number,
    type: string,
  ) => { layout: Layout; widget: Widget; id: string }
> = {
  image: (x, y) => {
    const id = `image-${uid(10)}`;
    return {
      id,
      layout: { x, y, w: 6, h: 4, i: id },
      widget: {
        i: id,
        type: "image",
        source: "/logo-nubeteck.png",
      },
    };
  },
  table: (x, y) => {
    const id = `table-${uid(10)}`;
    return {
      id,
      layout: { x, y, h: 7, w: 14, i: id },
      widget: {
        i: id,
        type: "table",
        title: "Titulo de la tabla",
        subtitle: "Subtítulo de la tabla",
      },
    };
  },
  kpi: (x, y) => {
    const id = `KPI-${uid(10)}`;
    return {
      id,
      layout: { x, y, w: 7, h: 3, i: id },
      widget: {
        i: id,
        type: "kpi",
        title: "Titulo",
        iconName: "dashboard",
        description: "Descripción",
      },
    };
  },
};

export interface IDashboardDrawerProps extends SidebarProps {
  settingId: number;
}

const DashboardDrawer = ({ settingId, ...props }: IDashboardDrawerProps) => {
  const { key, forceUpdate } = useForceUpdate("Playground");

  const updateGeneralSetting = useGlobalMutation(
    "GeneralSettings",
    "updateValor",
  );
  const { data } = useGlobalQuery("GeneralSettings", "getById", settingId, {
    enabled: !!settingId,
  });

  const [optionsCollapsed, setOptionsCollapsed] = React.useState(false);

  const form = useForm<FormValues>({
    defaultValues: {
      widgets: [],
      editable: true,
      breakpoint: "lg",
      name: "Panel de control",
      layouts: { lg: [], md: [], sm: [], xs: [], xl: [] },
    },
  });
  const { reset, control, setValue } = form;

  React.useEffect(() => {
    if (data) {
      reset(JSON.parse(data.Valor ?? "{}"), { keepDefaultValues: true });
    }
  }, [data, reset]);

  const {
    append,
    remove,
    update,
    fields: widgets,
  } = useFieldArray({
    control,
    name: "widgets",
  });

  const layouts = useWatch({ control, name: "layouts" });
  const editable = useWatch({ control, name: "editable" });
  const breakpoint = useWatch({ control, name: "breakpoint" });
  const width = Constants.breakpoints[breakpoint];

  const addWidget = (type: WidgetType, x: number = 0, y: number = Infinity) => {
    if (!functions[type]) return toast.error("Hubo un error con el Widget.");

    const { id, layout, widget } = functions[type](x, y, type);

    const updatedLayouts = Object.keys(layouts).reduce(
      (acc, key) => {
        const currentLayout = layouts[key as Breakpoints] || [];
        const isWidgetInLayout = currentLayout.some((item) => item.i === id);
        if (!isWidgetInLayout) {
          acc[key as Breakpoints] = [...currentLayout, layout];
        }
        return acc;
      },
      {} as Record<Breakpoints, Layout[]>,
    );

    append(widget);
    form.setValue("layouts", updatedLayouts);
  };

  const addWidgetChart = (
    type: WidgetType,
    title: string = "Titulo del gráfico",
    x: number = 0,
    y: number = Infinity,
  ) => {
    const id = `chart-${uid(10)}`;
    const layout: Layout = { x, y, w: 7, h: 7, i: id };
    const widget: Widget = {
      i: id,
      title,
      type: "chart",
      chartType: type,
      subtitle: "Subtitulo del gráfico",
    };

    const updatedLayouts = Object.keys(layouts).reduce(
      (acc, key) => {
        const currentLayout = layouts[key as Breakpoints] || [];
        const isWidgetInLayout = currentLayout.some((item) => item.i === id);
        if (!isWidgetInLayout) {
          acc[key as Breakpoints] = [...currentLayout, layout];
        }
        return acc;
      },
      {} as Record<Breakpoints, Layout[]>,
    );

    append(widget);
    form.setValue("layouts", updatedLayouts);
  };

  const removeWidget = React.useCallback(
    (id: string) => {
      if (!id) return;

      const updatedLayouts = Object.keys(layouts).reduce(
        (acc, key) => {
          const currentLayout = layouts[key as Breakpoints] || [];
          const layoutsFiltered = currentLayout.filter(
            (layout) => layout.i !== id,
          );
          acc[key as Breakpoints] = layoutsFiltered;
          return acc;
        },
        {} as Record<Breakpoints, Layout[]>,
      );

      const itemIndex = widgets.findIndex((widget) => widget.i === id);

      remove(itemIndex);
      setValue("layouts", updatedLayouts);
    },
    [layouts, remove, setValue, widgets],
  );

  const onSubmit = (values: FormValues) => {
    const valuesOmitted = omit(values, ["breakpoint", "editable"]);
    return updateGeneralSetting.mutateAsync(
      {
        ConfiguracionId: settingId,
        Valor: JSON.stringify(valuesOmitted ?? {}),
      },
      {
        onSuccess: () => {
          props.onHide();
          window.location.reload();
        },
      },
    );
  };

  const renderHeader = React.useCallback(
    () => (
      <div className="flex flex-column gap-2 flex-wrap md:flex-row md:align-items-center justify-content-between">
        <div>
          <div className="text-lg font-semibold text-800">
            Preferencias del panel de control
          </div>
          <div className="font-medium text-sm p-text-secondary">
            Personaliza el aspecto y funcionamiento de tu panel de control.
          </div>
        </div>
      </div>
    ),
    [],
  );

  const renderIcons = React.useCallback(
    () => (
      <>
        {optionsCollapsed ? (
          <Icon
            className="mr-2"
            name="layout-sidebar-right-expand"
            onClick={EventUtils.callEvent(setOptionsCollapsed, false)}
          />
        ) : (
          <Icon
            className="mr-2"
            name="layout-sidebar-right-collapse"
            onClick={EventUtils.callEvent(setOptionsCollapsed, true)}
          />
        )}
      </>
    ),
    [optionsCollapsed],
  );

  return (
    <Sidebar
      {...props}
      fullScreen
      position="right"
      icons={renderIcons()}
      header={renderHeader()}
      closeIcon={<Icon name="x" />}
      className="p-dashboard-sidebar"
    >
      <Form
        form={form}
        className="h-full"
        onSubmit={form.handleSubmit(onSubmit)}
      >
        <div className="flex flex-column md:flex-row h-full">
          <div style={{ flex: 3.5 }} className="surface-200 overflow-auto">
            <div
              className="h-full"
              style={{ margin: "0 auto", width: width || "100%" }}
            >
              <ResponsiveGridLayout
                isDroppable
                rowHeight={35}
                useCSSTransforms
                margin={[10, 10]}
                layouts={layouts}
                maxRows={Infinity}
                isDraggable={editable}
                isResizable={editable}
                breakpoint={breakpoint}
                onResizeStop={() => forceUpdate()}
                breakpoints={Constants.breakpoints}
                className="bg-primary-500 min-h-full"
                cols={{ xs: 4, sm: 6, xl: 32, lg: 24, md: 12 }}
                resizeHandles={["se", "sw", "ne", "nw", "e", "w"]}
                droppingItem={{ h: 2.5, w: 2.5, i: "dropping_item" }}
                onDragStop={(_l, _o, _n, _p, event) => event.preventDefault()}
                onDragStart={(_l, _o, _n, _p, event) => event.preventDefault()}
                onBreakpointChange={(breakpoint) => {
                  form.setValue("breakpoint", breakpoint as Breakpoints);
                  forceUpdate();
                }}
                onLayoutChange={(_, allLayouts) => {
                  form.setValue("layouts", allLayouts as FormValues["layouts"]);
                  forceUpdate();
                }}
                onDrop={(_, item, event) => {
                  event.preventDefault();
                  const type = JSON.parse(
                    (event as unknown as React.DragEvent).dataTransfer.getData(
                      "text/plain",
                    ),
                  );

                  if (["kpi", "table", "image"].includes(type.id)) {
                    addWidget(type.id, item.x, item.y);
                  } else {
                    addWidgetChart(type.id, type.title, item.x, item.y);
                  }
                }}
              >
                {widgets.map((widget) => {
                  const Component = widget.type.includes("chart")
                    ? components["chart"]
                    : components[widget.type];
                  return (
                    <div key={widget.i} style={{ display: "flex" }}>
                      <Component
                        key={key}
                        {...widget}
                        editable={true}
                        onUpdateWidget={update}
                        onForceUpdate={forceUpdate}
                        onRemoveWidget={removeWidget}
                        onEditable={(value: boolean) =>
                          setValue("editable", value)
                        }
                      />
                    </div>
                  );
                })}
              </ResponsiveGridLayout>
            </div>
          </div>
          {!optionsCollapsed && <DashboardDrawerOptions />}
        </div>
      </Form>
    </Sidebar>
  );
};

export default DashboardDrawer;
