import API from "@lib/api";
import { BaseApi } from "@api";
import { Messages } from "@interfaces";

import { ICountry, ISectores, IProvincias, IMunicipios } from "../interfaces";

export class CountryApi extends BaseApi {
  protected static api = API({ namespace: "Paises" });
  protected static filterApi = API({ namespace: "Filtro" });
  protected static messages: Messages<typeof CountryApi> = {};

  public static async getAll(): Promise<ICountry[]> {
    const result = await this.api.get("GetAll");
    return result.data;
  }

  public static async getProvinciasByPaisId(
    id: number,
  ): Promise<IProvincias[]> {
    const result = await this.api.get(`GetProvinciasByPaisId/${id}`);
    return result.data;
  }

  public static async getMunicipiosByProvinciaId(
    id: number,
  ): Promise<IMunicipios[]> {
    const result = await this.api.get(`GetMunicipiosByProvinciaId/${id}`);
    return result.data;
  }

  public static async getSectoresByMunicipioId(
    id: number,
  ): Promise<ISectores[]> {
    const result = await this.api.get(`GetSectoresByMunicipioId/${id}`);
    return result.data;
  }
}
