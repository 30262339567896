import React from "react";
import { Toaster } from "sonner";
import { IUser } from "@interfaces";
import { Datejs } from "@nubeteck/utils";
import queryClient from "@lib/query-client";
import { NavigationProvider } from "@contexts";
import { IconsProvider } from "@nubeteck/icons";
import { Outlet, Navigate } from "react-router";
import { createStore, AuthProvider } from "@nubeteck/auth";
import { QueryClientProvider } from "@tanstack/react-query";
import { addLocale as addLocalePrime } from "primereact/api";
import {
  addLocale,
  PrimeReactProvider,
  HeaderActionsProvider,
} from "@nubeteck/prime";

import { routes } from "./navigation";
import LocaleES from "./core/locale.es.json";

Datejs.locale("es");

const store = createStore<IUser>({
  authName: "_auth",
  authType: "cookie",
  cookieDomain: window.location.hostname,
  cookieSecure: window.location.protocol === "https:",
});

const AppProviders = () => {
  React.useEffect(() => {
    addLocalePrime("es", LocaleES);
    addLocale("es", LocaleES);
    addLocalePrime("es", LocaleES);
  }, []);

  return (
    <AuthProvider
      store={store}
      fallbackPath="/login"
      OutletComponent={Outlet}
      permissionKey="permisos"
      NavigateComponent={Navigate}
    >
      <QueryClientProvider client={queryClient}>
        <PrimeReactProvider value={{ ripple: true, locale: "es" }}>
          <HeaderActionsProvider>
            <NavigationProvider routes={routes} />
          </HeaderActionsProvider>
          <Toaster richColors />
          <IconsProvider />
        </PrimeReactProvider>
      </QueryClientProvider>
    </AuthProvider>
  );
};

export default AppProviders;
