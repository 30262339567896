import React from "react";
import { omit } from "radash";
import { Dialog } from "@components";
import { Button } from "primereact/button";
import { IndicatorGoalTypes } from "@core";
import { ArrayUtils } from "@nubeteck/utils";
import { DialogProps } from "primereact/dialog";
import { useForm, useWatch } from "react-hook-form";
import { useGlobalQuery, useGlobalMutation } from "@hooks";
import { IStrategicPlansActivityIndicator } from "@interfaces";
import { Form, Dropdown, InputNumber, InputTextarea } from "@components";

export interface IIndicatorDialogProps extends DialogProps {
  activityId: number;
  orderCount: number;
  indicatorId?: number;
  indicatorTypeId: number;
}

type FormValues = Omit<
  IStrategicPlansActivityIndicator,
  "EstadoNombre" | "Cuantitativo" | "ActividadNombre"
> & {
  Meta: number;
  TipoMetaId: number;
  TipoCondicionId: number;
};

const model = {
  from: (data: IStrategicPlansActivityIndicator): FormValues => {
    return {
      Orden: data.Orden,
      EstadoId: data.EstadoId,
      Indicador: data.Indicador,
      IndicadorId: data.IndicadorId,
      PlantillaId: data.PlantillaId,
      ActividadId: data.ActividadId,
      Meta: data.Cuantitativo?.Meta ?? 0,
      TipoIndicadorId: data.TipoIndicadorId,
      MedioDeVerificacion: data.MedioDeVerificacion,
      TipoMetaId: data.Cuantitativo?.TipoMetaId ?? 0,
      TipoMedicionResultadoId: data.TipoMedicionResultadoId,
      TipoCondicionId: data.Cuantitativo?.TipoCondicionId ?? 0,
    };
  },
  to: (data: FormValues): IStrategicPlansActivityIndicator => {
    return {
      Orden: data.Orden,
      EstadoId: data.EstadoId,
      Indicador: data.Indicador,
      IndicadorId: data.IndicadorId,
      ActividadId: data.ActividadId,
      PlantillaId: data.PlantillaId,
      TipoIndicadorId: data.TipoIndicadorId,
      MedioDeVerificacion: data.MedioDeVerificacion,
      TipoMedicionResultadoId: data.TipoMedicionResultadoId,
      Cuantitativo:
        data.TipoMedicionResultadoId === 2
          ? {
              Meta: data.Meta ?? 0,
              TipoMetaId: data.TipoMetaId,
              TipoCondicionId: data.TipoCondicionId,
            }
          : undefined,
    };
  },
};

const IndicatorDialog = ({
  visible,
  activityId,
  orderCount,
  indicatorId,
  indicatorTypeId,
  ...props
}: IIndicatorDialogProps) => {
  const createIndicator = useGlobalMutation(
    "StrategicPlansIndicator",
    "create",
  );
  const updateIndicator = useGlobalMutation(
    "StrategicPlansIndicator",
    "update",
  );
  const getCreate = useGlobalQuery(
    "StrategicPlansIndicator",
    "getCreate",
    activityId,
    { networkMode: "always", enabled: !indicatorId && !!activityId && visible },
  );
  const getUpdate = useGlobalQuery(
    "StrategicPlansIndicator",
    "getUpdate",
    indicatorId,
    { networkMode: "always", enabled: !!indicatorId },
  );

  const form = useForm<FormValues>({});
  const { reset } = form;

  const measurementType = useWatch({
    control: form.control,
    name: "TipoMedicionResultadoId",
  });

  const metaType = useWatch({
    name: "TipoMetaId",
    control: form.control,
  });

  React.useEffect(() => {
    if (!indicatorId && getCreate.data) {
      reset(
        omit({ ...getCreate.data, TipoIndicadorId: indicatorTypeId }, [
          "Validaciones",
          "SelectOptions",
        ]) as never,
      );
    }
  }, [reset, getCreate.data, indicatorId, indicatorTypeId]);

  React.useEffect(() => {
    if (indicatorId && getUpdate.data) {
      reset(model.from(getUpdate.data));
    }
  }, [reset, getUpdate.data, indicatorId]);

  const handleSuccess = () => {
    form.reset();
    props.onHide();
  };

  const onSubmit = (values: FormValues) => {
    if (indicatorId) {
      return updateIndicator.mutateAsync(model.to(values), {
        onSuccess: handleSuccess,
      });
    }
    return createIndicator.mutateAsync(
      model.to({ ...values, Orden: orderCount + 1 }),
      { onSuccess: handleSuccess },
    );
  };

  const validations = React.useMemo(() => {
    if (indicatorId) return getUpdate.data?.Validaciones;
    else return getCreate.data?.Validaciones;
  }, [indicatorId, getUpdate.data?.Validaciones, getCreate.data?.Validaciones]);

  const templates = React.useMemo(() => {
    if (indicatorId)
      return getUpdate.data?.SelectOptions?.PlantillaSelect ?? [];
    else return getCreate.data?.SelectOptions?.PlantillaSelect ?? [];
  }, [
    getCreate.data?.SelectOptions?.PlantillaSelect,
    getUpdate.data?.SelectOptions?.PlantillaSelect,
    indicatorId,
  ]);

  const conditions = React.useMemo(() => {
    if (indicatorId)
      return getUpdate.data?.SelectOptions?.CondicionesSelect ?? [];
    else return getCreate.data?.SelectOptions?.CondicionesSelect ?? [];
  }, [
    getCreate.data?.SelectOptions?.CondicionesSelect,
    getUpdate.data?.SelectOptions?.CondicionesSelect,
    indicatorId,
  ]);

  const conditionsOptions = React.useMemo(() => {
    if (!conditions.length) return [];
    return ArrayUtils.selectLabelValue(
      conditions ?? [],
      (item) => `${item.CondicionNombre} (${item.Simbolo})`,
      "CondicionId",
    );
  }, [conditions]);

  const metasTypes = React.useMemo(() => {
    if (indicatorId)
      return getUpdate.data?.SelectOptions.TiposMetasSelect ?? [];
    else return getCreate.data?.SelectOptions.TiposMetasSelect ?? [];
  }, [
    getCreate.data?.SelectOptions.TiposMetasSelect,
    getUpdate.data?.SelectOptions.TiposMetasSelect,
    indicatorId,
  ]);

  const metasTypesOptions = React.useMemo(() => {
    if (!metasTypes.length) return [];
    return ArrayUtils.selectLabelValue(
      metasTypes ?? [],
      "TipoMetaNombre",
      "TipoMetaId",
    );
  }, [metasTypes]);

  const medicionTypes = React.useMemo(() => {
    if (indicatorId)
      return getUpdate.data?.SelectOptions.TiposMedicionesSelect ?? [];
    else return getCreate.data?.SelectOptions.TiposMedicionesSelect ?? [];
  }, [
    getCreate.data?.SelectOptions.TiposMedicionesSelect,
    getUpdate.data?.SelectOptions.TiposMedicionesSelect,
    indicatorId,
  ]);

  const medicionTypesOptions = React.useMemo(() => {
    if (!medicionTypes.length) return [];
    return ArrayUtils.selectLabelValue(
      medicionTypes ?? [],
      "TipoMedicionNombre",
      "TipoMedicionId",
    );
  }, [medicionTypes]);

  const indicatorTypes = React.useMemo(() => {
    if (indicatorId)
      return getUpdate.data?.SelectOptions.TiposIndicadoresSelect ?? [];
    else return getCreate.data?.SelectOptions.TiposIndicadoresSelect ?? [];
  }, [
    getCreate.data?.SelectOptions.TiposIndicadoresSelect,
    getUpdate.data?.SelectOptions.TiposIndicadoresSelect,
    indicatorId,
  ]);

  const indicatorTypesOptions = React.useMemo(() => {
    if (!indicatorTypes.length) return [];
    return ArrayUtils.selectLabelValue(
      indicatorTypes ?? [],
      "TipoIndicadorNombre",
      "TipoIndicadorId",
    );
  }, [indicatorTypes]);

  const loading = React.useMemo(() => {
    if (indicatorId) return getUpdate.isPending;
    else return getCreate.isPending;
  }, [getCreate.isPending, getUpdate.isPending, indicatorId]);

  return (
    <Dialog
      {...props}
      visible={visible}
      loading={loading}
      className="w-full md:w-8"
      subtitle="Rellena los datos del formulario"
      title={indicatorId ? "Editando indicador" : "Creando nuevo indicador"}
      onHide={() => {
        if (indicatorId) form.reset();
        props.onHide();
      }}
      footer={() => (
        <div className="flex flex-1 justify-content-end gap-2">
          <Button
            size="small"
            severity="warning"
            label="Reiniciar formulario"
            disabled={!form.formState.isDirty}
            onClick={() => {
              form.reset();
            }}
          />
          <Button
            size="small"
            disabled={!form.formState.isDirty}
            onClick={form.handleSubmit(onSubmit)}
            label={indicatorId ? "Guardar cambios" : "Crear indicador"}
            loading={createIndicator.isPending || updateIndicator.isPending}
          />
        </div>
      )}
    >
      <Form form={form}>
        <Dropdown
          disabledInput
          loading={loading}
          name="TipoIndicadorId"
          label="Tipo de indicador"
          className="p-inputtext-sm"
          options={indicatorTypesOptions}
        />
        <Dropdown
          loading={loading}
          label="Tipo de medición"
          className="p-inputtext-sm"
          name="TipoMedicionResultadoId"
          options={medicionTypesOptions}
          placeholder="Selecciona un tipo de medición"
        />
        {measurementType === 2 && (
          <div className="flex flex-wrap column-gap-3">
            <Dropdown
              name="TipoMetaId"
              loading={loading}
              label="Tipo de meta"
              fieldClassName="flex-1"
              className="p-inputtext-sm"
              options={metasTypesOptions}
              placeholder="Selecciona un tipo de meta"
            />
            <Dropdown
              label="Condición"
              loading={loading}
              name="TipoCondicionId"
              fieldClassName="flex-1"
              className="p-inputtext-sm"
              options={conditionsOptions}
              placeholder="Selecciona una condición"
            />
            <InputNumber
              min={0}
              name="Meta"
              label="Meta"
              fieldClassName="flex-1"
              className="p-inputtext-sm"
              placeholder="Ingresa la meta"
              max={metaType === IndicatorGoalTypes.PERCENTAGE ? 100 : undefined}
              minFractionDigits={
                metaType === IndicatorGoalTypes.NUMERIC ? 2 : 0
              }
              suffix={
                metaType === IndicatorGoalTypes.PERCENTAGE ? "%" : undefined
              }
              helperText={
                metaType === IndicatorGoalTypes.PERCENTAGE
                  ? "El decimal no sera tomado en cuenta."
                  : undefined
              }
            />
          </div>
        )}
        {measurementType === 1 && (
          <Dropdown
            label="Plantilla"
            name="PlantillaId"
            optionLabel="Nombre"
            optionValue="PlantillaId"
            options={templates ?? []}
            className="p-inputtext-sm"
            rules={validations?.PlantillaId}
            helperText="Plantilla de las opciones cualitativas"
            placeholder="Seleccionar la plantilla para las opciones cualitativas"
            itemTemplate={(option) => {
              return (
                <div className="flex flex-column">
                  <span className="font-bold">{option?.Nombre}</span>
                  <span>{option?.Descripcion}</span>
                </div>
              );
            }}
          />
        )}
        <InputTextarea
          name="Indicador"
          placeholder="Escribe..."
          className="p-inputtext-sm"
          label="Descripción del Indicador"
          rules={validations?.["Indicador"]}
        />
        <InputTextarea
          className="p-inputtext-sm"
          label="Medio de verificación"
          rules={validations?.["MedioDeVerificacion"]}
          name="MedioDeVerificacion.MedioVerificacion"
          placeholder="Escribe el medio de verificación"
          helperText="Con esto le indica al usuario que evidencia subir al completar la actividad."
        />
      </Form>
    </Dialog>
  );
};

export default IndicatorDialog;
