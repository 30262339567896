import API from "@lib/api";
import { BaseApi } from "@api";
import {
  Messages,
  IStrategicPlansActivity,
  IStrategicPlansActivitiesGetForCreate,
  IStrategicPlansActivitiesGetForUpdate,
} from "@interfaces";

export class StrategicPlansActivitiesApi extends BaseApi {
  protected static api = API({ namespace: "PlanesEstrategicos" });
  protected static messages: Messages<typeof StrategicPlansActivitiesApi> = {
    create: {
      error: (error) => error,
      loading: "Creando nueva actividad del plan operativo...",
      success: "Actividad del plan operativo creada correctamente.",
    },
    complete: {
      error: (error) => error,
      loading: "Completando actividad del plan operativo...",
      success: "Actividad del plan operativo completada correctamente.",
    },
    update: {
      error: (error) => error,
      loading: "Actualizando actividad del plan operativo...",
      success: "Actividad del plan operativo actualizada correctamente.",
    },
    changeState: {
      error: (error) => error,
      loading: "Actualizando actividad del plan operativo...",
      success: "Actividad del plan operativo actualizada correctamente.",
    },
  };

  public static async getAllByPoa(id: number) {
    const result = await this.api.get<IStrategicPlansActivity[]>(
      `GetAllActividadByPOAId/${id}`,
    );
    return result.data;
  }

  public static async getById(id: number) {
    const result = await this.api.get<IStrategicPlansActivity>(
      `GetActividadById/${id}`,
    );
    return result.data;
  }

  public static async create(data: IStrategicPlansActivity) {
    const result = await this.api.post<{ ActividadId: number }>(
      "AddPlanEstrategicoActividad",
      data,
    );
    return result.data;
  }

  public static async getCreate() {
    const result = await this.api.get<IStrategicPlansActivitiesGetForCreate>(
      "GetCreatePlanEstrategicoActividad",
    );
    return result.data;
  }

  public static async update(data: Partial<IStrategicPlansActivity>) {
    const result = await this.api.put<{ ActividadId: number }>(
      `UpdatePlanEstrategicoActividad/${data.ActividadId}`,
      data,
    );
    return result.data;
  }

  public static async getUpdate(id: number) {
    const result = await this.api.get<IStrategicPlansActivitiesGetForUpdate>(
      `GetUpdatePlanEstrategicoActividad/${id}`,
    );
    return result.data;
  }

  public static async changeState(data: {
    ActividadId: number;
    EstadoId: number;
  }) {
    const result = await this.api.post(
      "ChangeStatePlanEstrategicoActividad",
      data,
    );
    return result.data;
  }

  public static async complete(id: number) {
    const result = await this.api.post(`CompletarActividadById/${id}`);
    return result.data;
  }
}
