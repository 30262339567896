import React from "react";
import { useForm } from "react-hook-form";
import { Button } from "primereact/button";
import { PickNullable } from "@interfaces";
import { Column } from "primereact/column";
import { useNavigate } from "react-router";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import { Icon, IIconProps } from "@nubeteck/icons";
import { DataTableExpandedRows } from "primereact/datatable";
import { Dropdown as DropdownPrime } from "primereact/dropdown";
import { DataTable, StatusTag, HeaderIcon } from "@nubeteck/prime";
import { InputText as InputTextPrime } from "primereact/inputtext";
import {
  Form,
  Dialog,
  Dropdown,
  Calendar,
  InputText,
  ConfirmDialog,
  InputTextarea,
} from "@components";

import { IPresupuestos, IPresupuestoCreate } from "../../interfaces";
import { useCostCenterQuery, useCostCenterMutation } from "../../hooks";
import { CostsCenterRoutes, CostsCenterRouteNames } from "../../routes";

type FormValues = PickNullable<
  IPresupuestoCreate,
  | "Nombre"
  | "FechaFin"
  | "EstadoId"
  | "FechaInicio"
  | "Descripcion"
  | "CentroCostoId"
  | "PresupuestoId"
>;

const model = {
  from: (data: IPresupuestoCreate): FormValues => {
    return {
      Nombre: data.Nombre,
      EstadoId: data.EstadoId,
      FechaFin: data.FechaFin,
      Descripcion: data.Descripcion,
      FechaInicio: data.FechaInicio,
      PresupuestoId: data.PresupuestoId,
      CentroCostoId: data.CentroCostoId,
    };
  },
  to: (data: FormValues): IPresupuestoCreate => {
    return {
      EstadoId: 1,
      Nombre: data.Nombre ?? "",
      FechaFin: data.FechaFin ?? "",
      Descripcion: data.Descripcion ?? "",
      FechaInicio: data.FechaInicio ?? "",
      CentroCostoId: data.CentroCostoId ?? 0,
      PresupuestoId: data.PresupuestoId ?? 0,
    };
  },
};

const BudgetPage = () => {
  const navigate = useNavigate();
  const form = useForm<FormValues>();
  const [anular, setAnular] = React.useState(false);
  const [selectPresupuestoId, setSelectPresupuestoId] = React.useState(0);
  const [estadoPresupuesto, setEstadoPresupuesto] = React.useState(0);
  const [searchText, setSearchText] = React.useState("");
  const [expandedRows, setExpandedRows] =
    React.useState<DataTableExpandedRows>();
  const [dialogBudget, setDialogBudget] = React.useState(false);
  const [editDialogBudget, setEditDialogBudget] = React.useState(0);
  const presupuestos = useCostCenterQuery("Budget", "getAll");
  const createBudget = useCostCenterMutation("Budget", "create");
  const updateBudget = useCostCenterMutation("Budget", "update");
  const changeStatePresupuesto = useCostCenterMutation(
    "Budget",
    "changeStatePresupuesto",
  );
  const { data: getByIdPresupuesto } = useCostCenterQuery(
    "Budget",
    "getById",
    editDialogBudget,
    {
      enabled: !!editDialogBudget,
    },
  );

  const onSubmit = (values: FormValues) => {
    if (editDialogBudget !== 0) {
      return updateBudget.mutateAsync(
        model.to({ ...values, PresupuestoId: values.PresupuestoId }),
        {
          onSuccess: () => {
            setDialogBudget(false), setEditDialogBudget(0);
          },
        },
      );
    }
    {
      return createBudget.mutateAsync(model.to(values), {
        onSuccess: () => {
          setDialogBudget(false), setEditDialogBudget(0);
        },
      });
    }
  };

  const rowExpansionTemplate = (rowData: IPresupuestos) => {
    return (
      <DataTable value={[rowData]}>
        <Column field="Descripcion" header="Descripción"></Column>
      </DataTable>
    );
  };

  const filteredPresupuestos = presupuestos.data?.filter((item) => {
    return (
      //item.CentroCostoId?.toLowerCase().includes(searchText.toLowerCase()) ||
      item.Descripcion?.toLowerCase().includes(searchText.toLowerCase()) ||
      item.Nombre?.toLowerCase().includes(searchText.toLowerCase())
    );
  });

  const header = () => {
    return (
      <div className="flex justify-content-between align-content-center m-0">
        <div className="flex align-items-center gap-2">
          <DropdownPrime
            options={[]}
            value={["Hola"]}
            placeholder="Seleccione un filtro"
            className="p-inputtext-sm w-full md:w-16rem"
            //onChange={(e) => onSelectFilterOption?.(e.value)}
            dropdownIcon={(opts) => (
              <Icon
                size={22}
                {...(opts.iconProps as IIconProps)}
                name="selector"
              />
            )}
          />
          <HeaderIcon
            size={24}
            name="filter-cog"
            //onClick={onClickFilterSettings}
            className="text-primary"
          />
        </div>
        <div className="flex flex-row p-0 m-0">
          <IconField
            className="mr-2"
            iconPosition="left"
            key="TableGlobalSearch"
            //className={clsx(hideGlobalSearch ? "hidden" : "")}
          >
            <InputIcon>
              <Icon size={22} name="search" />
            </InputIcon>
            <InputTextPrime
              type="search"
              value={searchText}
              placeholder="Búsqueda..."
              style={{ marginTop: "1px" }}
              className="w-full p-inputtext-sm"
              onChange={(e) => setSearchText(e.target.value)}
            />
          </IconField>{" "}
          <Button
            key={1}
            size="small"
            label="Nuevo presupuesto"
            style={{ height: "37px" }}
            icon={<Icon name="plus"></Icon>}
            onClick={() => setDialogBudget(true)}
          ></Button>
        </div>
      </div>
    );
  };

  React.useEffect(() => {
    if (editDialogBudget !== 0) {
      if (getByIdPresupuesto) {
        const formData = model.from(getByIdPresupuesto);
        form.reset(formData, { keepDefaultValues: true });
      }
    }
  }, [editDialogBudget, form, getByIdPresupuesto]);

  const formatDate = (dateString: string): string => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  return (
    <div className="flex flex-column bg-white">
      <div className="pt-2 pr-3 pl-3 pb-2">{header()}</div>
      <DataTable
        expandedRows={expandedRows}
        value={filteredPresupuestos}
        rowExpansionTemplate={rowExpansionTemplate}
        onRowToggle={(e) => setExpandedRows(e.data as DataTableExpandedRows)}
      >
        <Column expander></Column>
        <Column header="Código" field="PresupuestoId"></Column>
        <Column field="CentroCostoNombre" header="Centro de costos"></Column>
        <Column field="Nombre" header="Nombre"></Column>
        <Column
          field="FechaInicio"
          header="Fecha de inicio"
          body={(rowData) => formatDate(rowData.FechaFin)}
        ></Column>
        <Column
          field="FechaFin"
          header="Fecha de fin"
          body={(rowData) => formatDate(rowData.FechaInicio)}
        ></Column>
        <Column
          header="Estado"
          field="EstadoId"
          body={(data) => (
            <StatusTag
              status={data.Estado.EstadoNombre ?? "Activo"}
            ></StatusTag>
          )}
        ></Column>
        <Column
          field=""
          header={
            <div className="flex justify-content-center align-items-center ml-6">
              <Icon name="click" size={"middle"}></Icon>
            </div>
          }
          body={(rowData) => {
            return (
              <div className="flex justify-content-center gap-2">
                <Icon
                  name="pencil"
                  size={"middle"}
                  onClick={() => {
                    setEditDialogBudget(rowData.PresupuestoId),
                      setDialogBudget(true);
                  }}
                ></Icon>
                <Icon
                  size={"middle"}
                  name="list-details"
                  onClick={() => {
                    navigate(
                      `.${CostsCenterRoutes[
                        CostsCenterRouteNames.BUDGET_DETAIL_PAGE
                      ].create({ id: `${rowData.PresupuestoId}` })}`,
                    );
                  }}
                ></Icon>
                <Icon
                  name="trash"
                  size={"middle"}
                  onClick={() => {
                    setAnular(true),
                      setEstadoPresupuesto(rowData.EstadoId),
                      setSelectPresupuestoId(rowData.PresupuestoId);
                  }}
                ></Icon>
              </div>
            );
          }}
        ></Column>
      </DataTable>

      <ConfirmDialog
        visible={anular}
        onHide={() => {
          setAnular(false), setSelectPresupuestoId(0);
        }}
        message={`¿Está seguro de que desea ${estadoPresupuesto === 1 ? "anular" : "activar"} este proveedor?`}
        accept={() =>
          changeStatePresupuesto.mutateAsync({
            Id: selectPresupuestoId,
            EstadoId: estadoPresupuesto === 1 ? 3 : 1,
          })
        }
        header={
          <div className="flex flex-row align-items-center mt-0 mb-0">
            <Icon
              size={"small"}
              className="mr-2"
              name={estadoPresupuesto === 1 ? "trash" : "plug"}
            ></Icon>
            <p className="text-base">{`${estadoPresupuesto === 1 ? "Anular" : "Activar"} proveedor`}</p>
          </div>
        }
      />

      <Dialog
        visible={dialogBudget}
        className="flex justify-content-center w-8 z-5"
        onHide={() => {
          setDialogBudget(false), setEditDialogBudget(0);
        }}
        title={
          editDialogBudget !== 0 ? "Editar presupuesto" : "Crear presupuesto"
        }
        footer={[
          <div key={1} className="flex justify-content-end gap-2">
            <Button
              outlined
              label="Cancelar"
              onClick={() => {
                setDialogBudget(false), setEditDialogBudget(0);
              }}
            ></Button>
            <Button
              onClick={form.handleSubmit(onSubmit)}
              label={editDialogBudget !== 0 ? "Editar" : "Crear"}
            ></Button>
          </div>,
        ]}
      >
        <Form form={form}>
          <div className="flex flex-row formgrid grid">
            <div className="flex flex-row field col-12">
              <div className="flex flex-column field col-6">
                <Dropdown
                  name="CentroCostoId"
                  label="Centro de costos"
                  placeholder="Selecciona un centro de costos"
                  rules={{ required: "Este campo es requerido" }}
                  options={[
                    { value: 1, label: "C-1" },
                    { value: 2, label: "C-2" },
                    { value: 3, label: "C-3" },
                  ]}
                />
              </div>
              <div className="flex flex-column field col-6">
                <InputText
                  name="Nombre"
                  label="Nombre"
                  placeholder="Ingrese el nombre"
                  rules={{ required: "Este campo es requerido" }}
                ></InputText>
              </div>
            </div>

            {editDialogBudget !== 0 ? null : (
              <div className="flex flex-row field col-12">
                <div className="flex flex-column field col-6">
                  <Calendar
                    baseZIndex={1102}
                    name="FechaInicio"
                    label="Fecha de inicio"
                    placeholder="Seleccione la fecha de inicio"
                    rules={{ required: "Este campo es requerido" }}
                  ></Calendar>
                </div>
                <div className="flex flex-column field col-6">
                  <Calendar
                    name="FechaFin"
                    baseZIndex={1102}
                    label="Fecha de fin"
                    placeholder="Seleccione la fecha de fin"
                    rules={{ required: "Este campo es requerido" }}
                  ></Calendar>
                </div>
              </div>
            )}

            <div className="flex flex-column field col-12 pl-3 pr-3">
              <InputTextarea
                name="Descripcion"
                label="Descripción"
                placeholder="Escribe una descripción"
                rules={{ required: "Este campo es requerido" }}
              ></InputTextarea>
            </div>
          </div>
        </Form>
      </Dialog>
    </div>
  );
};
export default BudgetPage;
