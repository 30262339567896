import React from "react";
import { group } from "radash";
import { ArrayUtils } from "@nubeteck/utils";
import { Divider } from "primereact/divider";
import { Dropdown, IDropdownProps } from "@components";

import { useDashboardQuery } from "../../hooks";

export interface IDataSourcesDropdownProps extends IDropdownProps {}

const DataSourcesDropdown = (props: IDataSourcesDropdownProps) => {
  const dataSources = useDashboardQuery("DataSources", "getAll");

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const groupedItemTemplate = React.useCallback((option: any) => {
    return (
      <Divider className="m-0 p-0">
        <span className="text-sm font-semibold">{option.label}</span>
      </Divider>
    );
  }, []);

  const dataSourcesOptions = React.useMemo(() => {
    if (!dataSources.data?.length) return [];
    const grouped = group(
      dataSources.data,
      (dataSource) => dataSource.ModuloNombre,
    );
    return Object.entries(grouped).map(([keys, items]) => {
      return {
        label: keys,
        items: ArrayUtils.selectLabelValue(
          items ?? [],
          "NombreFuente",
          "FuenteId",
        ),
      };
    });
  }, [dataSources.data]);

  return (
    <Dropdown
      {...props}
      optionLabel="label"
      optionGroupLabel="label"
      className="p-inputtext-sm"
      optionGroupChildren="items"
      options={dataSourcesOptions}
      loading={dataSources.isPending}
      optionGroupTemplate={groupedItemTemplate}
    />
  );
};

export default DataSourcesDropdown;
