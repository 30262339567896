import API from "@lib/api";
import { BaseApi } from "@api";
import { IFile, Messages, QueryFilter } from "@interfaces";

import {
  IMonthlyClosing,
  IAccountingMovement,
  IAccountingMovementForm,
  IAccountCatalogDetailSelect,
} from "../interfaces";

interface IChangeStatuCierreMensual {
  Id: number;
  EstadoId: number;
}

export class AccountingMovementsApi extends BaseApi {
  protected static readonly api = API({ namespace: "MovimientosContables" });
  protected static readonly filterApi = API({ namespace: "Filtro" });
  protected static readonly messages: Messages<typeof AccountingMovementsApi> =
    {
      createFile: {
        error: (error) => error,
        loading: "Subiendo archivo...",
        success: "Archivo subido correctamente.",
      },
      createCierreMensual: {
        error: (error) => error,
        loading: "Creando nuevo mes...",
        success: "Nuevo mes creado correctamente.",
      },
      delete: {
        error: (error) => error,
        loading: "Anulando el movimiento contable...",
        success: "Movimiento contable anulado correctamente.",
      },
      create: {
        error: (error) => error,
        loading: "Creando un nuevo movimiento contable...",
        success: "Movimiento contable creado correctamente.",
      },
      update: {
        error: (error) => error,
        loading: "Actualizando movimiento contable...",
        success: "Movimiento contable actualizado correctamente.",
      },
      changeStateCierreMensual: {
        error: (error) => error,
        loading: "Cambiando estado del mes...",
        success: "Se ha cambiado el estado del mes correctamente.",
      },
    };

  public static async getAll(): Promise<IAccountingMovement[]> {
    const result = await this.api.get("GetAll");
    return result.data;
  }

  public static async getAllByFilter(params: {
    filterId: number;
    page: number;
    pageSize: number;
  }) {
    const result = await this.filterApi.get<QueryFilter<IAccountingMovement[]>>(
      `FiltroPorArea/${params.filterId}?PageSize=${params.pageSize}&Page=${params.page}`,
    );
    return result.data;
  }

  public static async getById(id: number): Promise<IAccountingMovement> {
    const result = await this.api.get(`GetById/${id}`);
    return result.data;
  }

  public static async getByBorrador(): Promise<IAccountingMovement[]> {
    const result = await this.api.get("GetByBorrador");
    return result.data;
  }

  public static async getCreate(): Promise<IAccountingMovementForm> {
    const result = await this.api.get("Create");
    return result.data;
  }

  public static async getUpdate(id: number): Promise<IAccountingMovementForm> {
    const result = await this.api.get(`Update/${id}`);
    return result.data;
  }

  public static async getAccountCatalogDetailsSelect(
    id: number,
  ): Promise<IAccountCatalogDetailSelect[]> {
    const result = await this.api.get(`GetCatalogosCuentasDetalleSelect/${id}`);
    return result.data;
  }

  public static async create(
    data: IAccountingMovement,
  ): Promise<IAccountingMovement> {
    const result = await this.api.post("Create", data);
    return result.data;
  }

  public static async createFile(data: FormData): Promise<{ FileId: number }> {
    const result = await this.api.post("UploadFile", data, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    return result.data;
  }

  public static async downloadFile(id: number): Promise<IFile> {
    const result = await this.api.get(`DownloadFile/${id}`);
    return result.data;
  }

  public static async update(
    data: IAccountingMovement,
  ): Promise<IAccountingMovement> {
    const result = await this.api.put(`Update/${data.AsientoId}`, data);
    return result.data;
  }

  public static async delete(id: number): Promise<IAccountingMovement> {
    const result = await this.api.delete(`Delete/${id}`);
    return result.data;
  }

  public static async createCierreMensual(
    data: IMonthlyClosing,
  ): Promise<IMonthlyClosing> {
    const result = await this.api.post("CreateCierreMensual", data);
    return result.data;
  }

  public static async getMesesCierreMensual(): Promise<IMonthlyClosing[]> {
    const result = await this.api.get("GetMesesCierreMensual");
    return result.data;
  }

  public static async changeStateCierreMensual(
    data: IChangeStatuCierreMensual,
  ) {
    const result = await this.api.post("ChangeStateCierreMensual", {
      Id: data.Id,
      EstadoId: data.EstadoId,
    });
    return result.data;
  }
}
