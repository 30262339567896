import React from "react";
import { Fallbacks } from "@core";
import { faker } from "@faker-js/faker";
import { Avatar } from "primereact/avatar";
import { Sidebar } from "primereact/sidebar";

interface IContactInfoSidebarProps {
  id?: number;
  title: string;
  visible: boolean;
  onHide: () => void;
}

const ContactInfoSidebar = ({
  id,
  title,
  onHide,
  visible,
}: IContactInfoSidebarProps) => {
  id; // Pa que no grite
  return (
    <Sidebar header={title} onHide={onHide} visible={visible}>
      <div className="flex justify-content-center">
        <Avatar
          shape="circle"
          image={faker.image.avatarGitHub()}
          imageFallback={Fallbacks.AVATAR_FALLBACK}
          style={{ width: "8rem", height: "8rem" }}
        />
      </div>
    </Sidebar>
  );
};

export default ContactInfoSidebar;
