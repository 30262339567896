import API from "@lib/api";
import { BaseApi } from "@api";
import { Messages } from "@interfaces";

import { IPresupuestos, IPresupuestoCreate } from "../interfaces";

interface IChangeStatuPresuspuesto {
  Id: number;
  EstadoId: number;
}

export class BudgetApi extends BaseApi {
  protected static api = API({ namespace: "Presupuesto" });
  protected static filterApi = API({ namespace: "Filtro" });
  protected static messages: Messages<typeof BudgetApi> = {
    create: {
      loading: "Creando presupuesto...",
      success: "Presupuesto creado correctamente.",
      error: (error) => `Hubo un error al crear el presupuesto: ${error}`,
    },
    update: {
      loading: "Actualizando el presupuesto...",
      success: "Presupuesto actualizado correctamente.",
      error: (error) => `Hubo un error al actualizar el presupuesto: ${error}`,
    },
    changeStatePresupuesto: {
      loading: "Cambiando estado del presupuesto...",
      success: "Se ha cambiado el estado del presupuesto correctamente.",
      error: (error) =>
        `Hubo un error al cambiar el estado del presupuesto: ${error}`,
    },
  };

  public static async getAll(): Promise<IPresupuestos[]> {
    const result = await this.api.get("GetAll");
    return result.data;
  }

  public static async getById(id: number): Promise<IPresupuestoCreate> {
    const result = await this.api.get(`GetById/${id}`);
    return result.data;
  }

  public static async create(data: IPresupuestoCreate) {
    const result = await this.api.post("CreatePresupuesto", data);
    return result.data;
  }
  public static async update(data: Partial<IPresupuestoCreate>) {
    const result = await this.api.put(
      `UpdatePresupuesto/${data.PresupuestoId}`,
      data,
    );
    return result.data;
  }

  public static async changeStatePresupuesto(data: IChangeStatuPresuspuesto) {
    const result = await this.api.post("ChangeStatePresupuesto?Id", {
      Id: data.Id,
      EstadoId: data.EstadoId,
    });
    return result.data;
  }
}
