import React from "react";
import { Statuses } from "@core";
import { capitalize } from "radash";
import { Icon } from "@nubeteck/icons";
import { Datejs } from "@nubeteck/utils";
import { Button } from "primereact/button";
import { SubmitHandler } from "react-hook-form";
import { useAuthPermissions } from "@nubeteck/auth";
import { useQueryClient } from "@tanstack/react-query";
import { Form, useForm, Checkbox } from "@nubeteck/forms";
import { CardTable, IDataTableColumnsProps } from "@nubeteck/prime";
import { useGetParams, useGlobalQuery, useGlobalMutation } from "@hooks";
import {
  IStrategicPlansActivity,
  IStrategicPlansActivitySchedule,
} from "@interfaces";

import { Permissions } from "../../core";

export interface ISchedulesTabProps {
  activity?: IStrategicPlansActivity;
}

type FormValues = Record<string, boolean>;

const SchedulesTab = ({ activity }: ISchedulesTabProps) => {
  const queryClient = useQueryClient();
  const strategicId = useGetParams("id");
  const permissions = useAuthPermissions();

  const createSchedules = useGlobalMutation(
    "StrategicPlansSchedules",
    "create",
  );
  const poa = useGlobalQuery("StrategicPlans", "getById", strategicId ?? 0, {
    enabled: !!strategicId,
  });

  const isDraft = poa.data?.EstadoId === Statuses.DRAFT_CODE;

  const form = useForm<FormValues>();
  const { reset } = form;

  React.useEffect(() => {
    if (activity) {
      reset(
        Array.from({ length: 12 }).reduce<Record<string, boolean>>(
          (acc, _, index) => {
            const activityFound = activity.Cronogramas?.find(
              (schedule) => schedule.MesId === index + 1,
            );
            acc[`Mes${index}`] = activityFound?.EstadoId === 1;
            return acc;
          },
          {},
        ),
      );
    }
  }, [activity, reset]);

  const invalidateDetails = React.useCallback(() => {
    queryClient.invalidateQueries({ queryKey: ["StrategicPlansActivities"] });
  }, [queryClient]);

  const onSubmit: SubmitHandler<FormValues> = React.useCallback(
    (values) => {
      const schedules = Object.entries(
        values,
      ).map<IStrategicPlansActivitySchedule>(([key, value]) => {
        const month = parseInt(key.replace("Mes", ""), 10) + 1;
        const cronograma = activity?.Cronogramas?.find(
          (schedule) => schedule.MesId === month,
        );
        return {
          MesId: month,
          EstadoId: value ? 1 : 2,
          ActividadId: activity?.ActividadId ?? 0,
          CronogramaId: cronograma?.CronogramaId ?? 0,
          Anio: parseInt(Datejs(poa.data?.FechaFin).format("YYYY")),
        };
      });
      return createSchedules.mutateAsync(schedules, {
        onSuccess: invalidateDetails,
      });
    },
    [
      activity?.ActividadId,
      activity?.Cronogramas,
      createSchedules,
      invalidateDetails,
      poa.data?.FechaFin,
    ],
  );

  const columns: IDataTableColumnsProps<Record<string, boolean>>[] = Array.from(
    { length: 12 },
    (_, index) => {
      const month = capitalize(Datejs().month(index).format("MMMM"));
      return {
        header: month,
        align: "center",
        field: `Mes${index}`,
        body: () => {
          return (
            <div className="flex justify-content-center">
              <Checkbox
                label=""
                defaultValue={false}
                name={`Mes${index}`}
                fieldClassName="m-0"
                disabledInput={
                  !isDraft ||
                  !permissions.hasPermission(
                    Permissions.CONFIGURE_SCHEDULE_ACTIVITIES_POA,
                  )
                }
              />
            </div>
          );
        },
      };
    },
  );

  return (
    <Form form={form}>
      <div className="flex flex-column gap-5">
        <CardTable
          type="data"
          showGridlines
          hideSelectFilter
          hideGlobalSearch
          hideBorderStyles
          columns={columns}
          tableActions={[]}
          paginator={false}
          value={[form.watch()]}
          hideFilterSettingsIcon
          title={`Cronogramas del ${Datejs(poa.data?.FechaInicio).format("YYYY")}`}
          headActions={[
            <Button
              size="small"
              key="SaveSchedule"
              label="Guardar cambios"
              onClick={form.handleSubmit(onSubmit)}
              icon={<Icon size={20} className="mr-1" name="device-floppy" />}
              disabled={
                !form.formState.isDirty ||
                !isDraft ||
                !permissions.hasPermission(
                  Permissions.CONFIGURE_SCHEDULE_ACTIVITIES_POA,
                )
              }
            />,
          ]}
        />
      </div>
    </Form>
  );
};

export default SchedulesTab;
