import React from "react";
import { Icon } from "@nubeteck/icons";
import { renderTooltip } from "@utils";
import { Button } from "primereact/button";
import { useNavigate } from "react-router";
import { Skeleton } from "primereact/skeleton";
import { useAuthPermissions } from "@nubeteck/auth";
import { TabView, TabPanel } from "primereact/tabview";
import { Datejs, JsxUtil, EventUtils } from "@nubeteck/utils";
import { Reducers, Statuses, StrategicPlansTypes } from "@core";
import { useGetParams, useGlobalQuery, useGlobalMutation } from "@hooks";
import { StatusTag, Description, useHeaderActions } from "@nubeteck/prime";
import {
  Unauthorized,
  ChangeLogDialog,
  RequestCommentsCard,
  RequestCommentsDialog,
} from "@components";

import { Permissions } from "../../core";
import { CostsCenterRoutes, CostsCenterRouteNames } from "../../routes";
import {
  PeuDetailsTable,
  ExecutionPoaTab,
  PoaDetailsTable,
} from "../../components";

const PeuPoaDetails = () => {
  const navigate = useNavigate();
  const permissions = useAuthPermissions();
  const strategicPlanId = useGetParams("id");
  const { dispatch: dispatchHeaderActions } = useHeaderActions();

  const {
    mutateAsync: sendRequestReview,
    isPending: isPendingSendRequestReview,
  } = useGlobalMutation("StrategicPlans", "sendRequestReview");
  const strategicPlan = useGlobalQuery(
    "StrategicPlans",
    "getById",
    strategicPlanId,
    { enabled: !!strategicPlanId },
  );

  const isPeu = strategicPlan.data?.TipoId === StrategicPlansTypes.PEU;
  const isDraft = strategicPlan.data?.EstadoId === Statuses.DRAFT_CODE;
  const isActive = strategicPlan.data?.EstadoId === Statuses.ACTIVE_CODE;

  const [requestReviewOpen, setRequestReviewOpen] = React.useState(false);
  const [changeLogState, changeLogDispatch] = React.useReducer(
    Reducers.DialogReducer,
    { id: 0, open: false },
  );

  React.useEffect(() => {
    dispatchHeaderActions({
      type: "SET_VARIABLES",
      payload: { pageTitle: strategicPlan.data?.Nombre || "..." },
    });
    return () => dispatchHeaderActions({ type: "CLEAR_VARIABLES" });
  }, [dispatchHeaderActions, strategicPlan.data]);

  React.useEffect(() => {
    if (strategicPlan.data) {
      dispatchHeaderActions({
        type: "SET_ACTIONS",
        payload: [
          permissions.hasPermission(
            isPeu ? Permissions.SEE_LOGS_PEU : Permissions.SEE_LOGS_POA,
          ) ? (
            <React.Fragment key="Logs">
              {renderTooltip(
                "Historial de cambios",
                "changelog-peu-poa",
                "bottom",
              )}
              <Icon
                key=""
                name="book"
                className="changelog-peu-poa align-self-center text-primary"
                onClick={EventUtils.callEvent(changeLogDispatch, {
                  type: "OPEN_DIALOG",
                  payload: strategicPlanId,
                })}
              />
            </React.Fragment>
          ) : (
            <></>
          ),
          !isPeu && isActive ? (
            <React.Fragment key="Reporting">
              {renderTooltip("Reporte del POA", "report-peu-poa", "bottom")}
              <Icon
                key=""
                name="report"
                className="report-peu-poa align-self-center text-primary"
                onClick={() => {
                  navigate(
                    `.${CostsCenterRoutes[CostsCenterRouteNames.REPORTING_PAGE].create({})}`,
                  );
                }}
              />
            </React.Fragment>
          ) : (
            <></>
          ),
          !isPeu &&
          !isActive &&
          permissions.hasPermission(Permissions.SEE_POA_ACTIVITIES_PAGE) ? (
            <Button
              size="small"
              key="NewStrategicPlanDetail"
              label="Ver todas las actividades"
              icon={<Icon size={18} name="activity" className="mr-1" />}
              onClick={() => {
                navigate(
                  `.${CostsCenterRoutes[CostsCenterRouteNames.POA_ACTIVITIES_PAGE].create({})}`,
                );
              }}
            />
          ) : (
            <></>
          ),
          isDraft &&
          permissions.hasPermission(
            isPeu
              ? Permissions.REQUEST_REVIEWS_COMMENTS_PEU
              : Permissions.REQUEST_REVIEWS_COMMENTS_POA,
          ) ? (
            <Button
              size="small"
              severity="info"
              key="RequestReview"
              label="Solicitar revision"
              loading={isPendingSendRequestReview}
              icon={<Icon size={18} name="send" className="mr-1" />}
              onClick={async () => {
                await sendRequestReview(strategicPlanId ?? 0);
              }}
            />
          ) : (
            <></>
          ),
          strategicPlan.data.EstadoId === Statuses.PENDING_APPROVAL_CODE &&
          permissions.hasPermission(
            isPeu
              ? Permissions.APPROVE_REJECT_PEU
              : Permissions.APPROVE_REJECT_POA,
          ) ? (
            <Button
              size="small"
              iconPos="right"
              label="Revisión"
              onClick={EventUtils.callEvent(setRequestReviewOpen, true)}
              icon={<Icon size={18} className="mr-1" name="eye-search" />}
            />
          ) : (
            <></>
          ),
        ],
      });
    }
    return () => dispatchHeaderActions({ type: "CLEAR_ACTIONS" });
  }, [
    dispatchHeaderActions,
    isActive,
    isDraft,
    isPendingSendRequestReview,
    isPeu,
    navigate,
    permissions,
    sendRequestReview,
    strategicPlan.data,
    strategicPlanId,
  ]);

  const stateId = strategicPlan.data?.EstadoId ?? 0;
  const peiId = strategicPlan.data?.Relacion?.PeiId ?? 0;
  const templateId = strategicPlan.data?.PlantillaId ?? 0;
  const detailsChildren = strategicPlan.data?.Detalles ?? [];

  if (
    !permissions.hasPermission(
      isPeu
        ? Permissions.SEE_PEU_DETAILS_PAGE
        : Permissions.SEE_POA_DETAILS_PAGE,
    )
  ) {
    return <Unauthorized />;
  }

  return (
    <>
      <div className="flex flex-column gap-3">
        {JsxUtil.renderLoader(
          strategicPlan.isPending,
          <>
            <Skeleton height="7rem" />
            <Skeleton height="15rem" />
            <Skeleton height="20rem" />
          </>,
        )(
          <>
            <Description
              vertical
              title="Información general"
              columns={{ sm: 1, md: 2, lg: 3, xl: 3 }}
              items={[
                {
                  label: "Plan estratégico",
                  value: strategicPlan.data?.Nombre ?? "",
                },
                {
                  label: "Tipo",
                  value: strategicPlan.data?.TipoNombre ?? "",
                },
                {
                  label: "Responsable",
                  value:
                    strategicPlan.data?.ResponsableNombre ?? "Sin responsable",
                },
                {
                  label: "Centro de costo",
                  value:
                    strategicPlan.data?.CentroCostoNombre ??
                    "Sin centro de costo",
                },
                {
                  label: "Periodo",
                  value: `Desde ${Datejs(strategicPlan.data?.FechaInicio).format("L")} hasta ${Datejs(strategicPlan.data?.FechaFin).format("L")}`,
                },
                {
                  label: "Estado",
                  value: (
                    <StatusTag
                      status={strategicPlan.data?.EstadoNombre ?? "Activo"}
                    />
                  ),
                },
              ]}
            />
            <TabView panelContainerClassName="p-0">
              <TabPanel
                header="Detalles"
                leftIcon={
                  <Icon size={20} className="mr-1" name="list-details" />
                }
              >
                {isPeu ? (
                  <PeuDetailsTable
                    peiId={peiId}
                    stateId={stateId}
                    templateId={templateId}
                    detailsChildren={detailsChildren}
                  />
                ) : (
                  <PoaDetailsTable
                    peiId={peiId}
                    stateId={stateId}
                    templateId={templateId}
                    detailsChildren={detailsChildren}
                    peuId={strategicPlan.data?.Relacion?.PeuId ?? 0}
                  />
                )}
              </TabPanel>
              {!isPeu &&
              isActive &&
              permissions.hasPermission(Permissions.SEE_POA_EXECUTION_TAB) ? (
                <TabPanel
                  header="Ejecución"
                  leftIcon={<Icon size={20} name="axe" className="mr-1" />}
                >
                  <ExecutionPoaTab />
                </TabPanel>
              ) : null}
            </TabView>
            {!isActive &&
            permissions.hasPermission(
              isPeu
                ? Permissions.SEE_REVIEWS_COMMENTS_PEU
                : Permissions.SEE_REVIEWS_COMMENTS_POA,
            ) ? (
              <RequestCommentsCard strategicPlanId={strategicPlanId} />
            ) : null}
          </>,
        )}
      </div>
      <ChangeLogDialog
        entityId={changeLogState.id}
        visible={changeLogState.open}
        entityName="planestrategicoid"
        onHide={EventUtils.callEvent(changeLogDispatch, {
          type: "CLOSE_DIALOG",
        })}
      />
      <RequestCommentsDialog
        visible={requestReviewOpen}
        strategicPlanId={strategicPlanId}
        onHide={EventUtils.callEvent(setRequestReviewOpen, false)}
      />
    </>
  );
};

export default PeuPoaDetails;
