import { Calendar } from "primereact/calendar";
import { ColumnFilterElementTemplateOptions } from "primereact/column";

/**
 * Creates a date filter template for use in PrimeReact DataTable columns.
 *
 * @param {ColumnFilterElementTemplateOptions} options - The options provided by PrimeReact for column filtering.
 * @returns {JSX.Element} A Calendar component configured for date filtering.
 *
 * @example
 * {
 *   filter: true,
 *   sortable: true,
 *   field: "Fecha",
 *   header: "Fecha",
 *   dataType: "date",
 *   showAddButton: false,
 *   showFilterOperator: false,
 *   filterElement: DateFilterTemplate,
 *   body: (rowData) => Datejs(rowData.Fecha).format("DD/MM/YYYY"),
 * }
 */
export const DateFilterTemplate = (
  options: ColumnFilterElementTemplateOptions,
) => {
  return (
    <Calendar
      mask="99/99/9999"
      value={options.value}
      dateFormat="dd/mm/yy"
      placeholder="dd/mm/yyyy"
      onChange={(e) => options.filterCallback(e.value, options.index)}
    />
  );
};
