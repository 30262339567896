import { IRouter } from "@interfaces";
import { Outlet } from "react-router";

import { Permissions } from "./core";
import { AccountsPayableRoutes, AccountsPayableRouteNames } from "./routes";
import {
  ProviderPage,
  ProviderFormPage,
  DocumentUploadPage,
  ProviderDetailPage,
  AccountsPayablePage,
  PaymentRequestsPage,
  DeliveryNoteEditPage,
  PurchaseDocumentsPage,
  PurchaseOrderEditPage,
  DeliveryNoteDetailPage,
  PurchaseInvoiceEditPage,
  PurchaseOrderDetailPage,
  PurchaseInvoiceDetailPage,
  RecurringPurchaseInvoicesPage,
} from "./pages";

const router: IRouter = {
  sidebar: true,
  element: <Outlet />,
  iconName: "receipt",
  title: "Cuentas por pagar",
  id: "accounts-payable-root",
  permissions: [Permissions.SEE_ACCOUNTS_PAYABLE_MODULE],
  path: AccountsPayableRoutes[
    AccountsPayableRouteNames.DASHBOARD_PAGE
  ].template(),
  children: [
    {
      path: "",
      index: true,
      sidebar: true,
      dashboardId: 2065,
      iconName: "dashboard",
      title: "Panel de control",
      id: "accounts-payable-dashboard-page",
    },
    {
      sidebar: true,
      iconName: "receipt",
      title: "Cuentas por pagar",
      id: "accounts-payable-page",
      element: <AccountsPayablePage />,
      path: AccountsPayableRoutes[
        AccountsPayableRouteNames.ACCOUNTS_PAYABLE
      ].template(),
    },
    {
      sidebar: true,
      iconName: "receipt",
      title: "Solicitudes pagos",
      id: "payment-requests-page",
      element: <PaymentRequestsPage />,
      path: AccountsPayableRoutes[
        AccountsPayableRouteNames.PAYMENT_REQUESTS
      ].template(),
    },
    {
      sidebar: true,
      element: <Outlet />,
      iconName: "shopping-cart",
      id: "purchase-documents-root",
      title: "Documentos de compra",
      path: AccountsPayableRoutes[
        AccountsPayableRouteNames.PURCHASE_DOCUMENTS
      ].template(),
      children: [
        {
          path: "",
          title: "",
          sidebar: false,
          id: "purchase-documents-page",
          element: <PurchaseDocumentsPage />,
        },
        {
          sidebar: false,
          id: "recurring-purchase-invoices-page",
          title: "Facturas de compras recurrentes",
          element: <RecurringPurchaseInvoicesPage />,
          path: AccountsPayableRoutes[
            AccountsPayableRouteNames.RECURRING
          ].template(),
        },
        {
          sidebar: false,
          title: "Factura de compra",
          id: "purchase-invoice-form-page",
          element: <PurchaseInvoiceEditPage />,
          path: AccountsPayableRoutes[
            AccountsPayableRouteNames.PURCHASE_INVOICE
          ].template(),
        },
        {
          sidebar: false,
          title: "Orden de compra",
          id: "purchase-order-form-page",
          element: <PurchaseOrderEditPage />,
          path: AccountsPayableRoutes[
            AccountsPayableRouteNames.PURCHASE_ORDER
          ].template(),
        },
        {
          sidebar: false,
          title: "Conduce",
          element: <DeliveryNoteEditPage />,
          id: "purchase-delivery-note-form-page",
          path: AccountsPayableRoutes[
            AccountsPayableRouteNames.PURCHASE_DELIVERY_NOTE
          ].template(),
        },
        {
          sidebar: false,
          title: "Detalle factura de compra",
          id: "purchase-invoice-detail-page",
          element: <PurchaseInvoiceDetailPage />,
          path: AccountsPayableRoutes[
            AccountsPayableRouteNames.PURCHASE_INVOICE_DETAIL
          ].template(),
        },
        {
          sidebar: false,
          title: "Detalle orden de compra",
          id: "purchase-order-detail-page",
          element: <PurchaseOrderDetailPage />,
          path: AccountsPayableRoutes[
            AccountsPayableRouteNames.PURCHASE_ORDER_DETAIL
          ].template(),
        },
        {
          sidebar: false,
          title: "Detalle conduce",
          element: <DeliveryNoteDetailPage />,
          id: "purchase-delivery-note-detail-page",
          path: AccountsPayableRoutes[
            AccountsPayableRouteNames.PURCHASE_DELIVERY_NOTE_DETAIL
          ].template(),
        },
      ],
    },
    {
      sidebar: true,
      iconName: "file-upload",
      id: "document-upload-page",
      title: "Carga de documentos",
      element: <DocumentUploadPage />,
      path: AccountsPayableRoutes[
        AccountsPayableRouteNames.DOCUMENT_UPLOAD
      ].template(),
    },
    {
      sidebar: true,
      iconName: "package",
      element: <Outlet />,
      title: "Proveedores",
      id: "general-ledger-provider-root",
      path: AccountsPayableRoutes[
        AccountsPayableRouteNames.PROVIDER_PAGE
      ].template(),
      children: [
        {
          path: "",
          index: true,
          sidebar: false,
          title: "Proveedores",
          id: "general-ledger-provider-page",
          element: <ProviderPage></ProviderPage>,
        },
        {
          sidebar: false,
          title: "{name}",
          id: "general-ledger-provider-details-page",
          element: <ProviderDetailPage></ProviderDetailPage>,
          path: AccountsPayableRoutes[
            AccountsPayableRouteNames.PROVIDER_DETAILS_PAGE
          ].template(),
        },
        {
          sidebar: false,
          title: "{title}",
          id: "general-ledger-provider-form-page",
          element: <ProviderFormPage></ProviderFormPage>,
          path: AccountsPayableRoutes[
            AccountsPayableRouteNames.PROVIDER_FORM_PAGE
          ].template(),
        },
      ],
    },
  ],
};

export default router;
