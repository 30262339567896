import React from "react";
import { omit } from "radash";
import { useForm } from "react-hook-form";
import { Button } from "primereact/button";
import { ICostsCenter } from "@interfaces";
import { ArrayUtils } from "@nubeteck/utils";
import { DialogProps } from "primereact/dialog";
import { Form, Dialog, Dropdown, InputText, InputTextarea } from "@components";

import { useCostCenterQuery, useCostCenterMutation } from "../../hooks";

export interface ISettingsCostsCenterDialogProps extends DialogProps {
  costsCenterId: number;
}

type FormValues = ICostsCenter;

const model = {
  to: (data: FormValues): ICostsCenter => {
    return {
      Nombre: data.Nombre,
      EstadoId: data.EstadoId,
      SucursalId: data.SucursalId,
      Descripcion: data.Descripcion,
      CentroCostoId: data.CentroCostoId,
    };
  },
  from: (data: ICostsCenter): FormValues => {
    return {
      Nombre: data.Nombre,
      EstadoId: data.EstadoId,
      SucursalId: data.SucursalId,
      Descripcion: data.Descripcion,
      CentroCostoId: data.CentroCostoId,
    };
  },
};

const SettingsCostsCenterDialog = ({
  costsCenterId,
  ...props
}: ISettingsCostsCenterDialogProps) => {
  const createCostCenter = useCostCenterMutation("CostsCenter", "create");
  const updateCostCenter = useCostCenterMutation("CostsCenter", "update");

  const getCreate = useCostCenterQuery("CostsCenter", "getCreate", undefined, {
    enabled: !costsCenterId,
  });
  const getUpdate = useCostCenterQuery(
    "CostsCenter",
    "getUpdate",
    costsCenterId,
    { enabled: !!costsCenterId },
  );

  const form = useForm<FormValues>({});
  const { reset } = form;

  React.useEffect(() => {
    if (!costsCenterId && getCreate.data) {
      reset(omit(getCreate.data, ["SucursalSelect"]), {
        keepDefaultValues: false,
      });
    }
  }, [getCreate.data, costsCenterId, reset]);

  React.useEffect(() => {
    if (costsCenterId && getUpdate.data) {
      reset(omit(getUpdate.data, ["SucursalSelect"]), {
        keepDefaultValues: true,
      });
    }
  }, [getUpdate.data, costsCenterId, reset]);

  const handleSuccess = () => {
    reset();
    props.onHide();
  };

  const onSubmit = (values: FormValues) => {
    if (costsCenterId) {
      return updateCostCenter.mutateAsync(model.to(values), {
        onSuccess: handleSuccess,
      });
    }
    return createCostCenter.mutateAsync(model.to(values), {
      onSuccess: handleSuccess,
    });
  };

  const branches = React.useMemo(() => {
    if (costsCenterId) return getUpdate.data?.SucursalSelect ?? [];
    else return getCreate.data?.SucursalSelect ?? [];
  }, [
    getCreate.data?.SucursalSelect,
    getUpdate.data?.SucursalSelect,
    costsCenterId,
  ]);

  const branchesOptions = React.useMemo(() => {
    if (!branches.length) return [];
    return ArrayUtils.selectLabelValue(
      branches ?? [],
      "SucursalNombre",
      "SucursalId",
    );
  }, [branches]);

  const validations = React.useMemo(() => {
    if (costsCenterId) return getUpdate.data?.Validaciones;
    else return getCreate.data?.Validaciones;
  }, [
    getCreate.data?.Validaciones,
    getUpdate.data?.Validaciones,
    costsCenterId,
  ]);

  return (
    <Dialog
      {...props}
      className="w-full md:w-8 lg:w-5"
      subtitle="Rellena los datos del formulario"
      onHide={() => {
        if (costsCenterId) form.reset();
        props.onHide();
      }}
      title={
        costsCenterId
          ? `Editando centro de costo`
          : "Creando nuevo centro de costo"
      }
      footer={(props) => (
        <div className="flex flex-1 justify-content-end gap-2">
          <Button
            size="small"
            label="Cancelar"
            severity="secondary"
            onClick={() => {
              form.reset();
              props.onHide();
            }}
          />
          <Button
            size="small"
            onClick={form.handleSubmit(onSubmit)}
            loading={createCostCenter.isPending || updateCostCenter.isPending}
            label={costsCenterId ? "Guardar cambios" : "Crear centro de costo"}
          />
        </div>
      )}
    >
      <Form form={form}>
        <InputText
          name="Nombre"
          label="Nombre"
          className="p-inputtext-sm"
          rules={validations?.Nombre}
          placeholder="Escribe un nombre..."
        />
        <Dropdown
          name="SucursalId"
          label="Sucursales"
          options={branchesOptions}
          className="p-inputtext-sm"
          rules={validations?.SucursalId}
          placeholder="Selecciona una sucursal"
        />
        <InputTextarea
          name="Descripcion"
          label="Descripción"
          fieldClassName="flex-1"
          rules={validations?.Descripcion}
          className="p-inputtext-sm flex-1"
          placeholder="Escribe una descripción..."
        />
      </Form>
    </Dialog>
  );
};

export default SettingsCostsCenterDialog;
