import { Fallbacks } from "@core";
import { Avatar } from "primereact/avatar";
import { DropdownProps } from "primereact/dropdown";

export const ImageWithLabelTemplate = (
  option: { label: string; src: string },
  props: DropdownProps,
) => {
  if (option) {
    return (
      <div className="flex align-items-center">
        <Avatar
          shape="circle"
          className="mr-2"
          image={option.src}
          imageAlt={option.label}
          style={{ width: 18, height: 18 }}
          imageFallback={Fallbacks.AVATAR_FALLBACK}
        />
        <div>{option.label}</div>
      </div>
    );
  }
  return <span>{props.placeholder}</span>;
};
