import React from "react";
import { Statuses } from "@core";
import { saveAs } from "file-saver";
import { Row } from "primereact/row";
import { Icon } from "@nubeteck/icons";
import { FormatterUtils } from "@utils";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { sum, sort, omit, group } from "radash";
import { ColumnGroup } from "primereact/columngroup";
import { SelectButton } from "primereact/selectbutton";
import { useGetParams, useGlobalQuery, useTreeStrategicDetails } from "@hooks";
import {
  TreeNode,
  CardTable,
  useHeaderActions,
  IDataTableColumnsProps,
} from "@nubeteck/prime";
import {
  TreeDetail,
  IStrategicPlansActivity,
  IStrategicPlansActivityIndicator,
} from "@interfaces";

type Tree = TreeNode<TreeDetail>;

type Columns = {
  Meta: number;
  Codigo: string;
  TipoId: number;
  Alcanzada: number;
  Porcentaje: number;
  TipoNombre: string;
  Descripcion: string;
};

const PoaReportingPage = () => {
  const strategicPlanId = useGetParams("id");
  const { dispatch: dispatchHeaderActions } = useHeaderActions();

  const indicators = useGlobalQuery("StrategicPlansIndicator", "getAll", {
    ActividadId: 0,
    IndicadorTipoId: 0,
  });
  const indicatorsResults = useGlobalQuery(
    "StrategicPlansIndicator",
    "getAllResults",
    0,
  );
  const activities = useGlobalQuery(
    "StrategicPlansActivities",
    "getAllByPoa",
    strategicPlanId,
    { enabled: !!strategicPlanId },
  );
  const templatesDetails = useGlobalQuery(
    "StrategicPlansTemplates",
    "getAllDetails",
    0,
  );
  const strategicPlan = useGlobalQuery(
    "StrategicPlans",
    "getById",
    strategicPlanId,
    { enabled: !!strategicPlanId },
  );

  const [filter, setFilter] = React.useState(1);

  const peiId = strategicPlan.data?.Relacion?.PeiId ?? 0;
  const peuId = strategicPlan.data?.Relacion?.PeuId ?? 0;

  const planPei = useGlobalQuery("StrategicPlans", "getById", peiId, {
    enabled: !!peiId,
  });
  const planPeu = useGlobalQuery("StrategicPlans", "getById", peuId, {
    enabled: !!peuId,
  });

  const detailsPei = React.useMemo(() => {
    if (!planPei.data?.Detalles?.length) return [];
    return planPei.data?.Detalles;
  }, [planPei.data?.Detalles]);

  const detailsPeu = React.useMemo(() => {
    if (!planPeu.data?.Detalles?.length) return [];
    return planPeu.data?.Detalles;
  }, [planPeu.data?.Detalles]);

  const { value: details } = useTreeStrategicDetails([
    ...detailsPei,
    ...detailsPeu,
  ]);

  const items = sort(
    templatesDetails.data?.map((detail) => ({
      name: detail.TipoNombre,
      value: detail.TipoDeNivelId,
    })) ?? [],
    (item) => item.value,
  );

  React.useEffect(() => {
    if (strategicPlan.data) {
      dispatchHeaderActions({
        type: "SET_VARIABLES",
        payload: { pageTitle: strategicPlan.data?.Nombre || "..." },
      });
    }
    return () => dispatchHeaderActions({ type: "CLEAR_VARIABLES" });
  }, [dispatchHeaderActions, strategicPlan.data]);

  const refetchAll = () => {
    planPei.refetch();
    planPeu.refetch();
    indicators.refetch();
    activities.refetch();
    strategicPlan.refetch();
    templatesDetails.refetch();
    indicatorsResults.refetch();
  };

  const activitiesGrouped = React.useMemo(() => {
    if (!activities.data?.length) return [];
    return group(activities.data, (item) => item.DetalleId);
  }, [activities.data]);

  const indicatorsGrouped = React.useMemo(() => {
    if (!indicators.data?.length) return [];
    return group(indicators.data, (item) => item.ActividadId);
  }, [indicators.data]);

  const indicatorsResultsGrouped = React.useMemo(() => {
    if (!indicatorsResults.data?.length) return [];
    return group(indicatorsResults.data, (item) => item.IndicadorId);
  }, [indicatorsResults.data]);

  const formatIndicator = React.useCallback(
    (indicator: IStrategicPlansActivityIndicator): Columns => {
      const result = indicatorsResultsGrouped[indicator.IndicadorId];
      const isCompleted = indicator.EstadoId === Statuses.COMPLETED_CODE;

      const meta =
        indicator.TipoMedicionResultadoId === 1
          ? 100
          : (indicator.CuantitativoMeta ?? 0);
      const reached = parseInt(result?.[0].IndicadorResultado ?? "0");
      const percentage = meta > 0 ? (reached / meta) * 100 : 0;

      return {
        TipoId: 0,
        Codigo: "",
        Meta: !isCompleted ? 0 : meta,
        Alcanzada: !isCompleted ? 0 : reached,
        Descripcion: indicator.Indicador ?? "",
        Porcentaje: !isCompleted ? 0 : percentage,
        TipoNombre: `${indicator.TipoIndicadorNombre ?? ""}`,
      };
    },
    [indicatorsResultsGrouped],
  );

  const formatActivity = React.useCallback(
    (activity: IStrategicPlansActivity) => {
      const indicatorsItems =
        indicatorsGrouped[activity.ActividadId]?.map(formatIndicator);

      const activityMeta = sum(
        indicatorsItems ?? [],
        (indicator) => indicator.Meta,
      );
      const activityReached = sum(
        indicatorsItems ?? [],
        (indicator) => indicator.Alcanzada,
      );
      const activityPercentage =
        activityMeta > 0 ? (activityReached / activityMeta) * 100 : 0;

      return {
        TipoId: 0,
        Codigo: "",
        Meta: activityMeta,
        TipoNombre: "Actividad",
        Alcanzada: activityReached,
        Indicadores: indicatorsItems,
        Porcentaje: activityPercentage,
        Descripcion: activity.Actividad ?? "",
      };
    },
    [formatIndicator, indicatorsGrouped],
  );

  const columns: IDataTableColumnsProps<Columns>[] = [
    { field: "Codigo", header: "Código" },
    {
      field: "Descripcion",
      header: "Descripción",
      body: ({ TipoNombre, Descripcion }) => {
        return (
          <>
            <b>{TipoNombre}:</b> {Descripcion}
          </>
        );
      },
    },
    { field: "Alcanzada", header: "Alcanzada" },
    { field: "Meta", header: "Meta" },
    {
      field: "Porcentaje",
      header: "Porcentaje (%)",
      body: ({ Porcentaje }) => `${Porcentaje.toFixed(2)}%`,
    },
  ];

  const headerGroup = (
    <ColumnGroup>
      <Row>
        <Column rowSpan={3} header="Código" field="DetalleId" />
        <Column rowSpan={3} field="Descripcion" header="Descripción" />
      </Row>
      <Row>
        <Column colSpan={3} alignHeader="center" header="Valoraciones" />
      </Row>
      <Row>
        <Column
          field="Alcanzada"
          header="Total Alcanzado"
          style={{ minWidth: "150px" }}
        />
        <Column field="Meta" header="Meta" style={{ minWidth: "100px" }} />
        <Column
          field="Porcentaje"
          header="Porcentaje (%)"
          style={{ minWidth: "150px" }}
        />
      </Row>
    </ColumnGroup>
  );

  const value = React.useMemo<Columns[]>(() => {
    const items: Columns[] = [];

    const formatNode = (tree: Tree): { Meta: number; Alcanzada: number } => {
      let totalMeta = 0;
      let totalReached = 0;

      const currentNode: Columns = {
        Meta: 0,
        Alcanzada: 0,
        Porcentaje: 0,
        Codigo: tree.id ?? "",
        TipoId: tree.data.Tipo ?? 0,
        Descripcion: `${tree.data.Descripcion}`,
        TipoNombre: tree.data.TipoNombre ?? "Detalle",
      };
      items.push(currentNode);

      const activitiesItems =
        activitiesGrouped[tree.data.DetalleId]?.map(formatActivity) ?? [];

      activitiesItems.forEach((activity) => {
        const indicators = activity.Indicadores ?? [];

        items.push({
          ...omit(activity, ["Indicadores"]),
          TipoId: tree.data.Tipo ?? 0,
        });

        indicators.forEach((indicator) => {
          items.push({ ...indicator, TipoId: tree.data.Tipo ?? 0 });
        });

        totalMeta += activity.Meta;
        totalReached += activity.Alcanzada;
      });

      if (tree.children && tree.children.length > 0) {
        tree.children.forEach((child) => {
          const childTotals = formatNode(child);
          totalMeta += childTotals.Meta;
          totalReached += childTotals.Alcanzada;
        });
      }

      currentNode.Meta = totalMeta;
      currentNode.Alcanzada = totalReached;
      currentNode.Porcentaje =
        totalMeta > 0 ? (totalReached / totalMeta) * 100 : 0;

      return { Meta: totalMeta, Alcanzada: totalReached };
    };

    details.forEach(formatNode);

    return items.reduce((acc, item) => {
      if ((item.TipoId ?? 0) <= (filter ?? 0)) {
        acc.push({
          ...item,
          Meta: FormatterUtils.number(item.Meta),
          Alcanzada: FormatterUtils.number(item.Alcanzada),
        } as never);
      }
      return acc;
    }, []);
  }, [activitiesGrouped, details, filter, formatActivity]);

  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const formatData = value.map((column) => {
        return {
          Codigo: column.Codigo,
          Descripcion: `${column.TipoNombre}: ${column.Descripcion}`,
          // eslint-disable-next-line perfectionist/sort-objects
          Meta: column.Meta,
          Alcanzada: column.Alcanzada,
          Porcentaje: `${column.Porcentaje.toFixed(2)}%`,
        };
      });
      const worksheet = xlsx.utils.json_to_sheet(formatData);
      const workbook = { SheetNames: ["data"], Sheets: { data: worksheet } };
      const excelBuffer = xlsx.write(workbook, {
        type: "array",
        bookType: "xlsx",
      });

      saveAsExcelFile(excelBuffer, strategicPlan.data?.Nombre ?? "");
    });
  };

  const saveAsExcelFile = (buffer: Buffer, fileName: string) => {
    const EXCEL_TYPE =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const EXCEL_EXTENSION = ".xlsx";
    const data = new Blob([buffer], {
      type: EXCEL_TYPE,
    });
    saveAs(
      data,
      fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION,
    );
  };

  const loading = React.useMemo(() => {
    return planPei.isFetching || planPeu.isFetching;
  }, [planPei.isFetching, planPeu.isFetching]);

  return (
    <div className="flex flex-1 flex-column h-full">
      <SelectButton
        value={filter}
        options={items}
        optionLabel="name"
        className="w-full mb-2"
        onChange={(e) => setFilter(e.value)}
      />
      <div className="h-full">
        <CardTable<Columns>
          scrollable
          type="data"
          value={value}
          title="Detalles"
          hideSelectFilter
          hideActionColumn
          loading={loading}
          columns={columns}
          paginator={false}
          tableActions={[]}
          hideFilterSettingsIcon
          headerColumnGroup={headerGroup}
          headActions={[
            <Button
              size="small"
              type="button"
              label="Refrescar"
              severity="success"
              key="RefreshButton"
              onClick={refetchAll}
              icon={<Icon size={18} name="refresh" className="mr-1" />}
            />,
            <Button
              size="small"
              type="button"
              key="ExportExcel"
              severity="warning"
              onClick={exportExcel}
              label="Exportar Excel"
              icon={<Icon size={18} className="mr-1" name="file-type-xls" />}
            />,
          ]}
        />
      </div>
    </div>
  );
};

export default PoaReportingPage;
