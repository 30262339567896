import { IInputProps } from "@interfaces";
import { useController } from "react-hook-form";
import { MultiSelect, MultiSelectProps } from "primereact/multiselect";

import { Field } from "../../field";

//! ANTES DE MODIFICAR ESTE COMPONENTE CONSULTE CON SUS DESARROLLADORES.
//! ANTES DE MODIFICAR ESTE COMPONENTE CONSULTE CON SUS DESARROLLADORES.
//! ANTES DE MODIFICAR ESTE COMPONENTE CONSULTE CON SUS DESARROLLADORES.

export interface IMultiSelectProps
  extends IInputProps,
    Omit<MultiSelectProps, "name" | "defaultValue"> {}

const $MultiSelect = ({
  name,
  label,
  rules,
  disabled,
  helperText,
  defaultValue,
  disabledInput,
  fieldClassName,
  shouldUnregister,
  ...props
}: IMultiSelectProps) => {
  const { field, fieldState } = useController({
    name,
    rules,
    disabled,
    defaultValue,
    shouldUnregister,
  });

  return (
    <Field
      name={name}
      label={label}
      helperText={helperText}
      className={fieldClassName}
      isRequired={!!rules?.required}
    >
      <MultiSelect
        id={field.name}
        {...field}
        {...props}
        disabled={disabledInput}
        invalid={fieldState.invalid}
      />
    </Field>
  );
};

export default $MultiSelect;
