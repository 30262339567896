import { param, route } from "typesafe-react-router";

export enum GeneralAccountingRouteNames {
  EDIT = "EDIT",
  CREATE = "CREATE",
  DETAILS = "DETAILS",
  CURRENCY_PAGE = "CURRENCY_PAGE",
  DASHBOARD_PAGE = "DASHBOARD_PAGE",
  EXCHANGE_RATES = "EXCHANGE_RATES",
  REPORTING_PAGE = "REPORTING_PAGE",
  DIRECCION_FORM_PAGE = "DIRECCION_FORM_PAGE",
  RECONCILIATION_PAGE = "RECONCILIATION_PAGE",
  MONTHLY_CLOSING_PAGE = "MONTHLY_CLOSING_PAGE",
  ACCOUNT_CATALOG_PAGE = "ACCOUNT_CATALOG_PAGE",
  ACCOUNTING_MOVEMENTS = "ACCOUNTING_MOVEMENTS",
  CURRENCY_DETAILS_PAGE = "CURRENCY_DETAILS_PAGE",
  FINANCIAL_STATEMENT_PAGE = "FINANCIAL_STATEMENT_PAGE",
  ACCOUNT_CATALOG_SIMPLE_PAGE = "ACCOUNT_CATALOG_SIMPLE_PAGE",
  ACCOUNTING_MOVEMENTS_DETAILS = "ACCOUNTING_MOVEMENTS_DETAILS",
  FINANCIAL_STATEMENT_RESULTS_PAGE = "FINANCIAL_STATEMENT_RESULT_PAGE",
  ACCOUNT_CATALOG_CONSOLIDATE_PAGE = "ACCOUNT_CATALOG_CONSOLIDATE_PAGE",
  FINANCIAL_STATEMENT_DIVISION_PAGE = "FINANCIAL_STATEMENT_DIVISION_PAGE",
  FINANCIAL_STATEMENT_SITUATION_PAGE = "FINANCIAL_STATEMENT_SITUATION_PAGE",
}

export const GeneralAccountingRoutes = {
  [GeneralAccountingRouteNames.CREATE]: route("create"),
  [GeneralAccountingRouteNames.DETAILS]: route(param("id")),
  [GeneralAccountingRouteNames.EDIT]: route("edit", param("id")),
  [GeneralAccountingRouteNames.CURRENCY_PAGE]: route("currency"),
  [GeneralAccountingRouteNames.REPORTING_PAGE]: route("reporting"),
  [GeneralAccountingRouteNames.DASHBOARD_PAGE]: route("accounting"),
  [GeneralAccountingRouteNames.EXCHANGE_RATES]: route("exchange-rate"),
  [GeneralAccountingRouteNames.CURRENCY_DETAILS_PAGE]: route(param("id")),
  [GeneralAccountingRouteNames.RECONCILIATION_PAGE]: route("reconciliation"),
  [GeneralAccountingRouteNames.DIRECCION_FORM_PAGE]: route("direccion-form"),
  [GeneralAccountingRouteNames.MONTHLY_CLOSING_PAGE]: route("monthly-closing"),
  [GeneralAccountingRouteNames.ACCOUNT_CATALOG_PAGE]: route("account-catalog"),
  [GeneralAccountingRouteNames.FINANCIAL_STATEMENT_PAGE]:
    route("financial-state"),
  [GeneralAccountingRouteNames.FINANCIAL_STATEMENT_RESULTS_PAGE]:
    route("resultado"),
  [GeneralAccountingRouteNames.FINANCIAL_STATEMENT_SITUATION_PAGE]:
    route("situacion"),
  [GeneralAccountingRouteNames.ACCOUNTING_MOVEMENTS]: route(
    "accounting-movements",
  ),
  [GeneralAccountingRouteNames.ACCOUNT_CATALOG_SIMPLE_PAGE]: route(
    "simple",
    param("id"),
  ),
  [GeneralAccountingRouteNames.FINANCIAL_STATEMENT_DIVISION_PAGE]: route(
    "financial-state-division",
  ),
  [GeneralAccountingRouteNames.ACCOUNT_CATALOG_CONSOLIDATE_PAGE]: route(
    "consolidate",
    param("id"),
  ),
};

export const EDIT_ROUTE = GeneralAccountingRoutes[
  GeneralAccountingRouteNames.EDIT
]
  .template()
  .slice(1);

export const CREATE_ROUTE = GeneralAccountingRoutes[
  GeneralAccountingRouteNames.CREATE
]
  .template()
  .slice(1);
