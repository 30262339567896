import React from "react";
import { Dialog } from "@components";
import { Icon } from "@nubeteck/icons";
import { Button } from "primereact/button";
import { useGlobalMutation } from "@hooks";
import { StrategicReviewTypes } from "@core";
import { DialogProps } from "primereact/dialog";
import { Form, useForm, InputTextarea } from "@nubeteck/forms";

export interface IRequestCommentsDialogProps extends DialogProps {
  strategicPlanId: number;
}

type FormValues = { Observacion: string };

const RequestCommentsDialog = ({
  strategicPlanId,
  ...props
}: IRequestCommentsDialogProps) => {
  const requestReview = useGlobalMutation("StrategicPlans", "requestReview");

  const form = useForm<FormValues>();

  const handleSuccess = () => {
    form.reset({ Observacion: "" });
    props.onHide();
  };

  const onSubmitRejected = (values: FormValues) => {
    return requestReview.mutateAsync(
      {
        Observacion: values.Observacion,
        PlanEstrategicoId: strategicPlanId ?? 0,
        TipoRevisionId: StrategicReviewTypes.CHANGE_REQUEST,
      },
      { onSuccess: handleSuccess },
    );
  };

  const onSubmitApproved = (values: FormValues) => {
    return requestReview.mutateAsync(
      {
        Observacion: values.Observacion,
        PlanEstrategicoId: strategicPlanId ?? 0,
        TipoRevisionId: StrategicReviewTypes.APPROVED,
      },
      { onSuccess: handleSuccess },
    );
  };

  return (
    <Dialog
      {...props}
      onHide={handleSuccess}
      title="Enviar revisión"
      className="w-full md:w-10 lg:w-6"
      subtitle="Rellena los datos del formulario"
      footer={() => (
        <div className="flex flex-1 justify-content-end gap-2">
          <Button
            size="small"
            label="Aprobar"
            onClick={form.handleSubmit(onSubmitApproved)}
            icon={<Icon size={18} name="check" className="mr-1" />}
          />
          <Button
            size="small"
            severity="danger"
            label="Solicitud de cambios"
            onClick={form.handleSubmit(onSubmitRejected)}
          />
        </div>
      )}
    >
      <Form form={form}>
        <InputTextarea
          rows={4}
          name="Observacion"
          label="Observación"
          className="p-inputtext-sm"
          placeholder="Escribe las observaciones..."
        />
      </Form>
    </Dialog>
  );
};

export default RequestCommentsDialog;
