import { IInputProps } from "@interfaces";
import { useController } from "react-hook-form";
import { InputSwitch, InputSwitchProps } from "primereact/inputswitch";

import { Field } from "../../field";

//! ANTES DE MODIFICAR ESTE COMPONENTE CONSULTE CON SUS DESARROLLADORES.
//! ANTES DE MODIFICAR ESTE COMPONENTE CONSULTE CON SUS DESARROLLADORES.
//! ANTES DE MODIFICAR ESTE COMPONENTE CONSULTE CON SUS DESARROLLADORES.

export interface IInputSwitchProps
  extends IInputProps,
    Omit<InputSwitchProps, "name" | "checked" | "defaultValue"> {}

const $InputSwitch = ({
  name,
  label,
  rules,
  disabled,
  helperText,
  defaultValue,
  disabledInput,
  fieldClassName,
  shouldUnregister,
  ...props
}: IInputSwitchProps) => {
  const { field, fieldState } = useController({
    name,
    rules,
    disabled,
    defaultValue,
    shouldUnregister,
  });

  return (
    <Field
      name={name}
      label={label}
      helperText={helperText}
      className={fieldClassName}
      isRequired={!!rules?.required}
    >
      <InputSwitch
        {...field}
        {...props}
        checked={field.value}
        disabled={disabledInput}
        invalid={fieldState.invalid}
      />
    </Field>
  );
};

export default $InputSwitch;
