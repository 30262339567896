import React from "react";
import { Icon } from "@nubeteck/icons";
import { Button } from "primereact/button";
import { useNavigate } from "react-router";

const Unauthorized = () => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-1 flex-column h-full align-content-center justify-content-center">
      <div
        className="text-center"
        style={{
          background:
            "radial-gradient(50% 109137.91% at 50% 50%, rgba(233, 30, 99, 0.2) 0%, rgba(254, 244, 247, 0) 100%)",
        }}
      >
        <span className="surface-ground text-pink-500 font-bold text-5xl inline-block px-3">
          {401}
        </span>
      </div>
      <div className="mt-4 mb-2 font-bold text-6xl text-900 text-center">
        Pagina restringida
      </div>
      <p className="text-700 text-xl mt-0 mb-4 text-center">
        No tienes los permisos necesarios para acceder a esta pagina.
      </p>
      <div className="flex justify-content-center gap-2 text-center">
        <Button
          text
          size="small"
          label="Volver"
          onClick={() => navigate(-1)}
          icon={<Icon size={20} className="mr-1" name="arrow-left" />}
        />
        <Button
          size="small"
          onClick={() => navigate("/")}
          label="Ir a la pagina principal"
          icon={<Icon size={20} name="home" className="mr-1" />}
        />
      </div>
    </div>
  );
};

export default Unauthorized;
