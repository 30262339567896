import React from "react";

const useForceUpdate = (defaultKey: string = "") => {
  const [key, setKey] = React.useState(0);
  return {
    key: `${defaultKey}${key}`,
    forceUpdate: () => setKey((prev) => prev + 1),
  };
};

export default useForceUpdate;
