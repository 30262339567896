import API from "@lib/api";
import { BaseApi } from "@api";
import { Messages } from "@interfaces";

import {
  IProvider,
  IArchivos,
  IProviderCreate,
  IProveedorDetalles,
  IProviderGetCreate,
  IProveedorDirecciones,
} from "../interfaces";

interface IChangeStatuProvider {
  Id: number;
  EstadoId: number;
}

interface IValidateDNI {
  DNI: string;
  DNITipoId: number;
}

export class ProviderApi extends BaseApi {
  protected static api = API({ namespace: "Proveedores" });
  protected static filterApi = API({ namespace: "Filtro" });
  protected static messages: Messages<typeof ProviderApi> = {
    validateDNI: {
      loading: "Validando DNI...",
      success: "DNI validado correctamente.",
      error: (error) => `Hubo un error al validar el DNI: ${error}`,
    },
    create: {
      loading: "Creando proveedor...",
      success: "Proveedor creado correctamente.",
      error: (error) => `Hubo un error al crear el proveedor: ${error}`,
    },
    createDireccion: {
      loading: "Creando dirección...",
      success: "Dirección creada correctamente.",
      error: (error) => `Hubo un error al crear la dirección: ${error}`,
    },
    update: {
      loading: "Actualizando el proveedor...",
      success: "Proveedor actualizado correctamente.",
      error: (error) => `Hubo un error al actualizar el proveedor: ${error}`,
    },
    updateDireccion: {
      loading: "Actualizando la dirección...",
      success: "Dirección actualizada correctamente.",
      error: (error) => `Hubo un error al actualizar la dirección: ${error}`,
    },
    deleteProfilePicture: {
      loading: "Eliminando foto de perfil...",
      success: "Foto de perfil eliminada correctamente.",
      error: (error) => `Hubo un error al eliminar la foto de perfil: ${error}`,
    },
    changeStateFile: {
      loading: "Cambiando estado del archivo...",
      success: "Se ha cambiado el estado de archivo correctamente.",
      error: (error) =>
        `Hubo un error al cambiar el estado del archivo: ${error}`,
    },
    changeState: {
      loading: "Cambiando estado del proveedor...",
      success: "Se ha cambiado el estado del proveedor correctamente.",
      error: (error) =>
        `Hubo un error al cambiar el estado del proveedor: ${error}`,
    },
    addFile: {
      loading: "Cargando archivo en proveedor addFile...",
      success: "Archivo cargado correctamente en proveedor addFile.",
      error: (error) =>
        `Hubo un error al cargar el archivo en el proveedor addFile: ${error}`,
    },
    changeStateDireccion: {
      loading: "Cambiando estado de la dirección...",
      success: "Se ha cambiado el estado de la dirección correctamente.",
      error: (error) =>
        `Hubo un error al cambiar el estado de la dirección: ${error}`,
    },
  };

  public static async getForUpdate(id: number): Promise<IProviderCreate> {
    //cambiar a solo get?
    const result = await this.api.get(`GetForUpdate/${id}`);
    return result.data;
  }

  public static async getForCreate(): Promise<IProviderGetCreate> {
    const result = await this.api.get("GetForCreate");
    return result.data;
  }

  public static async getAll(): Promise<IProvider[]> {
    const result = await this.api.get("GetAll");
    return result.data;
  }

  public static async getById(id: number): Promise<IProveedorDetalles> {
    const result = await this.api.get(`GetById/${id}`);
    return result.data;
  }

  public static async getByEstado(id: number): Promise<IProvider[]> {
    const result = await this.api.get(`GetByEstado/${id}`);
    return result.data;
  }

  public static async addFile(data: IArchivos) {
    const result = await this.api.post("AddFile", {
      EstadoId: data.EstadoId,
      ArchivoId: data.ArchivoId,
      ProveedorId: data.ProveedorId,
      TipoArchivoId: data.TipoArchivoId,
    });
    return result.data;
  }

  public static async deleteProfilePicture(id: number) {
    const result = await this.api.delete(`DeleteProfilePicture/${id}`);
    return result.data;
  }

  public static async create(data: IProviderCreate) {
    const result = await this.api.post("Create", data);
    return result.data;
  }

  public static async createDireccion(data: IProveedorDirecciones) {
    const result = await this.api.post("AddDireccion", data);
    return result.data;
  }

  public static async update(data: Partial<IProviderCreate>) {
    const result = await this.api.put(`Update/${data.ProveedorId}`, data);
    return result.data;
  }

  public static async updateDireccion(data: Partial<IProveedorDirecciones>) {
    const result = await this.api.put(
      `UpdateDireccion/${data.DireccionId}`,
      data,
    );
    return result.data;
  }

  public static async validateDNI(data: IValidateDNI) {
    const result = await this.api.post("ValidateDNI", {
      DNI: data.DNI,
      DNITipoId: data.DNITipoId,
    });
    return result.data;
  }

  public static async changeState(data: IChangeStatuProvider) {
    const result = await this.api.post("ChangeStateProveedor", {
      Id: data.Id,
      EstadoId: data.EstadoId,
    });
    return result.data;
  }

  public static async changeStateFile(data: IChangeStatuProvider) {
    const result = await this.api.post("ChangeStateProveedorFile", {
      Id: data.Id,
      EstadoId: data.EstadoId,
    });
    return result.data;
  }

  public static async changeStateDireccion(data: IChangeStatuProvider) {
    const result = await this.api.post("ChangeStateProveedorDireccion", {
      Id: data.Id,
      EstadoId: data.EstadoId,
    });
    return result.data;
  }
}
