import React from "react";
import { Icon } from "@nubeteck/icons";
import { Button, ButtonProps } from "primereact/button";
import { confirmPopup, ConfirmPopup } from "primereact/confirmpopup";

type ResetButtonProps = Omit<
  ButtonProps,
  "icon" | "title" | "label" | "onClick" | "outlined" | "severity"
> & { accept: () => void };

const ResetButton = ({ accept, ...props }: ResetButtonProps) => {
  const button = React.useRef<Button>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    confirmPopup({
      accept,
      target: event.currentTarget,
      message: "¿Confirma restablecer el formulario?",
      icon: <Icon className="text-red-500" name="exclamation-circle" />,
    });
  };

  return (
    <>
      <Button
        {...props}
        outlined
        ref={button}
        size="small"
        label="Restablecer"
        onClick={handleClick}
        className={props.className}
        title="Restablecer formulario"
        icon={<Icon size={20} name="reload" className="md:mr-2" />}
      />
      <ConfirmPopup />
    </>
  );
};

export default ResetButton;
