import API from "@lib/api";
import { IRole, Messages } from "@interfaces";

import { BaseApi } from "./base-api";

export class RolesApi extends BaseApi {
  protected static api = API({ namespace: "Rol" });
  protected static messages: Messages<typeof RolesApi> = {
    // update: {
    //   loading: "Actualizando moneda...",
    //   success: "Moneda actualizada correctamente.",
    //   error: (error) => `Hubo un error al actualizar la moneda: ${error}`,
    // },
    // create: {
    //   loading: "Creando una nueva moneda...",
    //   success: "Moneda creada correctamente.",
    //   error: (error) => `Hubo un error al crear la nueva moneda: ${error}`,
    // },
  };

  public static async getAll() {
    const filters = await this.api.get<IRole[]>("GetAll");
    return filters.data;
  }

  public static async getById(id: number) {
    const filter = await this.api.get<IRole>(`GetById/${id}`);
    return filter.data;
  }

  // public static async create(data: IRole) {
  //   const filter = await this.api.post("Create", data);
  //   return filter.data;
  // }

  //   public static async getCreate(area: string) {
  //     const result = await this.api.get<IRoleCreate>(`GetForCreate/${area}`);
  //     return result.data;
  //   }

  //   public static async getUpdate(id: number) {
  //     const result = await this.api.get<IRoleUpdate>(`GetForUpdate/${id}`);
  //     return result.data;
  //   }

  // public static async update(data: Partial<IRole>) {
  //   const filter = await this.api.put(`Update/${data.RolId}`, data);
  //   return filter.data;
  // }

  //   public static async delete(id: number) {
  //     return await this.api.post("ChangeState", { EstadoId: 3, FiltroId: id });
  //   }
}
