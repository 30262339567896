import API from "@lib/api";
import { sleep } from "radash";
import { Messages, IUserLoginResult } from "@interfaces";

import { BaseApi } from "./base-api";

export class AuthApi extends BaseApi {
  protected static api = API({ namespace: "Auth" });
  protected static messages: Messages<typeof AuthApi> = {
    login: {
      error: (error) => error,
      loading: "Iniciando sesión...",
      success: "Sesión iniciada correctamente.",
    },
    forgotPassword: {
      error: (error) => error,
      loading: "Enviando solicitud...",
      success: "Solicitud enviada correctamente.",
    },
  };

  public static async login(data: { Email: string; Password: string }) {
    const result = await this.api.post<IUserLoginResult>("Login", data);
    return result.data;
  }

  public static async forgotPassword(_props: { email: string }) {
    await sleep(2000);
    return "forgotPassword";
  }
}
