import React from "react";
import { ArrayUtils } from "@utils";
import { Icon } from "@nubeteck/icons";
import { Card } from "@nubeteck/prime";
import { useForceUpdate } from "@hooks";
import { MenuItem } from "primereact/menuitem";
import { useForm, useWatch } from "react-hook-form";
import { ContextMenu } from "primereact/contextmenu";
import Chart, { GoogleChartWrapperChartType } from "react-google-charts";

import { useDashboardQuery } from "../../../hooks";
import { DashboardDialogSources } from "../../dashboard-dialog-sources";
import { DashboardTableDialogSettings } from "../../dashboard-table-dialog-settings";
import {
  Widget,
  IDataSourceField,
  IDashboardComponent,
} from "../../../interfaces";

interface IDashboardProps {
  dates: Date[];
  title: string;
  subtitle: string;
  FuenteId: null | number;
  CamposId: IDataSourceField[];
  chartType: GoogleChartWrapperChartType;
}

export type IDashboardChartCardProps = {
  (props: IDashboardProps): React.JSX.Element;
  Dashboard: React.FC<IDashboardProps & IDashboardComponent>;
};

type FormValues = {
  title: string;
  subtitle: string;
  FuenteId: null | number;
  CamposId: IDataSourceField[];
};

const ChartCard: IDashboardChartCardProps = ({
  title,
  dates,
  subtitle,
  FuenteId,
  CamposId,
  chartType,
}) => {
  const { key, forceUpdate } = useForceUpdate("ChartCard");

  const { data: result } = useDashboardQuery(
    "DataSources",
    "getData",
    { date1: dates[0], date2: dates[1], id: FuenteId ?? 0 },
    { enabled: !!FuenteId },
  );

  const data = React.useMemo(
    () =>
      ArrayUtils.createTableMatrix(
        CamposId?.map((field) => field.NombreCampo) ?? [],
        result ?? [],
      ) ?? [],
    [CamposId, result],
  );

  return (
    <Card
      key={key}
      title={title}
      hideContentPadding
      subtitle={subtitle}
      classNames={{ body: "overflow-hidden" }}
      actions={[
        <Icon
          size={20}
          key="Tool"
          name="tool"
          title="Fix"
          onClick={forceUpdate}
        />,
      ]}
    >
      {!FuenteId ? (
        <span className="font-medium">No hay fuente de datos seleccionada</span>
      ) : (
        <Chart
          data={data}
          width="100%"
          height="100%"
          chartLanguage="es"
          chartType={chartType}
          loader={<div className="p-2">Cargando gráfico...</div>}
        />
      )}
    </Card>
  );
};

ChartCard.Dashboard = function Dashboard({
  i,
  title,
  subtitle,
  CamposId,
  FuenteId,
  chartType,
  onEditable,
  onRemoveWidget,
  onUpdateWidget,
}) {
  const contextMenuRef = React.useRef<ContextMenu>(null);

  const form = useForm<FormValues>();
  const { reset } = form;
  const widgets = useWatch({ name: "widgets" }) as Widget[];

  const [dialogSourcesOpen, setDialogSourcesOpen] = React.useState(false);
  const [dialogSettingsOpen, setDialogSettingsOpen] = React.useState(false);

  React.useEffect(() => {
    reset(
      { title, CamposId, FuenteId, subtitle },
      { keepDefaultValues: false },
    );
  }, [CamposId, FuenteId, reset, title, subtitle]);

  const onRightClick = React.useCallback((event: React.MouseEvent) => {
    if (contextMenuRef.current) {
      contextMenuRef.current.show(event);
    }
  }, []);

  const onSubmit = (values: FormValues) => {
    const widgetIndex = widgets.findIndex((widget) => widget.i === i);
    onUpdateWidget?.(widgetIndex, { i, chartType, type: "chart", ...values });
    setDialogSettingsOpen(false);
    setDialogSourcesOpen(false);
    onEditable?.(true);
  };

  const items: MenuItem[] = [
    {
      label: "Ajustes",
      icon: <Icon size={20} name="settings" className="mr-2" />,
      command: () => {
        onEditable?.(false);
        setDialogSettingsOpen(true);
      },
    },
    {
      label: "Fuente de datos",
      icon: <Icon size={20} name="database" className="mr-2" />,
      command: () => {
        onEditable?.(false);
        setDialogSourcesOpen(true);
      },
    },
    {
      label: "Eliminar",
      command: () => onRemoveWidget?.(i),
      icon: <Icon size={20} name="trash" className="mr-2" />,
    },
  ];

  return (
    <>
      <div className="flex flex-1" onContextMenu={onRightClick}>
        <ChartCard
          dates={[]}
          title={title}
          subtitle={subtitle}
          CamposId={CamposId}
          FuenteId={FuenteId}
          chartType={chartType}
        />
      </div>
      {/* ContextMenu */}
      <ContextMenu model={items} breakpoint="767px" ref={contextMenuRef} />
      <DashboardTableDialogSettings
        form={form}
        onSubmitClick={onSubmit}
        visible={dialogSettingsOpen}
        onHide={() => {
          onEditable?.(true);
          setDialogSettingsOpen(false);
        }}
      />
      <DashboardDialogSources
        form={form}
        onSubmitClick={onSubmit}
        visible={dialogSourcesOpen}
        onHide={() => {
          onEditable?.(true);
          setDialogSourcesOpen(false);
        }}
      />
    </>
  );
};

ChartCard.Dashboard.displayName = "ChartCard.Dashboard";

export default ChartCard;
