import API from "@lib/api";
import { Messages, ICurrency } from "@interfaces";

import { BaseApi } from "./base-api";

export class CurrenciesApi extends BaseApi {
  protected static api = API({ namespace: "Moneda" });
  protected static messages: Messages<typeof CurrenciesApi> = {
    update: {
      loading: "Actualizando moneda...",
      success: "Moneda actualizada correctamente.",
      error: (error) => `Hubo un error al actualizar la moneda: ${error}`,
    },
    create: {
      loading: "Creando una nueva moneda...",
      success: "Moneda creada correctamente.",
      error: (error) => `Hubo un error al crear la nueva moneda: ${error}`,
    },
  };

  public static async getAll() {
    const filters = await this.api.get<ICurrency[]>("GetAll");
    return filters.data;
  }

  public static async getById(id: number) {
    const filter = await this.api.get<ICurrency>(`GetById/${id}`);
    return filter.data;
  }

  public static async create(data: ICurrency) {
    const filter = await this.api.post("Create", data);
    return filter.data;
  }

  //   public static async getCreate(area: string) {
  //     const result = await this.api.get<ICurrencyCreate>(`GetForCreate/${area}`);
  //     return result.data;
  //   }

  //   public static async getUpdate(id: number) {
  //     const result = await this.api.get<ICurrencyUpdate>(`GetForUpdate/${id}`);
  //     return result.data;
  //   }

  public static async update(data: Partial<ICurrency>) {
    const filter = await this.api.put(`Update/${data.MonedaId}`, data);
    return filter.data;
  }

  //   public static async delete(id: number) {
  //     return await this.api.post("ChangeState", { EstadoId: 3, FiltroId: id });
  //   }
}
