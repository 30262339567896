import React from "react";
import { Datejs } from "@nubeteck/utils";
import { EmptyMessage } from "@components";
import { Link, generatePath } from "react-router";
import { FilterOperator, FilterMatchMode } from "primereact/api";
import { CardTable, StatusTag, IDataTableColumnsProps } from "@nubeteck/prime";

import { useAccountingQuery } from "../../hooks";
import { IAccountingMovement } from "../../interfaces";
import {
  EDIT_ROUTE,
  GeneralAccountingRoutes,
  GeneralAccountingRouteNames,
} from "../../routes";

const ReconciliationTab = () => {
  const {
    refetch,
    isPending,
    data: movements,
  } = useAccountingQuery("AccountingMovements", "getByBorrador");

  const generateEditLink = React.useCallback((asientoId: string) => {
    const basePath =
      GeneralAccountingRoutes[
        GeneralAccountingRouteNames.DASHBOARD_PAGE
      ].template();
    const movementsPath =
      GeneralAccountingRoutes[
        GeneralAccountingRouteNames.ACCOUNTING_MOVEMENTS
      ].template();
    return generatePath(`${basePath}/${movementsPath}/${EDIT_ROUTE}`, {
      id: asientoId,
    });
  }, []);

  const columns: IDataTableColumnsProps<IAccountingMovement>[] = [
    {
      sortable: true,
      field: "Secuencia",
      header: "Secuencia",
      body: (rowData) => {
        return (
          <Link
            title="Ir al asiento"
            className="no-underline text-blue-500"
            to={generateEditLink((rowData.AsientoId ?? 0).toString())}
          >
            {rowData.Secuencia}
          </Link>
        );
      },
    },
    {
      sortable: true,
      field: "Referencia",
      header: "Referencia",
    },
    {
      filter: true,
      sortable: true,
      field: "Fecha",
      header: "Fecha",
      dataType: "date",
      filterOperator: FilterOperator.AND,
      filterMatchMode: FilterMatchMode.DATE_IS,
      body: (rowData) => (
        <span title={Datejs(rowData.Fecha).format("LL")}>
          {Datejs(rowData.Fecha).format("DD/MM/YYYY")}
        </span>
      ),
    },
    {
      header: "Estado",
      field: "EstadoNombre",
      body: ({ EstadoNombre }) => <StatusTag status={EstadoNombre} />,
    },
  ];

  const value = movements?.map(({ Fecha, ...rest }) => ({
    ...rest,
    Fecha: new Date(Fecha),
  }));

  const renderEmptyMessage = React.useCallback(() => {
    return <EmptyMessage message="No hay asientos pendientes" />;
  }, []);

  return (
    <CardTable<IAccountingMovement>
      type="data"
      removableSort
      hideBorderStyles
      hideSelectFilter
      resizableColumns
      columns={columns}
      tableActions={[]}
      value={value ?? []}
      loading={isPending}
      dataKey="AsientoId"
      columnResizeMode="expand"
      onRefresh={() => refetch()}
      emptyMessage={renderEmptyMessage}
      title="Borradores por completar y aprobar"
    />
  );
};

export default ReconciliationTab;
