import React from "react";
import { Dialog } from "@components";
import { Button } from "primereact/button";
import { EventUtils } from "@nubeteck/utils";
import { Message } from "primereact/message";
import { DialogProps } from "primereact/dialog";
import { confirmDialog } from "primereact/confirmdialog";
import { InputTextarea } from "primereact/inputtextarea";
import { useGlobalQuery, useGlobalMutation } from "@hooks";
import { IIndicatorExecutionGetForUpdate } from "@interfaces";
import { CardTable, IDataTableColumnsProps } from "@nubeteck/prime";

export interface IExecutionPoaFilesDialogProps extends DialogProps {
  indicatorId: number;
}

const ExecutionPoaFilesDialog = ({
  visible,
  indicatorId,
  ...props
}: IExecutionPoaFilesDialogProps) => {
  const downloadFile = useGlobalMutation("Files", "download");
  const requestReview = useGlobalMutation(
    "StrategicPlansIndicator",
    "requestReview",
  );
  const getUpdateExecution = useGlobalQuery(
    "StrategicPlansIndicator",
    "getUpdateExecution",
    indicatorId,
    { enabled: !!indicatorId && visible },
  );

  const [observation, setObservation] = React.useState("");

  const measurementTypeId =
    getUpdateExecution.data?.TipoMedicionResultadoId ?? 0;

  const handleDownloadFile = React.useCallback(
    (id: number) => {
      confirmDialog({
        acceptLabel: "Descargar",
        header: "Descargar archivo",
        acceptClassName: "p-button-sm",
        message:
          "¿Está seguro de que desea descargar este archivo? (Puede que tarde mucho en descargarse por su tamaño)",
        accept: async () => {
          await downloadFile.mutateAsync(id, {
            onSuccess: (file) => {
              const linkSource = file.Data as string;
              const downloadLink = document.createElement("a");
              downloadLink.href = linkSource;
              downloadLink.target = "_blank";
              downloadLink.download = file.FileName;
              downloadLink.click();
            },
          });
        },
      });
    },
    [downloadFile],
  );

  const handleRequestReview = React.useCallback(
    (id: number) => {
      return requestReview.mutateAsync(
        {
          TipoRevisionId: id,
          IndicadorId: indicatorId,
          Observacion: observation,
        },
        {
          onSuccess: () => {
            props.onHide();
            setObservation("");
          },
        },
      );
    },
    [indicatorId, observation, props, requestReview],
  );

  const columns: IDataTableColumnsProps<
    IIndicatorExecutionGetForUpdate["MediosVerificaciones"][0]
  >[] = [
    { header: "Nombre", field: "ArchivoNombre" },
    {
      header: "Tamaño",
      field: "ArchivoSize",
      body: ({ ArchivoSize }) => `${((ArchivoSize ?? 0) / 1000).toFixed(2)} kB`,
    },
  ];

  return (
    <Dialog
      {...props}
      visible={visible}
      className="w-full md:w-8"
      subtitle="Rellena los datos del formulario"
      title={`Revisar evidencias ${getUpdateExecution.data?.Indicador ?? ""}`}
      footer={() => (
        <div className="flex flex-1 justify-content-end gap-2">
          <Button
            size="small"
            severity="danger"
            label="Solicitud de cambios"
            onClick={EventUtils.callEvent(handleRequestReview, 1)}
          />
          <Button
            size="small"
            label="Aprobar"
            severity="success"
            onClick={EventUtils.callEvent(handleRequestReview, 2)}
          />
        </div>
      )}
    >
      <Message
        text={
          <span className="font-semibold mb-1">
            Resultado: <b>{getUpdateExecution.data?.Resultado}</b> de{" "}
            {measurementTypeId === 2
              ? (getUpdateExecution.data?.Cuantitativo?.Meta ?? 0)
              : 100}
          </span>
        }
      />
      <CardTable
        type="data"
        hideSelectFilter
        hideBorderStyles
        hideGlobalSearch
        paginator={false}
        columns={columns}
        hideFilterSettingsIcon
        title="Archivos subidos"
        value={getUpdateExecution.data?.MediosVerificaciones ?? []}
        tableActions={(data) => [
          {
            icon: "download",
            label: "Descargar archivo",
            onClick: EventUtils.callEvent(handleDownloadFile, data.ArchivoId),
          },
        ]}
      />
      <div className="flex flex-column gap-1 mt-3">
        <span className="font-semibold">Observación</span>
        <InputTextarea
          rows={3}
          className="w-full"
          value={observation}
          onChange={(event) => setObservation(event.target.value)}
          placeholder="Escribe la observación y luego solicita cambios o aprueba."
        />
      </div>
    </Dialog>
  );
};

export default ExecutionPoaFilesDialog;
