import React from "react";
import { Reducers } from "@core";
import { IUser } from "@interfaces";
import { Link } from "react-router";
import { Icon } from "@nubeteck/icons";
import { Button } from "primereact/button";
import { Avatar } from "primereact/avatar";
import { EventUtils } from "@nubeteck/utils";
import { confirmDialog } from "primereact/confirmdialog";
import { useGlobalQuery, useGlobalMutation } from "@hooks";
import { CardTable, StatusTag, IDataTableColumnsProps } from "@nubeteck/prime";
import {
  ConfirmDialog,
  UserFormDialog,
  ChangeLogDialog,
  UserCostsCenterFormDialog,
} from "@components";

const UsersSettingsPage = () => {
  const users = useGlobalQuery("Users", "getAll");
  const changeIsActive = useGlobalMutation("Users", "changeIsActive");

  const [state, dispatch] = React.useReducer(Reducers.DialogReducer, {
    id: 0,
    open: false,
  });
  const [stateCenterCosts, dispatchCenterCosts] = React.useReducer(
    Reducers.DialogReducer,
    { id: 0, open: false },
  );
  const [changeLogState, changeLogDispatch] = React.useReducer(
    Reducers.DialogReducer,
    { id: 0, open: false },
  );

  const handleActiveUser = (user: IUser) => {
    confirmDialog({
      acceptLabel: "Activar",
      header: "Activando usuario",
      acceptClassName: "p-button-sm p-button-success",
      message: `¿Deseas activar este usuario '${user.FullName}'?`,
      accept: () => {
        return changeIsActive.mutateAsync({
          IsActive: true,
          UserId: user.UserId,
        });
      },
    });
  };

  const handleInactiveUser = (user: IUser) => {
    confirmDialog({
      acceptLabel: "Desactivar",
      header: "Desactivando usuario",
      message: `¿Deseas desactivar este usuario '${user.FullName}'?`,
      accept: () => {
        return changeIsActive.mutateAsync({
          IsActive: false,
          UserId: user.UserId,
        });
      },
    });
  };

  const columns = React.useMemo<IDataTableColumnsProps<IUser>[]>(
    () => [
      {
        header: "Usuario",
        field: "FullName",
        body: (data) => (
          <div className="flex gap-2 align-items-center">
            <Avatar
              size="xlarge"
              shape="circle"
              image="/avatar-placeholder.webp"
            />
            <div className="flex flex-column">
              <span className="font-medium">{data.FullName}</span>
              <span className="text-sm">@{data.Username}</span>
            </div>
          </div>
        ),
      },
      {
        field: "Email",
        header: "Correo electrónico",
        body: (data) => {
          if (!data.Email) return "-";
          return (
            <Link className="no-underline" to={`mailto:${data.Email}`}>
              {data.Email}
            </Link>
          );
        },
      },
      {
        header: "Estado",
        field: "IsActive",
        body: (data) => (
          <StatusTag status={data.IsActive ? "Activo" : "Inactivo"} />
        ),
      },
    ],
    [],
  );

  return (
    <>
      <CardTable<IUser>
        type="data"
        hideSelectFilter
        title="Usuarios"
        columns={columns}
        hideFilterSettingsIcon
        value={users.data ?? []}
        loading={users.isFetching}
        onRefresh={() => users.refetch()}
        subtitle="Configuración de los usuarios"
        headActions={[
          <Button
            size="small"
            key="UserDropdown"
            label="Crear nuevo usuario"
            icon={<Icon size={20} className="mr-1" name="user-plus" />}
            onClick={EventUtils.callEvent(dispatch, {
              payload: 0,
              type: "OPEN_DIALOG",
            })}
          />,
        ]}
        tableActions={(data) => [
          {
            icon: "pencil",
            label: "Editar",
            onClick: EventUtils.callEvent(dispatch, {
              type: "OPEN_DIALOG",
              payload: data.UserId,
            }),
          },
          {
            icon: "plus",
            label: "Asignar centro de costos",
            onClick: EventUtils.callEvent(dispatchCenterCosts, {
              type: "OPEN_DIALOG",
              payload: data.UserId,
            }),
          },
          {
            icon: "ban",
            label: "Desactivar",
            disabled: !data.IsActive,
            iconClassName: "text-red-500",
            onClick: EventUtils.callEvent(handleInactiveUser, data),
          },
          {
            icon: "plug",
            label: "Activar",
            disabled: data.IsActive,
            iconClassName: "text-green-500",
            onClick: EventUtils.callEvent(handleActiveUser, data),
          },
          {
            icon: "book",
            label: "Historial de cambios",
            onClick: EventUtils.callEvent(changeLogDispatch, {
              type: "OPEN_DIALOG",
              payload: data.UserId,
            }),
          },
        ]}
      />
      <ConfirmDialog />
      <ChangeLogDialog
        entityName="UserId"
        entityId={changeLogState.id}
        visible={changeLogState.open}
        onHide={EventUtils.callEvent(changeLogDispatch, {
          type: "CLOSE_DIALOG",
        })}
      />
      <UserFormDialog
        userId={state.id}
        visible={state.open}
        onHide={EventUtils.callEvent(dispatch, { type: "CLOSE_DIALOG" })}
      />
      <UserCostsCenterFormDialog
        userId={stateCenterCosts.id}
        visible={stateCenterCosts.open}
        onHide={EventUtils.callEvent(dispatchCenterCosts, {
          type: "CLOSE_DIALOG",
        })}
      />
    </>
  );
};

export default UsersSettingsPage;
