import React from "react";
import { getDateRange } from "@utils";
import { Icon } from "@nubeteck/icons";
import { Datejs } from "@nubeteck/utils";
import { Button } from "primereact/button";
import { omit, group, capitalize } from "radash";
import { InputNumber } from "primereact/inputnumber";
import { Sidebar, SidebarProps } from "primereact/sidebar";
import { CardTable, IDataTableColumnsProps } from "@nubeteck/prime";

import Tree from "../../core/Tree";
import { INode } from "../../interfaces";
import { BudgetConceptsDialog } from "../budget-concept-dialog";
import { IConcept } from "../budget-concept-dialog/budget-concept-dialog";

export interface IBudgetConceptsDrawerProps extends SidebarProps {}

type Row = Record<string, number | string>;

const BudgetConceptsDrawer = ({ ...props }: IBudgetConceptsDrawerProps) => {
  const [tree, setTree] = React.useState(
    new Tree({
      id: 0,
      type: 0,
      data: {},
      tipo: "",
      name: "Raiz",
      parent: null,
    }),
  );
  const [conceptsSelected, setConceptsSelected] = React.useState<IConcept[]>(
    [],
  );
  const [conceptDialogOpen, setConceptDialogOpen] = React.useState(false);

  const categorias = [
    { CategoriaId: 1, Nombre: "Ingresos" },
    { CategoriaId: 2, Nombre: "Gastos" },
    { CategoriaId: 3, Nombre: "Inversión" },
  ];

  const dates = React.useMemo(() => {
    const fechaInicio = Datejs("13/03/2024", "DD/MM/YYYY").toDate();
    const fechaFin = Datejs("13/08/2025", "DD/MM/YYYY").toDate();
    return getDateRange(fechaInicio, fechaFin);
  }, []);

  const columns = React.useMemo<IDataTableColumnsProps<Row>[]>(
    () => [
      {
        frozen: true,
        field: "Concepto",
        header: "Concepto",
        alignFrozen: "left",
      },
      ...dates.map((date) => {
        return {
          header: capitalize(date),
          style: { minWidth: "200px" },
          field: date.replace(" ", ""),
          body: (data: Row) => {
            const value = data[date.replace(" ", "")];
            if (data.type !== 2) return value;
            return (
              <InputNumber
                className="p-inputtext-sm"
                placeholder="Ingresa el numero"
                value={parseInt(`${value}`, 10)}
                onChange={(event) => {
                  const id = parseInt(`${data.id}`, 10);

                  const childNode = tree.getNodeById(id ?? -1, 2);
                  const parentNode = tree.getNodeById(
                    childNode?.parent?.id ?? -1,
                    1,
                  );

                  if (childNode) {
                    tree.updateData(
                      date.replace(" ", ""),
                      event.value ?? 0,
                      childNode,
                    );
                  }
                  tree.updateSubcategoriesData(parentNode, dates);
                  tree.dirtyNode(childNode);

                  setTree(new Tree(tree.root));
                }}
              />
            );
          },
        };
      }),
      {
        frozen: true,
        field: "Total",
        header: "Total",
        alignFrozen: "right",
        body: (data) => {
          const total = dates.reduce((acc, date) => {
            acc += data[date.replace(" ", "") as string] as number;
            return acc;
          }, 0);
          return total;
        },
      },
    ],
    [dates, tree],
  );

  const categories = React.useMemo(
    () => group(conceptsSelected, (concept) => concept.Categoria),
    [conceptsSelected],
  );

  const setData = React.useCallback(() => {
    const newTree = new Tree({
      id: 0,
      type: 0,
      tipo: "",
      data: {},
      name: "Raiz",
      parent: null,
    });

    const items = Object.entries(categories).map(
      ([category, concepts], index) => {
        return {
          type: 1,
          tipo: "",
          data: {},
          id: index + 1,
          name: category,
          children: concepts?.map((concept) => {
            return {
              type: 2,
              tipo: concept.Tipo,
              id: concept.Codigo,
              name: concept.Nombre,
              data: dates.reduce<Record<string, number>>((acc, value) => {
                acc[value.replace(" ", "")] = 0;
                return acc;
              }, {}),
            };
          }),
        };
      },
    );

    items.forEach((item) => {
      newTree.insertNode(newTree.root, omit(item, ["children"]) as never);
      const parentNode = newTree.getNodeById(item.id, 1);
      item.children?.forEach((child) => {
        newTree.insertNode(parentNode, child as never);
      });
      newTree.updateSubcategoriesData(parentNode as INode, dates);
    });

    setTree(newTree);
  }, [categories, dates]);

  React.useEffect(() => {
    setData();
  }, [setData]);

  const formatTreeData = (child?: INode, category?: string): Row => {
    return {
      Total: 0,
      id: child?.id ?? 0,
      type: child?.type ?? 0,
      tipo: child?.tipo ?? "",
      Categoria: category ?? "",
      Concepto: child?.name ?? "",
      ...child?.data,
    };
  };

  const renderTreeData = (node: null | INode, tipo: string): Row[] => {
    if (!node) return [];

    return (
      node.children?.reduce((acc, node) => {
        node.children?.forEach((child) => {
          acc.push(formatTreeData(child, node.name));
        });

        acc.push({
          ...formatTreeData(
            { ...node, name: `Totales de ${node.name}` },
            node.name,
          ),
          ...dates.reduce<Record<string, number>>((acc, value) => {
            const total = tree.calculateTotal(
              node,
              value.replace(" ", ""),
              tipo,
            );
            acc[value.replace(" ", "")] = total ? total : 0;
            return acc;
          }, {}),
        });

        return acc.filter((a) => a);
      }, [] as Row[]) ?? []
    );
  };

  const [expandedIngresosRows, setExpandedIngresosRows] = React.useState([]);
  const [expandedGastosRows, setExpandedGastosRows] = React.useState([]);
  const [expandedInversionRows, setExpandedInversionRows] = React.useState([]);

  return (
    <Sidebar
      {...props}
      fullScreen
      position="right"
      className="relative"
      header={
        <div className="flex align-items-center justify-content-between w-full">
          <div className="flex align-items-center gap-2">
            <Icon name="contract" touchable={false} />
            <span className="font-semibold text-lg">
              Conceptos del presupuesto
            </span>
          </div>
          <Button
            size="small"
            label="Agregar nuevo concepto"
            onClick={() => setConceptDialogOpen(true)}
          />
        </div>
      }
    >
      <div className="flex flex-1 gap-4 flex-column">
        {categorias.map((categoria) => (
          <CardTable<Row>
            scrollable
            type="data"
            sortOrder={1}
            hideSelectFilter
            hideGlobalSearch
            headActions={[]}
            columns={columns}
            paginator={false}
            sortMode="single"
            expandableRowGroups
            sortField="Categoria"
            hideFilterSettingsIcon
            groupRowsBy="Categoria"
            title={categoria.Nombre}
            rowGroupMode="subheader"
            key={categoria.CategoriaId}
            tableActions={[{ icon: "trash", label: "Eliminar" }]}
            rowGroupHeaderTemplate={(data) => (
              <React.Fragment>
                <span className="ml-2 font-bold">{data.Categoria}</span>
              </React.Fragment>
            )}
            value={renderTreeData(tree.root, categoria.Nombre).filter(
              (row) =>
                (row.type === 2 && row.tipo === categoria.Nombre) ||
                row.type === 1,
            )}
            expandedRows={
              categoria.Nombre === "Ingresos"
                ? expandedIngresosRows
                : categoria.Nombre === "Gastos"
                  ? expandedGastosRows
                  : categoria.Nombre === "Inversión"
                    ? expandedInversionRows
                    : (null as never)
            }
            onRowToggle={(e) =>
              categoria.Nombre === "Ingresos"
                ? setExpandedIngresosRows(e.data as never)
                : categoria.Nombre === "Gastos"
                  ? setExpandedGastosRows(e.data as never)
                  : categoria.Nombre === "Inversión"
                    ? setExpandedInversionRows(e.data as never)
                    : null
            }
          />
        ))}
      </div>
      <BudgetConceptsDialog
        visible={conceptDialogOpen}
        selected={conceptsSelected}
        onHide={() => setConceptDialogOpen(false)}
        onAddComplete={(selected) => {
          selected.forEach((item) => {
            if (!conceptsSelected.includes(item as never)) {
              // TODO: FIX NEVER
              setConceptsSelected((prev) => [...prev, item] as never); // TODO: FIX NEVER
            }
          });
          setConceptDialogOpen(false);
        }}
      />
    </Sidebar>
  );
};

export default BudgetConceptsDrawer;
