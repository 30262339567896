import React from "react";
import { ArrayUtils } from "@utils";
import { useNavigation } from "@hooks";
import { Divider } from "primereact/divider";
import { NavLink, INavLinkProps } from "@nubeteck/prime";

import "./sidebar.css";

const Sidebar = () => {
  const { routes } = useNavigation();

  const items = React.useMemo(() => {
    const mainRoute = routes.find((route) => route.id === "app-root");
    return mainRoute?.children?.reduce<INavLinkProps[]>((acc, route) => {
      const routeFormatted = ArrayUtils.formatNavbarItems(route);
      if (routeFormatted) return [...acc, routeFormatted];
      return acc;
    }, []);
  }, [routes]);

  return (
    <aside
      id="sidebar"
      className="hidden lg:flex flex-column absolute h-full shadow-1 top-1 bottom-1 left-1 surface-section transition-duration-500"
    >
      <div className="flex h-3rem justify-content-center align-items-center px-3">
        <img height={30} alt="nubeteck-logo" src="/logo-nubeteck.png" />
      </div>
      <Divider className="m-0" />
      <nav
        id="sidebar-navbar"
        className="flex flex-1 overflow-scroll flex-column px-3 pt-2 w-full gap-1"
      >
        <ul className="p-0 m-0 list-none">
          <NavLink to="/" items={[]} icon="home" title="Inicio" />
          {!!items?.length &&
            items?.map((item) => <NavLink key={item.title} {...item} />)}
        </ul>
      </nav>
    </aside>
  );
};

export default Sidebar;
