const validateDocument = (document: string): string | boolean => {
  const valueToBe = document.replace(/-/g, "");
  const c = valueToBe.split("");
  const v = [1, 2, 1, 2, 1, 2, 1, 2, 1, 2];
  let result = 0;
  let oc;
  for (let i = 0; i < 10; i++) {
    const up = Number.parseInt(c[i]) * v[i];
    const ab = up;
    if (ab >= 10) {
      oc = ab
        .toString()
        .split("")
        .map((x) => parseInt(x))
        .reduce((x, y) => x + y);
    } else {
      oc = ab;
    }
    result = parseFloat(result.toString()) + parseFloat(oc.toString());
  }
  let dp = result;
  const ac = dp.toString().split("")[0] + "0";
  const acInt = parseInt(ac);
  const uj = (acInt / 10) * 10;
  if (uj < dp) {
    dp = uj + 10 - dp;
  } else {
    dp = uj - dp;
  }

  if (Number.parseInt(c[10]) == dp) {
    return true;
  } else {
    return "La cédula ingresada no es válida";
  }
};

export default validateDocument;
