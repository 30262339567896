import API from "@lib/api";
import { BaseApi } from "@api";
import { FakerUtils } from "@utils";
import { Messages } from "@interfaces";

import { IPurchaseInvoice, IPurchaseInvoiceForm } from "../interfaces";

export class PurchaseInvoicesApi extends BaseApi {
  protected static readonly api = API({ namespace: "FacturasCompras" });
  protected static readonly filterApi = API({ namespace: "Filtro" });
  protected static readonly messages: Messages<typeof PurchaseInvoicesApi> = {
    createPurchaseInvoice: {
      loading: "Creando una nueva factura de compra...",
      success: "Factura de compra creada correctamente.",
      error: (error) => `Hubo un error al crear la factura de compra: ${error}`,
    },
  };

  // public static async getAll(): Promise<IPurchaseDocument[]> {
  //   const result = await this.api.get("GetAll");
  //   return result.data;
  // }

  //   public static async getAll(): Promise<IPurchaseDocument[]> {
  //     const result = FakerUtils.getAllPurchaseInvoices();
  //     return result.data;
  //   }

  //   public static async getAllByFilter(
  //     filterId: number,
  //   ): Promise<IPurchaseDocument[]> {
  //     const result = await this.filterApi.get<IPurchaseDocument[]>(
  //       `FiltroPorArea/${filterId}`,
  //     );
  //     return result.data;
  //   }

  // public static async getCreate(): Promise<IPurchaseInvoiceForm> {
  //   const result = await this.api.get("Create");
  //   return result.data;
  // }

  public static async getUpdate(): Promise<IPurchaseInvoiceForm> {
    const result = FakerUtils.getPurchaseInvoiceFormUpdateValues();
    return result.data;
  }

  public static async createPurchaseInvoice(
    data: object,
  ): Promise<IPurchaseInvoice> {
    const result = await this.api.post("Create", data);
    return result.data;
  }
}
