import { omit } from "radash";

import { INode } from "../interfaces";

type INodeNull = null | INode;
type IData = INode["data"];

export default class Tree {
  root: INodeNull;

  constructor(parentNode?: INodeNull) {
    if (parentNode) {
      this.root = parentNode;
    } else {
      this.root = null;
    }
  }

  getNodeById(
    id: number,
    type: INode["type"],
    node: INodeNull = this.root,
  ): INodeNull {
    const queue: INodeNull[] = [node];

    while (queue.length > 0) {
      const currentNode = queue.shift()!;

      if (currentNode.id === id && currentNode.type === type) {
        return currentNode;
      }

      if (currentNode.children) {
        queue.push(...currentNode.children);
      }
    }

    return null;
  }

  insertNode(parent: INodeNull, newNode: INode) {
    if (!parent) {
      this.root = newNode;
      return;
    }

    if (!parent.children) {
      parent.children = [];
    }

    newNode.parent = omit(parent, ["children"]);
    parent.children.push(newNode);
  }

  updateNodeDataArray(node: INode, nodeId: number, newData: IData) {
    if (node.id === nodeId) {
      node.data = newData;
    } else if (node.children) {
      for (const child of node.children) {
        this.updateNodeDataArray(child, nodeId, newData);
      }
    }
  }

  updateData(dataKey: string, newValue: string | number, node: INodeNull) {
    if (node) {
      node.data[dataKey as string] = newValue;
    }
  }

  calculateTotal(node: INodeNull, dateKey: string, tipo: string) {
    if (node && node.type === 1) {
      let total = 0;

      node.children
        ?.filter((child) => child.tipo === tipo)
        ?.forEach((child) => {
          total += parseInt(`${child.data[dateKey]}`, 10);
        });

      return total;
    }

    return null;
  }

  updateSubcategoriesData(parentNode: INodeNull, dates: string[]) {
    if (parentNode) {
      if (parentNode.children && parentNode.type === 1) {
        const data = dates.reduce<Record<string, number>>((acc, date) => {
          acc[date.replace(" ", "")] = 0;
          return acc;
        }, {});

        for (const child of parentNode.children) {
          if (child.type === 2) {
            dates.forEach((date) => {
              data[date.replace(" ", "")] += (child.data[
                date.replace(" ", "")
              ] ?? 0) as number;
            });
          }
        }

        parentNode.data = data;
      }
    }
  }

  dirtyNode(node: INodeNull) {
    if (!node) return;

    node.isDirty = true;

    if (node.parent && node.parent.type !== 0) {
      const parentNode = this.getNodeById(node.parent?.id as number, 1);
      this.dirtyNode(parentNode);
    }
  }

  hasSomeDirtyNode(node: INodeNull = this.root): boolean {
    if (!node) return false;

    if (node.isDirty) {
      return true;
    }

    if (node.children) {
      for (const child of node.children) {
        if (this.hasSomeDirtyNode(child)) {
          return true;
        }
      }
    }

    return false;
  }
}
