import API from "@lib/api";
import { BaseApi } from "@api";
import { IReport, Messages } from "@interfaces";

export class ReportsApi extends BaseApi {
  protected static api = API({ namespace: "Reporte" });
  protected static messages: Messages<typeof ReportsApi> = {
    // create: {
    //   loading: "Creando un nuevo filtro...",
    //   success: "Filtro creado correctamente.",
    //   error: (error) => `Hubo un error al crear el filtro: ${error}`,
    // },
    // delete: {
    //   loading: "Eliminando filtro...",
    //   success: "Filtro eliminado correctamente.",
    //   error: (error) => `Hubo un error al eliminar el filtro: ${error}`,
    // },
    // update: {
    //   loading: "Actualizando filtro...",
    //   success: "Filtro actualizado correctamente.",
    //   error: (error) => `Hubo un error al actualizar el filtro: ${error}`,
    // },
  };

  public static async getAllByCategory(categoryId: number) {
    const reports = await this.api.get<IReport[]>(
      `GetAllByCategoryId/${categoryId}`,
    );
    return reports.data;
  }

  public static async getById(id: number) {
    const report = await this.api.get<IReport>(`GetById/${id}`);
    return report.data;
  }
}
