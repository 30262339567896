import API from "@lib/api";
import { BaseApi } from "@api";
import {
  Messages,
  IStrategicPlansDetailsFields,
  IStrategicPlansDetailsFieldsValues,
  IStrategicPlansDetailsFieldsGetForCreate,
  IStrategicPlansDetailsFieldsGetForUpdate,
} from "@interfaces";

export class StrategicPlansFieldsApi extends BaseApi {
  protected static api = API({ namespace: "PlanesEstrategicos" });
  protected static messages: Messages<typeof StrategicPlansFieldsApi> = {
    create: {
      error: (error) => error,
      loading: "Creando nuevo campo...",
      success: "Campo creado correctamente.",
    },
    update: {
      error: (error) => error,
      loading: "Actualizando campo...",
      success: "Campo actualizado correctamente.",
    },
    // changeState: {
    //   error: (error) => error,
    //   loading: "Actualizando indicador de impacto...",
    //   success: "Indicador de impacto actualizado correctamente.",
    // },
  };

  public static async getAll(id: number) {
    const result = await this.api.get<IStrategicPlansDetailsFields[]>(
      `GetAllDetalleCampo/${id}`,
    );
    return result.data;
  }

  public static async getAllValues(detailId: number) {
    const result = await this.api.get<IStrategicPlansDetailsFieldsValues[]>(
      `GetAllCamposValoresDetalle/${detailId}`,
    );
    return result.data;
  }

  public static async create(data: IStrategicPlansDetailsFields) {
    const result = await this.api.post("AddPlanEstrategicoDetalleCampo", data);
    return result.data;
  }

  public static async getCreate(id: number) {
    const result = await this.api.get<IStrategicPlansDetailsFieldsGetForCreate>(
      `GetCreatePlanEstrategicoDetalleCampo/${id}`,
    );
    return result.data;
  }

  public static async update(data: Partial<IStrategicPlansDetailsFields>) {
    const result = await this.api.put(
      `UpdatePlanEstrategicoDetalleCampo/${data.CampoId}`,
      data,
    );
    return result.data;
  }

  public static async getUpdate(id: number) {
    const result = await this.api.get<IStrategicPlansDetailsFieldsGetForUpdate>(
      `GetUpdatePlanEstrategicoDetalleCampo/${id}`,
    );
    return result.data;
  }

  // public static async changeState(data: {
  //   IndicadorImpactoId: number;
  //   EstadoId: number;
  // }) {
  //   const result = await this.api.post(
  //     "ChangeStatePlanEstrategicoActividadIndicadorImpacto",
  //     data,
  //   );
  //   return result.data;
  // }
}
