import API from "@lib/api";
import { BaseApi } from "@api";
import { Messages, IStrategicPlansActivitySchedule } from "@interfaces";

export class StrategicPlansSchedulesApi extends BaseApi {
  protected static api = API({ namespace: "PlanesEstrategicos" });
  protected static messages: Messages<typeof StrategicPlansSchedulesApi> = {
    create: {
      error: (error) => error,
      loading: "Agregando nuevos cronogramas de la actividad...",
      success: "Cronogramas agregados a la actividad correctamente.",
    },
  };

  public static async getAllByActivity(activityId: number) {
    const result = await this.api.get<IStrategicPlansActivitySchedule[]>(
      `GetAllCronogramas/${activityId}`,
    );
    return result.data;
  }

  public static async create(data: IStrategicPlansActivitySchedule[]) {
    const result = await this.api.post(
      "AddPlanEstrategicoActividadCronograma",
      data,
    );
    return result.data;
  }
}
