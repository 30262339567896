import React from "react";
import { IRouter } from "@interfaces";
import { useAuthPermissions } from "@nubeteck/auth";
import { Unauthorized, ErrorBoundary } from "@components";
import { RouteObject, RouterProvider, createBrowserRouter } from "react-router";

import * as Features from "../../features";

import NavigationContext from "./navigation-context";

export interface IProviderProps {
  routes: IRouter[];
}

const Provider = ({ routes }: IProviderProps) => {
  const permissions = useAuthPermissions();

  const formatRoutes = React.useCallback(
    (route: IRouter, parentPath: string = "/"): Omit<RouteObject, "index"> => {
      const isDashboard = !!route.dashboardId;
      const isRoot = route.id.includes("root");
      const path = `${parentPath}/${route.path}`.replace(/\/{2,}/gi, "/");

      const isAllowed = route.permissions?.length
        ? permissions?.hasPermission(
            route.permissions ?? [],
            route.permissionOperator,
          )
        : true;

      const children = route.children?.length
        ? route.children.map((child) => formatRoutes(child, path))
        : undefined;

      return {
        path,
        children,
        id: route.id,
        title: route.title,
        errorElement: isRoot ? <ErrorBoundary /> : undefined,
        handle: {
          title: route.title,
          iconName: route.iconName ?? "error-404",
          sidebar: isAllowed ? route.sidebar : false,
        },
        element: isDashboard ? (
          <Features.DashboardFeature.Pages.DashboardTemplatePage
            settingId={route.dashboardId ?? 0}
          />
        ) : isAllowed ? (
          route.element
        ) : (
          <Unauthorized />
        ),
      };
    },
    [permissions],
  );

  const routesFormatted = routes.map((route) => formatRoutes(route, "/"));

  return (
    <NavigationContext.Provider value={{ routes: routesFormatted }}>
      <RouterProvider router={createBrowserRouter(routesFormatted)} />
    </NavigationContext.Provider>
  );
};

export default Provider;
