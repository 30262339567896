import React from "react";
import { omit } from "radash";
import { useForm } from "react-hook-form";
import { Button } from "primereact/button";
import { IPermissions } from "@interfaces";
import { ArrayUtils } from "@nubeteck/utils";
import { useQueryClient } from "@tanstack/react-query";
import { useGlobalQuery, useGlobalMutation } from "@hooks";

import { Form } from "../../form";
import { Dialog, IDialogProps } from "../dialog";
import { Dropdown, InputText, InputTextarea } from "../../inputs";

export interface IPermissionsFormDialogProps extends IDialogProps {
  moduleId?: number;
  permissionsId: number;
}

type FormValues = IPermissions;

const model = {
  to: (data: FormValues): IPermissions => {
    return {
      ModuloId: data.ModuloId,
      PermisoId: data.PermisoId,
      Descripcion: data.Descripcion,
      EstaVigente: data.EstaVigente,
      PermisoNombre: data.PermisoNombre,
    };
  },
  from: (data: IPermissions): FormValues => {
    return {
      ModuloId: data.ModuloId,
      PermisoId: data.PermisoId,
      Descripcion: data.Descripcion,
      EstaVigente: data.EstaVigente,
      PermisoNombre: data.PermisoNombre,
    };
  },
};

const PermissionsFormDialog = ({
  moduleId,
  permissionsId,
  ...props
}: IPermissionsFormDialogProps) => {
  const queryClient = useQueryClient();
  const createPermission = useGlobalMutation("Permissions", "create");
  const updatePermission = useGlobalMutation("Permissions", "update");

  const getCreate = useGlobalQuery("Permissions", "getCreate", moduleId, {
    enabled: !!moduleId,
  });
  const getUpdate = useGlobalQuery("Permissions", "getUpdate", permissionsId, {
    enabled: !!permissionsId,
  });

  const [moduleDisabled, setModuleDisabled] = React.useState(true);

  const form = useForm<FormValues>();
  const { reset } = form;

  React.useEffect(() => {
    if (permissionsId && getUpdate.data) {
      reset(model.from(getUpdate.data), { keepDefaultValues: true });
    }
  }, [reset, permissionsId, getUpdate.data]);

  React.useEffect(() => {
    if (!permissionsId && getCreate.data) {
      reset(omit(getCreate.data, ["ModulosSelect", "Validaciones"]), {
        keepDefaultValues: false,
      });
    }
  }, [reset, getCreate.data, permissionsId]);

  const handleOnHide = () => {
    reset();
    props.onHide();
    queryClient.invalidateQueries({ queryKey: ["Modules"] });
  };

  const onSubmit = (values: FormValues) => {
    if (permissionsId) {
      return updatePermission.mutateAsync(model.to(values), {
        onSuccess: handleOnHide,
      });
    }
    return createPermission.mutateAsync(model.to(values), {
      onSuccess: handleOnHide,
    });
  };

  const modules = React.useMemo(() => {
    if (permissionsId) return getUpdate.data?.ModulosSelect;
    else return getCreate.data?.ModulosSelect;
  }, [
    getCreate.data?.ModulosSelect,
    getUpdate.data?.ModulosSelect,
    permissionsId,
  ]);

  const modulesOptions = React.useMemo(() => {
    if (!modules?.length) return [];
    return ArrayUtils.selectLabelValue(modules, "ModuloNombre", "ModuloId");
  }, [modules]);

  const loading = React.useMemo(() => {
    if (permissionsId) return getUpdate.isPending;
    return getCreate.isPending;
  }, [permissionsId, getCreate.isPending, getUpdate.isPending]);

  const validations = React.useMemo(() => {
    if (permissionsId) return getUpdate.data?.Validaciones;
    else return getCreate.data?.Validaciones;
  }, [
    getCreate.data?.Validaciones,
    getUpdate.data?.Validaciones,
    permissionsId,
  ]);

  return (
    <Dialog
      {...props}
      loading={loading}
      className="w-full lg:w-5"
      subtitle="Rellena los datos del formulario"
      onHide={() => {
        if (permissionsId) form.reset();
        props.onHide();
      }}
      title={
        permissionsId
          ? `Editando permiso '${getUpdate.data?.PermisoNombre ?? ""}'`
          : "Creando nuevo permiso"
      }
      footer={
        <div className="flex flex-1 justify-content-end gap-2">
          <Button
            size="small"
            label="Cancelar"
            severity="secondary"
            onClick={handleOnHide}
          />
          <Button
            size="small"
            onClick={form.handleSubmit(onSubmit)}
            label={permissionsId ? "Guardar cambios" : "Crear permiso"}
            loading={
              loading ||
              createPermission.isPending ||
              updatePermission.isPending
            }
          />
        </div>
      }
    >
      <Form form={form}>
        <InputText
          name="PermisoNombre"
          fieldClassName="flex-1"
          label="Nombre del permiso"
          className="p-inputtext-sm"
          rules={validations?.PermisoNombre}
          placeholder="Escribe el nombre del permiso"
        />
        <InputTextarea
          name="Descripcion"
          fieldClassName="flex-1"
          className="p-inputtext-sm"
          label="Descripción del permiso"
          rules={validations?.Descripcion}
          placeholder="Escribe una descripción"
        />
        <Dropdown
          label="Módulo"
          name="ModuloId"
          key="ModuleDropdown"
          options={modulesOptions}
          className="p-inputtext-sm"
          disabledInput={moduleDisabled}
          placeholder="Selecciona un modulo"
        />
        {moduleDisabled && (
          <Button
            size="small"
            type="button"
            className="w-auto"
            severity="secondary"
            label="Cambiar de modulo"
            onClick={() => setModuleDisabled(false)}
          />
        )}
      </Form>
    </Dialog>
  );
};

export default PermissionsFormDialog;
