import { Datejs } from "@nubeteck/utils";

export const getDateRange = (startDate: Date, endDate: Date) => {
  const months: string[] = [];

  let current = Datejs(startDate);

  while (current.isBefore(endDate) || current.isSame(endDate)) {
    months.push(current.format("MMMM YYYY"));
    current = current.add(1, "month");
  }

  return months;
};
