import React from "react";
import { Reducers } from "@core";
import { renderTooltip } from "@utils";
import { Icon } from "@nubeteck/icons";
import { useParams } from "react-router";
import { Button } from "primereact/button";
import { EventUtils } from "@nubeteck/utils";
import { ChangeLogDialog } from "@components";
import { TabView, TabPanel } from "primereact/tabview";
import {
  CardTable,
  Description,
  useHeaderActions,
  IDataTableColumnsProps,
} from "@nubeteck/prime";

import { useCostCenterQuery } from "../hooks";
import { BudgetConceptsDrawer } from "../components";

import { IConceptosPresupuestos } from "./budget-detail/budget-detail";

const BudgetDetails = () => {
  const { id } = useParams();
  const { dispatch } = useHeaderActions();

  const budgetId = parseInt(`${id}`, 10);

  const { data: budget } = useCostCenterQuery("Budget", "getById", budgetId, {
    enabled: !!budgetId,
  });

  const [conceptsSidebarOpen, setConceptsSidebarOpen] = React.useState(false);
  const [changeLogState, changeLogDispatch] = React.useReducer(
    Reducers.DialogReducer,
    { id: 0, open: false },
  );

  React.useEffect(() => {
    dispatch({
      type: "SET_ACTIONS",
      payload: [
        renderTooltip("Historial de cambios", "changelog"),
        <Icon
          name="book"
          key="ChangeLogIcon"
          className="changelog align-self-center text-primary mr-1"
          onClick={EventUtils.callEvent(changeLogDispatch, {
            type: "OPEN_DIALOG",
            payload: Number(id ?? 0),
          })}
        />,
        <Button
          size="small"
          key="AddNewConcept"
          label="Agregar nuevo concepto"
          onClick={() => setConceptsSidebarOpen(true)}
          icon={<Icon size={18} name="contract" className="mr-2"></Icon>}
        />,
      ],
    });
    return () => dispatch({ type: "CLEAR_ACTIONS" });
  }, [dispatch, id]);

  React.useEffect(() => {
    if (budget?.Nombre) {
      dispatch({
        type: "SET_VARIABLES",
        payload: {
          name: `${budget?.Nombre || ""} ${budget?.PresupuestoId || ""}`,
        },
      });
    }
    () => dispatch({ type: "CLEAR_VARIABLES" });
  }, [dispatch, budget?.Nombre, budget?.PresupuestoId]);

  const value: IConceptosPresupuestos[] = [
    {
      Orden: 1,
      Monto: 123,
      ConceptoId: 1,
      Concepto: "Concepto 1",
      FechaFin: "13/08/2024",
      FechaInicio: "13/03/2024",
    },
  ];

  const columns: IDataTableColumnsProps<IConceptosPresupuestos>[] = [
    {
      field: "Concepto",
      header: "Concepto",
    },
    {
      field: "FechaInicio",
      header: "Fecha de inicio",
    },
    {
      field: "FechaFin",
      header: "Fecha de fin",
    },
    {
      field: "Monto",
      header: "Monto",
    },
    {
      field: "Orden",
      header: "Orden",
    },
  ];

  return (
    <>
      <Description
        columns={2}
        className="mb-4"
        title="Presupuesto #4 detalle"
        items={[
          {
            value: 4,
            label: "Código",
          },
          {
            label: "Nombre",
            value: "Presupuesto anual",
          },
          {
            value: "C-4",
            label: "Centro costo",
          },
          {
            value: "13/03/2024",
            label: "Fecha de inicio",
          },
          {
            value: "13/03/2024",
            label: "Fecha de fin",
          },
          {
            label: "Estado",
            value: "Activo",
            //  <StatusTag status={"Activo"}></StatusTag>,
          },
          {
            label: "Descripción",
            value: "Presupuesto anual",
          },
        ]}
      />
      <TabView panelContainerClassName="p-0">
        <TabPanel header="Conceptos">
          <CardTable
            type="data"
            value={value}
            hideSelectFilter
            headActions={[]}
            columns={columns}
            tableActions={[]}
            hideFilterSettingsIcon
            title="Presupuestos conceptos"
          ></CardTable>
        </TabPanel>
      </TabView>
      <BudgetConceptsDrawer
        visible={conceptsSidebarOpen}
        onHide={() => setConceptsSidebarOpen(false)}
      />
      <ChangeLogDialog
        entityName={"a"}
        entityId={changeLogState.id}
        visible={changeLogState.open}
        onHide={EventUtils.callEvent(changeLogDispatch, {
          type: "CLOSE_DIALOG",
        })}
      />
    </>
  );
};

export default BudgetDetails;
