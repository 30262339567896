import React from "react";

// TODO: UPDATE a ConducesCompras y otras tablas relacionadas n:n
// ConducesCompras
// ConducesComprasDetalles
// ConducesComprasArchivos
// ConducesComprasClonaciones
// ConducesComprasFacturasCompras
// ConducesComprasOrdenesCompras
const DeliveryNoteEdit = () => {
  return <div>DeliveryNoteEdit</div>;
};

export default DeliveryNoteEdit;
