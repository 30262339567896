import React from "react";
import { useGlobalQuery } from "@hooks";
import { Navigate } from "react-router";
import { useLocation, useNavigate } from "react-router";
import { Config, AppRoutes, AppRouteNames } from "@core";
import { ProgressSpinner } from "primereact/progressspinner";
import { useAuthSignIn, useIsAuthenticated } from "@nubeteck/auth";

const VerifyAuthPage = () => {
  const signIn = useAuthSignIn();
  const location = useLocation();
  const navigate = useNavigate();
  const isAuthenticated = useIsAuthenticated()();

  const getMe = useGlobalQuery("Users", "getMe", undefined, {
    networkMode: "always",
    enabled: window.location.pathname.includes("verify"),
  });

  React.useEffect(() => {
    if (location.state?.from.includes("login") && !isAuthenticated) {
      if (getMe.isSuccess) {
        const result = signIn({
          userState: getMe.data,
          auth: { type: "Bearer", token: location.state?.data?.Token },
        });

        if (result) {
          localStorage.removeItem("token");
          window.location.href = AppRoutes[AppRouteNames.HOME_PAGE].create({});
        }
      }
    }
  }, [
    getMe.data,
    getMe.isSuccess,
    isAuthenticated,
    location.state?.data?.Token,
    location.state?.from,
    navigate,
    signIn,
  ]);

  if (isAuthenticated) {
    return (
      <Navigate replace to={AppRoutes[AppRouteNames.HOME_PAGE].create({})} />
    );
  }

  if (!location.state?.from.includes("login") && !isAuthenticated) {
    return (
      <Navigate replace to={AppRoutes[AppRouteNames.SIGN_IN_PAGE].create({})} />
    );
  }

  return (
    <div className="flex flex-1 align-items-center justify-content-center h-screen w-screen login-bg">
      <div className="surface-ground px-3 md:px-5 py-5 shadow-4 md:w-8 lg:w-5 xl:w-4 w-full">
        <div className="mb-3 flex flex-column text-center gap-1">
          <img
            height={70}
            className="mb-2"
            src={Config.app_logo}
            alt={Config.app_description}
            style={{ objectFit: "contain" }}
          />
          <span className="text-xl font-bold">
            Verificación de autenticación
          </span>
          <span className="text-600 text-md font-medium">
            Estamos verificando la autenticidad de tus datos.
          </span>
        </div>
        <div className="h-12rem flex justify-content-center align-items-center">
          <ProgressSpinner
            strokeWidth="5"
            animationDuration="1s"
            fill="var(--surface-ground)"
            className="w-full w-3rem h-3rem"
          />
        </div>
      </div>
    </div>
  );
};

export default VerifyAuthPage;
