import React from "react";
import { useParams } from "react-router";
import { useForm } from "react-hook-form";
import { Button } from "primereact/button";
import { last, omit, isArray } from "radash";
import { DialogProps } from "primereact/dialog";
import { IStrategicPlansActivity } from "@interfaces";
import { useQueryClient } from "@tanstack/react-query";
import {
  useGlobalQuery,
  useGlobalMutation,
  useTreeStrategicDetails,
} from "@hooks";
import {
  Form,
  Dialog,
  TreeSelect,
  InputSwitch,
  InputTextarea,
} from "@components";

export interface IActivityFormDialogProps extends DialogProps {
  activityId?: number;
  onCompleteForm?: (activityId: number) => void;
}

type FormValues = Omit<IStrategicPlansActivity, "Cronogramas" | "EstadoNombre">;

const model = {
  from: (data: IStrategicPlansActivity): FormValues => {
    return {
      EstadoId: data.EstadoId,
      Recursos: data.Recursos,
      Actividad: data.Actividad,
      DetalleId: data.DetalleId,
      ActividadId: data.ActividadId,
      LineasBases: data.LineasBases,
      Presupuesto: data.Presupuesto,
    };
  },
  to: (data: FormValues): IStrategicPlansActivity => {
    return {
      EstadoId: data.EstadoId,
      Recursos: data.Recursos,
      Actividad: data.Actividad,
      DetalleId: data.DetalleId,
      ActividadId: data.ActividadId,
      LineasBases: data.LineasBases,
      Presupuesto: data.Presupuesto,
      PlanOperativoId: data.PlanOperativoId,
    };
  },
};

const ActivityFormDialog = ({
  visible,
  activityId,
  onCompleteForm,
  ...props
}: IActivityFormDialogProps) => {
  const { id } = useParams();
  const queryClient = useQueryClient();

  const strategicId = parseInt(`${id}`, 10);

  const createActivity = useGlobalMutation(
    "StrategicPlansActivities",
    "create",
  );
  const updateActivity = useGlobalMutation(
    "StrategicPlansActivities",
    "update",
  );
  const getCreate = useGlobalQuery(
    "StrategicPlansActivities",
    "getCreate",
    undefined,
    { enabled: !activityId && visible },
  );
  const getUpdate = useGlobalQuery(
    "StrategicPlansActivities",
    "getUpdate",
    activityId,
    { enabled: !!activityId },
  );
  const poa = useGlobalQuery("StrategicPlans", "getById", strategicId ?? 0, {
    enabled: !!strategicId,
  });
  const pei = useGlobalQuery(
    "StrategicPlans",
    "getById",
    poa.data?.Relacion?.PeiId ?? 0,
    { enabled: !!poa.data?.Relacion?.PeiId },
  );
  const peu = useGlobalQuery(
    "StrategicPlans",
    "getById",
    poa.data?.Relacion?.PeuId ?? 0,
    { enabled: !!poa.data?.Relacion?.PeuId },
  );
  const peuTemplate = useGlobalQuery(
    "StrategicPlansTemplates",
    "getById",
    peu.data?.PlantillaId ?? 0,
    { enabled: !!peu.data?.PlantillaId },
  );

  const detailPeuTemplate = React.useMemo(() => {
    return last(peuTemplate.data?.Detalles ?? []);
  }, [peuTemplate.data?.Detalles]);

  const detailsPei = React.useMemo(() => {
    if (!pei.data?.Detalles?.length) return [];
    return pei.data?.Detalles;
  }, [pei.data?.Detalles]);

  const detailsPeu = React.useMemo(() => {
    if (!peu.data?.Detalles?.length) return [];
    return peu.data?.Detalles;
  }, [peu.data?.Detalles]);

  const detailsPoa = React.useMemo(() => {
    if (!poa.data?.Detalles?.length) return [];
    return poa.data?.Detalles;
  }, [poa.data?.Detalles]);

  const { value } = useTreeStrategicDetails(
    [...detailsPei, ...detailsPeu, ...detailsPoa],
    detailPeuTemplate?.TipoDeNivelId ?? 0,
  );

  const form = useForm<FormValues>();
  const { reset } = form;

  React.useEffect(() => {
    if (!activityId && getCreate.data) {
      reset(omit(getCreate.data, ["Validaciones"]));
    }
  }, [reset, getCreate.data, activityId]);

  React.useEffect(() => {
    if (activityId && getUpdate.data) {
      reset(model.from(getUpdate.data));
    }
  }, [reset, getUpdate.data, activityId]);

  const handleSuccess = React.useCallback(
    (data: { ActividadId: number }) => {
      form.reset();
      props.onHide();
      queryClient.invalidateQueries({
        queryKey: ["StrategicPlansActivities", data.ActividadId],
      });
      onCompleteForm?.(data.ActividadId);
    },
    [form, onCompleteForm, props, queryClient],
  );

  const onSubmit = (values: FormValues) => {
    if (activityId) {
      return updateActivity.mutateAsync(
        model.to({ ...values, PlanOperativoId: strategicId }),
        { onSuccess: handleSuccess },
      );
    }
    return createActivity.mutateAsync(
      model.to({ ...values, PlanOperativoId: strategicId }),
      { onSuccess: handleSuccess },
    );
  };

  const validations = React.useMemo(() => {
    if (activityId) return getUpdate.data?.Validaciones;
    else return getCreate.data?.Validaciones;
  }, [activityId, getUpdate.data?.Validaciones, getCreate.data?.Validaciones]);

  const loading = React.useMemo(() => {
    if (activityId) return getUpdate.isPending;
    else return getCreate.isPending;
  }, [activityId, getUpdate.isPending, getCreate.isPending]);

  return (
    <Dialog
      {...props}
      visible={visible}
      loading={loading}
      className="w-full md:w-10"
      subtitle="Rellena los datos del formulario"
      title={activityId ? "Editando actividad" : "Creando nueva actividad"}
      onHide={() => {
        if (activityId) form.reset();
        props.onHide();
      }}
      footer={() => (
        <div className="flex flex-1 justify-content-end gap-2">
          <Button
            size="small"
            severity="warning"
            label="Reiniciar formulario"
            disabled={!form.formState.isDirty}
            onClick={() => {
              form.reset();
            }}
            loading={createActivity.isPending || updateActivity.isPending}
          />
          <Button
            size="small"
            disabled={!form.formState.isDirty}
            onClick={form.handleSubmit(onSubmit)}
            label={activityId ? "Guardar cambios" : "Crear actividad"}
            loading={createActivity.isPending || updateActivity.isPending}
          />
        </div>
      )}
    >
      <Form form={form}>
        <TreeSelect
          filter
          filterBy="id"
          options={value}
          label="Detalle"
          name="DetalleId"
          selectionMode="single"
          rules={validations?.["DetalleId"]}
          placeholder="Selecciona un detalle"
          filterPlaceholder="Escribe el código de detalle"
          helperText={`Selecciona solo ${detailPeuTemplate?.TipoNombre ?? ""}`}
          nodeTemplate={({ data, selectable }) => {
            return (
              <span className={selectable ? "text-primary" : "text-color"}>
                {data.Codigo} <b>{data.TipoNombre}:</b> {data.Descripcion}
              </span>
            );
          }}
          valueTemplate={(treeNode) => {
            if (!isArray(treeNode) || !treeNode.length)
              return <>Selecciona un detalle</>;
            return (
              <span>
                {treeNode[0]?.id} <b>{treeNode[0]?.data.TipoNombre}:</b>{" "}
                {treeNode[0]?.data.Descripcion}
              </span>
            );
          }}
        />
        <InputTextarea
          name="Actividad"
          label="Actividad"
          className="p-inputtext-sm"
          rules={validations?.["Actividad"]}
          placeholder="Ej: Revisar funciones"
        />
        <InputTextarea
          name="LineasBases"
          label="Lineas Bases"
          placeholder="Escribe..."
          className="p-inputtext-sm"
          rules={validations?.["LineasBases"]}
        />
        <InputTextarea
          name="Recursos"
          label="Recursos"
          placeholder="Escribe..."
          className="p-inputtext-sm"
          rules={validations?.["Recursos"]}
        />
        <InputSwitch name="Presupuesto" label="¿Necesita presupuesto?" />
      </Form>
    </Dialog>
  );
};

export default ActivityFormDialog;
