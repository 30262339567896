import clsx from "clsx";
import React from "react";
import { capitalize } from "radash";
import { Dialog } from "@components";
import { Icon } from "@nubeteck/icons";
import { useGlobalQuery } from "@hooks";
import { Datejs } from "@nubeteck/utils";
import { Column } from "primereact/column";
import { Message } from "primereact/message";
import { Timeline } from "primereact/timeline";
import { EmptyMessage } from "@nubeteck/prime";
import { DialogProps } from "primereact/dialog";
import { DataTable } from "primereact/datatable";
import { ILog, IChangeLogEntry } from "@interfaces";

export interface IChangeLogDialogProps extends DialogProps {
  entityId: number;
  entityName: string;
  entityId2?: number;
  entityName2?: string;
}

const formatDate = (date: string) => {
  const today = Datejs().startOf("day");
  const givenDate = Datejs(date).startOf("day");
  const currentYear = Datejs().year();
  const dateYear = Datejs(date).year();

  const formatters = {
    isToday: () => "Hoy a las " + Datejs(date).format("h:mm a"),
    isYesterday: () => "Ayer a las " + Datejs(date).format("h:mm a"),
    default: () => capitalize(Datejs(date).format("dddd D [de] MMMM, h:mm A")),
    isPreviousYear: () =>
      capitalize(Datejs(date).format("dddd D [de] MMMM [del] YYYY, h:mm A")),
  };

  const getFormatterKey = () => {
    if (givenDate.isSame(today, "day")) return "isToday";
    if (givenDate.isSame(today.subtract(1, "day"), "day")) return "isYesterday";
    if (dateYear < currentYear) return "isPreviousYear";
    return "default";
  };

  return formatters[getFormatterKey()]();
};

// Procedimiento LogsGetLogsByEntityAndIdTEST en CoopHillary
const ChangeLogDialog = ({
  visible,
  entityId,
  entityId2,
  entityName,
  entityName2,
  ...props
}: IChangeLogDialogProps) => {
  const logs = useGlobalQuery(
    "Logs",
    "getByEntityAndQuery",
    { Id: entityId, Id2: entityId2, Entity: entityName, Entity2: entityName2 },
    { networkMode: "always", enabled: !!entityId && visible },
  );

  const renderValueDisplay = React.useCallback(
    (value: string, isOriginal?: boolean) => {
      return (
        <Message
          text={value}
          style={{ minWidth: 250 }}
          severity={isOriginal ? "error" : "success"}
          className="w-full justify-content-between text-gray-600"
          icon={
            <Icon
              size={16}
              touchable={false}
              name={isOriginal ? "minus" : "plus"}
              className={clsx(
                isOriginal ? "bg-red-100" : "bg-green-100",
                "border-round-lg p-1",
              )}
            />
          }
        />
      );
    },
    [],
  );

  const customizedContent = (item: ILog) => {
    const logs = JSON.parse(item.Changes).Changes as IChangeLogEntry[];
    return (
      <div className="flex flex-column mb-5">
        <div className="flex justify-content-between align-items-center gap-2 mb-3 font-medium">
          <span className="line-height-1">{formatDate(item.ModifiedAt)}</span>
          <span className="line-height-1">
            Modificado por:{" "}
            <a className="text-primary-600 cursor-pointer">{item.User}</a>
          </span>
        </div>
        <DataTable
          size="small"
          value={logs ?? []}
          className="border-round-md border-1 surface-border changelog-datatable"
        >
          <Column field="ColumnName" header="Propiedad" />
          <Column
            field="ValorOriginal"
            header="Valor original"
            body={(data: IChangeLogEntry) =>
              renderValueDisplay(data.OriginalValue, true)
            }
          />
          <Column
            field="ValorModificado"
            header="Valor modificado"
            body={(data: IChangeLogEntry) =>
              renderValueDisplay(data.CurrentValue, false)
            }
          />
        </DataTable>
      </div>
    );
  };

  const customizedMarker = () => (
    <div className="py-1">
      <div className="p-1 bg-primary border-circle" />
    </div>
  );

  return (
    <Dialog
      {...props}
      visible={visible}
      className="w-full md:w-11"
      title="Historial de cambios"
    >
      {logs.data?.length ? (
        <Timeline
          value={logs.data ?? []}
          marker={customizedMarker}
          content={customizedContent}
          className="change-log-timeline"
        />
      ) : (
        <EmptyMessage message="No hay historial de cambios" />
      )}
    </Dialog>
  );
};

export default ChangeLogDialog;
