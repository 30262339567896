import React from "react";
import { Reducers, Statuses } from "@core";
import { Button } from "primereact/button";
import { ICostsCenter } from "@interfaces";
import { ConfirmDialog } from "@components";
import { EventUtils } from "@nubeteck/utils";
import { FilterMatchMode } from "primereact/api";
import { useAuthPermissions } from "@nubeteck/auth";
import { confirmDialog } from "primereact/confirmdialog";
import { CardTable, StatusTag, IDataTableColumnsProps } from "@nubeteck/prime";

import { Permissions } from "../../core";
import { SettingsCostsCenterDialog } from "../../components";
import { useCostCenterQuery, useCostCenterMutation } from "../../hooks";

const SettingsCostsCenterPage = () => {
  const permissions = useAuthPermissions();

  const costsCenter = useCostCenterQuery("CostsCenter", "getAll");
  const changeState = useCostCenterMutation("CostsCenter", "changeState");

  const [state, dispatch] = React.useReducer(Reducers.DialogReducer, {
    id: 0,
    open: false,
  });

  const handleRestore = React.useCallback(
    (id: number) => {
      confirmDialog({
        acceptLabel: "Activar",
        acceptClassName: "p-button-sm",
        header: "Activar centro de costo",
        message: "¿Está seguro de que desea activar este centro de costo?",
        accept: async () => {
          await changeState.mutateAsync({
            CentroCostoId: id,
            EstadoId: Statuses.ACTIVE_CODE,
          });
        },
      });
    },
    [changeState],
  );

  const handleNullify = React.useCallback(
    (id: number) => {
      confirmDialog({
        acceptLabel: "Anular",
        header: "Anular centro de costo",
        message: "¿Está seguro de que desea anular este centro de costo?",
        accept: async () => {
          await changeState.mutateAsync({
            CentroCostoId: id,
            EstadoId: Statuses.NULLIFIED_CODE,
          });
        },
      });
    },
    [changeState],
  );

  const columns: IDataTableColumnsProps<ICostsCenter>[] = [
    { header: "#", sortable: true, field: "CentroCostoId" },
    {
      filter: true,
      field: "Nombre",
      header: "Nombre",
      dataType: "text",
      filterMatchMode: FilterMatchMode.CONTAINS,
    },
    {
      filter: true,
      dataType: "text",
      header: "Sucursal",
      field: "SucursalNombre",
      filterMatchMode: FilterMatchMode.CONTAINS,
    },
    {
      filter: true,
      dataType: "text",
      field: "Descripcion",
      header: "Descripción",
      filterMatchMode: FilterMatchMode.CONTAINS,
      body: ({ Descripcion }) => Descripcion || "Sin descripción",
    },
    {
      header: "Estado",
      field: "EstadoNombre",
      body: (data) => <StatusTag status={data.EstadoNombre ?? "Activo"} />,
    },
  ];

  return (
    <>
      <div className="w-full">
        <CardTable
          type="data"
          hideSelectFilter
          columns={columns}
          hideFilterSettingsIcon
          title="Centros de costos"
          value={costsCenter.data ?? []}
          loading={costsCenter.isPending}
          subtitle="Configuración de centro de costos"
          headActions={[
            <Button
              size="small"
              key="AddButtonCostCenter"
              label="Agregar centro de costo"
              onClick={EventUtils.callEvent(dispatch, {
                payload: 0,
                type: "OPEN_DIALOG",
              })}
              disabled={
                !permissions.hasPermission(
                  Permissions.CREATE_COSTS_CENTER_SETTINGS,
                )
              }
            />,
          ]}
          tableActions={(data) => [
            {
              icon: "pencil",
              label: "Editar",
              disabled: !permissions.hasPermission(
                Permissions.EDIT_COSTS_CENTER_SETTINGS,
              ),
              onClick: EventUtils.callEvent(dispatch, {
                type: "OPEN_DIALOG",
                payload: data.CentroCostoId,
              }),
            },
            {
              icon: "ban",
              label: "Anular",
              iconClassName: "text-red-500",
              onClick: EventUtils.callEvent(handleNullify, data.CentroCostoId),
              disabled:
                data.EstadoId === Statuses.NULLIFIED_CODE ||
                !permissions.hasPermission(
                  Permissions.NULLIFY_COSTS_CENTER_SETTINGS,
                ),
            },
            {
              icon: "plug",
              label: "Activar",
              iconClassName: "text-primary-500",
              onClick: EventUtils.callEvent(handleRestore, data.CentroCostoId),
              disabled:
                data.EstadoId === Statuses.ACTIVE_CODE ||
                !permissions.hasPermission(
                  Permissions.RESTORE_COSTS_CENTER_SETTINGS,
                ),
            },
          ]}
        />
      </div>
      <SettingsCostsCenterDialog
        visible={state.open}
        costsCenterId={state.id}
        onHide={EventUtils.callEvent(dispatch, {
          type: "CLOSE_DIALOG",
        })}
      />
      <ConfirmDialog />
    </>
  );
};

export default SettingsCostsCenterPage;
