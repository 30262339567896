import React from "react";
import { Dialog } from "@components";
import { Button } from "primereact/button";
import { Skeleton } from "primereact/skeleton";
import { DialogProps } from "primereact/dialog";
import { ICurrency, PickNullable } from "@interfaces";
import { Form, useForm, InputText } from "@nubeteck/forms";

import { useAccountingQuery, useAccountingMutation } from "../../hooks";

export interface ICurrencyDialogProps extends DialogProps {
  currencyId?: number;
}

type FormValues = PickNullable<
  ICurrency,
  | "Locale"
  | "Simbolo"
  | "MonedaId"
  | "EstadoId"
  | "NombreISO"
  | "EsPrincipal"
  | "MonedaNombre"
>;

const model = {
  from: (data: ICurrency): FormValues => {
    return {
      Locale: data.Locale,
      Simbolo: data.Simbolo,
      EstadoId: data.EstadoId,
      MonedaId: data.MonedaId ?? 0,
      EsPrincipal: data.EsPrincipal,
      NombreISO: data.NombreISO ?? "",
      MonedaNombre: data.MonedaNombre ?? "",
    };
  },
  to: (data: FormValues): ICurrency => {
    return {
      Locale: data.Locale ?? "",
      Simbolo: data.Simbolo ?? "",
      MonedaId: data.MonedaId ?? 0,
      EstadoId: data.EstadoId ?? 1,
      NombreISO: data.NombreISO ?? "",
      MonedaNombre: data.MonedaNombre ?? "",
      EsPrincipal: data.EsPrincipal ?? false,
    };
  },
};

const CurrencyDialog = ({ currencyId, ...props }: ICurrencyDialogProps) => {
  const { data: currencyData, isFetching: isCurrencyFetching } =
    useAccountingQuery("Currency", "getById", currencyId, {
      enabled: !!currencyId,
    });

  const createCurrency = useAccountingMutation("Currency", "create");
  const updateCurrency = useAccountingMutation("Currency", "update");

  const form = useForm<FormValues>({
    defaultValues: {
      Locale: "",
      Simbolo: "",
      MonedaId: 0,
      EstadoId: 1,
      NombreISO: "",
      MonedaNombre: "",
      EsPrincipal: false,
    },
  });

  React.useEffect(() => {
    if (currencyData) {
      form.reset(model.from(currencyData), { keepDefaultValues: true });
    }
  }, [currencyData, form]);

  const onSubmit = (values: FormValues) => {
    if (currencyId) {
      return updateCurrency.mutateAsync(model.to(values), {
        onSuccess: () => {
          form.reset();
          props.onHide();
        },
      });
    }

    return createCurrency.mutateAsync(model.to(values), {
      onSuccess: () => {
        form.reset();
        props.onHide();
      },
    });
  };

  return (
    <Dialog
      {...props}
      style={{ width: "50rem" }}
      subtitle="Rellena los datos del formulario"
      title={currencyId ? `Editando moneda '${""}'` : "Creando nueva moneda"}
      onHide={() => {
        if (currencyId) form.reset();
        props.onHide();
      }}
      footer={(props) => (
        <div className="flex flex-1 justify-content-end gap-2">
          <Button
            size="small"
            label="Cancelar"
            severity="secondary"
            onClick={() => {
              form.reset();
              props.onHide();
            }}
          />
          <Button
            size="small"
            loading={isCurrencyFetching}
            onClick={form.handleSubmit(onSubmit)}
            label={currencyId ? "Guardar cambios" : "Crear moneda"}
          />
        </div>
      )}
    >
      {currencyId && isCurrencyFetching ? (
        <div>
          <Skeleton height="18rem" />
        </div>
      ) : (
        <Form form={form}>
          <InputText
            label="Nombre"
            name="MonedaNombre"
            fieldClassName="mb-1"
            className="p-inputtext-sm"
            placeholder="Escriba el nombre"
            rules={{ required: "Este campo es necesario" }}
            helperText="Por ejemplo: Dolar(es) americano(s)"
          />
          <InputText
            label="ISO"
            name="NombreISO"
            fieldClassName="mb-1"
            className="p-inputtext-sm"
            placeholder="Escriba el nombre ISO"
            rules={{ required: "Este campo es necesario" }}
            helperText="Ingrese el código ISO 4217 de la moneda (ej. USD, EUR, GBP)"
          />
          <InputText
            name="Simbolo"
            label="Símbolo"
            fieldClassName="mb-1"
            className="p-inputtext-sm"
            helperText="Por ejemplo: $"
            placeholder="Escriba el símbolo"
            rules={{ required: "Este campo es necesario" }}
          />
          <InputText
            name="Locale"
            label="Locale"
            fieldClassName="mb-1"
            className="p-inputtext-sm"
            placeholder="Escriba el Locale"
            rules={{ required: "Este campo es necesario" }}
            helperText="Por ejemplo: en-US (https://simplelocalize.io/data/locales/)"
          />
        </Form>
      )}
    </Dialog>
  );
};

export default CurrencyDialog;
