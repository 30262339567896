import { isEmpty } from "radash";
import { Datejs } from "@nubeteck/utils";
import { Skeleton } from "primereact/skeleton";
import { StatusTag, Description } from "@nubeteck/prime";

import { IAccountingMovement } from "../../interfaces";

interface IAccountingMovementDescriptionProps {
  isSkeletonLoading?: boolean;
  accountingMovement?: IAccountingMovement;
}

const AccountingMovementDescription = ({
  isSkeletonLoading,
  accountingMovement,
}: IAccountingMovementDescriptionProps) => {
  const isPeso = accountingMovement?.MonedaId === 1;
  return (
    <>
      {isSkeletonLoading && <Skeleton width="100%" height="10.5rem" />}
      {!isSkeletonLoading && (
        <>
          <Description
            title=""
            columns={isPeso ? 2 : 3}
            items={[
              {
                label: "Fecha",
                value: Datejs(accountingMovement?.Fecha).format("DD/MM/YYYY"),
              },
              {
                label: "Referencia",
                value: accountingMovement?.Referencia ?? "",
              },
              {
                label: "Estado",
                value: (
                  <StatusTag
                    key="STATUS"
                    status={accountingMovement?.EstadoNombre ?? "Activo"}
                  />
                ),
              },
              {
                label: "Moneda",
                value: accountingMovement?.MonedaNombre ?? "",
              },
              !isPeso && accountingMovement?.Tasa
                ? {
                    label: "Tasa",
                    value: accountingMovement.Tasa,
                  }
                : undefined,
              !isPeso
                ? {
                    label: "Fecha de la tasa",
                    value: Datejs(accountingMovement?.TasaCambioFecha).format(
                      "DD/MM/YYYY",
                    ),
                  }
                : undefined,
            ].filter((item) => item !== undefined)}
          />
          <Description
            title=""
            columns={1}
            items={[
              {
                label: "Observaciones",
                value: !isEmpty(accountingMovement?.Observaciones?.trim())
                  ? (accountingMovement?.Observaciones ?? "")
                  : "Sin observaciones",
              },
            ]}
          />
        </>
      )}
    </>
  );
};

export default AccountingMovementDescription;
