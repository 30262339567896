import API from "@lib/api";
import { BaseApi } from "@api";
// import { FakerUtils } from "@utils";
import { Messages } from "@interfaces";

// import { IMonthlyClosing } from "../interfaces";

export class MonthlyClosingApi extends BaseApi {
  protected static api = API({ namespace: "CierresMensuales" });
  protected static messages: Messages<typeof MonthlyClosingApi> = {};

  // public static async getAll(): Promise<IMonthlyClosing[]> {
  //   const result = FakerUtils.getMonthlyClosingPage();
  //   return result.data;
  // }
}
