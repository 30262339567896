import clsx from "clsx";
import React from "react";
import { omit, isDate } from "radash";
import { IFilter } from "@interfaces";
import { Datejs } from "@nubeteck/utils";
import { Button } from "primereact/button";
import { useForm, useFieldArray } from "react-hook-form";
import { useGlobalQuery, useGlobalMutation } from "@hooks";

import { Form } from "../../form";
import { Dialog, IDialogProps } from "../dialog";
import { InputText, InputSwitch } from "../../inputs";
import { FilterFormSection } from "../../filter-form-section";

export interface IFiltersFormDialogProps extends IDialogProps {
  area: string;
  filterId?: number;
}

type FormValues = IFilter;

const model = {
  from: (data: IFilter): FormValues => {
    return {
      Nombre: data.Nombre,
      EstadoId: data.EstadoId,
      FiltroId: data.FiltroId,
      EsPorDefecto: data.EsPorDefecto,
      FiltroAreaId: data.FiltroAreaId,
      FiltrosParametros: data.FiltrosParametros?.map((param) => {
        const value = param.Campo.includes("Fecha")
          ? Datejs(param.Valor1, "YYYY-MM-DDTHH:mm:ssZ[Z]").toDate()
          : param.Valor1;
        const value2 = param.Campo.includes("Fecha")
          ? Datejs(param.Valor2, "YYYY-MM-DDTHH:mm:ssZ[Z]").toDate()
          : param.Valor2;

        return {
          ...param,
          Valor1: value as string,
          Valor2: value2 as string,
        };
      }),
    };
  },
  to: (data: FormValues): IFilter => {
    return {
      Nombre: data.Nombre,
      EstadoId: data.EstadoId,
      FiltroId: data.FiltroId,
      EsPorDefecto: data.EsPorDefecto,
      FiltroAreaId: data.FiltroAreaId,
      FiltrosParametros: data.FiltrosParametros?.map((param, index) => {
        const value = isDate(param.Valor1)
          ? Datejs(param.Valor1).format("YYYY-MM-DDTHH:mm:ssZ[Z]")
          : param.Valor1;
        const value2 = isDate(param.Valor2)
          ? Datejs(param.Valor2).format("YYYY-MM-DDTHH:mm:ssZ[Z]")
          : param.Valor2;

        return {
          Valor1: value,
          Valor2: value2,
          Orden: index + 1,
          Campo: param.Campo,
          ParametroId: param.ParametroId ?? 0,
          FiltroCondicionId: param.FiltroCondicionId,
          FiltroId: (param.FiltroId || data.FiltroId) ?? 0,
          OperadorLogico: index === 0 ? null : param.OperadorLogico,
        };
      }),
    };
  },
};

const FiltersFormDialog = ({
  area,
  filterId,
  ...props
}: IFiltersFormDialogProps) => {
  const createFilter = useGlobalMutation("Filters", "create");
  const updateFilter = useGlobalMutation("Filters", "update");
  const getCreate = useGlobalQuery("Filters", "getCreate", area, {
    enabled: !filterId,
  });
  const getUpdate = useGlobalQuery("Filters", "getUpdate", filterId, {
    enabled: !!filterId,
  });

  const form = useForm<FormValues>();
  const { reset } = form;

  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: "FiltrosParametros",
  });

  React.useEffect(() => {
    if (filterId && getUpdate.data) {
      reset(model.from(getUpdate.data), { keepDefaultValues: true });
    }
  }, [filterId, getUpdate.data, reset]);

  React.useEffect(() => {
    if (!filterId && getCreate.data) {
      reset(
        omit(getCreate.data, [
          "CamposSelect",
          "Validaciones",
          "FiltroAreaAlias",
          "FiltrosCondicionesSelect",
          "ValidacionesParametros",
        ]),
        { keepDefaultValues: false },
      );
    }
  }, [getCreate.data, filterId, reset]);

  const handleOnHide = () => {
    form.reset();
    props.onHide();
  };

  const onSubmit = (values: FormValues) => {
    if (filterId) {
      return updateFilter.mutateAsync(model.to(values), {
        onSuccess: handleOnHide,
      });
    }
    return createFilter.mutateAsync(model.to(values), {
      onSuccess: handleOnHide,
    });
  };

  const loading = React.useMemo(() => {
    if (filterId) return getUpdate.isPending;
    return getCreate.isPending;
  }, [filterId, getCreate.isPending, getUpdate.isPending]);

  const validations = React.useMemo(() => {
    if (filterId) return getUpdate.data?.Validaciones;
    else return getCreate.data?.Validaciones;
  }, [getCreate.data?.Validaciones, getUpdate.data?.Validaciones, filterId]);

  return (
    <Dialog
      {...props}
      loading={loading}
      className="w-full lg:w-9"
      subtitle="Rellena los datos del formulario"
      onHide={() => {
        if (filterId) form.reset();
        props.onHide();
      }}
      title={
        filterId
          ? `Editando filtro '${getUpdate.data?.Nombre ?? ""}'`
          : "Creando nuevo filtro"
      }
      footer={
        <div className="flex flex-1 justify-content-end gap-2">
          <Button
            size="small"
            label="Cancelar"
            severity="secondary"
            onClick={handleOnHide}
          />
          <Button
            size="small"
            onClick={form.handleSubmit(onSubmit)}
            label={filterId ? "Guardar cambios" : "Crear filtro"}
            loading={
              loading || createFilter.isPending || updateFilter.isPending
            }
          />
        </div>
      }
    >
      <Form form={form}>
        <div className="flex flex-column md:flex-row md:gap-3">
          <InputText
            name="Nombre"
            label="Nombre"
            fieldClassName="flex-1"
            className="p-inputtext-sm"
            rules={validations?.["Nombre"]}
            placeholder="Ingresa el nombre del filtro"
          />
          <InputSwitch
            name="EsPorDefecto"
            label="Por defecto"
            fieldClassName="flex-1"
          />
        </div>
        <div className={clsx(fields.length ? "" : "hidden")}>
          {fields.map((field, index) => {
            return (
              <FilterFormSection
                area={area}
                index={index}
                loading={loading}
                filterId={filterId ?? 0}
                onRemoveSection={remove}
                getCreate={getCreate.data}
                getUpdate={getUpdate.data}
                key={`${field.id}${index}`}
                fieldsLength={fields.length ?? 0}
              />
            );
          })}
        </div>
        <div className="flex gap-3">
          <Button
            size="small"
            type="button"
            className="flex w-auto"
            label="Agregar parámetro"
            onClick={() =>
              append({ OperadorLogico: "AND" } as never, {
                shouldFocus: true,
              })
            }
          />
        </div>
      </Form>
    </Dialog>
  );
};

export default FiltersFormDialog;
