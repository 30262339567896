import { RouteObject } from "react-router";
import { INavLinkProps } from "@nubeteck/prime";

export const createTableMatrix = (
  columns: string[],
  rows: Record<string, string | number>[],
): (string | number)[][] => {
  // Iniciamos la matriz con las columnas como primer elemento
  const data: (string | number)[][] = [columns];

  // Agregamos las filas de datos a la matriz
  rows.forEach((row) => {
    const rowData = columns.map((column) => row[column]);
    data.push(rowData);
  });

  return data;
};

export const getArrayOf = <T extends object>(
  fn: (_: unknown, i: number) => T,
  length = 5,
) => Array.from({ length }).map(fn);

export const formatNavbarItems = (
  route: RouteObject,
): undefined | INavLinkProps => {
  if (!route?.handle.sidebar) return;

  const items = route.children?.reduce<INavLinkProps[]>((acc, child) => {
    const item = formatNavbarItems(child);
    if (item) return [...acc, item];
    return acc;
  }, []);

  return {
    items: items ?? [],
    title: route.title,
    icon: route?.handle.iconName,
    to: !items?.length ? route.path : undefined,
  };
};
