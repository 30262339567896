import React from "react";
import { useNavigate } from "react-router";
import { useAuthSignOut } from "@nubeteck/auth";
import { Config, AppRoutes, AppRouteNames } from "@core";
import { ProgressSpinner } from "primereact/progressspinner";

const SignOutPage = () => {
  const navigate = useNavigate();
  const signOut = useAuthSignOut();

  React.useEffect(() => {
    signOut();
    navigate(AppRoutes[AppRouteNames.SIGN_IN_PAGE].create({}));
  }, [navigate, signOut]);

  return (
    <div className="flex flex-1 align-items-center justify-content-center h-screen w-screen login-bg">
      <div className="surface-ground px-3 md:px-5 py-5 shadow-4 md:w-8 lg:w-5 xl:w-4 w-full">
        <div className="mb-3 flex flex-column text-center gap-1">
          <img
            height={70}
            className="mb-2"
            src={Config.app_logo}
            alt={Config.app_description}
            style={{ objectFit: "contain" }}
          />
          <span className="text-xl font-bold">Cerrando sesión</span>
          <span className="text-600 text-md font-medium">
            Estamos cerrando la sesión de tu usuario.
          </span>
        </div>
        <div className="h-12rem flex justify-content-center align-items-center">
          <ProgressSpinner
            strokeWidth="5"
            animationDuration="1s"
            fill="var(--surface-ground)"
            className="w-full w-3rem h-3rem"
          />
        </div>
      </div>
    </div>
  );
};

export default SignOutPage;
