import { IRouter } from "@interfaces";
import { Outlet } from "react-router";

import { Permissions } from "./core";
import { PeiRoutes, PeiRouteNames } from "./routes";
import { SettingsTemplatesIndicatorPage } from "./pages/settings-templates-indicator";
import {
  PeiPage,
  PeiDetailsPage,
  SettingsDetailsFields,
  SettingsTemplatesPage,
  SettingsDetailsTypesPage,
} from "./pages";

const router: IRouter = {
  title: "PEI",
  sidebar: true,
  iconName: "script",
  element: <Outlet />,
  id: "feature-pei-root",
  permissions: [Permissions.SEE_PEI_MODULE],
  path: PeiRoutes[PeiRouteNames.PEI].template(),
  children: [
    {
      path: "",
      index: true,
      element: <PeiPage />,
      id: "feature-pei-page",
      title: "Planes Estratégicos Institucionales",
    },
    {
      element: <PeiDetailsPage />,
      id: "feature-pei-details-page",
      title: "Detalles de {pageTitle}",
      permissions: [Permissions.SEE_PEI_DETAILS_PAGE],
      path: PeiRoutes[PeiRouteNames.PEI_DETAILS].template(),
    },
  ],
};

const routerSettings: IRouter = {
  sidebar: true,
  element: <Outlet />,
  iconName: "settings",
  title: "Planes Estratégicos",
  id: "feature-pei-settings-root",
  permissions: [Permissions.SEE_PEI_SETTINGS],
  path: PeiRoutes[PeiRouteNames.PEI].template(),
  children: [
    {
      sidebar: true,
      title: "Plantillas",
      iconName: "settings",
      element: <SettingsTemplatesPage />,
      id: "feature-pei-settings-templates-page",
      permissions: [Permissions.SEE_TEMPLATES_SETTINGS_PAGE],
      path: PeiRoutes[PeiRouteNames.SETTINGS_TEMPLATES].template(),
    },
    {
      sidebar: true,
      iconName: "settings",
      title: "Plantillas de cualitativas",
      element: <SettingsTemplatesIndicatorPage />,
      id: "feature-pei-settings-templates-indicator-page",
      permissions: [Permissions.SEE_TEMPLATES_INDICATOR_SETTINGS_PAGE],
      path: PeiRoutes[PeiRouteNames.SETTINGS_TEMPLATES_INDICATOR].template(),
    },
    {
      sidebar: true,
      iconName: "settings",
      title: "Tipos de detalles",
      element: <SettingsDetailsTypesPage />,
      id: "feature-pei-settings-details-types-page",
      permissions: [Permissions.SEE_DETAILS_TYPES_SETTINGS_PAGE],
      path: PeiRoutes[PeiRouteNames.SETTINGS_DETAILS_TYPES].template(),
    },
    {
      sidebar: true,
      iconName: "settings",
      title: "Campos personalizados",
      element: <SettingsDetailsFields />,
      id: "feature-pei-settings-details-fields-page",
      permissions: [Permissions.SEE_DETAILS_FIELDS_SETTINGS_PAGE],
      path: PeiRoutes[PeiRouteNames.SETTINGS_DETAILS_FIELDS].template(),
    },
  ],
};

export { router, routerSettings };
