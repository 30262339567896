import React from "react";
import { omit } from "radash";
import { Button } from "primereact/button";
import { EventUtils } from "@nubeteck/utils";
import { DialogProps } from "primereact/dialog";
import { useForm, useFieldArray } from "react-hook-form";
import { useGlobalQuery, useGlobalMutation } from "@hooks";
import { IStrategicPlansTemplatesIndicator } from "@interfaces";
import { CardTable, IDataTableColumnsProps } from "@nubeteck/prime";
import {
  Form,
  Dialog,
  InputText,
  InputNumber,
  InputTextarea,
} from "@components";

export interface ISettingsTemplatesIndicatorDialogProps extends DialogProps {
  templateId: number;
}

type FormValues = IStrategicPlansTemplatesIndicator & {
  Detalles?: { Nombre: string; Valoracion: number }[];
};

const model = {
  from: (data: IStrategicPlansTemplatesIndicator): FormValues => {
    return {
      Nombre: data.Nombre,
      EstadoId: data.EstadoId,
      PlantillaId: data.PlantillaId,
      Descripcion: data.Descripcion,
    };
  },
  to: (data: FormValues): IStrategicPlansTemplatesIndicator => {
    return {
      Nombre: data.Nombre,
      EstadoId: data.EstadoId,
      Descripcion: data.Descripcion,
      PlantillaId: data.PlantillaId,
      Detalles: data.Detalles?.map((detail) => ({
        Nombre: detail.Nombre,
        DetalleId: detail.DetalleId,
        Valoracion: detail.Valoracion,
        EstadoId: detail.EstadoId ?? 1,
        PlantillaId: data.PlantillaId ?? 0,
      })),
    };
  },
};

const SettingsTemplatesIndicatorDialog = ({
  templateId,
  ...props
}: ISettingsTemplatesIndicatorDialogProps) => {
  const createTemplate = useGlobalMutation(
    "StrategicPlansTemplatesIndicator",
    "create",
  );
  const updateTemplate = useGlobalMutation(
    "StrategicPlansTemplatesIndicator",
    "update",
  );
  const getCreate = useGlobalQuery(
    "StrategicPlansTemplatesIndicator",
    "getCreate",
  );
  const getUpdate = useGlobalQuery(
    "StrategicPlansTemplatesIndicator",
    "getUpdate",
    templateId,
    { enabled: !!templateId },
  );

  const form = useForm<FormValues>({});
  const { reset } = form;

  const { fields, append, remove } = useFieldArray({
    name: "Detalles",
    control: form.control,
  });

  React.useEffect(() => {
    if (!templateId && getCreate.data) {
      reset(omit(getCreate.data, ["Validaciones"]), {
        keepDefaultValues: false,
      });
    }
  }, [reset, getCreate.data, templateId]);

  React.useEffect(() => {
    if (templateId && getUpdate.data) {
      reset(model.from(getUpdate.data), { keepDefaultValues: true });
    }
  }, [reset, getUpdate.data, templateId]);

  const onSubmit = (values: FormValues) => {
    if (templateId) {
      return updateTemplate.mutateAsync(model.to(values), {
        onSuccess: () => {
          form.reset();
          props.onHide();
        },
      });
    }
    return createTemplate.mutateAsync(model.to(values), {
      onSuccess: () => {
        form.reset();
        props.onHide();
      },
    });
  };

  const validationsDetails = React.useMemo(() => {
    if (templateId) return getUpdate.data?.ValidacionesDetalles;
    else return getCreate.data?.ValidacionesDetalles;
  }, [
    getCreate.data?.ValidacionesDetalles,
    getUpdate.data?.ValidacionesDetalles,
    templateId,
  ]);

  const columns: IDataTableColumnsProps<(typeof fields)[0]>[] = [
    {
      header: "#",
      field: "id",
      style: { width: "50px" },
      body: ({ id }) => {
        const index = fields.findIndex((field) => field.id === id);
        return <span className="font-bold">{index + 1}</span>;
      },
    },
    {
      field: "Nombre",
      header: "Nombre",
      body: ({ id }) => {
        const index = fields.findIndex((field) => field.id === id);
        return (
          <InputText
            label=""
            fieldClassName="m-0"
            className="p-inputtext-sm"
            placeholder="Escribe el nombre"
            name={`Detalles.${index}.Nombre`}
            rules={validationsDetails?.Nombre}
          />
        );
      },
    },
    {
      field: "Valoracion",
      header: "Valoración",
      body: ({ id }) => {
        const index = fields.findIndex((field) => field.id === id);
        return (
          <InputNumber
            label=""
            fieldClassName="m-0"
            className="p-inputtext-sm"
            placeholder="Ingresa el valor"
            name={`Detalles.${index}.Valoracion`}
            rules={validationsDetails?.Valoracion}
          />
        );
      },
    },
  ];

  const validations = React.useMemo(() => {
    if (templateId) return getUpdate.data?.Validaciones;
    else return getCreate.data?.Validaciones;
  }, [getCreate.data?.Validaciones, getUpdate.data?.Validaciones, templateId]);

  const loading = React.useMemo(() => {
    if (templateId) return getUpdate.isPending;
    return getCreate.isPending;
  }, [templateId, getCreate.isPending, getUpdate.isPending]);

  return (
    <Dialog
      {...props}
      loading={loading}
      className="w-full lg:w-8 xl:w-6"
      subtitle="Rellena los datos del formulario"
      title={templateId ? `Editando plantilla` : "Creando nueva plantilla"}
      onHide={() => {
        if (templateId) form.reset();
        props.onHide();
      }}
      footer={(props) => (
        <div className="flex flex-1 justify-content-end gap-2">
          <Button
            size="small"
            label="Cancelar"
            severity="secondary"
            onClick={() => {
              form.reset();
              props.onHide();
            }}
          />
          <Button
            size="small"
            onClick={form.handleSubmit(onSubmit)}
            label={templateId ? "Guardar cambios" : "Crear plantilla"}
            loading={
              loading || createTemplate.isPending || updateTemplate.isPending
            }
          />
        </div>
      )}
    >
      <Form form={form}>
        <InputText
          name="Nombre"
          label="Nombre"
          fieldClassName="flex-1"
          rules={validations?.["Nombre"]}
          className="p-inputtext-sm flex-1"
          placeholder="Escribe un nombre..."
        />
        <InputTextarea
          name="Descripcion"
          label="Descripción"
          fieldClassName="flex-1"
          className="p-inputtext-sm flex-1"
          rules={validations?.["Descripcion"]}
          placeholder="Escribe una descripción..."
        />
        <CardTable
          type="data"
          value={fields}
          hideBorderStyles
          hideGlobalSearch
          hideSelectFilter
          title="Opciones"
          columns={columns}
          paginator={false}
          actionMenu="non-float"
          hideFilterSettingsIcon
          actionColumnProps={{ bodyStyle: { width: "1%" } }}
          headActions={[
            <Button
              size="small"
              type="button"
              key="AddDetails"
              label="Agregar opción"
              className="flex w-auto"
              onClick={() =>
                append({ Nombre: "", Valoracion: 0 }, { shouldFocus: true })
              }
            />,
          ]}
          tableActions={({ id }) => {
            const index = fields.findIndex((field) => field.id === id);
            return [
              {
                icon: "x",
                label: "Eliminar",
                iconClassName: "text-red-500",
                onClick: EventUtils.callEvent(remove, index),
              },
            ];
          }}
        />
      </Form>
    </Dialog>
  );
};

export default SettingsTemplatesIndicatorDialog;
