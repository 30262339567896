import API from "@lib/api";
import { BaseApi } from "@api";
import {
  Messages,
  IStrategicPlansDetails,
  IStrategicPlansDetailsTypes,
  IStrategicPlansDetailsGetForCreate,
  IStrategicPlansDetailsGetForUpdate,
} from "@interfaces";

export class StrategicPlansDetailsApi extends BaseApi {
  protected static api = API({ namespace: "PlanesEstrategicos" });
  protected static messages: Messages<typeof StrategicPlansDetailsApi> = {
    create: {
      error: (error) => error,
      loading: "Creando nuevo detalle del plan estratégico...",
      success: "Detalle del plan estratégico creado correctamente.",
    },
    update: {
      error: (error) => error,
      loading: "Actualizando detalle del plan estratégico...",
      success: "Detalle del plan estratégico actualizado correctamente.",
    },
    changeState: {
      error: (error) => error,
      loading: "Actualizando detalle del plan estratégico...",
      success: "Detalle del plan estratégico actualizado correctamente.",
    },
    changeOrder: {
      error: (error) => error,
      loading: "Actualizando orden del detalle del plan estratégico...",
      success:
        "Orden del detalle del plan estratégico actualizado correctamente.",
    },
  };

  public static async getById(id: number) {
    const result = await this.api.get<IStrategicPlansDetails>(
      `GetDetalleById/${id}`,
    );
    return result.data;
  }

  public static async create(data: IStrategicPlansDetails) {
    const result = await this.api.post<IStrategicPlansDetails>(
      "AddPlanEstrategicoDetalle",
      data,
    );
    return result.data;
  }

  public static async getCreate(id: number) {
    const result = await this.api.get<IStrategicPlansDetailsGetForCreate>(
      `GetCreatePlanEstrategicoDetalle/${id}`,
    );
    return result.data;
  }

  public static async update(data: Partial<IStrategicPlansDetails>) {
    const result = await this.api.put(
      `UpdatePlanEstrategicoDetalle/${data.PlanEstrategicoId}`,
      data,
    );
    return result.data;
  }

  public static async getUpdate(id: number) {
    const result = await this.api.get<IStrategicPlansDetailsGetForUpdate>(
      `GetUpdatePlanEstrategicoDetalle/${id}`,
    );
    return result.data;
  }

  public static async changeState(data: {
    DetalleId: number;
    EstadoId: number;
  }) {
    const result = await this.api.post(
      "ChangeStatePlanEstrategicoDetalle",
      data,
    );
    return result.data;
  }

  public static async changeOrder(
    data: { DetalleId: number; Orden: number }[],
  ) {
    const result = await this.api.post("ChangePlanEstrategicoDetalleOrder", {
      DetalleOrdenes: data,
    });
    return result.data;
  }

  public static async verifyChildren(id: number) {
    const result = await this.api.post<boolean>(
      `VerifyPlanEstrategicoDetalleHijos/${id}`,
    );
    return result.data;
  }

  public static async getAllTypes() {
    const result =
      await this.api.get<IStrategicPlansDetailsTypes[]>("GetAllDetalleTipo");
    return result.data;
  }
}
