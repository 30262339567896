import { param, route } from "typesafe-react-router";

export enum PeiRouteNames {
  PEI = "PEI",
  PEI_DETAILS = "PEI_DETAILS",
  SETTINGS_TEMPLATES = "SETTINGS_TEMPLATES",
  SETTINGS_DETAILS_TYPES = "SETTINGS_DETAILS_TYPES",
  SETTINGS_DETAILS_FIELDS = "SETTINGS_DETAILS_FIELDS",
  SETTINGS_TEMPLATES_INDICATOR = "SETTINGS_TEMPLATES_INDICATOR",
}

export const PeiRoutes = {
  [PeiRouteNames.PEI]: route("pei"),
  [PeiRouteNames.PEI_DETAILS]: route(param("id")),
  [PeiRouteNames.SETTINGS_TEMPLATES]: route("templates"),
  [PeiRouteNames.SETTINGS_DETAILS_FIELDS]: route("fields"),
  [PeiRouteNames.SETTINGS_DETAILS_TYPES]: route("details-types"),
  [PeiRouteNames.SETTINGS_TEMPLATES_INDICATOR]: route("templates-indicator"),
};
