import API from "@lib/api";
import { BaseApi } from "@api";
import { FakerUtils } from "@utils";

import { IPurchaseDocument } from "../interfaces";

export class PurchaseDocumentsApi extends BaseApi {
  protected static readonly api = API({ namespace: "Documentos" });
  protected static readonly filterApi = API({ namespace: "Filtro" });

  // public static async getAll(): Promise<IPurchaseDocument[]> {
  //   const result = await this.api.get("GetAll");
  //   return result.data;
  // }

  public static async getAll(): Promise<IPurchaseDocument[]> {
    const result = FakerUtils.getAllPurchaseDocuments();
    return result.data;
  }

  public static async getAllByFilter(
    filterId: number,
  ): Promise<IPurchaseDocument[]> {
    const result = await this.filterApi.get<IPurchaseDocument[]>(
      `FiltroPorArea/${filterId}`,
    );
    return result.data;
  }
}
