import React from "react";
import { renderTooltip } from "@utils";
import { Icon } from "@nubeteck/icons";
import { Reducers, Statuses } from "@core";
import { Button } from "primereact/button";
import { Skeleton } from "primereact/skeleton";
import { useAuthPermissions } from "@nubeteck/auth";
import { Datejs, JsxUtil, EventUtils } from "@nubeteck/utils";
import { useGetParams, useGlobalQuery, useGlobalMutation } from "@hooks";
import { StatusTag, Description, useHeaderActions } from "@nubeteck/prime";
import {
  ChangeLogDialog,
  RequestCommentsCard,
  RequestCommentsDialog,
  StrategicPlansTreeTable,
} from "@components";

import { Permissions } from "../../core";

const PeiDetailsPage = () => {
  const permissions = useAuthPermissions();
  const strategicPlanId = useGetParams("id");
  const { dispatch: dispatchHeaderActions } = useHeaderActions();

  const {
    mutateAsync: sendRequestReview,
    isPending: isPendingSendRequestReview,
  } = useGlobalMutation("StrategicPlans", "sendRequestReview");

  const strategicPlan = useGlobalQuery(
    "StrategicPlans",
    "getById",
    strategicPlanId,
    { enabled: !!strategicPlanId },
  );
  const template = useGlobalQuery(
    "StrategicPlansTemplates",
    "getById",
    strategicPlan.data?.PlantillaId ?? 0,
    { enabled: !!strategicPlan.data?.PlantillaId },
  );

  const [requestReviewOpen, setRequestReviewOpen] = React.useState(false);
  const [changeLogState, changeLogDispatch] = React.useReducer(
    Reducers.DialogReducer,
    { id: 0, open: false },
  );

  React.useEffect(() => {
    dispatchHeaderActions({
      type: "SET_VARIABLES",
      payload: { pageTitle: strategicPlan.data?.Nombre || "..." },
    });
    return () => dispatchHeaderActions({ type: "CLEAR_VARIABLES" });
  }, [dispatchHeaderActions, strategicPlan.data?.Nombre]);

  React.useEffect(() => {
    if (strategicPlan.data) {
      dispatchHeaderActions({
        type: "SET_ACTIONS",
        payload: [
          permissions?.hasPermission(Permissions.SEE_LOGS_PEI) ? (
            <React.Fragment key="Logs">
              {renderTooltip("Historial de cambios", "changelog-pei", "left")}
              <Icon
                name="book"
                className="changelog-pei align-self-center text-primary mr-1"
                onClick={EventUtils.callEvent(changeLogDispatch, {
                  type: "OPEN_DIALOG",
                  payload: strategicPlanId,
                })}
              />
            </React.Fragment>
          ) : (
            <></>
          ),
          strategicPlan.data.EstadoId === Statuses.DRAFT_CODE ? (
            <Button
              size="small"
              severity="info"
              key="RequestReview"
              label="Solicitar revision"
              loading={isPendingSendRequestReview}
              icon={<Icon size={18} name="send" className="mr-1" />}
              onClick={async () => {
                await sendRequestReview(strategicPlanId ?? 0);
              }}
              disabled={
                !permissions?.hasPermission(Permissions.APPROVE_REJECT_PEI)
              }
            />
          ) : (
            <></>
          ),
          strategicPlan.data.EstadoId === Statuses.PENDING_APPROVAL_CODE ? (
            <Button
              size="small"
              iconPos="right"
              label="Revisión"
              onClick={EventUtils.callEvent(setRequestReviewOpen, true)}
              icon={<Icon size={18} className="mr-1" name="eye-search" />}
              disabled={
                !permissions?.hasPermission(Permissions.APPROVE_REJECT_PEI)
              }
            />
          ) : (
            <></>
          ),
        ],
      });
    }
    return () => dispatchHeaderActions({ type: "CLEAR_ACTIONS" });
  }, [
    dispatchHeaderActions,
    isPendingSendRequestReview,
    permissions,
    sendRequestReview,
    strategicPlan.data,
    strategicPlanId,
  ]);

  const levels = React.useMemo(() => {
    if (!template.data?.Detalles?.length) return [];
    return template.data.Detalles?.map((detail) => detail.TipoDeNivelId);
  }, [template.data?.Detalles]);

  if (strategicPlan.isFetched && strategicPlan.isError) {
    throw new Error(
      "Hubo un error al cargar los datos del plan estratégico. Compruebe su conexión o si existe este plan estratégico.",
    );
  }

  return (
    <>
      <div className="flex flex-column gap-3">
        {JsxUtil.renderLoader(
          strategicPlan.isPending,
          <>
            <Skeleton height="7rem" />
            <Skeleton height="15rem" />
            <Skeleton height="20rem" />
          </>,
        )(
          <>
            <Description
              vertical
              title="Información general"
              columns={{ xl: 4, md: 2, sm: 1, lg: 4 }}
              items={[
                {
                  label: "Nombre",
                  value: strategicPlan.data?.Nombre ?? "",
                },
                {
                  label: "Periodo",
                  value: `Desde ${Datejs(strategicPlan.data?.FechaInicio).format("L")} hasta ${Datejs(strategicPlan.data?.FechaFin).format("L")}`,
                },
                {
                  label: "Plantilla",
                  value: strategicPlan.data?.PlantillaNombre ?? "",
                },
                {
                  label: "Estado",
                  value: (
                    <StatusTag
                      status={strategicPlan.data?.EstadoNombre ?? "Activo"}
                    />
                  ),
                },
              ]}
            />
            <StrategicPlansTreeTable
              firstTypeLevel
              title="Detalles"
              typesAllowed={levels}
              customActionAllowed={[]}
              loading={strategicPlan.isFetching}
              stateId={strategicPlan.data?.EstadoId}
              onRefresh={() => strategicPlan.refetch()}
              details={strategicPlan.data?.Detalles ?? []}
              templateId={strategicPlan.data?.PlantillaId ?? 0}
              permissions={{
                canEdit: permissions.hasPermission(
                  Permissions.EDIT_DETAILS_PEI,
                ),
                canOrder: permissions.hasPermission(
                  Permissions.ORDER_DETAILS_PEI,
                ),
                canCreate: permissions.hasPermission(
                  Permissions.CREATE_DETAILS_PEI,
                ),
                canNullify: permissions.hasPermission(
                  Permissions.NULLIFY_DETAILS_PEI,
                ),
                canRestore: permissions.hasPermission(
                  Permissions.RESTORE_DETAILS_PEI,
                ),
                canSeeLogs: permissions.hasPermission(
                  Permissions.SEE_LOGS_DETAILS_PEI,
                ),
                canSeeAdditionalInfo: permissions.hasPermission(
                  Permissions.SEE_ADDITIONAL_INFO_DETAILS_PEI,
                ),
              }}
            />
            {strategicPlan.data?.EstadoId !== Statuses.ACTIVE_CODE &&
            permissions?.hasPermission(Permissions.SEE_REVIEWS_COMMENTS) ? (
              <RequestCommentsCard strategicPlanId={strategicPlanId} />
            ) : null}
          </>,
        )}
      </div>
      <RequestCommentsDialog
        visible={requestReviewOpen}
        strategicPlanId={strategicPlanId}
        onHide={EventUtils.callEvent(setRequestReviewOpen, false)}
      />
      <ChangeLogDialog
        entityId={changeLogState.id}
        visible={changeLogState.open}
        entityName="PlanEstrategicoId"
        onHide={EventUtils.callEvent(changeLogDispatch, {
          type: "CLOSE_DIALOG",
        })}
      />
    </>
  );
};

export default PeiDetailsPage;
