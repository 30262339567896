import API from "@lib/api";
import { BaseApi } from "@api";
import { Messages } from "@interfaces";

import { IPurchaseOrder } from "../interfaces";

export class PurchaseOrdersApi extends BaseApi {
  protected static readonly api = API({ namespace: "OrdenesCompras" });
  protected static readonly filterApi = API({ namespace: "Filtro" });
  protected static readonly messages: Messages<typeof PurchaseOrdersApi> = {
    createPurchaseOrder: {
      loading: "Creando una nueva orden de compra...",
      success: "Orden de compra creada correctamente.",
      error: (error) => `Hubo un error al crear la orden de compra: ${error}`,
    },
  };

  // public static async getAll(): Promise<IPurchaseDocument[]> {
  //   const result = await this.api.get("GetAll");
  //   return result.data;
  // }

  //   public static async getAll(): Promise<IPurchaseDocument[]> {
  //     const result = FakerUtils.getAllPurchaseOrders();
  //     return result.data;
  //   }

  //   public static async getAllByFilter(
  //     filterId: number,
  //   ): Promise<IPurchaseDocument[]> {
  //     const result = await this.filterApi.get<IPurchaseDocument[]>(
  //       `FiltroPorArea/${filterId}`,
  //     );
  //     return result.data;
  //   }

  public static async createPurchaseOrder(
    data: object,
  ): Promise<IPurchaseOrder> {
    const result = await this.api.post("Create", data);
    return result.data;
  }
}
