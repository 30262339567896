import clsx from "clsx";
import React from "react";
import { unique, template } from "radash";
import { MenuItem } from "primereact/menuitem";
import { Link, useParams, useMatches } from "react-router";

const useBreadcrumb = () => {
  const params = useParams();
  const matches = useMatches();

  const formatBreadcrumb = React.useCallback(
    (item: (typeof matches)[0], isLast: boolean): MenuItem => {
      return {
        url: item.pathname,
        label: item.handle?.title,
        template: ({ url, label }) => {
          const title = template(label ?? "", params, /{(.+?)}/g);

          const className = clsx(
            "text-sm",
            !isLast ? "text-color" : "text-800",
            url && !isLast && "hover:text-primary cursor-pointer",
          );

          if (url && !isLast) {
            return (
              <Link to={url ?? ""} className={clsx(className, "no-underline")}>
                {title}
              </Link>
            );
          }

          return <span className={className}>{title}</span>;
        },
      };
    },
    [params],
  );

  const items = React.useMemo(() => {
    if (!matches.length) return [];

    const matchesUniques = unique(
      matches.filter((match) => match.handle?.title),
      (item) => `${item.handle?.title}`,
    );

    const matchesUniquesFiltered = matchesUniques.filter(
      (match) => match.id !== "app-root",
    );

    return matchesUniquesFiltered.map((match, index) =>
      formatBreadcrumb(match, matchesUniquesFiltered.length === index + 1),
    );
  }, [formatBreadcrumb, matches]);

  return { items };
};

export default useBreadcrumb;
