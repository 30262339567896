import React from "react";
import { Tag } from "primereact/tag";
import { useGlobalQuery } from "@hooks";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import { Link, useNavigate } from "react-router";
import { Icon, IIconProps } from "@nubeteck/icons";
import { ArrayUtils, EventUtils } from "@nubeteck/utils";
import { ConfirmDialog, FiltersDrawer } from "@components";
import { DataViewLayoutOptions } from "primereact/dataview";
import { CardTable, HeaderIcon, IDataTableColumnsProps } from "@nubeteck/prime";

import { IProvider } from "../../interfaces";
import { ProviderCard } from "../../components";
import { AccountsPayableRoutes, AccountsPayableRouteNames } from "../../routes";
import {
  useAccountsPayableQuery,
  useAccountsPayableMutation,
} from "../../hooks";

const FILTER_AREA = "fcProveedoresBuscar";

const ProviderPage = () => {
  const navigate = useNavigate();
  const providers = useAccountsPayableQuery("Provider", "getAll");
  const [anular, setAnular] = React.useState(false);
  const [status, setStatus] = React.useState("");
  const [selectProvider, setSelectProvider] = React.useState(0);
  const [selectButton, setSelectButton] = React.useState("list");
  const changeState = useAccountsPayableMutation("Provider", "changeState");

  const columns: IDataTableColumnsProps<IProvider>[] = [
    {
      header: "Código",
      field: "ProveedorId",
    },
    {
      field: "Imagen",
      header: "Fotografía",
      body: (rowData) => {
        return (
          <div className="flex  justify-content-center">
            <img
              alt="Image"
              style={{ width: "65px", height: "65px", objectFit: "cover" }}
              className="flex justify-content-center border-circle responsive-img"
              src={
                rowData.Imagen
                  ? rowData.Imagen
                  : "https://static.vecteezy.com/system/resources/previews/004/141/669/non_2x/no-photo-or-blank-image-icon-loading-images-or-missing-image-mark-image-not-available-or-image-coming-soon-sign-simple-nature-silhouette-in-frame-isolated-illustration-vector.jpg"
              }
            />
          </div>
        );
      },
    },
    {
      field: "NombreCompleto",
      header: "Nombre completo",
      body: (rowData) => (
        <Link
          className="no-underline"
          to={`.${AccountsPayableRoutes[
            AccountsPayableRouteNames.PROVIDER_DETAILS_PAGE
          ].create({ id: `${rowData.ProveedorId}` })}`}
        >
          {rowData.NombreCompleto}
        </Link>
      ),
    },
    {
      field: "DNI",
      header: "Documento de identidad",
      body: (e) => (e.DNI !== "" ? <p>{e.DNI}</p> : "N/A"),
    },
    {
      field: "Telefono",
      header: "Teléfono",
    },
    {
      field: "Correo",
      header: "Correo",
    },
    {
      field: "Estado",
      header: "Estado",
      body: (rowData) => (
        <Tag severity={rowData.Estado === "Activo" ? "info" : "danger"}>
          {rowData.Estado}
        </Tag>
      ),
    },
  ];

  const [searchText, setSearchText] = React.useState("");

  const filteredProviders = providers.data?.filter((item) => {
    return (
      item.NombreCompleto.toLowerCase().includes(searchText.toLowerCase()) ||
      item.DNI.toLowerCase().includes(searchText.toLowerCase()) ||
      item.Telefono.toLowerCase().includes(searchText.toLowerCase()) ||
      item.Correo.toLowerCase().includes(searchText.toLowerCase())
    );
  });

  const header = () => {
    return (
      <div className="flex justify-content-between align-content-center m-0">
        <div className="flex align-items-center gap-2">
          <Dropdown
            options={[]}
            value={["Hola"]}
            placeholder="Seleccione un filtro"
            className="p-inputtext-sm w-full md:w-16rem"
            //onChange={(e) => onSelectFilterOption?.(e.value)}
            dropdownIcon={(opts) => (
              <Icon
                size={22}
                {...(opts.iconProps as IIconProps)}
                name="selector"
              />
            )}
          />
          <HeaderIcon
            size={24}
            name="filter-cog"
            //onClick={onClickFilterSettings}
            className="text-primary"
          />
        </div>
        <div className="flex flex-row p-0 m-0">
          <IconField
            className="mr-2"
            iconPosition="left"
            key="TableGlobalSearch"
            //className={clsx(hideGlobalSearch ? "hidden" : "")}
          >
            <InputIcon>
              <Icon size={22} name="search" />
            </InputIcon>
            <InputText
              type="search"
              value={searchText}
              placeholder="Búsqueda..."
              style={{ marginTop: "1px" }}
              className="w-full p-inputtext-sm"
              onChange={(e) => setSearchText(e.target.value)}
            />
          </IconField>
          <Button
            size="small"
            className="mr-2 text-sm font-semibold"
            icon={<Icon name="package" className="mr-2"></Icon>}
            style={{ margin: "1px", width: "auto", height: "40px" }}
            onClick={() => {
              navigate(
                `.${AccountsPayableRoutes[
                  AccountsPayableRouteNames.PROVIDER_FORM_PAGE
                ].template()}`,
              );
            }}
            //.create({ id: `${rowData.CatalogoId}` })}`}
          >
            Agregar proveedor
          </Button>
          <DataViewLayoutOptions
            layout={selectButton as "list" | "grid"}
            onChange={(e) => setSelectButton(e.value)}
          />
        </div>
      </div>
    );
  };

  const { data: filters } = useGlobalQuery(
    "Filters",
    "getAllByArea",
    FILTER_AREA,
  );

  const [filterSettingsVisible, setFilterSettingsVisible] =
    React.useState(false);
  const [filterSelected, setFilterSelected] = React.useState<
    number | undefined
  >();
  const filtroById = useGlobalQuery("Filters", "getById", filterSelected);

  const valor = filtroById.data?.FiltrosParametros?.find(
    (x) => x.Campo === "EstadoId",
  )?.Valor1;

  const providersFiltres = useAccountsPayableQuery(
    "Provider",
    "getByEstado",
    Number(valor),
    {
      enabled: !!valor,
    },
  );

  React.useEffect(() => {
    const defaultFilterId = filters?.find(
      (filter) => filter.EsPorDefecto,
    )?.FiltroId;

    setFilterSelected(defaultFilterId);
  }, [filters]);

  const filtersOptions = React.useMemo(() => {
    if (!filters?.length) return [];
    return ArrayUtils.selectLabelValue(filters ?? [], "Nombre", "FiltroId");
  }, [filters]);

  const handleEdit = (id: number) => {
    navigate(
      `.${AccountsPayableRoutes[
        AccountsPayableRouteNames.PROVIDER_FORM_PAGE
      ].create({ id: `${id}` })}`,
    );
  };

  const handleDetails = (id: number) => {
    navigate(
      `.${AccountsPayableRoutes[
        AccountsPayableRouteNames.PROVIDER_DETAILS_PAGE
      ].create({ id: `${id}` })}`,
    );
  };

  const handleDelete = (id: number, currentStatus: string) => {
    setAnular(true);
    setStatus(currentStatus);
    setSelectProvider(id);
  };

  return (
    <>
      {selectButton === "list" ? (
        <CardTable<IProvider>
          type="data"
          size="small"
          className="mt-4"
          columns={columns}
          title="Proveedores"
          //value={providers.data}
          valueFilter={filterSelected}
          filterOptions={filtersOptions}
          value={providersFiltres.data ?? []}
          loading={providersFiltres.isPending}
          onSelectFilterOption={(value) => setFilterSelected(value)}
          onClickFilterSettings={EventUtils.callEvent(
            setFilterSettingsVisible,
            true,
          )}
          headActions={[
            <Button
              key={1}
              size="small"
              label="Agregar proveedor"
              icon={<Icon name="package" className="mr-2"></Icon>}
              onClick={() => {
                navigate(
                  `.${AccountsPayableRoutes[
                    AccountsPayableRouteNames.PROVIDER_FORM_PAGE
                  ].create({ id: "0" })}`,
                );
              }}
            />,
            <DataViewLayoutOptions
              key={2}
              layout={selectButton as "list" | "grid"}
              onChange={(e) => {
                setSelectButton(e.value);
                setSearchText("");
              }}
            />,
          ]}
          tableActions={(rowData) => [
            {
              icon: "pencil",
              label: "Editar",
              onClick: () => {
                navigate(
                  `.${AccountsPayableRoutes[
                    AccountsPayableRouteNames.PROVIDER_FORM_PAGE
                  ].create({ id: `${rowData.ProveedorId}` })}`,
                );
              },
            },
            {
              label: "Detalles",
              icon: "list-details",
              onClick: () => {
                navigate(
                  `.${AccountsPayableRoutes[
                    AccountsPayableRouteNames.PROVIDER_DETAILS_PAGE
                  ].create({ id: `${rowData.ProveedorId}` })}`,
                );
              },
            },
            {
              icon: rowData.Estado === "Activo" ? "trash" : "plug",
              label: rowData.Estado === "Activo" ? "Anular" : "Activar",
              onClick: () => {
                setAnular(true);
                setStatus(rowData.Estado);
                setSelectProvider(rowData.ProveedorId);
              },
            },
          ]}
        ></CardTable>
      ) : (
        <div className="flex flex-column bg-white border-round-top pt-2 pr-3 pl-3">
          <div>{header()}</div>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2 mt-4">
            {filteredProviders && filteredProviders.length > 0 ? (
              filteredProviders?.map((item) => (
                <ProviderCard
                  provider={item}
                  onEdit={handleEdit}
                  key={item.ProveedorId}
                  onDelete={handleDelete}
                  onDetails={handleDetails}
                />
              ))
            ) : (
              <div className="flex justify-center items-center p-4">
                <span className="text-bluegray-900">
                  No hay datos disponibles
                </span>
              </div>
            )}
          </div>
        </div>
      )}

      <FiltersDrawer
        area={FILTER_AREA}
        visible={filterSettingsVisible}
        onHide={EventUtils.callEvent(setFilterSettingsVisible, false)}
      />
      <ConfirmDialog
        visible={anular}
        onHide={() => {
          setAnular(false), setSelectProvider(0);
        }}
        message={`¿Está seguro de que desea ${status === "Activo" ? "anular" : "activar"} este proveedor?`}
        accept={() =>
          changeState.mutateAsync({
            Id: selectProvider,
            EstadoId: status === "Activo" ? 2 : 1,
          })
        }
        header={
          <div className="flex flex-row align-items-center mt-0 mb-0">
            <Icon
              size={"small"}
              className="mr-2"
              name={status === "Activo" ? "trash" : "plug"}
            ></Icon>
            <p className="text-base">{`${status === "Activo" ? "Anular" : "Activar"} proveedor`}</p>
          </div>
        }
      />
    </>
  );
};
export default ProviderPage;
