import React from "react";
import { Tag } from "primereact/tag";
import { Reducers, Statuses } from "@core";
import { ConfirmDialog } from "@components";
import { EventUtils } from "@nubeteck/utils";
import { useAuthPermissions } from "@nubeteck/auth";
import { IStrategicPlansActivity } from "@interfaces";
import { confirmDialog } from "primereact/confirmdialog";
import { useGetParams, useGlobalQuery, useGlobalMutation } from "@hooks";
import { StatusTag, CardTable, IDataTableColumnsProps } from "@nubeteck/prime";

import { Permissions } from "../../core";
import { ExecutionPoaDialog } from "../execution-poa-dialog";
import { ExecutionPoaIndicators } from "../execution-poa-indicators";
import { ExecutionScheduleDialog } from "../execution-schedule-dialog";

const ExecutionPoaTab = () => {
  const strategicId = useGetParams("id");
  const permissions = useAuthPermissions();

  const completeActivity = useGlobalMutation(
    "StrategicPlansActivities",
    "complete",
  );
  const activities = useGlobalQuery(
    "StrategicPlansActivities",
    "getAllByPoa",
    strategicId ?? 0,
    { enabled: !!strategicId },
  );

  const [expandedRows, setExpandedRows] = React.useState<
    IStrategicPlansActivity[]
  >([]);
  const [state, dispatch] = React.useReducer(Reducers.DialogReducer, {
    id: 0,
    open: false,
  });
  const [stateSchedule, dispatchSchedule] = React.useReducer(
    Reducers.DialogReducer,
    { id: 0, open: false },
  );

  const columns: IDataTableColumnsProps<IStrategicPlansActivity>[] = [
    {
      field: "" as never,
      expander: (data) => data.EstadoId === Statuses.COMPLETED_CODE,
    },
    { field: "Actividad", header: "Actividad" },
    { field: "LineasBases", header: "Lineas Bases" },
    {
      field: "Presupuesto",
      header: "Presupuesto",
      body: (data) => {
        return (
          <Tag severity={data.Presupuesto ? "success" : "danger"}>
            {data.Presupuesto ? "Necesita" : "No necesita"}
          </Tag>
        );
      },
    },
    {
      field: "Recursos",
      header: "Recursos",
      body: (data) => data.Recursos || "Sin recursos",
    },
    {
      header: "Estado",
      field: "EstadoId",
      body: (data) => <StatusTag status={data.EstadoNombre ?? "Activo"} />,
    },
  ];

  const handleComplete = React.useCallback(
    (id: number) => {
      confirmDialog({
        acceptLabel: "Completar",
        acceptClassName: "p-button-sm",
        header: "Completar actividad del plan estratégico",
        message: "¿Está seguro de que desea completar esta actividad?",
        accept: async () => {
          await completeActivity.mutateAsync(id);
        },
      });
    },
    [completeActivity],
  );

  return (
    <>
      <CardTable<IStrategicPlansActivity>
        type="data"
        hideSelectFilter
        headActions={[]}
        columns={columns}
        dataKey="ActividadId"
        hideFilterSettingsIcon
        expandedRows={expandedRows}
        value={activities.data ?? []}
        loading={activities.isFetching}
        onRefresh={() => activities.refetch()}
        onRowToggle={(e) =>
          setExpandedRows(e.data as IStrategicPlansActivity[])
        }
        rowExpansionTemplate={(data) => (
          <ExecutionPoaIndicators activityId={data.ActividadId ?? 0} />
        )}
        tableActions={(data) => [
          {
            icon: "check",
            label: "Completar",
            onClick: EventUtils.callEvent(handleComplete, data.ActividadId),
            disabled:
              data.EstadoId === Statuses.COMPLETED_CODE ||
              !permissions.hasPermission(Permissions.COMPLETE_ACTIVITIES_POA),
          },
          {
            icon: "calendar",
            label: "Cronogramas",
            onClick: EventUtils.callEvent(dispatchSchedule, {
              type: "OPEN_DIALOG",
              payload: data.ActividadId,
            }),
          },
        ]}
      />
      <ExecutionScheduleDialog
        visible={stateSchedule.open}
        activityId={stateSchedule.id}
        onHide={EventUtils.callEvent(dispatchSchedule, {
          type: "CLOSE_DIALOG",
        })}
      />
      <ExecutionPoaDialog
        visible={state.open}
        indicatorId={state.id}
        onHide={EventUtils.callEvent(dispatch, {
          type: "CLOSE_DIALOG",
        })}
      />
      <ConfirmDialog />
    </>
  );
};

export default ExecutionPoaTab;
