import API from "@lib/api";
import { BaseApi } from "@api";
import { Messages } from "@interfaces";

import { IProvincias } from "../interfaces";

export class ProvinceApi extends BaseApi {
  protected static api = API({ namespace: "Provincias" });
  protected static filterApi = API({ namespace: "Filtro" });
  protected static messages: Messages<typeof ProvinceApi> = {};

  public static async getAll(): Promise<IProvincias[]> {
    const result = await this.api.get("GetAll");
    return result.data;
  }
}
