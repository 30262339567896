import React from "react";
import { ArrayUtils } from "@utils";
import { Outlet } from "react-router";
import { useNavigation } from "@hooks";
import { Divider } from "primereact/divider";
import { NavLink, INavLinkProps } from "@nubeteck/prime";

const SettingsLayout = () => {
  const { routes } = useNavigation();

  const settingsModeled = React.useMemo(() => {
    const mainRoute = routes.find((route) => route.id === "app-root");
    const settingsRoutes = mainRoute?.children?.find(
      (route) => route.id === "settings-root",
    );
    return settingsRoutes?.children?.reduce<INavLinkProps[]>((acc, route) => {
      const routeFormatted = ArrayUtils.formatNavbarItems(route);
      if (routeFormatted) return [...acc, routeFormatted];
      return acc;
    }, []);
  }, [routes]);

  return (
    <div className="flex flex-column lg:flex-row gap-2 flex-1 h-full">
      <div className="flex w-full flex-column lg:w-16rem surface-card border-round shadow-2 select-none">
        <Divider className="mb-0 mt-1 z-0">
          <span className="text-sm font-semibold text-color">
            Menu de configuraciones
          </span>
        </Divider>
        <ul className="list-none w-full p-2 m-0 overflow-hidden">
          {settingsModeled?.map((item) => (
            <NavLink {...item} key={item.title} />
          ))}
        </ul>
      </div>
      <div className="flex flex-1 h-full">
        <Outlet />
      </div>
    </div>
  );
};

export default SettingsLayout;
