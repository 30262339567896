import React from "react";
import { useGlobalQuery } from "@hooks";
import { Button } from "primereact/button";
import { ArrayUtils } from "@nubeteck/utils";
import { Divider } from "primereact/divider";
import { FieldValues } from "react-hook-form";
import {
  Form,
  Dialog,
  Dropdown,
  InputText,
  InputNumber,
  InputSwitch,
} from "@components";

import { IDashboardWidgetDialogProps } from "../../interfaces";

const DashboardKPIDialogSettings = <T extends FieldValues>({
  form,
  onSubmitClick,
  ...props
}: IDashboardWidgetDialogProps<T>) => {
  const currencies = useGlobalQuery("Currencies", "getAll");

  const formatType = form.watch("formatType" as never) as unknown as string;

  const formatTypesOptions = React.useMemo(
    () => [
      { value: "Peso", label: "Numero (.,)" },
      { value: "currency", label: "Moneda ($)" },
      { value: "percentage", label: "Porcentaje (%)" },
    ],
    [],
  );

  const formatCurrenciesOptions = React.useMemo(() => {
    if (!currencies.data?.length) return [];
    return ArrayUtils.selectLabelValue(
      currencies.data,
      (currency) => `${currency.MonedaNombre} (${currency.Simbolo})`,
      "MonedaId",
    );
  }, [currencies.data]);

  const formatLocalesOptions = React.useMemo(() => {
    if (!currencies.data?.length) return [];
    return ArrayUtils.selectLabelValue(currencies.data, "Locale", "Locale");
  }, [currencies.data]);

  return (
    <Dialog
      {...props}
      title="Ajustes del widget"
      className="w-full md:w-10 lg:w-6"
      footer={
        <div className="flex justify-content-end gap-2">
          <Button
            size="small"
            type="button"
            label="Cancelar"
            severity="secondary"
            onClick={() => {
              form.reset();
              props.onHide();
            }}
          />
          <Button
            size="small"
            label="Guardar cambios"
            onClick={form.handleSubmit(onSubmitClick)}
          />
        </div>
      }
    >
      <Form form={form}>
        <InputText name="title" label="Titulo" className="p-inputtext-sm" />
        <InputText
          name="description"
          label="Descripción"
          className="p-inputtext-sm"
        />
        <InputText
          name="iconName"
          label="Nombre del icono"
          className="p-inputtext-sm"
          helperText="Buscar iconos en la suite: https://tabler.io/icons y pones el nombre del icono."
        />
        <Divider className="m-0 my-2">
          <span className="font-semibold text-sm">Ajustes de formatos</span>
        </Divider>
        <Dropdown
          name="formatType"
          defaultValue="number"
          label="Tipo de formato"
          className="p-inputtext-sm"
          options={formatTypesOptions}
          placeholder="Selecciona un formato"
          helperText="Este formato sera dado al valor cuando se muestre."
        />
        {formatType === "currency" && (
          <Dropdown
            name="formatCurrency"
            className="p-inputtext-sm"
            label="Formato de la moneda"
            options={formatCurrenciesOptions}
            placeholder="Selecciona una moneda"
            helperText="Esta moneda sera dado al valor cuando se muestre."
          />
        )}
        <InputNumber
          max={4}
          min={0}
          defaultValue={2}
          name="formatPrecision"
          className="p-inputtext-sm"
          label="Precisión del valor"
          placeholder="Ingresa una precisión"
        />
        {formatType! !== "currency" && (
          <Dropdown
            name="formatLocale"
            label="Localización"
            className="p-inputtext-sm"
            options={formatLocalesOptions}
            placeholder="Selecciona una localización"
            helperText="Solo aplica cuando el tipo de formato no es moneda."
          />
        )}
        <InputSwitch
          name="formatAbbreviate"
          label="¿Abreviar valor?"
          helperText="Convertirá los números grandes a un formato abreviado."
        />
      </Form>
    </Dialog>
  );
};

export default DashboardKPIDialogSettings;
