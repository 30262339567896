import { Icon } from "@nubeteck/icons";
import { Card } from "@nubeteck/prime";

interface IProviderUserCardProps {
  name: string;
  email: string;
  telefono: string;
  imageSrc: string;
}

const UserCard = ({
  name,
  email,
  telefono,
  imageSrc,
}: IProviderUserCardProps) => {
  return (
    <Card className="flex flex-row justify-content-between p-0">
      <div className="flex flex-row">
        <img
          alt="User"
          src={imageSrc}
          style={{ width: "100px", height: "100px", objectFit: "cover" }}
          className="flex justify-content-center border-circle responsive-img mt-3 ml-4 mb-3"
        />
        <div className="flex flex-column">
          <h2 className="text-600 mb-0 ml-5 p-0 mt-5">{name}</h2>
          <div className="flex flex-row mr-3 ml-3 mt-0">
            <div className="flex flex-row align-content-center">
              <Icon name="id" className="ml-3 mr-3" />
              <p className="mr-3 mt-0">{telefono}</p>
            </div>
            <div className="flex flex-row align-content-center">
              <Icon name="id" className="mr-3" />
              <p className="mr-3 mt-0">{email}</p>
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default UserCard;
