import React from "react";
import { useGlobalQuery } from "@hooks";
import { Button } from "primereact/button";
import { ArrayUtils } from "@nubeteck/utils";
import { Description } from "@nubeteck/prime";
import { DialogProps } from "primereact/dialog";
import { Dialog, EmptyMessage } from "@components";

export interface IDetailsFieldsDialogProps extends DialogProps {
  detailId: null | number;
}

const DetailsFieldsDialog = ({
  detailId,
  ...props
}: IDetailsFieldsDialogProps) => {
  const fields = useGlobalQuery(
    "StrategicPlansFields",
    "getAllValues",
    detailId ?? 0,
    { enabled: !!detailId },
  );

  const items = React.useMemo(() => {
    if (!fields.data?.length) return [];
    return ArrayUtils.selectLabelValue(
      fields.data,
      "CampoNombre",
      (field) => field.Valor || "Sin valor",
    );
  }, [fields.data]);

  return (
    <Dialog
      {...props}
      title="Campos adicionales"
      className="w-full lg:w-8 xl:w-6"
      footer={(props) => (
        <div className="flex flex-1 justify-content-end gap-2">
          <Button
            size="small"
            label="Aceptar"
            onClick={() => {
              props.onHide();
            }}
          />
        </div>
      )}
    >
      {items.length ? (
        <Description title="" vertical columns={1} items={items} />
      ) : (
        <EmptyMessage message="No hay campos adicionales agregados" />
      )}
    </Dialog>
  );
};

export default DetailsFieldsDialog;
