import React from "react";
import { useParams } from "react-router";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { PickNullable } from "ClientApp/interfaces/helpers";
import {
  Form,
  useForm,
  useWatch,
  InputText,
  MultiSelect,
  InputTextarea,
} from "@nubeteck/forms";

import { ICatalogoCuentaDetalles } from "../../interfaces";
import { useAccountingQuery, useAccountingMutation } from "../../hooks";

interface IEstadoFinancieroDivision {
  Codigo: string;
  Nombre: string;
  EstadoFinancieroId: number;
  EstadoFinancieroDivisionId: number;
}

interface IEstadoFinanciero {
  Nombre: string;
  EstadoFinancieroId: number;
  TipoEstadoFinancieroId: number;
  EstadoFinancieroDivisiones: IEstadoFinancieroDivision[];
}

interface IAccountCatalogFormProps {
  edit: boolean;
  open: boolean;
  onClose?: () => void;
  cuentaPadreId?: number;
}

type FormValues = PickNullable<
  ICatalogoCuentaDetalles,
  | "Nivel"
  | "Activa"
  | "Cuenta"
  | "CuentaId"
  | "EstadoId"
  | "EsControl"
  | "CatalogoId"
  | "Descripcion"
  | "CuentaNombre"
  | "NaturalezaId"
  | "CuentaPadreId"
  | "Clasificacion"
  | "DivisionesIds"
  | "PermiteCambios"
>;

const model = {
  to: (data: FormValues): ICatalogoCuentaDetalles => {
    // console.log(data, "data del to");
    return {
      Nivel: 0,
      EstadoId: 1,
      Activa: true,
      EsControl: true,
      PermiteCambios: true,
      //NaturalezaId: 1,
      Cuenta: data.Cuenta ?? "",
      //Clasificacion: "string",
      CuentaId: data.CuentaId ?? 0,
      CatalogoId: data.CatalogoId ?? 0,
      Descripcion: data.Descripcion ?? "",
      CuentaNombre: data.CuentaNombre ?? "",
      CuentaPadreId: data.CuentaPadreId ?? 0,
      DivisionesIds: data.DivisionesIds ?? [],
    };
  },
  from: (data: ICatalogoCuentaDetalles): FormValues => {
    // console.log(data, "data del from");
    return {
      Nivel: data?.Nivel ?? 0,
      CuentaId: data?.CuentaId,
      EstadoId: data?.EstadoId,
      Cuenta: data?.Cuenta ?? "",
      EsControl: data?.EsControl,
      Activa: data?.Activa ?? true,
      NaturalezaId: data?.NaturalezaId,
      CatalogoId: data?.CatalogoId ?? 0,
      Clasificacion: data?.Clasificacion,
      DivisionesIds: data?.DivisionesIds,
      PermiteCambios: data?.PermiteCambios,
      Descripcion: data?.Descripcion ?? "",
      CuentaNombre: data?.CuentaNombre ?? "",
      CuentaPadreId: data?.CuentaPadreId ?? 0,
    };
  },
};

const AccountCatalogForm = ({
  edit,
  open,
  onClose,
  cuentaPadreId,
}: IAccountCatalogFormProps) => {
  // console.log(cuentaPadreId, "padreid");
  const { id } = useParams();
  const form = useForm<FormValues>({
    defaultValues: {
      CatalogoId: Number(id),
    },
  });
  const createCatalogo = useAccountingMutation(
    "AccountCatalog",
    "createDetails",
  );
  const updateCatalogo = useAccountingMutation(
    "AccountCatalog",
    "updateDetails",
  );
  const selectedDivisiones = useWatch({
    name: "DivisionesIds",
    control: form.control,
  });

  //get de datos actual
  const {
    data: accountCatalogDetalleData,
    isFetching: accountCatalogDetalleFetching,
  } = useAccountingQuery("AccountCatalog", "getUpdateDetalle", cuentaPadreId, {
    enabled: !!cuentaPadreId,
  });

  //get datos del cuentapadreid
  const { data: cuentaPadreDataNombre } = useAccountingQuery(
    "AccountCatalog",
    "getUpdateDetalle",
    accountCatalogDetalleData?.CuentaPadreId,
    {
      enabled: !!accountCatalogDetalleData?.CuentaPadreId,
    },
  );

  //select divisiones
  const divisionesOption = React.useMemo(() => {
    if (!accountCatalogDetalleData?.DivisionesSelect.length) {
      return [];
    }

    return accountCatalogDetalleData.DivisionesSelect.map(
      (estadoFinanciero: IEstadoFinanciero) => {
        const isDisabled = estadoFinanciero.EstadoFinancieroDivisiones.some(
          (division) =>
            selectedDivisiones?.includes(division.EstadoFinancieroDivisionId),
        );

        return {
          label: estadoFinanciero.Nombre,
          items: estadoFinanciero.EstadoFinancieroDivisiones.map(
            (division) => ({
              label: division.Codigo,
              value: division.EstadoFinancieroDivisionId,
              disabled:
                isDisabled &&
                !selectedDivisiones?.includes(
                  division.EstadoFinancieroDivisionId,
                ),
            }),
          ),
        };
      },
    );
  }, [accountCatalogDetalleData?.DivisionesSelect, selectedDivisiones]);

  //datos en from
  React.useEffect(() => {
    if (edit && accountCatalogDetalleData) {
      const formData = model.from({
        ...accountCatalogDetalleData,
        DivisionesIds: accountCatalogDetalleData.DivisionesIds,
      });
      form.reset(formData, { keepDefaultValues: true });
    }
    if (!edit) {
      form.reset();
    }
  }, [accountCatalogDetalleData, edit, form]);

  const onSubmit = (values: FormValues) => {
    if (edit) {
      const valuesWithDivisiones = {
        ...values,
        DivisionesIds: selectedDivisiones,
      };
      return updateCatalogo.mutateAsync(model.to(valuesWithDivisiones), {
        onSuccess: () => {
          form.reset();
          handleOnClose();
        },
      });
    }

    return createCatalogo.mutateAsync(
      model.to({ ...values, CuentaPadreId: Number(cuentaPadreId) }),
      {
        onSuccess: () => {
          form.reset();
          handleOnClose();
        },
      },
    );
  };

  const handleOnClose = React.useCallback(() => {
    onClose?.();
  }, [onClose]);

  return (
    <Dialog
      visible={open}
      header={edit ? "Editar cuenta" : "Crear cuenta"}
      onHide={() => {
        if (!open) return;
        form.reset();
        handleOnClose();
      }}
      footer={
        <div className="flex justify-content-end gap-2">
          <Button raised severity="secondary" onClick={handleOnClose}>
            Cerrar
          </Button>
          <Button onClick={form.handleSubmit(onSubmit)}>
            {edit ? "Editar" : "Crear"}
          </Button>
        </div>
      }
    >
      {accountCatalogDetalleData !== undefined &&
      accountCatalogDetalleData.CuentaPadreId !== null ? (
        <div className="flex align-items-center flex-row pb-3">
          <p>Cuenta padre:</p>
          {cuentaPadreDataNombre !== undefined ? (
            <h4 className="pl-2">{cuentaPadreDataNombre.CuentaNombre}</h4>
          ) : null}
        </div>
      ) : null}

      {accountCatalogDetalleFetching ? (
        <p>Cargando...</p>
      ) : (
        <Form form={form}>
          <InputText
            label="Nombre"
            id="CuentaNombre"
            name="CuentaNombre"
            rules={{ required: "Este campo es necesario" }}
          />
          <InputTextarea
            id="Descripcion"
            name="Descripcion"
            label="Descripción"
            placeholder="Descripción"
            className="lg:w-full sm:w-full"
            rules={{ required: "Este campo es necesario" }}
          />
          <MultiSelect
            id="DivisionesIds"
            label="Divisiones"
            className="w-full"
            optionLabel="label"
            name={"DivisionesIds"}
            optionGroupLabel="label"
            options={divisionesOption}
            //value={selectedDivisiones}
            optionGroupChildren="items"
            placeholder="Selecciona una division"
            rules={{ required: "Este campo es necesario" }}
            //onChange={(e) => setSelectedDivisiones(e.value)}
          />
        </Form>
      )}
    </Dialog>
  );
};

export default AccountCatalogForm;
