import { omit } from "radash";
import { IInputProps } from "@interfaces";
import { useController } from "react-hook-form";
import { InputNumber, InputNumberProps } from "primereact/inputnumber";

import { Field } from "../../field";
import { InputGroup, IInputGroupProps } from "../../input-group";

//! ANTES DE MODIFICAR ESTE COMPONENTE CONSULTE CON SUS DESARROLLADORES.
//! ANTES DE MODIFICAR ESTE COMPONENTE CONSULTE CON SUS DESARROLLADORES.
//! ANTES DE MODIFICAR ESTE COMPONENTE CONSULTE CON SUS DESARROLLADORES.

export interface IInputNumberProps
  extends IInputProps,
    Omit<InputNumberProps, "name" | "defaultValue">,
    IInputGroupProps {}

const $InputNumber = ({
  name,
  label,
  rules,
  disabled,
  helperText,
  addonAfter,
  addonBefore,
  defaultValue,
  disabledInput,
  fieldClassName,
  shouldUnregister,
  ...props
}: IInputNumberProps) => {
  const { field, fieldState } = useController({
    name,
    rules,
    disabled,
    defaultValue,
    shouldUnregister,
  });

  return (
    <Field
      name={name}
      label={label}
      helperText={helperText}
      className={fieldClassName}
      isRequired={!!rules?.required}
    >
      <InputGroup addonAfter={addonAfter} addonBefore={addonBefore}>
        <InputNumber
          onChange={(event) => field.onChange(event.value)}
          {...omit(field, ["disabled", "onChange"])}
          {...props}
          invalid={fieldState.invalid}
          disabled={field.disabled || disabledInput}
        />
      </InputGroup>
    </Field>
  );
};

export default $InputNumber;
