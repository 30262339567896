import API from "@lib/api";
import { Messages, IGeneralSetting, ISettingCategory } from "@interfaces";

import { BaseApi } from "./base-api";

export class GeneralSettingsApi extends BaseApi {
  protected static api = API({ namespace: "ConfiguracionGeneral" });
  protected static messages: Messages<typeof GeneralSettingsApi> = {
    update: {
      error: (error) => error,
      loading: "Actualizando configuración general...",
      success: "Configuración general actualizado correctamente.",
    },
    updateValor: {
      error: (error) => error,
      loading: "Actualizando configuraciones generales...",
      success: "Configuraciones generales actualizado correctamente.",
    },
    updateRange: {
      error: (error) => error,
      loading: "Actualizando configuraciones generales...",
      success: "Configuraciones generales actualizadas correctamente.",
    },
  };

  public static async getAll() {
    const result = await this.api.get<IGeneralSetting[]>("GetAll");
    return result.data;
  }

  public static async getAllCategories() {
    const result = await this.api.get<ISettingCategory[]>("GetAllCategorias");
    return result.data;
  }

  public static async getById(id: number) {
    const result = await this.api.get<IGeneralSetting>(`GetById/${id}`);
    return result.data;
  }

  public static async update(data: Partial<IGeneralSetting>) {
    const result = await this.api.put(`Update/${data.ConfiguracionId}`, data);
    return result.data;
  }

  public static async updateRange(
    data: Pick<IGeneralSetting, "Valor" | "ConfiguracionId">[],
  ) {
    const result = await this.api.put("UpdateRange", data);
    return result.data;
  }

  public static async updateValor(data: Partial<IGeneralSetting>) {
    const result = await this.api.put(
      `UpdateValor/${data.ConfiguracionId}`,
      data,
    );
    return result.data;
  }
}
