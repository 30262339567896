import React from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { PickNullable } from "@interfaces";
import { useNavigate } from "react-router";
import {
  Form,
  useForm,
  Dropdown,
  InputText,
  MultiSelect,
} from "@nubeteck/forms";

import { IAccountCatalogCreate } from "../../interfaces";
import { useAccountingQuery, useAccountingMutation } from "../../hooks";
import {
  GeneralAccountingRoutes,
  GeneralAccountingRouteNames,
} from "../../routes";

interface IAccountCatalogListFormProps {
  open: boolean;
  cuentaId?: number;
  onClose?: () => void;
}

type FormValues = PickNullable<
  IAccountCatalogCreate,
  | "Nombre"
  | "EstadoId"
  | "CatalogoId"
  | "SucursalesIds"
  | "CatalogoTipoId"
  | "SucursalesSelect"
  | "CatalogosCuentasDetalles"
> & { Sucursales: ISucursales[] };

const model = {
  from: (data: IAccountCatalogCreate): FormValues => {
    return {
      Nombre: data.Nombre,
      EstadoId: data.EstadoId,
      CatalogoId: data.CatalogoId,
      Sucursales: data.Sucursales ?? [],
      SucursalesIds: data.SucursalesIds,
      CatalogoTipoId: data.CatalogoTipoId ?? 0,
    };
  },
  to: (data: FormValues): IAccountCatalogCreate => {
    // console.log(data, "data to update");
    return {
      EstadoId: 1,
      Nombre: data.Nombre || "",
      CatalogoId: data.CatalogoId ?? 0,
      Sucursales: data.Sucursales ?? [],
      CatalogoTipoId: data.CatalogoTipoId ?? 0,
      SucursalesIds: data.Sucursales?.map((city) => city.SucursalId) || [],
    };
  },
};

interface ICatalogoType {
  Nombre: string;
  CatalogoTipoId: number;
}

interface ISucursales {
  SucursalId: number;
  SucursalNombre: string;
}

const AccountCatalogListForm = ({
  open,
  onClose,
  cuentaId,
}: IAccountCatalogListFormProps) => {
  const form = useForm<FormValues>({
    defaultValues: {
      Nombre: "",
      CatalogoTipoId: null,
    },
  });
  const navigate = useNavigate();
  const createCatalogo = useAccountingMutation("AccountCatalog", "create");
  const updateCatalogo = useAccountingMutation("AccountCatalog", "update");
  const getCreateAccountCatalog = useAccountingQuery(
    "AccountCatalog",
    "getCreate",
  );

  const { data: accountCatalogData, isFetching: isAccountCatalogFetching } =
    useAccountingQuery("AccountCatalog", "getById", cuentaId, {
      enabled: !!cuentaId,
    });

  const catalogTypeOptions = React.useMemo(() => {
    if (!getCreateAccountCatalog.data?.CatalogosTiposSelect?.length) return [];
    return getCreateAccountCatalog.data?.CatalogosTiposSelect.map(
      (catalogoTipo: ICatalogoType) => ({
        label: catalogoTipo.Nombre,
        value: catalogoTipo.CatalogoTipoId,
      }),
    );
  }, [getCreateAccountCatalog.data?.CatalogosTiposSelect]);
  // console.log(catalogTypeOptions, "catalogTypeOptions");

  const sucursalOptions = React.useMemo(() => {
    if (!getCreateAccountCatalog.data?.SucursalesSelect?.length) return [];
    return getCreateAccountCatalog.data?.SucursalesSelect.map(
      (sucursal: ISucursales) => ({
        SucursalId: sucursal.SucursalId,
        SucursalNombre: sucursal.SucursalNombre,
      }),
    );
  }, [getCreateAccountCatalog.data?.SucursalesSelect]);

  React.useEffect(() => {
    if (cuentaId && accountCatalogData) {
      // Filtrar las sucursales seleccionadas basadas en SucursalesIds
      const selected = sucursalOptions.filter((option: ISucursales) =>
        accountCatalogData.SucursalesIds?.includes(option.SucursalId),
      );

      const formData = model.from({
        ...accountCatalogData,
        Sucursales: selected,
      });

      form.reset(formData, { keepDefaultValues: true });
    }
    if (!cuentaId) {
      form.reset();
      form.resetField("Sucursales");
    }
  }, [form, accountCatalogData, cuentaId, sucursalOptions]);

  const handleOnClose = React.useCallback(() => {
    onClose?.();
  }, [onClose]);

  const onSubmit = (values: FormValues) => {
    if (cuentaId !== 0) {
      return updateCatalogo.mutateAsync(model.to(values), {
        onSuccess: () => {
          form.resetField("Sucursales");
          form.reset();
          handleOnClose();
        },
      });
    }

    return createCatalogo.mutateAsync(model.to(values), {
      //updatedvaues mira ver
      onSuccess: (result) => {
        if (values.CatalogoTipoId === 1) {
          navigate(
            `.${GeneralAccountingRoutes[
              GeneralAccountingRouteNames.ACCOUNT_CATALOG_SIMPLE_PAGE
            ].create({ id: `${result.CatalogoId}` })}`,
          );
        } else {
          navigate(
            `.${GeneralAccountingRoutes[
              GeneralAccountingRouteNames.ACCOUNT_CATALOG_CONSOLIDATE_PAGE
            ].create({ id: `${result.CatalogoId}` })}`,
          );
        }
        form.resetField("Sucursales");
        form.reset();
        handleOnClose();
      },
    });
  };

  return (
    <Dialog
      visible={open}
      header={
        cuentaId === 0
          ? "Crear catalogo de cuenta"
          : "Editar catalogo de cuenta"
      }
      onHide={() => {
        if (!open) return;
        form.resetField("Sucursales");
        handleOnClose();
        form.reset();
      }}
      footer={
        <div className="flex justify-content-end gap-2">
          <Button raised severity="secondary" onClick={handleOnClose}>
            Cerrar
          </Button>
          <Button
            loading={isAccountCatalogFetching}
            onClick={form.handleSubmit(onSubmit)}
          >
            {cuentaId === 0 ? "Crear" : "Editar"}
          </Button>
        </div>
      }
    >
      <Form form={form}>
        <InputText
          id="Nombre"
          name="Nombre"
          label="Nombre"
          placeholder="Nombre"
          className="lg:w-full sm:w-full"
          rules={{ required: "Este campo es necesario" }}
        />
        <Dropdown
          label="Tipo"
          className="w-full"
          name="CatalogoTipoId"
          options={catalogTypeOptions}
          disabledInput={cuentaId !== 0}
          placeholder="Selecciona un tipo"
          rules={{ required: "Este campo es necesario" }}
        />
        <MultiSelect
          id="Sucursales"
          name="Sucursales"
          label="Sucursales"
          className="w-full"
          //value={selectedCities}
          options={sucursalOptions}
          optionLabel="SucursalNombre"
          //onChange={(e) => setSelectedCities(e.value)}
          placeholder="Seleccione al menos una sucursal"
          rules={{ required: "Este campo es necesario" }}
        />
      </Form>
    </Dialog>
  );
};

export default AccountCatalogListForm;
