import clsx from "clsx";
import React from "react";
import { FormRow } from "@components";
import { ArrayUtils } from "@nubeteck/utils";
import { TabView, TabPanel } from "primereact/tabview";
import { Dropdown, useWatch, useFormContext } from "@nubeteck/forms";

import { useAccountingQuery } from "../../../../hooks";
import { IProject, IStrategicPlanDetail } from "../../../../interfaces";

type Props = {
  index: number;
  isFormLoading?: boolean;
};

const ExtraInfo = ({ index, isFormLoading = false }: Props) => {
  const createFormValues = useAccountingQuery(
    "AccountingMovements",
    "getCreate",
  );
  const options = createFormValues.data?.AditionalSelectOptions;
  const form = useFormContext();

  const projectId = useWatch({
    control: form.control,
    name: `MovimientosContablesDetalles.${index}.ProyectoId`,
  });

  const detalleId = useWatch({
    control: form.control,
    name: `MovimientosContablesDetalles.${index}.PlanEstrategicoDetalleId`,
  });

  const costCentersOptions = React.useMemo(() => {
    if (!options?.CentrosCostosSelect?.length) return [];

    return ArrayUtils.selectLabelValue(
      options.CentrosCostosSelect,
      "Nombre",
      "CentroCostoId",
    );
  }, [options?.CentrosCostosSelect]);

  const projectsOptions = React.useMemo(() => {
    if (!options?.ProyectosSelect?.length) return [];

    return ArrayUtils.selectLabelValue(
      options.ProyectosSelect,
      "ProyectoNombre",
      "ProyectoId",
    );
  }, [options?.ProyectosSelect]);

  const detalleOptions = React.useMemo(() => {
    if (!projectId || !options?.ProyectosSelect?.length) {
      return [];
    }

    const selectedProject = options.ProyectosSelect.find(
      (project: IProject) => project.ProyectoId === projectId,
    );

    if (!selectedProject?.PlanEstrategicoDetalle.length) {
      return [];
    }

    return ArrayUtils.selectLabelValue(
      selectedProject.PlanEstrategicoDetalle,
      "Descripcion",
      "DetalleId",
    );
  }, [projectId, options?.ProyectosSelect]);

  const activityOptions = React.useMemo(() => {
    if (!projectId || !detalleId || !options?.ProyectosSelect?.length) {
      return [];
    }

    const selectedProject = options.ProyectosSelect.find(
      (project: IProject) => project.ProyectoId === projectId,
    );

    const selectedDetalle = selectedProject?.PlanEstrategicoDetalle.find(
      (detail: IStrategicPlanDetail) => detail.DetalleId === detalleId,
    );

    if (!selectedDetalle?.Actividades.length) {
      return [];
    }

    return ArrayUtils.selectLabelValue(
      selectedDetalle.Actividades,
      "Actividad",
      "ActividadId",
    );
  }, [projectId, detalleId, options?.ProyectosSelect]);

  return (
    <TabView>
      <TabPanel header="Centro de costo">
        <Dropdown
          showClear
          filterInputAutoFocus
          label="Centro de costo"
          placeholder="Seleccione"
          fieldClassName="w-16rem"
          className="p-inputtext-sm"
          options={costCentersOptions}
          filter={costCentersOptions.length > 5}
          loading={isFormLoading || createFormValues.isPending}
          name={`MovimientosContablesDetalles.${index}.CentroCostoId`}
        />
      </TabPanel>
      <TabPanel header="Proyecto">
        <FormRow>
          <Dropdown
            showClear
            label="Proyecto"
            filterInputAutoFocus
            placeholder="Seleccione"
            fieldClassName="w-16rem"
            options={projectsOptions}
            className="p-inputtext-sm"
            filterPlaceholder="Búsqueda..."
            filter={projectsOptions.length > 5}
            loading={isFormLoading || createFormValues.isPending}
            name={`MovimientosContablesDetalles.${index}.ProyectoId`}
          />
          <Dropdown
            filterInputAutoFocus
            placeholder="Seleccione"
            options={detalleOptions}
            className="p-inputtext-sm"
            label="Objetivo estratégico"
            filterPlaceholder="Búsqueda..."
            filter={detalleOptions.length > 5}
            loading={isFormLoading || createFormValues.isPending}
            name={`MovimientosContablesDetalles.${index}.PlanEstrategicoDetalleId`}
            fieldClassName={clsx("w-16rem", {
              hidden: !projectId || detalleOptions.length === 0,
            })}
          />
          <Dropdown
            label="Actividad"
            filterInputAutoFocus
            placeholder="Seleccione"
            options={activityOptions}
            className="p-inputtext-sm"
            filterPlaceholder="Búsqueda..."
            filter={activityOptions.length > 5}
            loading={isFormLoading || createFormValues.isPending}
            name={`MovimientosContablesDetalles.${index}.ActividadId`}
            fieldClassName={clsx("w-16rem", {
              hidden: !detalleId || activityOptions.length === 0,
            })}
          />
        </FormRow>
      </TabPanel>
    </TabView>
  );
};

export default ExtraInfo;
