import { param, route } from "typesafe-react-router";

export enum CostsCenterRouteNames {
  BUDGET_PAGE = "BUDGET_PAGE",
  PEU_POA_PAGE = "PEU_POA_PAGE",
  CONCEPT_PAGE = "CONCEPT_PAGE",
  REPORTING_PAGE = "REPORTING_PAGE",
  REPORTING_PAGE_ID = "REPORTING_PAGE_ID",
  COSTS_CENTER_PAGE = "COSTS_CENTER_PAGE",
  BUDGET_DETAIL_PAGE = "BUDGET_DETAIL_PAGE",
  POA_ACTIVITIES_PAGE = "POA_ACTIVITIES_PAGE",
  PEU_POA_DETAILS_PAGE = "PEU_POA_DETAILS_PAGE",
}

export const CostsCenterRoutes = {
  [CostsCenterRouteNames.BUDGET_PAGE]: route("budget"),
  [CostsCenterRouteNames.PEU_POA_PAGE]: route("peu-poa"),
  [CostsCenterRouteNames.CONCEPT_PAGE]: route("concept"),
  [CostsCenterRouteNames.REPORTING_PAGE]: route("reporting"),
  [CostsCenterRouteNames.PEU_POA_DETAILS_PAGE]: route(param("id")),
  [CostsCenterRouteNames.POA_ACTIVITIES_PAGE]: route("activities"),
  [CostsCenterRouteNames.COSTS_CENTER_PAGE]: route("costs-center"),
  [CostsCenterRouteNames.REPORTING_PAGE_ID]: route(param("reportId")),
  [CostsCenterRouteNames.BUDGET_DETAIL_PAGE]: route(
    "budget-details",
    param("id"),
  ),
};
