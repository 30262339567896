import clsx from "clsx";
import { Icon } from "@nubeteck/icons";

export const renderCheckIcon = (value: boolean) => (
  <Icon
    touchable={false}
    name={value ? "circle-check-filled" : "circle-x-filled"}
    className={clsx(value ? "text-primary" : "text-red-500")}
  />
);
