import clsx from "clsx";
import React from "react";
import { Row } from "primereact/row";
import { sum, isEmpty } from "radash";
import { Icon } from "@nubeteck/icons";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { useMediaQuery } from "usehooks-ts";
import { ConfirmDialog } from "@components";
import { EventUtils } from "@nubeteck/utils";
import { Skeleton } from "primereact/skeleton";
import { ButtonGroup } from "primereact/buttongroup";
import { ColumnGroup } from "primereact/columngroup";
import { useParams, useNavigate } from "react-router";
import { renderTooltip, FormatterUtils } from "@utils";
import { TabView, TabPanel } from "primereact/tabview";
import { confirmDialog } from "primereact/confirmdialog";
import { Reducers, Statuses, LogEntities, CurrencyCode } from "@core";
import {
  DataTableValueArray,
  DataTableExpandedRows,
} from "primereact/datatable";
import {
  CardTable,
  useHeaderActions,
  IDataTableColumnsProps,
} from "@nubeteck/prime";
import {
  FileTable,
  EmptyMessage,
  ChangeLogDialog,
  AttachmentButton,
  ReferenceDocumentsTable,
} from "@components";

import { AccountingMovementDescription } from "../../components";
import { useAccountingQuery, useAccountingMutation } from "../../hooks";
import {
  IAccountingMovement,
  IAccountingMovementDetail,
} from "../../interfaces";
import {
  GeneralAccountingRoutes,
  GeneralAccountingRouteNames,
} from "../../routes";

const AccountingMovementsDetails = () => {
  const isTablet = useMediaQuery("(min-width: 768px)");
  const { id } = useParams();
  const navigate = useNavigate();
  const { dispatch } = useHeaderActions();
  const [changeLogState, changeLogDispatch] = React.useReducer(
    Reducers.DialogReducer,
    {
      id: 0,
      open: false,
    },
  );
  const {
    data: accountingMovement,
    isPending: isAccountingMovementPending,
    isFetching: isAccountingMovementFetching,
  } = useAccountingQuery("AccountingMovements", "getById", Number(id ?? 0), {
    enabled: !!id,
  });
  const isDraft = accountingMovement?.EstadoId === Statuses.DRAFT_CODE;
  const canBeNullified =
    accountingMovement?.EstadoId !== Statuses.NULLIFIED_CODE;
  const [files, setFiles] = React.useState<number[]>([]);
  const [expandedRows, setExpandedRows] = React.useState<
    undefined | DataTableValueArray | DataTableExpandedRows
  >(undefined);

  const currencyIso =
    accountingMovement?.MonedaNombreISO as keyof typeof CurrencyCode;
  const details = React.useMemo(
    () => accountingMovement?.MovimientosContablesDetalles ?? [],
    [accountingMovement?.MovimientosContablesDetalles],
  );

  const allowExpansion = (rowData: IAccountingMovementDetail) =>
    !isEmpty(rowData.ProyectoNombre?.trim()) ||
    !isEmpty(rowData.CentroCostoNombre?.trim()) ||
    !isEmpty(rowData.ActividadNombre?.trim());

  const rowExpansionTemplate = (data: IAccountingMovementDetail) => {
    const expandedFields = [
      { label: "Centro de costo", value: data.CentroCostoNombre },
      { label: "Proyecto", value: data.ProyectoNombre },
      { label: "Actividad", value: data.ActividadNombre },
    ].filter((field) => Boolean(field.value));
    return (
      <ul className="list-none flex flex-column md:flex-row gap-3 justify-content-center">
        {expandedFields.map((field, index) => (
          <li
            key={index}
            className={clsx(
              "ml-2 pr-4",
              index !== expandedFields.length - 1 &&
                isTablet &&
                "border-300 border-right-1",
            )}
          >
            <strong>{field.label}:</strong> {field.value}
          </li>
        ))}
      </ul>
    );
  };

  const debit = React.useMemo(
    () =>
      FormatterUtils.currency(
        sum(details, (item) => item.Debito),
        {
          currencyIso,
        },
      ),
    [currencyIso, details],
  );

  const credit = React.useMemo(
    () =>
      FormatterUtils.currency(
        sum(details, (item) => item.Credito),
        {
          currencyIso,
        },
      ),
    [currencyIso, details],
  );

  const columns = React.useMemo<
    IDataTableColumnsProps<IAccountingMovementDetail>[]
  >(
    () => [
      {
        expander: allowExpansion,
        field: "DetalleAsientoId",
      },
      {
        sortable: true,
        header: "Cuenta",
        field: "CuentaLabel",
      },
      {
        sortable: true,
        field: "Descripcion",
        header: "Descripción",
        body: (rowData) =>
          rowData.Descripcion ?? (
            <span className="text-400">Sin descripción</span>
          ),
      },
      {
        sortable: true,
        field: "Referencia",
        header: "Referencia",
        body: (rowData) =>
          rowData.Referencia ?? (
            <span className="text-400">Sin referencia</span>
          ),
      },
      {
        sortable: true,
        field: "Debito",
        header: "Débito",
        body: (rowData: IAccountingMovementDetail) =>
          FormatterUtils.currency(rowData.Debito, {
            currencyIso,
          }),
      },
      {
        sortable: true,
        field: "Credito",
        header: "Crédito",
        body: (rowData: IAccountingMovementDetail) =>
          FormatterUtils.currency(rowData.Credito, {
            currencyIso,
          }),
      },
    ],
    [currencyIso],
  );

  React.useEffect(() => {
    if (accountingMovement?.MovimientosContablesAdjuntos?.length) {
      setFiles(accountingMovement.MovimientosContablesAdjuntos);
    }
  }, [accountingMovement]);

  React.useEffect(() => {
    dispatch({
      type: "SET_VARIABLES",
      payload: { sec: accountingMovement?.Secuencia ?? "" },
    });
    return () => dispatch({ type: "CLEAR_VARIABLES" });
  }, [accountingMovement?.Secuencia, dispatch]);

  React.useEffect(() => {
    const actions = (
      <>
        {renderTooltip("Historial de cambios", "changelog")}
        <Icon
          name="book"
          className="changelog align-self-center text-primary mr-1"
          onClick={EventUtils.callEvent(changeLogDispatch, {
            type: "OPEN_DIALOG",
            payload: Number(id ?? 0),
          })}
        />
        {isDraft && (
          <AttachmentButton
            key="ATTACHMENT_BUTTON"
            currentFiles={accountingMovement.MovimientosContablesAdjuntos}
          />
        )}
        {canBeNullified && !isDraft && (
          <NullifyButton
            accountingMovement={accountingMovement}
            isAccountingMovementFetching={isAccountingMovementFetching}
          />
        )}
        <ButtonGroup key="FORM_BUTTONS">
          {canBeNullified && isDraft && (
            <NullifyButton
              accountingMovement={accountingMovement}
              isAccountingMovementFetching={isAccountingMovementFetching}
            />
          )}
          {isDraft && (
            <Button
              size="small"
              label="Editar"
              loading={isAccountingMovementFetching}
              icon={<Icon size={20} name="pencil" className="mr-2" />}
              onClick={() =>
                navigate(
                  `..${GeneralAccountingRoutes[GeneralAccountingRouteNames.EDIT].create({ id: `${accountingMovement.AsientoId}` })}`,
                )
              }
            />
          )}
        </ButtonGroup>
      </>
    );

    dispatch({
      payload: [actions],
      type: "SET_ACTIONS",
    });

    return () => {
      dispatch({ type: "CLEAR_ACTIONS" });
    };
  }, [
    accountingMovement,
    canBeNullified,
    dispatch,
    id,
    isAccountingMovementFetching,
    isDraft,
    navigate,
  ]);

  const renderEmptyMessage = React.useCallback(() => {
    return <EmptyMessage customWidth="100px" message="No hay detalles" />;
  }, []);

  const footerGroup = (
    <ColumnGroup>
      <Row>
        <Column
          colSpan={4}
          footer="Totales:"
          footerStyle={{ textAlign: "right" }}
        />
        <Column footer={debit} />
        <Column footer={credit} />
      </Row>
    </ColumnGroup>
  );

  return (
    <div className="flex flex-column gap-3">
      <AccountingMovementDescription
        accountingMovement={accountingMovement}
        isSkeletonLoading={isAccountingMovementPending}
      />
      {isAccountingMovementPending && <Skeleton width="100%" height="23rem" />}
      {!isAccountingMovementPending && (
        <TabView panelContainerClassName="p-0">
          <TabPanel header="Detalles">
            <CardTable
              type="data"
              showGridlines
              value={details}
              hideSelectFilter
              hideGlobalSearch
              columns={columns}
              tableActions={[]}
              scrollable={true}
              paginatorLeft={true}
              removableSort={true}
              hideFilterSettingsIcon
              scrollHeight="20.5rem"
              style={{ minHeight: 296 }}
              dataKey="DetalleAsientoId"
              expandedRows={expandedRows}
              footerColumnGroup={footerGroup}
              emptyMessage={renderEmptyMessage}
              rowsPerPageOptions={[8, 16, 32, 64]}
              paginator={(details?.length ?? 0) > 8}
              rowExpansionTemplate={rowExpansionTemplate}
              onRowToggle={(e) => setExpandedRows(e.data)}
            />
          </TabPanel>
          <TabPanel header="Archivos adjuntos">
            <FileTable files={files} setFiles={setFiles} />
          </TabPanel>
          <TabPanel header="Documentos de referencia">
            <ReferenceDocumentsTable id={Number(id ?? 0)} />
          </TabPanel>
        </TabView>
      )}
      <ConfirmDialog closable={false} />
      <ChangeLogDialog
        entityId={changeLogState.id}
        visible={changeLogState.open}
        entityName={LogEntities.ACCOUNTING_MOVEMENT}
        onHide={EventUtils.callEvent(changeLogDispatch, {
          type: "CLOSE_DIALOG",
        })}
      />
    </div>
  );
};

interface INullifyButtonProps {
  isAccountingMovementFetching: boolean;
  accountingMovement?: IAccountingMovement;
}

const NullifyButton = ({
  accountingMovement,
  isAccountingMovementFetching,
}: INullifyButtonProps) => {
  const nullifyAccountingMovement = useAccountingMutation(
    "AccountingMovements",
    "delete",
  );

  const handleAnularAccept = React.useCallback(async () => {
    await nullifyAccountingMovement.mutateAsync(
      accountingMovement?.AsientoId ?? 0,
    );
  }, [accountingMovement?.AsientoId, nullifyAccountingMovement]);

  const handleAnular = React.useCallback(() => {
    confirmDialog({
      acceptLabel: "Anular",
      rejectLabel: "Cancelar",
      accept: handleAnularAccept,
      message: "Esta acción no podrá deshacerse.",
      header: `¿Estás seguro(a) de anular el asiento ${accountingMovement?.Secuencia}?`,
    });
  }, [accountingMovement?.Secuencia, handleAnularAccept]);

  return (
    <Button
      size="small"
      label="Anular"
      key="BTN_TRASH"
      severity="danger"
      onClick={handleAnular}
      loading={isAccountingMovementFetching}
      icon={<Icon size={20} name="trash" className="mr-2" />}
    />
  );
};

export default AccountingMovementsDetails;
