import React from "react";
import { toast } from "sonner";

const useToast = () => {
  const [identifier, setIdentifier] = React.useState<string | number>("");

  const loading = (
    message: string = "Espere mientras se completa la acción...",
    id?: string,
  ) => {
    const notificationId = toast.loading(message, { id });
    if (identifier !== notificationId) setIdentifier(notificationId);
    return notificationId;
  };

  const success = (message: string, id?: string) =>
    toast.success(message, { duration: 2000, id: id || identifier });

  const error = (message: string, id?: string) =>
    toast.error(message, { duration: 3000, id: id || identifier });

  return { error, loading, success };
};

export default useToast;
