import API from "@lib/api";
import { BaseApi } from "@api";
import {
  Messages,
  IStrategicPlansDetailsTypes,
  IStrategicPlansDetailsTypesGetForCreate,
  IStrategicPlansDetailsTypesGetForUpdate,
} from "@interfaces";

export class StrategicPlansDetailsTypesApi extends BaseApi {
  protected static api = API({ namespace: "PlanesEstrategicos" });
  protected static messages: Messages<typeof StrategicPlansDetailsTypesApi> = {
    create: {
      error: (error) => error,
      loading: "Creando nuevo tipo de detalle...",
      success: "Tipo de detalle creado correctamente.",
    },
    update: {
      error: (error) => error,
      loading: "Actualizando tipo de detalle...",
      success: "Tipo de detalle actualizado correctamente.",
    },
    changeState: {
      error: (error) => error,
      loading: "Actualizando estado del tipo de detalle...",
      success: "Estado del tipo de detalle actualizado correctamente.",
    },
  };

  public static async getAll() {
    const result =
      await this.api.get<IStrategicPlansDetailsTypes[]>("GetAllDetalleTipo");
    return result.data;
  }

  public static async getById(id: number) {
    const result = await this.api.get<IStrategicPlansDetailsTypes>(
      `GetDetalleTipoById/${id}`,
    );
    return result.data;
  }

  public static async create(data: IStrategicPlansDetailsTypes) {
    const result = await this.api.post<IStrategicPlansDetailsTypes>(
      "AddPlanEstrategicoDetalleTipo",
      data,
    );
    return result.data;
  }

  public static async getCreate() {
    const result = await this.api.get<IStrategicPlansDetailsTypesGetForCreate>(
      "GetCreatePlanEstrategicoDetalleTipo",
    );
    return result.data;
  }

  public static async update(data: Partial<IStrategicPlansDetailsTypes>) {
    const result = await this.api.put(
      `UpdatePlanEstrategicoDetalleTipo/${data.DetalleTipoId}`,
      data,
    );
    return result.data;
  }

  public static async getUpdate(id: number) {
    const result = await this.api.get<IStrategicPlansDetailsTypesGetForUpdate>(
      `GetUpdatePlanEstrategicoDetalleTipo/${id}`,
    );
    return result.data;
  }

  public static async changeState(data: {
    DetalleTipoId: number;
    EstadoId: number;
  }) {
    const result = await this.api.post(
      "ChangeStatePlanEstrategicoDetalleTipo",
      data,
    );
    return result.data;
  }
}
