import API from "@lib/api";
import { ILog, Messages } from "@interfaces";

import { BaseApi } from "./base-api";

export class LogsApi extends BaseApi {
  protected static api = API({ namespace: "Logs" });
  protected static messages: Messages<typeof LogsApi> = {};

  public static async getAll() {
    const logs = await this.api.get<ILog[]>("GetAll");
    return logs.data;
  }

  public static async getById(id: number) {
    const log = await this.api.get<ILog>(`GetById/${id}`);
    return log.data;
  }

  public static async getByEntityAndQuery(data: {
    Id: number;
    Id2?: number;
    Entity: string;
    Entity2?: string;
  }) {
    const log = await this.api.post<ILog[]>("GetLogsByEntityAndIdQuery", data);
    return log.data;
  }
}
