import { convertToDate } from "@utils";
import { Datejs } from "@nubeteck/utils";
import { sum, omit, capitalize } from "radash";

import { IAccountingMovementForm } from "../interfaces";

interface Model<I, C = I> {
  to?: (data: C) => I | null;
  from?: (data: I) => C | null;
}

const ModelAccountingMovement: Model<
  IAccountingMovementForm,
  IAccountingMovementForm
> = {
  from: (data) => {
    if (!data) return null;

    return omit(
      {
        ...data,
        Fecha: convertToDate(data.Fecha),
        TasaCambioFecha: convertToDate(data.TasaCambioFecha),
        FechaCreacion: capitalize(
          Datejs(convertToDate(data.FechaCreacion)).format(
            "dddd D [de] MMMM [del] YYYY, h:mm A",
          ),
        ),
      },
      ["SelectOptions"],
    );
  },
  to: (data) => {
    if (!data) return null;

    const TotalDebitos = sum(
      data.MovimientosContablesDetalles,
      (item) => item.Debito,
    );

    const TotalCreditos = sum(
      data.MovimientosContablesDetalles,
      (item) => item.Credito,
    );

    return {
      TotalDebitos,
      TotalCreditos,
      Secuencia: "", // Autogenerada
      Tasa: data.Tasa,
      Fecha: data.Fecha,
      EstadoId: data.EstadoId,
      MonedaId: data.MonedaId,
      AsientoId: data.AsientoId,
      SucursalId: data.SucursalId,
      Referencia: data.Referencia,
      Observaciones: data.Observaciones,
      MovimientosContablesAdjuntos: data.MovimientosContablesAdjuntos,
      TasaCambioFecha: Datejs(data.TasaCambioFecha).format("YYYY-MM-DD"),
      MovimientosContablesDetalles: data.MovimientosContablesDetalles.map(
        (item) => ({
          Debito: item.Debito ?? 0,
          AsientoId: data.AsientoId,
          Credito: item.Credito ?? 0,
          Descripcion: item.Descripcion,
          ProyectoId: item.ProyectoId ?? null,
          ActividadId: item.ActividadId ?? null,
          CuentaContableId: item.CuentaContableId,
          DetalleAsientoId: item.DetalleAsientoId,
          CentroCostoId: item.CentroCostoId ?? null,
        }),
      ),
    };
  },
};

export default ModelAccountingMovement;
