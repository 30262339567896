import { omit } from "radash";
import { convertToDate } from "@utils";

import { IPurchaseInvoiceForm } from "../interfaces";

interface Model<I, C = I> {
  to?: (data: C) => I | null;
  from?: (data: I) => C | null;
}

const ModelPurchaseInvoice: Model<IPurchaseInvoiceForm, IPurchaseInvoiceForm> =
  {
    to: (data) => {
      if (!data) return null;
      return data;
    },
    from: (data) => {
      if (!data) return null;

      return omit(
        {
          ...data,
          Fecha: convertToDate(data.Fecha),
          TasaFecha: convertToDate(data.TasaFecha),
          FechaVencimientoNCF: convertToDate(data.FechaVencimientoNCF),
          FechaLimiteDescuento: convertToDate(data.FechaLimiteDescuento),
        },
        ["SelectOptions"],
      );
    },
  };

export default ModelPurchaseInvoice;
