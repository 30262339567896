import { toast } from "sonner";
import { useNavigate } from "react-router";

import { Constants } from "../core";
import { IPurchaseDocument } from "../interfaces";
import { AccountsPayableRoutes, AccountsPayableRouteNames } from "../routes";

/**
 * A custom hook that provides a function to navigate to purchase details based on the document type.
 *
 * @returns {Function} A function that takes an IPurchaseDocument and navigates to the appropriate detail page.
 */
const useNavigateToPurchaseDetails = () => {
  const navigate = useNavigate();

  /**
   * Navigates to the appropriate purchase detail page based on the document type.
   *
   * @param {IPurchaseDocument} rowData - The purchase document data.
   */
  return (rowData: IPurchaseDocument) => {
    /**
     * A mapping of document type IDs to their corresponding route names.
     */
    const routeMap = {
      [Constants.PURCHASE_DOC_TYPES.ORDER]:
        AccountsPayableRouteNames.PURCHASE_ORDER_DETAIL,
      [Constants.PURCHASE_DOC_TYPES.INVOICE]:
        AccountsPayableRouteNames.PURCHASE_INVOICE_DETAIL,
      [Constants.PURCHASE_DOC_TYPES.DELIVERY_NOTE]:
        AccountsPayableRouteNames.PURCHASE_DELIVERY_NOTE_DETAIL,
    };

    const routeName =
      routeMap[rowData.TipoDocumentoId as keyof typeof routeMap];

    if (routeName) {
      navigate(
        `.${AccountsPayableRoutes[routeName].create({
          id: `${rowData.DocumentoId}`,
        })}`,
      );
    } else {
      toast.error(`ID de documento inválido: ${rowData.DocumentoId}`, {
        dismissible: true,
        closeButton: true,
        style: { fontSize: 14 },
      });
    }
  };
};

export default useNavigateToPurchaseDetails;
