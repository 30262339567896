import clsx from "clsx";
import React from "react";
import { Button } from "primereact/button";
import { Sidebar } from "primereact/sidebar";
import { Divider } from "primereact/divider";
import { HeaderIcon } from "@nubeteck/prime";
import { Skeleton } from "primereact/skeleton";
import { Form, Dropdown, InputText } from "@components";
import { ArrayUtils, EventUtils } from "@nubeteck/utils";
import { useForm, useFieldArray } from "react-hook-form";

import { useAccountingQuery, useAccountingMutation } from "../../hooks";
import {
  IFinancialStatement,
  IFinancialStatementUpdate,
} from "../../interfaces";

type FormValues = IFinancialStatement;

export interface IFinancialStatementDivisionDrawerProps {
  id?: number;
  open: boolean;
  onHide: () => void;
}

const model = {
  to: (data: FormValues) => {
    return {
      EstadoNombre: "",
      Nombre: data.Nombre,
      EstadoId: data.EstadoId ?? 1,
      TipoEstadoFinancieroNombre: "",
      EstadoFinancieroId: data.EstadoFinancieroId,
      Divisiones: data.EstadoFinancieroDivisiones ?? [],
      TipoEstadoFinancieroId: data.TipoEstadoFinancieroId,
    };
  },
  from: (data: IFinancialStatementUpdate): FormValues => {
    return {
      EstadoNombre: "",
      Nombre: data.Nombre,
      EstadoId: data.EstadoId ?? 1,
      TipoEstadoFinancieroNombre: "",
      EstadoFinancieroId: data.EstadoFinancieroId,
      EstadoFinancieroDivisiones: data.Divisiones ?? [],
      TipoEstadoFinancieroId: data.TipoEstadoFinancieroId,
    };
  },
};

const FinancialStatementDivisionDrawer = ({
  id,
  open,
  onHide,
}: IFinancialStatementDivisionDrawerProps) => {
  const createFinancial = useAccountingMutation("FinancialStatement", "create");
  const updateFinancial = useAccountingMutation("FinancialStatement", "update");
  const getCreateFinancial = useAccountingQuery(
    "FinancialStatement",
    "getCreate",
  );
  const getUpdateFinancial = useAccountingQuery(
    "FinancialStatement",
    "getUpdate",
    id,
    { enabled: !!id },
  );

  const form = useForm<FormValues>({
    defaultValues: {
      Nombre: "",
      EstadoId: 1,
      EstadoFinancieroId: undefined,
      TipoEstadoFinancieroId: undefined,
      EstadoFinancieroDivisiones: [
        {
          Codigo: "",
          Nombre: "",
          EstadoFinancieroId: undefined,
          EstadoFinancieroDivisionId: undefined,
        },
      ],
    },
  });
  const { reset } = form;

  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: "EstadoFinancieroDivisiones",
  });

  React.useEffect(() => {
    if (getUpdateFinancial.data) {
      reset(model.from(getUpdateFinancial.data), {
        keepDefaultValues: true,
      });
    }
  }, [reset, getUpdateFinancial.data]);

  const renderSection = (index: number) => {
    const name = `EstadoFinancieroDivisiones.${index}`;

    return (
      <div className="flex gap-2 align-items-center">
        <InputText
          label="Código"
          name={`${name}.Codigo`}
          className="p-inputtext-sm"
          placeholder="Ingresa el código"
          rules={{ required: "Campo requerido" }}
        />
        <InputText
          label="Nombre"
          name={`${name}.Nombre`}
          className="p-inputtext-sm"
          placeholder="Ingresa un nombre"
          rules={{ required: "Campo requerido" }}
        />
        {fields.length >= 2 && (
          <div className="mt-2">
            <HeaderIcon
              size={26}
              name="trash"
              className="text-red-500"
              onClick={EventUtils.callEvent(remove, index)}
            />
          </div>
        )}
      </div>
    );
  };

  const handleOnHide = () => {
    form.reset();
    onHide();
  };

  const onSubmit = (values: FormValues) => {
    if (id) {
      return updateFinancial.mutateAsync(model.to(values), {
        onSuccess: handleOnHide,
      });
    }
    return createFinancial.mutateAsync(model.to(values), {
      onSuccess: handleOnHide,
    });
  };

  const financialStatementTypeOptions = React.useMemo(() => {
    if (!getCreateFinancial.data?.TiposEstadosFinancierosSelect?.length)
      return [];
    return ArrayUtils.selectLabelValue(
      getCreateFinancial.data.TiposEstadosFinancierosSelect ?? [],
      "Nombre",
      "TipoEstadoFinancieroId",
    );
  }, [getCreateFinancial.data?.TiposEstadosFinancierosSelect]);

  return (
    <Sidebar
      visible={open}
      position="right"
      onHide={id ? handleOnHide : onHide}
      className="w-full sm:w-9 lg:w-5 p-sidebar-custom"
      header={
        <div className="flex flex-column gap-2 flex-wrap md:flex-row md:align-items-center justify-content-between">
          <div>
            <div className="text-xl font-semibold text-800">
              {id ? "Editando" : "Nuevo"} estado financiero
            </div>
            <div className="font-medium text-sm p-text-secondary">
              {id ? "Editando una division " : "Agregando una nueva division "}
              de estado financiero
            </div>
          </div>
        </div>
      }
    >
      {id && getUpdateFinancial.isPending ? (
        <div className="px-3">
          <div className="mb-3 flex flex-column gap-1">
            <Skeleton width="40%" height="0.8rem" />
            <Skeleton width="100%" height="2.5rem" />
          </div>
          <div className="mb-3 flex flex-column gap-1">
            <Skeleton width="40%" height="0.8rem" />
            <Skeleton width="100%" height="2.5rem" />
          </div>
          <Skeleton width="100%" height="10rem" />
        </div>
      ) : (
        <Form form={form} className="flex flex-column h-full">
          <div className="flex-1 overflow-auto">
            <div className="flex flex-column px-3">
              <InputText
                name="Nombre"
                className="p-inputtext-sm"
                placeholder="Ingresa un nombre"
                label="Nombre del estado financiero"
                rules={{ required: "Campo requerido" }}
              />
              <Dropdown
                className="p-inputtext-sm"
                name="TipoEstadoFinancieroId"
                placeholder="Seleccione un tipo"
                label="Tipo de estado financiero"
                loading={getCreateFinancial.isLoading}
                options={financialStatementTypeOptions}
                rules={{ required: "Campo requerido" }}
              />
            </div>
            <Divider className="mb-2 mt-0">
              <span className="text-sm font-semibold">Divisiones</span>
            </Divider>
            <div
              className={clsx(
                "overflow-auto mt-3 px-3",
                fields.length ? "" : "hidden",
              )}
            >
              {fields.map((field, index) => {
                return (
                  <React.Fragment key={`${field.id}${index + 1}`}>
                    {renderSection(index)}
                  </React.Fragment>
                );
              })}
            </div>
            <div className="flex flex-column md:flex-row px-3 gap-2">
              <Button
                size="small"
                type="button"
                className="h-auto w-auto"
                label="Agregar otra division"
                onClick={() =>
                  append({
                    Codigo: "",
                    Nombre: "",
                    EstadoFinancieroId: 0,
                    EstadoFinancieroDivisionId: 0,
                  })
                }
              />
            </div>
          </div>
          <div className="flex flex-column border-top-1 surface-border md:flex-row justify-content-end p-3 gap-2">
            <div>
              <Button
                size="small"
                type="button"
                label="Cancelar"
                severity="secondary"
                onClick={handleOnHide}
              />
            </div>
            <div>
              <Button
                size="small"
                label={id ? "Guardar" : "Agregar"}
                onClick={form.handleSubmit(onSubmit)}
              />
            </div>
          </div>
        </Form>
      )}
    </Sidebar>
  );
};

export default FinancialStatementDivisionDrawer;
