import React from "react";
import { IStrategicPlansDetailsFields } from "@interfaces";
import {
  Dropdown,
  InputText,
  InputNumber,
  MultiSelect,
  SelectButton,
  InputTextarea,
} from "@components";

import { SettingDataTypes } from "../../core";

export interface IAdditionalFieldInputProps {
  prefix: string;
  data: IStrategicPlansDetailsFields;
}

const AdditionalFieldInput = ({ data, prefix }: IAdditionalFieldInputProps) => {
  const options = React.useMemo(() => {
    return data.Opciones?.Configuracion?.split("\n").reduce<
      { label: string; value: number }[]
    >((acc, config) => {
      if (!config) return acc;

      const configSplitted = config?.split(",") ?? [];

      return [
        ...acc,
        { label: configSplitted[0], value: parseInt(configSplitted[1]) },
      ];
    }, []);
  }, [data.Opciones?.Configuracion]);

  const rules = React.useMemo(() => {
    try {
      return JSON.parse(data.Rules ?? "{}");
    } catch (error) {
      return {};
    }
  }, [data.Rules]);

  const inputProps = React.useMemo(
    () => ({
      label: data.Nombre,
      helperText: data.Descripcion,
      name: `${prefix}.Campo${data.CampoId}`,
      rules: { required: data.Requerido, ...rules },
    }),
    [
      data.CampoId,
      data.Descripcion,
      data.Nombre,
      data.Requerido,
      prefix,
      rules,
    ],
  );

  const mapping = React.useMemo<Record<number, React.JSX.Element>>(
    () => ({
      [SettingDataTypes.NUMBER]: (
        <InputNumber
          {...inputProps}
          className="p-inputtext-sm"
          placeholder="Ingresa un numero"
        />
      ),
      [SettingDataTypes.SHORT_TEXT]: (
        <InputText
          {...inputProps}
          type="text"
          className="p-inputtext-sm"
          placeholder="Escribe un texto"
        />
      ),
      [SettingDataTypes.LONG_TEXT]: (
        <InputTextarea
          {...inputProps}
          rows={2}
          className="p-inputtext-sm"
          placeholder="Escribe una descripción"
        />
      ),
      [SettingDataTypes.BOOLEAN]: (
        <SelectButton
          {...inputProps}
          defaultValue="False"
          className="w-14rem p-selectbutton-sm"
          options={[
            { value: "True", label: "Habilitar" },
            { value: "False", label: "Deshabilitar" },
          ]}
        />
      ),
      [SettingDataTypes.OPTIONS]: data.Opciones?.PermitirVarias ? (
        <MultiSelect
          {...inputProps}
          options={options}
          placeholder="Selecciona una opción"
        />
      ) : (
        <Dropdown
          {...inputProps}
          options={options}
          className="p-inputtext-sm"
          placeholder="Selecciona una opción"
        />
      ),
    }),
    [data.Opciones?.PermitirVarias, inputProps, options],
  );

  return (
    mapping[data.TipoDatoId] ?? `[Este configuración no esta siendo manejada]`
  );
};

export default AdditionalFieldInput;
