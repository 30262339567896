import React from "react";
import { Icon } from "@nubeteck/icons";
import { Reducers, Statuses } from "@core";
import { Button } from "primereact/button";
import { IStrategicPlans } from "@interfaces";
import { FilterMatchMode } from "primereact/api";
import { Link, useNavigate } from "react-router";
import { useAuthPermissions } from "@nubeteck/auth";
import { confirmDialog } from "primereact/confirmdialog";
import { useGlobalQuery, useGlobalMutation } from "@hooks";
import { Datejs, ArrayUtils, EventUtils } from "@nubeteck/utils";
import { EmptyMessage, ConfirmDialog, FiltersDrawer } from "@components";
import {
  StatusTag,
  CardTable,
  PaginatorState,
  IDataTableColumnsProps,
} from "@nubeteck/prime";

import { Permissions } from "../../core";
import { PeiDialog } from "../../components";
import { PeiRoutes, PeiRouteNames } from "../../routes";

const AREA_FILTER_DEFAULT = "fcPlanesEstrategicosBuscar";

type IStrategicPlansFormatted = Omit<
  IStrategicPlans,
  "FechaFin" | "FechaInicio"
> & { FechaInicio: Date; FechaFin: Date };

const PeiPage = () => {
  const navigate = useNavigate();
  const permissions = useAuthPermissions();

  const changeStatePlan = useGlobalMutation("StrategicPlans", "changeState");
  const { data: filters, isPending: isFiltersPending } = useGlobalQuery(
    "Filters",
    "getAllByArea",
    AREA_FILTER_DEFAULT,
  );

  const [filterSettingsVisible, setFilterSettingsVisible] =
    React.useState(false);
  const [filterSelected, setFilterSelected] = React.useState<
    number | undefined
  >();
  const [paginator, setPaginator] = React.useState<PaginatorState>({
    page: 0,
    first: 0,
    rows: 10,
  });
  const [state, dispatch] = React.useReducer(Reducers.DialogReducer, {
    id: 0,
    open: false,
  });

  const strategicPlans = useGlobalQuery(
    "StrategicPlans",
    "getAllByFilter",
    {
      page: paginator.page,
      pageSize: paginator.rows,
      filterId: filterSelected ?? 0,
    },
    { enabled: !!filterSelected },
  );

  React.useEffect(() => {
    const defaultFilterId = filters?.find(
      (filter) => filter.EsPorDefecto,
    )?.FiltroId;
    setFilterSelected(defaultFilterId);
  }, [filters]);

  const handleRestore = React.useCallback(
    (id: number) => {
      confirmDialog({
        acceptLabel: "Activar",
        header: "Activar plan estratégico",
        acceptClassName: "p-button-sm p-button-success",
        message: "¿Está seguro de que desea activar este plan estratégico?",
        accept: async () => {
          await changeStatePlan.mutateAsync({
            PlanId: id,
            EstadoId: Statuses.ACTIVE_CODE,
          });
        },
      });
    },
    [changeStatePlan],
  );

  const handleNullify = React.useCallback(
    (id: number) => {
      confirmDialog({
        acceptLabel: "Anular",
        header: "Anular plan estratégico",
        message: "¿Está seguro de que desea anular este plan estratégico?",
        accept: async () => {
          await changeStatePlan.mutateAsync({
            PlanId: id,
            EstadoId: Statuses.NULLIFIED_CODE,
          });
        },
      });
    },
    [changeStatePlan],
  );

  const renderEmptyMessage = React.useCallback(() => {
    return (
      <EmptyMessage message="No hay planes estratégicos institucionales agregados" />
    );
  }, []);

  const hasAccessDetails = permissions?.hasPermission(
    Permissions.SEE_PEI_DETAILS_PAGE,
  );

  const statusesOptions = React.useMemo(() => {
    if (!strategicPlans.data?.Data.length) return [];
    return ArrayUtils.selectLabelValue(
      strategicPlans.data.Data,
      "EstadoNombre",
      "EstadoNombre",
    );
  }, [strategicPlans.data?.Data]);

  const columns: IDataTableColumnsProps<IStrategicPlansFormatted>[] = [
    {
      filter: true,
      sortable: true,
      field: "Nombre",
      header: "Nombre",
      dataType: "text",
      style: { minWidth: "200px" },
      filterMatchMode: FilterMatchMode.CONTAINS,
      body: (data) => {
        if (!hasAccessDetails) return data.Nombre;
        return (
          <Link
            className="no-underline"
            to={`.${PeiRoutes[PeiRouteNames.PEI_DETAILS].create({
              id: `${data?.PlanEstrategicoId}`,
            })}`}
          >
            {data?.Nombre}
          </Link>
        );
      },
    },
    {
      filter: true,
      sortable: true,
      dataType: "date",
      field: "FechaInicio",
      header: "Fecha de Inicio",
      style: { minWidth: "200px" },
      filterMatchMode: FilterMatchMode.DATE_IS,
      body: (data) => Datejs(data?.FechaInicio).format("L"),
    },
    {
      filter: true,
      sortable: true,
      dataType: "date",
      field: "FechaFin",
      header: "Fecha final",
      style: { minWidth: "200px" },
      filterMatchMode: FilterMatchMode.DATE_IS,
      body: (data) => Datejs(data?.FechaFin).format("L"),
    },
    {
      filter: true,
      header: "Estado",
      dataType: "select",
      field: "EstadoNombre",
      style: { width: "200px" },
      filterOptions: statusesOptions,
      filterMatchMode: FilterMatchMode.EQUALS,
      body: (data) => <StatusTag status={data?.EstadoNombre ?? "Activo"} />,
    },
  ];

  const filtersOptions = React.useMemo(() => {
    if (!filters?.length) return [];
    return ArrayUtils.selectLabelValue(filters ?? [], "Nombre", "FiltroId");
  }, [filters]);

  const value = React.useMemo(() => {
    if (!strategicPlans.data?.Data.length) return [];
    return strategicPlans.data?.Data.map((plan) => ({
      ...plan,
      FechaFin: Datejs(plan.FechaFin).toDate(),
      FechaInicio: Datejs(plan.FechaInicio).toDate(),
    }));
  }, [strategicPlans.data?.Data]);

  return (
    <>
      <CardTable<IStrategicPlansFormatted>
        type="data"
        value={value}
        columns={columns}
        enableFilterPagination
        actionMenu="non-float"
        dataKey="PlanEstrategicoId"
        valueFilter={filterSelected}
        filterOptions={filtersOptions}
        emptyMessage={renderEmptyMessage()}
        isLoadingSearchText={isFiltersPending}
        title="Planes estratégicos institucional"
        onRefresh={() => strategicPlans.refetch()}
        totalRecords={strategicPlans.data?.TotalRecords}
        onPaginationChange={(event) => setPaginator(event)}
        loading={strategicPlans.isFetching || isFiltersPending}
        onSelectFilterOption={(value) => setFilterSelected(value)}
        onClickFilterSettings={EventUtils.callEvent(
          setFilterSettingsVisible,
          true,
        )}
        headActions={[
          <Button
            size="small"
            key="NewStrategicPlan"
            label="Agregar plan estratégico"
            loading={strategicPlans.isPending}
            icon={<Icon size={20} className="mr-1" name="script-plus" />}
            disabled={!permissions?.hasPermission(Permissions.CREATE_PEI)}
            onClick={EventUtils.callEvent(dispatch, {
              payload: 0,
              type: "OPEN_DIALOG",
            })}
          />,
        ]}
        tableActions={(data) => {
          const isDraft = data.EstadoId === Statuses.DRAFT_CODE;
          const isNullified = data.EstadoId === Statuses.NULLIFIED_CODE;
          return [
            {
              label: "Detalles",
              icon: "list-details",
              disabled: !hasAccessDetails,
              onClick: () => {
                navigate(
                  `.${PeiRoutes[PeiRouteNames.PEI_DETAILS].create({
                    id: `${data.PlanEstrategicoId}`,
                  })}`,
                );
              },
            },
            {
              icon: "pencil",
              label: "Editar",
              disabled:
                !isDraft || !permissions?.hasPermission(Permissions.EDIT_PEI),
              onClick: EventUtils.callEvent(dispatch, {
                type: "OPEN_DIALOG",
                payload: data.PlanEstrategicoId,
              }),
            },
            {
              icon: "ban",
              label: "Anular",
              iconClassName: "text-red-500",
              onClick: EventUtils.callEvent(
                handleNullify,
                data.PlanEstrategicoId,
              ),
              disabled:
                isDraft ||
                isNullified ||
                !permissions?.hasPermission(Permissions.NULLIFY_PEI),
            },
            {
              icon: "plug",
              label: "Activar",
              iconClassName: "text-primary-500",
              disabled:
                !isNullified ||
                !permissions?.hasPermission(Permissions.RESTORE_PEI),
              onClick: EventUtils.callEvent(
                handleRestore,
                data.PlanEstrategicoId,
              ),
            },
          ];
        }}
      />
      <PeiDialog
        visible={state.open}
        strategicPlanId={state.id}
        onHide={EventUtils.callEvent(dispatch, { type: "CLOSE_DIALOG" })}
      />
      <FiltersDrawer
        area={AREA_FILTER_DEFAULT}
        visible={filterSettingsVisible}
        onHide={EventUtils.callEvent(setFilterSettingsVisible, false)}
      />
      <ConfirmDialog />
    </>
  );
};

export default PeiPage;
