import React from "react";
import { PathUtils } from "@utils";
import { mapEntries } from "radash";
import { useForm } from "react-hook-form";
import { Button } from "primereact/button";
import { Skeleton } from "primereact/skeleton";
import { Form, ReportInput } from "@components";
import { Datejs, JsxUtil } from "@nubeteck/utils";
import { useGetParams, useGlobalQuery } from "@hooks";
import { Card, useHeaderActions } from "@nubeteck/prime";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type FormValues = Record<string, any>;

const ReportingPage = () => {
  const reportId = useGetParams("reportId");
  const { dispatch } = useHeaderActions();

  const report = useGlobalQuery("Reports", "getById", reportId, {
    enabled: !!reportId,
  });

  const form = useForm<FormValues>();
  const { reset } = form;

  React.useEffect(() => {
    if (report.data) {
      reset(
        report.data.ReportesParametros?.reduce(
          (acc, parameter) => {
            acc[parameter.ReporteParametroCampo] = null;
            return acc;
          },
          {} as Record<string, null>,
        ),
      );
    }
  }, [report.data, reset]);

  React.useEffect(() => {
    dispatch({
      type: "SET_VARIABLES",
      payload: { reportName: report.data?.ReporteNombre || "..." },
    });
    return () => dispatch({ type: "CLEAR_VARIABLES" });
  }, [dispatch, report.data?.ReporteNombre]);

  const onSubmit = (values: FormValues) => {
    const path = PathUtils.getReportViewUrl(
      report.data!.ReporteArchivoNombre,
      mapEntries(values, (key, value) => {
        const keyFormatted = `Record[${key}]`;
        if (value instanceof Date) {
          return [keyFormatted, Datejs(value).format("DD/MM/YYYY")];
        }
        return [keyFormatted, value];
      }),
    );
    window.open(path);
  };

  if (report.isFetched && report.isError) {
    throw new Error(
      "Hubo un error al cargar los datos del reporte. Compruebe su conexión o si existe este reporte.",
    );
  }

  return (
    <Card
      title={report.data?.ReporteNombre ?? ""}
      subtitle="Rellena los datos requeridos y luego haz clic en generar reporte."
      actions={[
        <Button
          size="small"
          key="ResetForm"
          severity="warning"
          onClick={form.reset}
          label="Reiniciar formulario"
        />,
        <Button
          size="small"
          key="GenerateReport"
          label="Generar reporte"
          onClick={form.handleSubmit(onSubmit)}
        />,
      ]}
    >
      <Form form={form}>
        {JsxUtil.renderLoader(
          report.isPending,
          <div className="flex flex-column gap-2">
            <Skeleton width="100%" height="3.5rem" />
            <Skeleton width="100%" height="3.5rem" />
          </div>,
        )(
          report.data?.ReportesParametros?.map((parameter) => {
            return (
              <ReportInput
                parameter={parameter}
                key={parameter.ReporteParametroId}
              />
            );
          }),
        )}
      </Form>
    </Card>
  );
};

export default ReportingPage;
